export default {
    'a-minuscule': {
        backgroundImagePath: 'a-minuscule.png',
        steps: [
            {
                imagePath: 'a-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 609,
                        y: 392
                    },
                    {
                        x: 457,
                        y: 287
                    },
                    {
                        x: 396,
                        y: 298
                    },
                    {
                        x: 343,
                        y: 328
                    },
                    {
                        x: 305,
                        y: 376
                    },
                    {
                        x: 285,
                        y: 430
                    },
                    {
                        x: 275,
                        y: 486
                    },
                    {
                        x: 277,
                        y: 543
                    },
                    {
                        x: 289,
                        y: 597
                    },
                    {
                        x: 313,
                        y: 648
                    },
                    {
                        x: 352,
                        y: 687
                    },
                    {
                        x: 403,
                        y: 715
                    },
                    {
                        x: 461,
                        y: 720
                    },
                    {
                        x: 518,
                        y: 706
                    },
                    {
                        x: 570,
                        y: 674
                    },
                    {
                        x: 609,
                        y: 630
                    },
                    {
                        x: 635,
                        y: 580
                    },
                    {
                        x: 647,
                        y: 527
                    },
                    {
                        x: 648,
                        y: 473
                    },
                    {
                        x: 648,
                        y: 424
                    },
                    {
                        x: 648,
                        y: 370
                    },
                    {
                        x: 648,
                        y: 298
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'a-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 648,
                        y: 289
                    },
                    {
                        x: 648,
                        y: 489
                    },
                    {
                        x: 648,
                        y: 540
                    },
                    {
                        x: 648,
                        y: 591
                    },
                    {
                        x: 648,
                        y: 642
                    },
                    {
                        x: 648,
                        y: 709
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'a-majuscule': {
        backgroundImagePath: 'a-majuscule.png',
        steps: [
            {
                imagePath: 'a-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 231,
                        y: 697
                    },
                    {
                        x: 304,
                        y: 519
                    },
                    {
                        x: 321,
                        y: 471
                    },
                    {
                        x: 339,
                        y: 422
                    },
                    {
                        x: 358,
                        y: 373
                    },
                    {
                        x: 376,
                        y: 323
                    },
                    {
                        x: 395,
                        y: 275
                    },
                    {
                        x: 413,
                        y: 227
                    },
                    {
                        x: 432,
                        y: 177
                    },
                    {
                        x: 456,
                        y: 114
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'a-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 518,
                        y: 115
                    },
                    {
                        x: 578,
                        y: 298
                    },
                    {
                        x: 596,
                        y: 347
                    },
                    {
                        x: 613,
                        y: 396
                    },
                    {
                        x: 630,
                        y: 445
                    },
                    {
                        x: 646,
                        y: 494
                    },
                    {
                        x: 665,
                        y: 543
                    },
                    {
                        x: 682,
                        y: 592
                    },
                    {
                        x: 700,
                        y: 641
                    },
                    {
                        x: 722,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'a-majuscule-step-3.png',
                checkpoints: [
                    {
                        x: 307,
                        y: 513
                    },
                    {
                        x: 491,
                        y: 513
                    },
                    {
                        x: 543,
                        y: 513
                    },
                    {
                        x: 595,
                        y: 513
                    },
                    {
                        x: 657,
                        y: 513
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'b-minuscule': {
        backgroundImagePath: 'b-minuscule.png',
        steps: [
            {
                imagePath: 'b-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 302,
                        y: 107
                    },
                    {
                        x: 302,
                        y: 281
                    },
                    {
                        x: 302,
                        y: 332
                    },
                    {
                        x: 302,
                        y: 383
                    },
                    {
                        x: 302,
                        y: 434
                    },
                    {
                        x: 302,
                        y: 485
                    },
                    {
                        x: 302,
                        y: 536
                    },
                    {
                        x: 302,
                        y: 587
                    },
                    {
                        x: 302,
                        y: 638
                    },
                    {
                        x: 302,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'b-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 302,
                        y: 703
                    },
                    {
                        x: 304,
                        y: 536
                    },
                    {
                        x: 306,
                        y: 480
                    },
                    {
                        x: 321,
                        y: 423
                    },
                    {
                        x: 352,
                        y: 368
                    },
                    {
                        x: 390,
                        y: 324
                    },
                    {
                        x: 438,
                        y: 296
                    },
                    {
                        x: 495,
                        y: 287
                    },
                    {
                        x: 556,
                        y: 298
                    },
                    {
                        x: 609,
                        y: 328
                    },
                    {
                        x: 647,
                        y: 376
                    },
                    {
                        x: 667,
                        y: 430
                    },
                    {
                        x: 677,
                        y: 486
                    },
                    {
                        x: 675,
                        y: 543
                    },
                    {
                        x: 663,
                        y: 597
                    },
                    {
                        x: 639,
                        y: 648
                    },
                    {
                        x: 600,
                        y: 687
                    },
                    {
                        x: 549,
                        y: 715
                    },
                    {
                        x: 491,
                        y: 720
                    },
                    {
                        x: 434,
                        y: 706
                    },
                    {
                        x: 382,
                        y: 674
                    },
                    {
                        x: 334,
                        y: 623
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'b-majuscule': {
        backgroundImagePath: 'b-majuscule.png',
        steps: [
            {
                imagePath: 'b-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 285,
                        y: 107
                    },
                    {
                        x: 285,
                        y: 281
                    },
                    {
                        x: 285,
                        y: 332
                    },
                    {
                        x: 285,
                        y: 383
                    },
                    {
                        x: 285,
                        y: 434
                    },
                    {
                        x: 285,
                        y: 485
                    },
                    {
                        x: 285,
                        y: 536
                    },
                    {
                        x: 285,
                        y: 587
                    },
                    {
                        x: 285,
                        y: 638
                    },
                    {
                        x: 285,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'b-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 281,
                        y: 112
                    },
                    {
                        x: 424,
                        y: 112
                    },
                    {
                        x: 475,
                        y: 112
                    },
                    {
                        x: 528,
                        y: 113
                    },
                    {
                        x: 581,
                        y: 122
                    },
                    {
                        x: 632,
                        y: 150
                    },
                    {
                        x: 664,
                        y: 197
                    },
                    {
                        x: 673,
                        y: 248
                    },
                    {
                        x: 662,
                        y: 299
                    },
                    {
                        x: 629,
                        y: 343
                    },
                    {
                        x: 584,
                        y: 375
                    },
                    {
                        x: 528,
                        y: 391
                    },
                    {
                        x: 475,
                        y: 391
                    },
                    {
                        x: 424,
                        y: 391
                    },
                    {
                        x: 371,
                        y: 391
                    },
                    {
                        x: 290,
                        y: 391
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'b-majuscule-step-3.png',
                checkpoints: [
                    {
                        x: 281,
                        y: 394
                    },
                    {
                        x: 424,
                        y: 394
                    },
                    {
                        x: 475,
                        y: 394
                    },
                    {
                        x: 528,
                        y: 395
                    },
                    {
                        x: 584,
                        y: 403
                    },
                    {
                        x: 638,
                        y: 430
                    },
                    {
                        x: 683,
                        y: 468
                    },
                    {
                        x: 704,
                        y: 522
                    },
                    {
                        x: 700,
                        y: 579
                    },
                    {
                        x: 679,
                        y: 633
                    },
                    {
                        x: 638,
                        y: 671
                    },
                    {
                        x: 588,
                        y: 696
                    },
                    {
                        x: 532,
                        y: 705
                    },
                    {
                        x: 479,
                        y: 705
                    },
                    {
                        x: 428,
                        y: 705
                    },
                    {
                        x: 375,
                        y: 705
                    },
                    {
                        x: 289,
                        y: 705
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'c-minuscule': {
        backgroundImagePath: 'c-minuscule.png',
        steps: [
            {
                imagePath: 'c-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 651,
                        y: 392
                    },
                    {
                        x: 487,
                        y: 287
                    },
                    {
                        x: 426,
                        y: 298
                    },
                    {
                        x: 373,
                        y: 328
                    },
                    {
                        x: 335,
                        y: 376
                    },
                    {
                        x: 315,
                        y: 430
                    },
                    {
                        x: 305,
                        y: 486
                    },
                    {
                        x: 307,
                        y: 543
                    },
                    {
                        x: 319,
                        y: 597
                    },
                    {
                        x: 343,
                        y: 648
                    },
                    {
                        x: 382,
                        y: 687
                    },
                    {
                        x: 433,
                        y: 715
                    },
                    {
                        x: 491,
                        y: 720
                    },
                    {
                        x: 548,
                        y: 706
                    },
                    {
                        x: 600,
                        y: 674
                    },
                    {
                        x: 647,
                        y: 620
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'c-majuscule': {
        backgroundImagePath: 'c-majuscule.png',
        steps: [
            {
                imagePath: 'c-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 718,
                        y: 226
                    },
                    {
                        x: 566,
                        y: 107
                    },
                    {
                        x: 505,
                        y: 103
                    },
                    {
                        x: 449,
                        y: 106
                    },
                    {
                        x: 394,
                        y: 118
                    },
                    {
                        x: 342,
                        y: 143
                    },
                    {
                        x: 299,
                        y: 175
                    },
                    {
                        x: 267,
                        y: 218
                    },
                    {
                        x: 243,
                        y: 267
                    },
                    {
                        x: 228,
                        y: 319
                    },
                    {
                        x: 220,
                        y: 371
                    },
                    {
                        x: 220,
                        y: 423
                    },
                    {
                        x: 224,
                        y: 476
                    },
                    {
                        x: 234,
                        y: 529
                    },
                    {
                        x: 256,
                        y: 582
                    },
                    {
                        x: 287,
                        y: 629
                    },
                    {
                        x: 326,
                        y: 670
                    },
                    {
                        x: 375,
                        y: 696
                    },
                    {
                        x: 429,
                        y: 710
                    },
                    {
                        x: 484,
                        y: 716
                    },
                    {
                        x: 542,
                        y: 710
                    },
                    {
                        x: 596,
                        y: 698
                    },
                    {
                        x: 645,
                        y: 670
                    },
                    {
                        x: 686,
                        y: 631
                    },
                    {
                        x: 727,
                        y: 564
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'd-minuscule': {
        backgroundImagePath: 'd-minuscule.png',
        steps: [
            {
                imagePath: 'd-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 609,
                        y: 392
                    },
                    {
                        x: 457,
                        y: 287
                    },
                    {
                        x: 396,
                        y: 298
                    },
                    {
                        x: 343,
                        y: 328
                    },
                    {
                        x: 305,
                        y: 376
                    },
                    {
                        x: 285,
                        y: 430
                    },
                    {
                        x: 275,
                        y: 486
                    },
                    {
                        x: 277,
                        y: 543
                    },
                    {
                        x: 289,
                        y: 597
                    },
                    {
                        x: 313,
                        y: 648
                    },
                    {
                        x: 352,
                        y: 687
                    },
                    {
                        x: 403,
                        y: 715
                    },
                    {
                        x: 461,
                        y: 720
                    },
                    {
                        x: 518,
                        y: 706
                    },
                    {
                        x: 570,
                        y: 674
                    },
                    {
                        x: 609,
                        y: 630
                    },
                    {
                        x: 635,
                        y: 580
                    },
                    {
                        x: 647,
                        y: 527
                    },
                    {
                        x: 648,
                        y: 473
                    },
                    {
                        x: 648,
                        y: 424
                    },
                    {
                        x: 648,
                        y: 370
                    },
                    {
                        x: 648,
                        y: 316
                    },
                    {
                        x: 648,
                        y: 263
                    },
                    {
                        x: 648,
                        y: 214
                    },
                    {
                        x: 648,
                        y: 165
                    },
                    {
                        x: 648,
                        y: 106
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'd-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 650,
                        y: 107
                    },
                    {
                        x: 650,
                        y: 281
                    },
                    {
                        x: 650,
                        y: 332
                    },
                    {
                        x: 650,
                        y: 383
                    },
                    {
                        x: 650,
                        y: 434
                    },
                    {
                        x: 650,
                        y: 485
                    },
                    {
                        x: 650,
                        y: 536
                    },
                    {
                        x: 650,
                        y: 587
                    },
                    {
                        x: 650,
                        y: 638
                    },
                    {
                        x: 650,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'd-majuscule': {
        backgroundImagePath: 'd-majuscule.png',
        steps: [
            {
                imagePath: 'd-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 254,
                        y: 107
                    },
                    {
                        x: 254,
                        y: 281
                    },
                    {
                        x: 254,
                        y: 332
                    },
                    {
                        x: 254,
                        y: 383
                    },
                    {
                        x: 254,
                        y: 434
                    },
                    {
                        x: 254,
                        y: 485
                    },
                    {
                        x: 254,
                        y: 536
                    },
                    {
                        x: 254,
                        y: 587
                    },
                    {
                        x: 254,
                        y: 638
                    },
                    {
                        x: 254,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'd-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 256,
                        y: 112
                    },
                    {
                        x: 399,
                        y: 112
                    },
                    {
                        x: 452,
                        y: 112
                    },
                    {
                        x: 505,
                        y: 112
                    },
                    {
                        x: 558,
                        y: 121
                    },
                    {
                        x: 607,
                        y: 144
                    },
                    {
                        x: 653,
                        y: 180
                    },
                    {
                        x: 689,
                        y: 228
                    },
                    {
                        x: 709,
                        y: 280
                    },
                    {
                        x: 723,
                        y: 332
                    },
                    {
                        x: 726,
                        y: 384
                    },
                    {
                        x: 725,
                        y: 435
                    },
                    {
                        x: 718,
                        y: 486
                    },
                    {
                        x: 707,
                        y: 537
                    },
                    {
                        x: 684,
                        y: 591
                    },
                    {
                        x: 652,
                        y: 636
                    },
                    {
                        x: 609,
                        y: 673
                    },
                    {
                        x: 559,
                        y: 695
                    },
                    {
                        x: 500,
                        y: 705
                    },
                    {
                        x: 441,
                        y: 705
                    },
                    {
                        x: 382,
                        y: 705
                    },
                    {
                        x: 323,
                        y: 705
                    },
                    {
                        x: 252,
                        y: 705
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'e-minuscule': {
        backgroundImagePath: 'e-minuscule.png',
        steps: [
            {
                imagePath: 'e-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 290,
                        y: 490
                    },
                    {
                        x: 445,
                        y: 490
                    },
                    {
                        x: 496,
                        y: 490
                    },
                    {
                        x: 547,
                        y: 490
                    },
                    {
                        x: 598,
                        y: 490
                    },
                    {
                        x: 665,
                        y: 490
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'e-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 663,
                        y: 490
                    },
                    {
                        x: 631,
                        y: 364
                    },
                    {
                        x: 597,
                        y: 325
                    },
                    {
                        x: 554,
                        y: 297
                    },
                    {
                        x: 504,
                        y: 285
                    },
                    {
                        x: 452,
                        y: 285
                    },
                    {
                        x: 402,
                        y: 301
                    },
                    {
                        x: 357,
                        y: 327
                    },
                    {
                        x: 323,
                        y: 366
                    },
                    {
                        x: 302,
                        y: 413
                    },
                    {
                        x: 290,
                        y: 464
                    },
                    {
                        x: 286,
                        y: 515
                    },
                    {
                        x: 290,
                        y: 566
                    },
                    {
                        x: 305,
                        y: 615
                    },
                    {
                        x: 331,
                        y: 659
                    },
                    {
                        x: 369,
                        y: 694
                    },
                    {
                        x: 417,
                        y: 713
                    },
                    {
                        x: 468,
                        y: 722
                    },
                    {
                        x: 520,
                        y: 719
                    },
                    {
                        x: 570,
                        y: 705
                    },
                    {
                        x: 613,
                        y: 676
                    },
                    {
                        x: 647,
                        y: 629
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'e-majuscule': {
        backgroundImagePath: 'e-majuscule.png',
        steps: [
            {
                imagePath: 'e-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 285,
                        y: 113
                    },
                    {
                        x: 285,
                        y: 281
                    },
                    {
                        x: 285,
                        y: 332
                    },
                    {
                        x: 285,
                        y: 383
                    },
                    {
                        x: 285,
                        y: 434
                    },
                    {
                        x: 285,
                        y: 485
                    },
                    {
                        x: 285,
                        y: 536
                    },
                    {
                        x: 285,
                        y: 587
                    },
                    {
                        x: 285,
                        y: 638
                    },
                    {
                        x: 285,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'e-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 285,
                        y: 113
                    },
                    {
                        x: 424,
                        y: 113
                    },
                    {
                        x: 475,
                        y: 113
                    },
                    {
                        x: 527,
                        y: 113
                    },
                    {
                        x: 578,
                        y: 113
                    },
                    {
                        x: 630,
                        y: 113
                    },
                    {
                        x: 699,
                        y: 113
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'e-majuscule-step-3.png',
                checkpoints: [
                    {
                        x: 285,
                        y: 397
                    },
                    {
                        x: 424,
                        y: 397
                    },
                    {
                        x: 475,
                        y: 397
                    },
                    {
                        x: 527,
                        y: 397
                    },
                    {
                        x: 578,
                        y: 397
                    },
                    {
                        x: 666,
                        y: 397
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'e-majuscule-step-4.png',
                checkpoints: [
                    {
                        x: 285,
                        y: 705
                    },
                    {
                        x: 424,
                        y: 705
                    },
                    {
                        x: 475,
                        y: 705
                    },
                    {
                        x: 527,
                        y: 705
                    },
                    {
                        x: 578,
                        y: 705
                    },
                    {
                        x: 630,
                        y: 705
                    },
                    {
                        x: 699,
                        y: 705
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'f-minuscule': {
        backgroundImagePath: 'f-minuscule.png',
        steps: [
            {
                imagePath: 'f-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 562,
                        y: 98
                    },
                    {
                        x: 474,
                        y: 183
                    },
                    {
                        x: 472,
                        y: 235
                    },
                    {
                        x: 472,
                        y: 286
                    },
                    {
                        x: 472,
                        y: 337
                    },
                    {
                        x: 472,
                        y: 388
                    },
                    {
                        x: 472,
                        y: 439
                    },
                    {
                        x: 472,
                        y: 490
                    },
                    {
                        x: 472,
                        y: 541
                    },
                    {
                        x: 472,
                        y: 592
                    },
                    {
                        x: 472,
                        y: 643
                    },
                    {
                        x: 472,
                        y: 708
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'f-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 396,
                        y: 293
                    },
                    {
                        x: 571,
                        y: 293
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'f-majuscule': {
        backgroundImagePath: 'f-majuscule.png',
        steps: [
            {
                imagePath: 'f-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 319,
                        y: 113
                    },
                    {
                        x: 319,
                        y: 281
                    },
                    {
                        x: 319,
                        y: 332
                    },
                    {
                        x: 319,
                        y: 383
                    },
                    {
                        x: 319,
                        y: 434
                    },
                    {
                        x: 319,
                        y: 485
                    },
                    {
                        x: 319,
                        y: 536
                    },
                    {
                        x: 319,
                        y: 587
                    },
                    {
                        x: 319,
                        y: 638
                    },
                    {
                        x: 319,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'f-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 323,
                        y: 109
                    },
                    {
                        x: 462,
                        y: 109
                    },
                    {
                        x: 513,
                        y: 109
                    },
                    {
                        x: 565,
                        y: 109
                    },
                    {
                        x: 616,
                        y: 109
                    },
                    {
                        x: 702,
                        y: 109
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'f-majuscule-step-3.png',
                checkpoints: [
                    {
                        x: 323,
                        y: 397
                    },
                    {
                        x: 462,
                        y: 397
                    },
                    {
                        x: 513,
                        y: 397
                    },
                    {
                        x: 565,
                        y: 397
                    },
                    {
                        x: 651,
                        y: 397
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'g-minuscule': {
        backgroundImagePath: 'g-minuscule.png',
        steps: [
            {
                imagePath: 'g-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 609,
                        y: 392
                    },
                    {
                        x: 457,
                        y: 287
                    },
                    {
                        x: 396,
                        y: 298
                    },
                    {
                        x: 343,
                        y: 328
                    },
                    {
                        x: 305,
                        y: 376
                    },
                    {
                        x: 285,
                        y: 430
                    },
                    {
                        x: 275,
                        y: 486
                    },
                    {
                        x: 277,
                        y: 543
                    },
                    {
                        x: 289,
                        y: 597
                    },
                    {
                        x: 313,
                        y: 648
                    },
                    {
                        x: 352,
                        y: 687
                    },
                    {
                        x: 403,
                        y: 715
                    },
                    {
                        x: 461,
                        y: 720
                    },
                    {
                        x: 518,
                        y: 706
                    },
                    {
                        x: 570,
                        y: 674
                    },
                    {
                        x: 609,
                        y: 630
                    },
                    {
                        x: 635,
                        y: 580
                    },
                    {
                        x: 647,
                        y: 527
                    },
                    {
                        x: 648,
                        y: 473
                    },
                    {
                        x: 648,
                        y: 424
                    },
                    {
                        x: 648,
                        y: 370
                    },
                    {
                        x: 648,
                        y: 298
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'g-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 648,
                        y: 289
                    },
                    {
                        x: 648,
                        y: 489
                    },
                    {
                        x: 648,
                        y: 540
                    },
                    {
                        x: 648,
                        y: 591
                    },
                    {
                        x: 648,
                        y: 642
                    },
                    {
                        x: 648,
                        y: 693
                    },
                    {
                        x: 644,
                        y: 747
                    },
                    {
                        x: 633,
                        y: 801
                    },
                    {
                        x: 612,
                        y: 858
                    },
                    {
                        x: 570,
                        y: 902
                    },
                    {
                        x: 520,
                        y: 920
                    },
                    {
                        x: 468,
                        y: 926
                    },
                    {
                        x: 416,
                        y: 923
                    },
                    {
                        x: 364,
                        y: 910
                    },
                    {
                        x: 299,
                        y: 858
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'g-majuscule': {
        backgroundImagePath: 'g-majuscule.png',
        steps: [
            {
                imagePath: 'g-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 708,
                        y: 225
                    },
                    {
                        x: 545,
                        y: 106
                    },
                    {
                        x: 485,
                        y: 100
                    },
                    {
                        x: 428,
                        y: 105
                    },
                    {
                        x: 373,
                        y: 117
                    },
                    {
                        x: 321,
                        y: 142
                    },
                    {
                        x: 278,
                        y: 174
                    },
                    {
                        x: 246,
                        y: 217
                    },
                    {
                        x: 219,
                        y: 266
                    },
                    {
                        x: 202,
                        y: 318
                    },
                    {
                        x: 193,
                        y: 370
                    },
                    {
                        x: 191,
                        y: 422
                    },
                    {
                        x: 194,
                        y: 475
                    },
                    {
                        x: 204,
                        y: 528
                    },
                    {
                        x: 223,
                        y: 581
                    },
                    {
                        x: 254,
                        y: 628
                    },
                    {
                        x: 305,
                        y: 669
                    },
                    {
                        x: 354,
                        y: 695
                    },
                    {
                        x: 408,
                        y: 709
                    },
                    {
                        x: 463,
                        y: 715
                    },
                    {
                        x: 521,
                        y: 711
                    },
                    {
                        x: 575,
                        y: 700
                    },
                    {
                        x: 626,
                        y: 677
                    },
                    {
                        x: 670,
                        y: 641
                    },
                    {
                        x: 712,
                        y: 598
                    },
                    {
                        x: 733,
                        y: 544
                    },
                    {
                        x: 740,
                        y: 454
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'g-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 530,
                        y: 425
                    },
                    {
                        x: 678,
                        y: 425
                    },
                    {
                        x: 740,
                        y: 425
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'g-majuscule-step-3.png',
                checkpoints: [
                    {
                        x: 744,
                        y: 425
                    },
                    {
                        x: 744,
                        y: 591
                    },
                    {
                        x: 744,
                        y: 644
                    },
                    {
                        x: 744,
                        y: 719
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'h-minuscule': {
        backgroundImagePath: 'h-minuscule.png',
        steps: [
            {
                imagePath: 'h-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 311,
                        y: 113
                    },
                    {
                        x: 311,
                        y: 281
                    },
                    {
                        x: 311,
                        y: 332
                    },
                    {
                        x: 311,
                        y: 383
                    },
                    {
                        x: 311,
                        y: 434
                    },
                    {
                        x: 311,
                        y: 485
                    },
                    {
                        x: 311,
                        y: 536
                    },
                    {
                        x: 311,
                        y: 587
                    },
                    {
                        x: 311,
                        y: 638
                    },
                    {
                        x: 311,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'h-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 310,
                        y: 703
                    },
                    {
                        x: 312,
                        y: 536
                    },
                    {
                        x: 314,
                        y: 480
                    },
                    {
                        x: 326,
                        y: 423
                    },
                    {
                        x: 352,
                        y: 368
                    },
                    {
                        x: 391,
                        y: 326
                    },
                    {
                        x: 441,
                        y: 297
                    },
                    {
                        x: 500,
                        y: 286
                    },
                    {
                        x: 556,
                        y: 291
                    },
                    {
                        x: 606,
                        y: 319
                    },
                    {
                        x: 635,
                        y: 370
                    },
                    {
                        x: 643,
                        y: 423
                    },
                    {
                        x: 643,
                        y: 480
                    },
                    {
                        x: 643,
                        y: 532
                    },
                    {
                        x: 643,
                        y: 584
                    },
                    {
                        x: 643,
                        y: 636
                    },
                    {
                        x: 643,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'h-majuscule': {
        backgroundImagePath: 'h-majuscule.png',
        steps: [
            {
                imagePath: 'h-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 251,
                        y: 113
                    },
                    {
                        x: 251,
                        y: 281
                    },
                    {
                        x: 251,
                        y: 332
                    },
                    {
                        x: 251,
                        y: 383
                    },
                    {
                        x: 251,
                        y: 434
                    },
                    {
                        x: 251,
                        y: 485
                    },
                    {
                        x: 251,
                        y: 536
                    },
                    {
                        x: 251,
                        y: 587
                    },
                    {
                        x: 251,
                        y: 638
                    },
                    {
                        x: 251,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'h-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 704,
                        y: 113
                    },
                    {
                        x: 704,
                        y: 281
                    },
                    {
                        x: 704,
                        y: 332
                    },
                    {
                        x: 704,
                        y: 383
                    },
                    {
                        x: 704,
                        y: 434
                    },
                    {
                        x: 704,
                        y: 485
                    },
                    {
                        x: 704,
                        y: 536
                    },
                    {
                        x: 704,
                        y: 587
                    },
                    {
                        x: 704,
                        y: 638
                    },
                    {
                        x: 704,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'h-majuscule-step-3.png',
                checkpoints: [
                    {
                        x: 252,
                        y: 387
                    },
                    {
                        x: 430,
                        y: 387
                    },
                    {
                        x: 481,
                        y: 387
                    },
                    {
                        x: 532,
                        y: 387
                    },
                    {
                        x: 583,
                        y: 387
                    },
                    {
                        x: 634,
                        y: 387
                    },
                    {
                        x: 704,
                        y: 387
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'i-minuscule': {
        backgroundImagePath: 'i-minuscule.png',
        steps: [
            {
                imagePath: 'i-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 481,
                        y: 317
                    },
                    {
                        x: 481,
                        y: 485
                    },
                    {
                        x: 481,
                        y: 536
                    },
                    {
                        x: 481,
                        y: 587
                    },
                    {
                        x: 481,
                        y: 638
                    },
                    {
                        x: 481,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'i-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 481,
                        y: 117
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'i-majuscule': {
        backgroundImagePath: 'i-majuscule.png',
        steps: [
            {
                imagePath: 'i-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 488,
                        y: 113
                    },
                    {
                        x: 488,
                        y: 281
                    },
                    {
                        x: 488,
                        y: 332
                    },
                    {
                        x: 488,
                        y: 383
                    },
                    {
                        x: 488,
                        y: 434
                    },
                    {
                        x: 488,
                        y: 485
                    },
                    {
                        x: 488,
                        y: 536
                    },
                    {
                        x: 488,
                        y: 587
                    },
                    {
                        x: 488,
                        y: 638
                    },
                    {
                        x: 488,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'j-minuscule': {
        backgroundImagePath: 'j-minuscule.png',
        steps: [
            {
                imagePath: 'j-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 480,
                        y: 317
                    },
                    {
                        x: 480,
                        y: 485
                    },
                    {
                        x: 480,
                        y: 536
                    },
                    {
                        x: 480,
                        y: 587
                    },
                    {
                        x: 480,
                        y: 638
                    },
                    {
                        x: 480,
                        y: 689
                    },
                    {
                        x: 480,
                        y: 740
                    },
                    {
                        x: 480,
                        y: 791
                    },
                    {
                        x: 480,
                        y: 842
                    },
                    {
                        x: 464,
                        y: 891
                    },
                    {
                        x: 401,
                        y: 921
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'j-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 481,
                        y: 117
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'j-majuscule': {
        backgroundImagePath: 'j-majuscule.png',
        steps: [
            {
                imagePath: 'j-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 606,
                        y: 113
                    },
                    {
                        x: 606,
                        y: 281
                    },
                    {
                        x: 606,
                        y: 332
                    },
                    {
                        x: 606,
                        y: 383
                    },
                    {
                        x: 606,
                        y: 434
                    },
                    {
                        x: 606,
                        y: 485
                    },
                    {
                        x: 606,
                        y: 536
                    },
                    {
                        x: 601,
                        y: 587
                    },
                    {
                        x: 590,
                        y: 638
                    },
                    {
                        x: 558,
                        y: 683
                    },
                    {
                        x: 509,
                        y: 708
                    },
                    {
                        x: 457,
                        y: 715
                    },
                    {
                        x: 405,
                        y: 712
                    },
                    {
                        x: 353,
                        y: 692
                    },
                    {
                        x: 317,
                        y: 651
                    },
                    {
                        x: 297,
                        y: 568
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'k-minuscule': {
        backgroundImagePath: 'k-minuscule.png',
        steps: [
            {
                imagePath: 'k-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 337,
                        y: 113
                    },
                    {
                        x: 337,
                        y: 281
                    },
                    {
                        x: 337,
                        y: 332
                    },
                    {
                        x: 337,
                        y: 383
                    },
                    {
                        x: 337,
                        y: 434
                    },
                    {
                        x: 337,
                        y: 485
                    },
                    {
                        x: 337,
                        y: 536
                    },
                    {
                        x: 337,
                        y: 587
                    },
                    {
                        x: 337,
                        y: 638
                    },
                    {
                        x: 337,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'k-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 615,
                        y: 291
                    },
                    {
                        x: 523,
                        y: 377
                    },
                    {
                        x: 486,
                        y: 413
                    },
                    {
                        x: 437,
                        y: 457
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'k-minuscule-step-3.png',
                checkpoints: [
                    {
                        x: 449,
                        y: 500
                    },
                    {
                        x: 550,
                        y: 616
                    },
                    {
                        x: 581,
                        y: 654
                    },
                    {
                        x: 627,
                        y: 705
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'k-majuscule': {
        backgroundImagePath: 'k-majuscule.png',
        steps: [
            {
                imagePath: 'k-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 271,
                        y: 113
                    },
                    {
                        x: 271,
                        y: 281
                    },
                    {
                        x: 271,
                        y: 332
                    },
                    {
                        x: 271,
                        y: 383
                    },
                    {
                        x: 271,
                        y: 434
                    },
                    {
                        x: 271,
                        y: 485
                    },
                    {
                        x: 271,
                        y: 536
                    },
                    {
                        x: 271,
                        y: 587
                    },
                    {
                        x: 271,
                        y: 638
                    },
                    {
                        x: 271,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'k-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 687,
                        y: 104
                    },
                    {
                        x: 595,
                        y: 190
                    },
                    {
                        x: 556,
                        y: 227
                    },
                    {
                        x: 518,
                        y: 262
                    },
                    {
                        x: 479,
                        y: 298
                    },
                    {
                        x: 441,
                        y: 334
                    },
                    {
                        x: 391,
                        y: 384
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'k-majuscule-step-3.png',
                checkpoints: [
                    {
                        x: 409,
                        y: 423
                    },
                    {
                        x: 496,
                        y: 509
                    },
                    {
                        x: 533,
                        y: 547
                    },
                    {
                        x: 571,
                        y: 584
                    },
                    {
                        x: 607,
                        y: 623
                    },
                    {
                        x: 646,
                        y: 660
                    },
                    {
                        x: 700,
                        y: 709
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'l-minuscule': {
        backgroundImagePath: 'l-minuscule.png',
        steps: [
            {
                imagePath: 'l-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 465,
                        y: 113
                    },
                    {
                        x: 465,
                        y: 281
                    },
                    {
                        x: 465,
                        y: 332
                    },
                    {
                        x: 465,
                        y: 383
                    },
                    {
                        x: 465,
                        y: 434
                    },
                    {
                        x: 465,
                        y: 485
                    },
                    {
                        x: 465,
                        y: 536
                    },
                    {
                        x: 465,
                        y: 587
                    },
                    {
                        x: 465,
                        y: 638
                    },
                    {
                        x: 475,
                        y: 693
                    },
                    {
                        x: 554,
                        y: 717
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'l-majuscule': {
        backgroundImagePath: 'l-majuscule.png',
        steps: [
            {
                imagePath: 'l-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 333,
                        y: 113
                    },
                    {
                        x: 333,
                        y: 281
                    },
                    {
                        x: 333,
                        y: 332
                    },
                    {
                        x: 333,
                        y: 383
                    },
                    {
                        x: 333,
                        y: 434
                    },
                    {
                        x: 333,
                        y: 485
                    },
                    {
                        x: 333,
                        y: 536
                    },
                    {
                        x: 333,
                        y: 587
                    },
                    {
                        x: 333,
                        y: 638
                    },
                    {
                        x: 333,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'l-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 335,
                        y: 705
                    },
                    {
                        x: 465,
                        y: 705
                    },
                    {
                        x: 517,
                        y: 705
                    },
                    {
                        x: 568,
                        y: 705
                    },
                    {
                        x: 620,
                        y: 705
                    },
                    {
                        x: 689,
                        y: 705
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'm-minuscule': {
        backgroundImagePath: 'm-minuscule.png',
        steps: [
            {
                imagePath: 'm-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 173,
                        y: 301
                    },
                    {
                        x: 173,
                        y: 475
                    },
                    {
                        x: 173,
                        y: 526
                    },
                    {
                        x: 173,
                        y: 577
                    },
                    {
                        x: 173,
                        y: 628
                    },
                    {
                        x: 173,
                        y: 701
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'm-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 173,
                        y: 703
                    },
                    {
                        x: 175,
                        y: 536
                    },
                    {
                        x: 177,
                        y: 480
                    },
                    {
                        x: 189,
                        y: 423
                    },
                    {
                        x: 215,
                        y: 368
                    },
                    {
                        x: 254,
                        y: 326
                    },
                    {
                        x: 304,
                        y: 297
                    },
                    {
                        x: 363,
                        y: 286
                    },
                    {
                        x: 415,
                        y: 299
                    },
                    {
                        x: 453,
                        y: 336
                    },
                    {
                        x: 469,
                        y: 384
                    },
                    {
                        x: 476,
                        y: 437
                    },
                    {
                        x: 477,
                        y: 490
                    },
                    {
                        x: 477,
                        y: 542
                    },
                    {
                        x: 477,
                        y: 594
                    },
                    {
                        x: 477,
                        y: 646
                    },
                    {
                        x: 477,
                        y: 713
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'm-minuscule-step-3.png',
                checkpoints: [
                    {
                        x: 473,
                        y: 703
                    },
                    {
                        x: 475,
                        y: 536
                    },
                    {
                        x: 477,
                        y: 480
                    },
                    {
                        x: 489,
                        y: 423
                    },
                    {
                        x: 515,
                        y: 368
                    },
                    {
                        x: 554,
                        y: 326
                    },
                    {
                        x: 604,
                        y: 297
                    },
                    {
                        x: 663,
                        y: 286
                    },
                    {
                        x: 715,
                        y: 299
                    },
                    {
                        x: 753,
                        y: 336
                    },
                    {
                        x: 769,
                        y: 384
                    },
                    {
                        x: 776,
                        y: 437
                    },
                    {
                        x: 777,
                        y: 490
                    },
                    {
                        x: 777,
                        y: 542
                    },
                    {
                        x: 777,
                        y: 594
                    },
                    {
                        x: 777,
                        y: 646
                    },
                    {
                        x: 777,
                        y: 713
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'm-majuscule': {
        backgroundImagePath: 'm-majuscule.png',
        steps: [
            {
                imagePath: 'm-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 191,
                        y: 703
                    },
                    {
                        x: 191,
                        y: 536
                    },
                    {
                        x: 191,
                        y: 485
                    },
                    {
                        x: 191,
                        y: 434
                    },
                    {
                        x: 191,
                        y: 383
                    },
                    {
                        x: 191,
                        y: 332
                    },
                    {
                        x: 191,
                        y: 281
                    },
                    {
                        x: 191,
                        y: 230
                    },
                    {
                        x: 191,
                        y: 179
                    },
                    {
                        x: 191,
                        y: 114
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'm-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 254,
                        y: 114
                    },
                    {
                        x: 303,
                        y: 231
                    },
                    {
                        x: 319,
                        y: 283
                    },
                    {
                        x: 337,
                        y: 335
                    },
                    {
                        x: 354,
                        y: 388
                    },
                    {
                        x: 371,
                        y: 441
                    },
                    {
                        x: 388,
                        y: 494
                    },
                    {
                        x: 405,
                        y: 547
                    },
                    {
                        x: 424,
                        y: 599
                    },
                    {
                        x: 445,
                        y: 652
                    },
                    {
                        x: 479,
                        y: 700
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'm-majuscule-step-3.png',
                checkpoints: [
                    {
                        x: 479,
                        y: 708
                    },
                    {
                        x: 539,
                        y: 598
                    },
                    {
                        x: 553,
                        y: 548
                    },
                    {
                        x: 570,
                        y: 499
                    },
                    {
                        x: 585,
                        y: 449
                    },
                    {
                        x: 603,
                        y: 400
                    },
                    {
                        x: 617,
                        y: 350
                    },
                    {
                        x: 634,
                        y: 301
                    },
                    {
                        x: 649,
                        y: 251
                    },
                    {
                        x: 666,
                        y: 202
                    },
                    {
                        x: 681,
                        y: 152
                    },
                    {
                        x: 741,
                        y: 111
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'm-majuscule-step-4.png',
                checkpoints: [
                    {
                        x: 769,
                        y: 113
                    },
                    {
                        x: 769,
                        y: 281
                    },
                    {
                        x: 769,
                        y: 332
                    },
                    {
                        x: 769,
                        y: 383
                    },
                    {
                        x: 769,
                        y: 434
                    },
                    {
                        x: 769,
                        y: 485
                    },
                    {
                        x: 769,
                        y: 536
                    },
                    {
                        x: 769,
                        y: 587
                    },
                    {
                        x: 769,
                        y: 638
                    },
                    {
                        x: 769,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'n-minuscule': {
        backgroundImagePath: 'n-minuscule.png',
        steps: [
            {
                imagePath: 'n-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 310,
                        y: 301
                    },
                    {
                        x: 310,
                        y: 475
                    },
                    {
                        x: 310,
                        y: 526
                    },
                    {
                        x: 310,
                        y: 577
                    },
                    {
                        x: 310,
                        y: 628
                    },
                    {
                        x: 310,
                        y: 701
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'n-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 308,
                        y: 703
                    },
                    {
                        x: 310,
                        y: 536
                    },
                    {
                        x: 312,
                        y: 480
                    },
                    {
                        x: 324,
                        y: 423
                    },
                    {
                        x: 350,
                        y: 368
                    },
                    {
                        x: 389,
                        y: 326
                    },
                    {
                        x: 439,
                        y: 297
                    },
                    {
                        x: 498,
                        y: 286
                    },
                    {
                        x: 556,
                        y: 294
                    },
                    {
                        x: 604,
                        y: 318
                    },
                    {
                        x: 632,
                        y: 364
                    },
                    {
                        x: 641,
                        y: 416
                    },
                    {
                        x: 644,
                        y: 471
                    },
                    {
                        x: 644,
                        y: 530
                    },
                    {
                        x: 644,
                        y: 584
                    },
                    {
                        x: 644,
                        y: 636
                    },
                    {
                        x: 644,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'n-majuscule': {
        backgroundImagePath: 'm-majuscule.png',
        steps: [
            {
                imagePath: 'n-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 247,
                        y: 703
                    },
                    {
                        x: 247,
                        y: 536
                    },
                    {
                        x: 247,
                        y: 485
                    },
                    {
                        x: 247,
                        y: 434
                    },
                    {
                        x: 247,
                        y: 383
                    },
                    {
                        x: 247,
                        y: 332
                    },
                    {
                        x: 247,
                        y: 281
                    },
                    {
                        x: 247,
                        y: 230
                    },
                    {
                        x: 247,
                        y: 179
                    },
                    {
                        x: 247,
                        y: 114
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'n-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 281,
                        y: 114
                    },
                    {
                        x: 362,
                        y: 231
                    },
                    {
                        x: 390,
                        y: 274
                    },
                    {
                        x: 416,
                        y: 319
                    },
                    {
                        x: 448,
                        y: 359
                    },
                    {
                        x: 476,
                        y: 402
                    },
                    {
                        x: 502,
                        y: 447
                    },
                    {
                        x: 533,
                        y: 488
                    },
                    {
                        x: 561,
                        y: 531
                    },
                    {
                        x: 587,
                        y: 576
                    },
                    {
                        x: 616,
                        y: 617
                    },
                    {
                        x: 671,
                        y: 684
                    }

                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'n-majuscule-step-3.png',
                checkpoints: [
                    {
                        x: 712,
                        y: 703
                    },
                    {
                        x: 712,
                        y: 536
                    },
                    {
                        x: 712,
                        y: 485
                    },
                    {
                        x: 712,
                        y: 434
                    },
                    {
                        x: 712,
                        y: 383
                    },
                    {
                        x: 712,
                        y: 332
                    },
                    {
                        x: 712,
                        y: 281
                    },
                    {
                        x: 712,
                        y: 230
                    },
                    {
                        x: 712,
                        y: 179
                    },
                    {
                        x: 712,
                        y: 114
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'o-minuscule': {
        backgroundImagePath: 'o-minuscule.png',
        steps: [
            {
                imagePath: 'o-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 663,
                        y: 490
                    },
                    {
                        x: 631,
                        y: 364
                    },
                    {
                        x: 597,
                        y: 325
                    },
                    {
                        x: 554,
                        y: 297
                    },
                    {
                        x: 504,
                        y: 285
                    },
                    {
                        x: 452,
                        y: 285
                    },
                    {
                        x: 402,
                        y: 301
                    },
                    {
                        x: 357,
                        y: 327
                    },
                    {
                        x: 323,
                        y: 366
                    },
                    {
                        x: 302,
                        y: 413
                    },
                    {
                        x: 290,
                        y: 464
                    },
                    {
                        x: 286,
                        y: 515
                    },
                    {
                        x: 290,
                        y: 566
                    },
                    {
                        x: 305,
                        y: 615
                    },
                    {
                        x: 331,
                        y: 659
                    },
                    {
                        x: 369,
                        y: 694
                    },
                    {
                        x: 417,
                        y: 713
                    },
                    {
                        x: 468,
                        y: 722
                    },
                    {
                        x: 520,
                        y: 719
                    },
                    {
                        x: 570,
                        y: 705
                    },
                    {
                        x: 613,
                        y: 676
                    },
                    {
                        x: 647,
                        y: 629
                    },
                    {
                        x: 661,
                        y: 579
                    },
                    {
                        x: 668,
                        y: 513
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'o-majuscule': {
        backgroundImagePath: 'o-majuscule.png',
        steps: [
            {
                imagePath: 'o-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 708,
                        y: 225
                    },
                    {
                        x: 545,
                        y: 106
                    },
                    {
                        x: 485,
                        y: 100
                    },
                    {
                        x: 428,
                        y: 105
                    },
                    {
                        x: 373,
                        y: 117
                    },
                    {
                        x: 321,
                        y: 142
                    },
                    {
                        x: 278,
                        y: 174
                    },
                    {
                        x: 246,
                        y: 217
                    },
                    {
                        x: 219,
                        y: 266
                    },
                    {
                        x: 202,
                        y: 318
                    },
                    {
                        x: 193,
                        y: 370
                    },
                    {
                        x: 191,
                        y: 422
                    },
                    {
                        x: 194,
                        y: 475
                    },
                    {
                        x: 204,
                        y: 528
                    },
                    {
                        x: 223,
                        y: 581
                    },
                    {
                        x: 254,
                        y: 628
                    },
                    {
                        x: 305,
                        y: 669
                    },
                    {
                        x: 354,
                        y: 695
                    },
                    {
                        x: 408,
                        y: 709
                    },
                    {
                        x: 463,
                        y: 715
                    },
                    {
                        x: 521,
                        y: 711
                    },
                    {
                        x: 575,
                        y: 700
                    },
                    {
                        x: 626,
                        y: 677
                    },
                    {
                        x: 670,
                        y: 641
                    },
                    {
                        x: 708,
                        y: 599
                    },
                    {
                        x: 733,
                        y: 550
                    },
                    {
                        x: 749,
                        y: 500
                    },
                    {
                        x: 759,
                        y: 451
                    },
                    {
                        x: 762,
                        y: 399
                    },
                    {
                        x: 759,
                        y: 347
                    },
                    {
                        x: 749,
                        y: 295
                    },
                    {
                        x: 724,
                        y: 237
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'p-minuscule': {
        backgroundImagePath: 'p-minuscule.png',
        steps: [
            {
                imagePath: 'p-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 303,
                        y: 309
                    },
                    {
                        x: 303,
                        y: 477
                    },
                    {
                        x: 303,
                        y: 528
                    },
                    {
                        x: 303,
                        y: 579
                    },
                    {
                        x: 303,
                        y: 630
                    },
                    {
                        x: 303,
                        y: 681
                    },
                    {
                        x: 303,
                        y: 732
                    },
                    {
                        x: 303,
                        y: 783
                    },
                    {
                        x: 303,
                        y: 834
                    },
                    {
                        x: 303,
                        y: 899
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'p-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 302,
                        y: 703
                    },
                    {
                        x: 304,
                        y: 536
                    },
                    {
                        x: 306,
                        y: 480
                    },
                    {
                        x: 321,
                        y: 423
                    },
                    {
                        x: 352,
                        y: 368
                    },
                    {
                        x: 390,
                        y: 324
                    },
                    {
                        x: 438,
                        y: 296
                    },
                    {
                        x: 495,
                        y: 287
                    },
                    {
                        x: 556,
                        y: 298
                    },
                    {
                        x: 609,
                        y: 328
                    },
                    {
                        x: 647,
                        y: 376
                    },
                    {
                        x: 667,
                        y: 430
                    },
                    {
                        x: 677,
                        y: 486
                    },
                    {
                        x: 675,
                        y: 543
                    },
                    {
                        x: 663,
                        y: 597
                    },
                    {
                        x: 639,
                        y: 648
                    },
                    {
                        x: 600,
                        y: 687
                    },
                    {
                        x: 549,
                        y: 715
                    },
                    {
                        x: 491,
                        y: 720
                    },
                    {
                        x: 434,
                        y: 706
                    },
                    {
                        x: 382,
                        y: 674
                    },
                    {
                        x: 334,
                        y: 623
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'p-majuscule': {
        backgroundImagePath: 'p-majuscule.png',
        steps: [
            {
                imagePath: 'p-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 306,
                        y: 113
                    },
                    {
                        x: 306,
                        y: 281
                    },
                    {
                        x: 306,
                        y: 332
                    },
                    {
                        x: 306,
                        y: 383
                    },
                    {
                        x: 306,
                        y: 434
                    },
                    {
                        x: 306,
                        y: 485
                    },
                    {
                        x: 306,
                        y: 536
                    },
                    {
                        x: 306,
                        y: 587
                    },
                    {
                        x: 306,
                        y: 638
                    },
                    {
                        x: 306,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'p-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 312,
                        y: 114
                    },
                    {
                        x: 459,
                        y: 114
                    },
                    {
                        x: 510,
                        y: 114
                    },
                    {
                        x: 561,
                        y: 118
                    },
                    {
                        x: 612,
                        y: 137
                    },
                    {
                        x: 653,
                        y: 171
                    },
                    {
                        x: 674,
                        y: 219
                    },
                    {
                        x: 681,
                        y: 270
                    },
                    {
                        x: 674,
                        y: 321
                    },
                    {
                        x: 651,
                        y: 369
                    },
                    {
                        x: 612,
                        y: 401
                    },
                    {
                        x: 563,
                        y: 417
                    },
                    {
                        x: 514,
                        y: 425
                    },
                    {
                        x: 465,
                        y: 425
                    },
                    {
                        x: 416,
                        y: 425
                    },
                    {
                        x: 367,
                        y: 425
                    },
                    {
                        x: 312,
                        y: 425
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'q-minuscule': {
        backgroundImagePath: 'q-minuscule.png',
        steps: [
            {
                imagePath: 'q-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 609,
                        y: 392
                    },
                    {
                        x: 457,
                        y: 287
                    },
                    {
                        x: 396,
                        y: 298
                    },
                    {
                        x: 343,
                        y: 328
                    },
                    {
                        x: 305,
                        y: 376
                    },
                    {
                        x: 285,
                        y: 430
                    },
                    {
                        x: 275,
                        y: 486
                    },
                    {
                        x: 277,
                        y: 543
                    },
                    {
                        x: 289,
                        y: 597
                    },
                    {
                        x: 313,
                        y: 648
                    },
                    {
                        x: 352,
                        y: 687
                    },
                    {
                        x: 403,
                        y: 715
                    },
                    {
                        x: 461,
                        y: 720
                    },
                    {
                        x: 518,
                        y: 706
                    },
                    {
                        x: 570,
                        y: 674
                    },
                    {
                        x: 609,
                        y: 630
                    },
                    {
                        x: 635,
                        y: 580
                    },
                    {
                        x: 647,
                        y: 527
                    },
                    {
                        x: 648,
                        y: 473
                    },
                    {
                        x: 648,
                        y: 424
                    },
                    {
                        x: 648,
                        y: 370
                    },
                    {
                        x: 648,
                        y: 298
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'q-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 654,
                        y: 309
                    },
                    {
                        x: 654,
                        y: 477
                    },
                    {
                        x: 654,
                        y: 528
                    },
                    {
                        x: 654,
                        y: 579
                    },
                    {
                        x: 654,
                        y: 630
                    },
                    {
                        x: 654,
                        y: 681
                    },
                    {
                        x: 654,
                        y: 732
                    },
                    {
                        x: 654,
                        y: 783
                    },
                    {
                        x: 654,
                        y: 834
                    },
                    {
                        x: 654,
                        y: 899
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'q-majuscule': {
        backgroundImagePath: 'q-majuscule.png',
        steps: [
            {
                imagePath: 'q-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 708,
                        y: 225
                    },
                    {
                        x: 545,
                        y: 106
                    },
                    {
                        x: 485,
                        y: 100
                    },
                    {
                        x: 428,
                        y: 105
                    },
                    {
                        x: 373,
                        y: 117
                    },
                    {
                        x: 321,
                        y: 142
                    },
                    {
                        x: 278,
                        y: 174
                    },
                    {
                        x: 246,
                        y: 217
                    },
                    {
                        x: 219,
                        y: 266
                    },
                    {
                        x: 202,
                        y: 318
                    },
                    {
                        x: 193,
                        y: 370
                    },
                    {
                        x: 191,
                        y: 422
                    },
                    {
                        x: 194,
                        y: 475
                    },
                    {
                        x: 204,
                        y: 528
                    },
                    {
                        x: 223,
                        y: 581
                    },
                    {
                        x: 254,
                        y: 628
                    },
                    {
                        x: 305,
                        y: 669
                    },
                    {
                        x: 354,
                        y: 695
                    },
                    {
                        x: 408,
                        y: 709
                    },
                    {
                        x: 463,
                        y: 715
                    },
                    {
                        x: 521,
                        y: 711
                    },
                    {
                        x: 575,
                        y: 700
                    },
                    {
                        x: 626,
                        y: 677
                    },
                    {
                        x: 670,
                        y: 641
                    },
                    {
                        x: 708,
                        y: 599
                    },
                    {
                        x: 733,
                        y: 550
                    },
                    {
                        x: 749,
                        y: 500
                    },
                    {
                        x: 759,
                        y: 451
                    },
                    {
                        x: 762,
                        y: 399
                    },
                    {
                        x: 759,
                        y: 347
                    },
                    {
                        x: 749,
                        y: 295
                    },
                    {
                        x: 724,
                        y: 237
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'q-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 602,
                        y: 554
                    },
                    {
                        x: 719,
                        y: 651
                    },
                    {
                        x: 768,
                        y: 696
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'r-minuscule': {
        backgroundImagePath: 'r-minuscule.png',
        steps: [
            {
                imagePath: 'r-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 428,
                        y: 317
                    },
                    {
                        x: 428,
                        y: 485
                    },
                    {
                        x: 428,
                        y: 536
                    },
                    {
                        x: 428,
                        y: 587
                    },
                    {
                        x: 428,
                        y: 638
                    },
                    {
                        x: 428,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'r-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 423,
                        y: 703
                    },
                    {
                        x: 425,
                        y: 536
                    },
                    {
                        x: 429,
                        y: 484
                    },
                    {
                        x: 439,
                        y: 437
                    },
                    {
                        x: 461,
                        y: 390
                    },
                    {
                        x: 491,
                        y: 349
                    },
                    {
                        x: 530,
                        y: 317
                    },
                    {
                        x: 595,
                        y: 294
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'r-majuscule': {
        backgroundImagePath: 'r-majuscule.png',
        steps: [
            {
                imagePath: 'r-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 285,
                        y: 107
                    },
                    {
                        x: 285,
                        y: 281
                    },
                    {
                        x: 285,
                        y: 332
                    },
                    {
                        x: 285,
                        y: 383
                    },
                    {
                        x: 285,
                        y: 434
                    },
                    {
                        x: 285,
                        y: 485
                    },
                    {
                        x: 285,
                        y: 536
                    },
                    {
                        x: 285,
                        y: 587
                    },
                    {
                        x: 285,
                        y: 638
                    },
                    {
                        x: 285,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'r-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 281,
                        y: 108
                    },
                    {
                        x: 424,
                        y: 108
                    },
                    {
                        x: 475,
                        y: 108
                    },
                    {
                        x: 528,
                        y: 108
                    },
                    {
                        x: 584,
                        y: 117
                    },
                    {
                        x: 638,
                        y: 144
                    },
                    {
                        x: 683,
                        y: 182
                    },
                    {
                        x: 704,
                        y: 236
                    },
                    {
                        x: 700,
                        y: 293
                    },
                    {
                        x: 679,
                        y: 347
                    },
                    {
                        x: 638,
                        y: 385
                    },
                    {
                        x: 588,
                        y: 410
                    },
                    {
                        x: 532,
                        y: 419
                    },
                    {
                        x: 479,
                        y: 419
                    },
                    {
                        x: 428,
                        y: 419
                    },
                    {
                        x: 375,
                        y: 419
                    },
                    {
                        x: 289,
                        y: 419
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'r-majuscule-step-3.png',
                checkpoints: [
                    {
                        x: 379,
                        y: 423
                    },
                    {
                        x: 466,
                        y: 509
                    },
                    {
                        x: 503,
                        y: 547
                    },
                    {
                        x: 541,
                        y: 584
                    },
                    {
                        x: 577,
                        y: 623
                    },
                    {
                        x: 616,
                        y: 660
                    },
                    {
                        x: 670,
                        y: 709
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    's-minuscule': {
        backgroundImagePath: 's-minuscule.png',
        steps: [
            {
                imagePath: 's-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 616,
                        y: 357
                    },
                    {
                        x: 522,
                        y: 288
                    },
                    {
                        x: 470,
                        y: 285
                    },
                    {
                        x: 419,
                        y: 290
                    },
                    {
                        x: 370,
                        y: 305
                    },
                    {
                        x: 333,
                        y: 341
                    },
                    {
                        x: 321,
                        y: 391
                    },
                    {
                        x: 339,
                        y: 439
                    },
                    {
                        x: 383,
                        y: 467
                    },
                    {
                        x: 432,
                        y: 483
                    },
                    {
                        x: 482,
                        y: 495
                    },
                    {
                        x: 532,
                        y: 507
                    },
                    {
                        x: 581,
                        y: 523
                    },
                    {
                        x: 624,
                        y: 551
                    },
                    {
                        x: 643,
                        y: 599
                    },
                    {
                        x: 631,
                        y: 649
                    },
                    {
                        x: 598,
                        y: 689
                    },
                    {
                        x: 553,
                        y: 714
                    },
                    {
                        x: 502,
                        y: 724
                    },
                    {
                        x: 450,
                        y: 723
                    },
                    {
                        x: 400,
                        y: 711
                    },
                    {
                        x: 354,
                        y: 687
                    },
                    {
                        x: 313,
                        y: 628
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    's-majuscule': {
        backgroundImagePath: 's-majuscule.png',
        steps: [
            {
                imagePath: 's-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 683,
                        y: 217
                    },
                    {
                        x: 609,
                        y: 129
                    },
                    {
                        x: 560,
                        y: 111
                    },
                    {
                        x: 508,
                        y: 103
                    },
                    {
                        x: 456,
                        y: 103
                    },
                    {
                        x: 405,
                        y: 111
                    },
                    {
                        x: 355,
                        y: 125
                    },
                    {
                        x: 312,
                        y: 154
                    },
                    {
                        x: 280,
                        y: 195
                    },
                    {
                        x: 267,
                        y: 245
                    },
                    {
                        x: 273,
                        y: 297
                    },
                    {
                        x: 303,
                        y: 339
                    },
                    {
                        x: 346,
                        y: 366
                    },
                    {
                        x: 395,
                        y: 384
                    },
                    {
                        x: 446,
                        y: 398
                    },
                    {
                        x: 497,
                        y: 408
                    },
                    {
                        x: 548,
                        y: 420
                    },
                    {
                        x: 599,
                        y: 431
                    },
                    {
                        x: 645,
                        y: 454
                    },
                    {
                        x: 686,
                        y: 485
                    },
                    {
                        x: 710,
                        y: 531
                    },
                    {
                        x: 710,
                        y: 583
                    },
                    {
                        x: 694,
                        y: 632
                    },
                    {
                        x: 659,
                        y: 670
                    },
                    {
                        x: 615,
                        y: 697
                    },
                    {
                        x: 566,
                        y: 712
                    },
                    {
                        x: 514,
                        y: 716
                    },
                    {
                        x: 462,
                        y: 716
                    },
                    {
                        x: 411,
                        y: 710
                    },
                    {
                        x: 361,
                        y: 696
                    },
                    {
                        x: 317,
                        y: 668
                    },
                    {
                        x: 281,
                        y: 632
                    },
                    {
                        x: 256,
                        y: 573
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    't-minuscule': {
        backgroundImagePath: 't-minuscule.png',
        steps: [
            {
                imagePath: 't-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 465,
                        y: 158
                    },
                    {
                        x: 465,
                        y: 332
                    },
                    {
                        x: 465,
                        y: 383
                    },
                    {
                        x: 465,
                        y: 434
                    },
                    {
                        x: 465,
                        y: 485
                    },
                    {
                        x: 465,
                        y: 536
                    },
                    {
                        x: 465,
                        y: 587
                    },
                    {
                        x: 465,
                        y: 638
                    },
                    {
                        x: 475,
                        y: 693
                    },
                    {
                        x: 554,
                        y: 717
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 't-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 381,
                        y: 295
                    },
                    {
                        x: 570,
                        y: 295
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    't-majuscule': {
        backgroundImagePath: 't-majuscule.png',
        steps: [
            {
                imagePath: 't-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 502,
                        y: 112
                    },
                    {
                        x: 502,
                        y: 280
                    },
                    {
                        x: 502,
                        y: 331
                    },
                    {
                        x: 502,
                        y: 382
                    },
                    {
                        x: 502,
                        y: 433
                    },
                    {
                        x: 502,
                        y: 484
                    },
                    {
                        x: 502,
                        y: 535
                    },
                    {
                        x: 502,
                        y: 586
                    },
                    {
                        x: 502,
                        y: 637
                    },
                    {
                        x: 502,
                        y: 702
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 't-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 271,
                        y: 112
                    },
                    {
                        x: 408,
                        y: 112
                    },
                    {
                        x: 459,
                        y: 112
                    },
                    {
                        x: 510,
                        y: 112
                    },
                    {
                        x: 561,
                        y: 112
                    },
                    {
                        x: 612,
                        y: 112
                    },
                    {
                        x: 663,
                        y: 112
                    },
                    {
                        x: 741,
                        y: 112
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'u-minuscule': {
        backgroundImagePath: 'u-minuscule.png',
        steps: [
            {
                imagePath: 'u-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 310,
                        y: 301
                    },
                    {
                        x: 310,
                        y: 475
                    },
                    {
                        x: 310,
                        y: 526
                    },
                    {
                        x: 310,
                        y: 577
                    },
                    {
                        x: 316,
                        y: 628
                    },
                    {
                        x: 337,
                        y: 677
                    },
                    {
                        x: 379,
                        y: 706
                    },
                    {
                        x: 430,
                        y: 719
                    },
                    {
                        x: 483,
                        y: 715
                    },
                    {
                        x: 533,
                        y: 699
                    },
                    {
                        x: 577,
                        y: 668
                    },
                    {
                        x: 619,
                        y: 629
                    },
                    {
                        x: 643,
                        y: 579
                    },
                    {
                        x: 643,
                        y: 526
                    },
                    {
                        x: 643,
                        y: 473
                    },
                    {
                        x: 643,
                        y: 420
                    },
                    {
                        x: 643,
                        y: 367
                    },
                    {
                        x: 643,
                        y: 299
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'u-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 645,
                        y: 317
                    },
                    {
                        x: 645,
                        y: 485
                    },
                    {
                        x: 645,
                        y: 536
                    },
                    {
                        x: 645,
                        y: 587
                    },
                    {
                        x: 645,
                        y: 638
                    },
                    {
                        x: 645,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'u-majuscule': {
        backgroundImagePath: 'u-majuscule.png',
        steps: [
            {
                imagePath: 'u-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 257,
                        y: 113
                    },
                    {
                        x: 257,
                        y: 281
                    },
                    {
                        x: 257,
                        y: 332
                    },
                    {
                        x: 257,
                        y: 383
                    },
                    {
                        x: 257,
                        y: 434
                    },
                    {
                        x: 257,
                        y: 485
                    },
                    {
                        x: 261,
                        y: 536
                    },
                    {
                        x: 273,
                        y: 587
                    },
                    {
                        x: 296,
                        y: 635
                    },
                    {
                        x: 330,
                        y: 674
                    },
                    {
                        x: 375,
                        y: 699
                    },
                    {
                        x: 425,
                        y: 714
                    },
                    {
                        x: 477,
                        y: 717
                    },
                    {
                        x: 529,
                        y: 715
                    },
                    {
                        x: 579,
                        y: 703
                    },
                    {
                        x: 625,
                        y: 679
                    },
                    {
                        x: 662,
                        y: 643
                    },
                    {
                        x: 688,
                        y: 599
                    },
                    {
                        x: 701,
                        y: 550
                    },
                    {
                        x: 708,
                        y: 499
                    },
                    {
                        x: 708,
                        y: 447
                    },
                    {
                        x: 708,
                        y: 395
                    },
                    {
                        x: 708,
                        y: 343
                    },
                    {
                        x: 708,
                        y: 291
                    },
                    {
                        x: 708,
                        y: 239
                    },
                    {
                        x: 708,
                        y: 187
                    },
                    {
                        x: 708,
                        y: 116
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'v-minuscule': {
        backgroundImagePath: 'v-minuscule.png',
        steps: [
            {
                imagePath: 'v-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 304,
                        y: 300
                    },
                    {
                        x: 354,
                        y: 444
                    },
                    {
                        x: 373,
                        y: 492
                    },
                    {
                        x: 391,
                        y: 540
                    },
                    {
                        x: 409,
                        y: 589
                    },
                    {
                        x: 428,
                        y: 637
                    },
                    {
                        x: 476,
                        y: 698
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'v-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 476,
                        y: 698
                    },
                    {
                        x: 545,
                        y: 582
                    },
                    {
                        x: 564,
                        y: 534
                    },
                    {
                        x: 581,
                        y: 486
                    },
                    {
                        x: 598,
                        y: 437
                    },
                    {
                        x: 617,
                        y: 389
                    },
                    {
                        x: 646,
                        y: 314
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'v-majuscule': {
        backgroundImagePath: 'v-majuscule.png',
        steps: [
            {
                imagePath: 'v-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 248,
                        y: 120
                    },
                    {
                        x: 298,
                        y: 262
                    },
                    {
                        x: 317,
                        y: 310
                    },
                    {
                        x: 335,
                        y: 358
                    },
                    {
                        x: 353,
                        y: 407
                    },
                    {
                        x: 372,
                        y: 455
                    },
                    {
                        x: 392,
                        y: 503
                    },
                    {
                        x: 411,
                        y: 551
                    },
                    {
                        x: 429,
                        y: 599
                    },
                    {
                        x: 447,
                        y: 648
                    },
                    {
                        x: 482,
                        y: 702
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'v-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 484,
                        y: 689
                    },
                    {
                        x: 558,
                        y: 559
                    },
                    {
                        x: 574,
                        y: 510
                    },
                    {
                        x: 593,
                        y: 462
                    },
                    {
                        x: 609,
                        y: 413
                    },
                    {
                        x: 629,
                        y: 365
                    },
                    {
                        x: 645,
                        y: 316
                    },
                    {
                        x: 664,
                        y: 269
                    },
                    {
                        x: 680,
                        y: 220
                    },
                    {
                        x: 697,
                        y: 171
                    },
                    {
                        x: 719,
                        y: 106
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'w-minuscule': {
        backgroundImagePath: 'w-minuscule.png',
        steps: [
            {
                imagePath: 'w-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 182,
                        y: 300
                    },
                    {
                        x: 230,
                        y: 444
                    },
                    {
                        x: 243,
                        y: 494
                    },
                    {
                        x: 256,
                        y: 544
                    },
                    {
                        x: 270,
                        y: 593
                    },
                    {
                        x: 287,
                        y: 642
                    },
                    {
                        x: 323,
                        y: 700
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'w-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 324,
                        y: 684
                    },
                    {
                        x: 385,
                        y: 560
                    },
                    {
                        x: 398,
                        y: 510
                    },
                    {
                        x: 412,
                        y: 460
                    },
                    {
                        x: 426,
                        y: 410
                    },
                    {
                        x: 441,
                        y: 361
                    },
                    {
                        x: 470,
                        y: 298
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'w-minuscule-step-3.png',
                checkpoints: [
                    {
                        x: 484,
                        y: 300
                    },
                    {
                        x: 532,
                        y: 444
                    },
                    {
                        x: 545,
                        y: 494
                    },
                    {
                        x: 558,
                        y: 544
                    },
                    {
                        x: 572,
                        y: 593
                    },
                    {
                        x: 589,
                        y: 642
                    },
                    {
                        x: 625,
                        y: 700
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'w-minuscule-step-4.png',
                checkpoints: [
                    {
                        x: 630,
                        y: 684
                    },
                    {
                        x: 691,
                        y: 560
                    },
                    {
                        x: 704,
                        y: 510
                    },
                    {
                        x: 718,
                        y: 460
                    },
                    {
                        x: 732,
                        y: 410
                    },
                    {
                        x: 747,
                        y: 361
                    },
                    {
                        x: 764,
                        y: 298
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'w-majuscule': {
        backgroundImagePath: 'w-majuscule.png',
        steps: [
            {
                imagePath: 'w-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 95,
                        y: 120
                    },
                    {
                        x: 133,
                        y: 262
                    },
                    {
                        x: 145,
                        y: 311
                    },
                    {
                        x: 158,
                        y: 360
                    },
                    {
                        x: 171,
                        y: 410
                    },
                    {
                        x: 185,
                        y: 460
                    },
                    {
                        x: 199,
                        y: 510
                    },
                    {
                        x: 213,
                        y: 560
                    },
                    {
                        x: 227,
                        y: 610
                    },
                    {
                        x: 268,
                        y: 682
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'w-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 269,
                        y: 684
                    },
                    {
                        x: 330,
                        y: 560
                    },
                    {
                        x: 343,
                        y: 510
                    },
                    {
                        x: 357,
                        y: 460
                    },
                    {
                        x: 371,
                        y: 410
                    },
                    {
                        x: 386,
                        y: 361
                    },
                    {
                        x: 398,
                        y: 311
                    },
                    {
                        x: 411,
                        y: 261
                    },
                    {
                        x: 424,
                        y: 211
                    },
                    {
                        x: 476,
                        y: 134
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'w-majuscule-step-3.png',
                checkpoints: [
                    {
                        x: 506,
                        y: 120
                    },
                    {
                        x: 544,
                        y: 262
                    },
                    {
                        x: 556,
                        y: 311
                    },
                    {
                        x: 569,
                        y: 360
                    },
                    {
                        x: 582,
                        y: 410
                    },
                    {
                        x: 596,
                        y: 460
                    },
                    {
                        x: 610,
                        y: 510
                    },
                    {
                        x: 624,
                        y: 560
                    },
                    {
                        x: 638,
                        y: 610
                    },
                    {
                        x: 679,
                        y: 682
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'w-majuscule-step-4.png',
                checkpoints: [
                    {
                        x: 679,
                        y: 684
                    },
                    {
                        x: 740,
                        y: 560
                    },
                    {
                        x: 753,
                        y: 510
                    },
                    {
                        x: 767,
                        y: 460
                    },
                    {
                        x: 781,
                        y: 410
                    },
                    {
                        x: 796,
                        y: 361
                    },
                    {
                        x: 808,
                        y: 311
                    },
                    {
                        x: 821,
                        y: 261
                    },
                    {
                        x: 834,
                        y: 211
                    },
                    {
                        x: 853,
                        y: 134
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'x-minuscule': {
        backgroundImagePath: 'x-minuscule.png',
        steps: [
            {
                imagePath: 'x-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 339,
                        y: 311
                    },
                    {
                        x: 424,
                        y: 437
                    },
                    {
                        x: 454,
                        y: 477
                    },
                    {
                        x: 483,
                        y: 517
                    },
                    {
                        x: 512,
                        y: 558
                    },
                    {
                        x: 543,
                        y: 599
                    },
                    {
                        x: 572,
                        y: 641
                    },
                    {
                        x: 615,
                        y: 697
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'x-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 618,
                        y: 302
                    },
                    {
                        x: 546,
                        y: 403
                    },
                    {
                        x: 516,
                        y: 445
                    },
                    {
                        x: 486,
                        y: 488
                    },
                    {
                        x: 457,
                        y: 531
                    },
                    {
                        x: 427,
                        y: 573
                    },
                    {
                        x: 397,
                        y: 615
                    },
                    {
                        x: 366,
                        y: 657
                    },
                    {
                        x: 323,
                        y: 711
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'x-majuscule': {
        backgroundImagePath: 'x-majuscule.png',
        steps: [
            {
                imagePath: 'x-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 286,
                        y: 123
                    },
                    {
                        x: 360,
                        y: 235
                    },
                    {
                        x: 390,
                        y: 275
                    },
                    {
                        x: 419,
                        y: 315
                    },
                    {
                        x: 448,
                        y: 356
                    },
                    {
                        x: 479,
                        y: 397
                    },
                    {
                        x: 508,
                        y: 439
                    },
                    {
                        x: 539,
                        y: 480
                    },
                    {
                        x: 568,
                        y: 522
                    },
                    {
                        x: 599,
                        y: 564
                    },
                    {
                        x: 629,
                        y: 606
                    },
                    {
                        x: 659,
                        y: 648
                    },
                    {
                        x: 701,
                        y: 704
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'x-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 692,
                        y: 110
                    },
                    {
                        x: 604,
                        y: 229
                    },
                    {
                        x: 576,
                        y: 272
                    },
                    {
                        x: 545,
                        y: 313
                    },
                    {
                        x: 515,
                        y: 355
                    },
                    {
                        x: 483,
                        y: 396
                    },
                    {
                        x: 453,
                        y: 436
                    },
                    {
                        x: 424,
                        y: 479
                    },
                    {
                        x: 394,
                        y: 520
                    },
                    {
                        x: 363,
                        y: 561
                    },
                    {
                        x: 333,
                        y: 602
                    },
                    {
                        x: 303,
                        y: 644
                    },
                    {
                        x: 264,
                        y: 702
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'y-minuscule': {
        backgroundImagePath: 'y-minuscule.png',
        steps: [
            {
                imagePath: 'y-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 306,
                        y: 296
                    },
                    {
                        x: 359,
                        y: 420
                    },
                    {
                        x: 380,
                        y: 467
                    },
                    {
                        x: 400,
                        y: 514
                    },
                    {
                        x: 420,
                        y: 561
                    },
                    {
                        x: 443,
                        y: 607
                    },
                    {
                        x: 484,
                        y: 678
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'y-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 650,
                        y: 296
                    },
                    {
                        x: 599,
                        y: 420
                    },
                    {
                        x: 579,
                        y: 468
                    },
                    {
                        x: 560,
                        y: 516
                    },
                    {
                        x: 541,
                        y: 563
                    },
                    {
                        x: 521,
                        y: 610
                    },
                    {
                        x: 501,
                        y: 657
                    },
                    {
                        x: 482,
                        y: 705
                    },
                    {
                        x: 462,
                        y: 752
                    },
                    {
                        x: 442,
                        y: 800
                    },
                    {
                        x: 423,
                        y: 848
                    },
                    {
                        x: 391,
                        y: 912
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'y-majuscule': {
        backgroundImagePath: 'y-majuscule.png',
        steps: [
            {
                imagePath: 'y-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 263,
                        y: 123
                    },
                    {
                        x: 331,
                        y: 229
                    },
                    {
                        x: 358,
                        y: 272
                    },
                    {
                        x: 385,
                        y: 315
                    },
                    {
                        x: 412,
                        y: 359
                    },
                    {
                        x: 440,
                        y: 403
                    },
                    {
                        x: 484,
                        y: 462
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'y-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 717,
                        y: 110
                    },
                    {
                        x: 640,
                        y: 229
                    },
                    {
                        x: 613,
                        y: 272
                    },
                    {
                        x: 585,
                        y: 316
                    },
                    {
                        x: 558,
                        y: 360
                    },
                    {
                        x: 530,
                        y: 403
                    },
                    {
                        x: 484,
                        y: 463
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'y-majuscule-step-3.png',
                checkpoints: [
                    {
                        x: 484,
                        y: 452
                    },
                    {
                        x: 484,
                        y: 638
                    },
                    {
                        x: 484,
                        y: 705
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'z-minuscule': {
        backgroundImagePath: 'z-minuscule.png',
        steps: [
            {
                imagePath: 'z-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 329,
                        y: 299
                    },
                    {
                        x: 468,
                        y: 299
                    },
                    {
                        x: 519,
                        y: 299
                    },
                    {
                        x: 570,
                        y: 299
                    },
                    {
                        x: 644,
                        y: 299
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'z-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 634,
                        y: 300
                    },
                    {
                        x: 530,
                        y: 437
                    },
                    {
                        x: 498,
                        y: 477
                    },
                    {
                        x: 465,
                        y: 517
                    },
                    {
                        x: 431,
                        y: 556
                    },
                    {
                        x: 398,
                        y: 595
                    },
                    {
                        x: 364,
                        y: 635
                    },
                    {
                        x: 313,
                        y: 708
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'z-minuscule-step-3.png',
                checkpoints: [
                    {
                        x: 331,
                        y: 710
                    },
                    {
                        x: 470,
                        y: 710
                    },
                    {
                        x: 521,
                        y: 710
                    },
                    {
                        x: 572,
                        y: 710
                    },
                    {
                        x: 646,
                        y: 710
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'z-majuscule': {
        backgroundImagePath: 'z-majuscule.png',
        steps: [
            {
                imagePath: 'z-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 282,
                        y: 112
                    },
                    {
                        x: 421,
                        y: 112
                    },
                    {
                        x: 472,
                        y: 112
                    },
                    {
                        x: 523,
                        y: 112
                    },
                    {
                        x: 574,
                        y: 112
                    },
                    {
                        x: 625,
                        y: 112
                    },
                    {
                        x: 703,
                        y: 112
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'z-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 708,
                        y: 116
                    },
                    {
                        x: 619,
                        y: 236
                    },
                    {
                        x: 587,
                        y: 276
                    },
                    {
                        x: 554,
                        y: 316
                    },
                    {
                        x: 522,
                        y: 356
                    },
                    {
                        x: 490,
                        y: 396
                    },
                    {
                        x: 457,
                        y: 436
                    },
                    {
                        x: 423,
                        y: 475
                    },
                    {
                        x: 390,
                        y: 515
                    },
                    {
                        x: 358,
                        y: 556
                    },
                    {
                        x: 325,
                        y: 595
                    },
                    {
                        x: 291,
                        y: 635
                    },
                    {
                        x: 257,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'z-majuscule-step-3.png',
                checkpoints: [
                    {
                        x: 279,
                        y: 706
                    },
                    {
                        x: 418,
                        y: 706
                    },
                    {
                        x: 469,
                        y: 706
                    },
                    {
                        x: 520,
                        y: 706
                    },
                    {
                        x: 571,
                        y: 706
                    },
                    {
                        x: 622,
                        y: 706
                    },
                    {
                        x: 700,
                        y: 706
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },



    'a-trema-minuscule': {
        backgroundImagePath: 'a-trema-minuscule.png',
        steps: [
            {
                imagePath: 'a-trema-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 609,
                        y: 392
                    },
                    {
                        x: 457,
                        y: 287
                    },
                    {
                        x: 396,
                        y: 298
                    },
                    {
                        x: 343,
                        y: 328
                    },
                    {
                        x: 305,
                        y: 376
                    },
                    {
                        x: 285,
                        y: 430
                    },
                    {
                        x: 275,
                        y: 486
                    },
                    {
                        x: 277,
                        y: 543
                    },
                    {
                        x: 289,
                        y: 597
                    },
                    {
                        x: 313,
                        y: 648
                    },
                    {
                        x: 352,
                        y: 687
                    },
                    {
                        x: 403,
                        y: 715
                    },
                    {
                        x: 461,
                        y: 720
                    },
                    {
                        x: 518,
                        y: 706
                    },
                    {
                        x: 570,
                        y: 674
                    },
                    {
                        x: 609,
                        y: 630
                    },
                    {
                        x: 635,
                        y: 580
                    },
                    {
                        x: 647,
                        y: 527
                    },
                    {
                        x: 648,
                        y: 473
                    },
                    {
                        x: 648,
                        y: 424
                    },
                    {
                        x: 648,
                        y: 370
                    },
                    {
                        x: 648,
                        y: 298
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'a-trema-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 648,
                        y: 289
                    },
                    {
                        x: 648,
                        y: 489
                    },
                    {
                        x: 648,
                        y: 540
                    },
                    {
                        x: 648,
                        y: 591
                    },
                    {
                        x: 648,
                        y: 642
                    },
                    {
                        x: 648,
                        y: 709
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'a-trema-minuscule-step-3.png',
                checkpoints: [
                    {
                        x: 408,
                        y: 155
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'a-trema-minuscule-step-4.png',
                checkpoints: [
                    {
                        x: 570,
                        y: 155
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'a-trema-majuscule': {
        backgroundImagePath: 'a-trema-majuscule.png',
        steps: [
            {
                imagePath: 'a-trema-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 231,
                        y: 833
                    },
                    {
                        x: 304,
                        y: 655
                    },
                    {
                        x: 321,
                        y: 607
                    },
                    {
                        x: 339,
                        y: 558
                    },
                    {
                        x: 358,
                        y: 509
                    },
                    {
                        x: 376,
                        y: 459
                    },
                    {
                        x: 395,
                        y: 411
                    },
                    {
                        x: 413,
                        y: 363
                    },
                    {
                        x: 432,
                        y: 313
                    },
                    {
                        x: 456,
                        y: 250
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'a-trema-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 518,
                        y: 255
                    },
                    {
                        x: 578,
                        y: 438
                    },
                    {
                        x: 596,
                        y: 487
                    },
                    {
                        x: 613,
                        y: 536
                    },
                    {
                        x: 630,
                        y: 585
                    },
                    {
                        x: 646,
                        y: 634
                    },
                    {
                        x: 665,
                        y: 683
                    },
                    {
                        x: 682,
                        y: 732
                    },
                    {
                        x: 700,
                        y: 781
                    },
                    {
                        x: 722,
                        y: 843
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'a-trema-majuscule-step-3.png',
                checkpoints: [
                    {
                        x: 307,
                        y: 650
                    },
                    {
                        x: 491,
                        y: 650
                    },
                    {
                        x: 543,
                        y: 650
                    },
                    {
                        x: 595,
                        y: 650
                    },
                    {
                        x: 657,
                        y: 650
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'a-trema-majuscule-step-4.png',
                checkpoints: [
                    {
                        x: 407,
                        y: 105
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'a-trema-majuscule-step-5.png',
                checkpoints: [
                    {
                        x: 568,
                        y: 105
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'o-trema-minuscule': {
        backgroundImagePath: 'o-trema-minuscule.png',
        steps: [
            {
                imagePath: 'o-trema-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 663,
                        y: 490
                    },
                    {
                        x: 631,
                        y: 364
                    },
                    {
                        x: 597,
                        y: 325
                    },
                    {
                        x: 554,
                        y: 297
                    },
                    {
                        x: 504,
                        y: 285
                    },
                    {
                        x: 452,
                        y: 285
                    },
                    {
                        x: 402,
                        y: 301
                    },
                    {
                        x: 357,
                        y: 327
                    },
                    {
                        x: 323,
                        y: 366
                    },
                    {
                        x: 302,
                        y: 413
                    },
                    {
                        x: 290,
                        y: 464
                    },
                    {
                        x: 286,
                        y: 515
                    },
                    {
                        x: 290,
                        y: 566
                    },
                    {
                        x: 305,
                        y: 615
                    },
                    {
                        x: 331,
                        y: 659
                    },
                    {
                        x: 369,
                        y: 694
                    },
                    {
                        x: 417,
                        y: 713
                    },
                    {
                        x: 468,
                        y: 722
                    },
                    {
                        x: 520,
                        y: 719
                    },
                    {
                        x: 570,
                        y: 705
                    },
                    {
                        x: 613,
                        y: 676
                    },
                    {
                        x: 647,
                        y: 629
                    },
                    {
                        x: 661,
                        y: 579
                    },
                    {
                        x: 668,
                        y: 513
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'o-trema-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 401,
                        y: 155
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'o-trema-minuscule-step-3.png',
                checkpoints: [
                    {
                        x: 565,
                        y: 155
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'o-trema-majuscule': {
        backgroundImagePath: 'o-trema-majuscule.png',
        steps: [
            {
                imagePath: 'o-trema-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 708,
                        y: 366
                    },
                    {
                        x: 545,
                        y: 247
                    },
                    {
                        x: 485,
                        y: 241
                    },
                    {
                        x: 428,
                        y: 246
                    },
                    {
                        x: 373,
                        y: 258
                    },
                    {
                        x: 321,
                        y: 283
                    },
                    {
                        x: 278,
                        y: 315
                    },
                    {
                        x: 246,
                        y: 358
                    },
                    {
                        x: 219,
                        y: 407
                    },
                    {
                        x: 202,
                        y: 459
                    },
                    {
                        x: 193,
                        y: 511
                    },
                    {
                        x: 191,
                        y: 563
                    },
                    {
                        x: 194,
                        y: 616
                    },
                    {
                        x: 204,
                        y: 669
                    },
                    {
                        x: 223,
                        y: 722
                    },
                    {
                        x: 254,
                        y: 769
                    },
                    {
                        x: 305,
                        y: 810
                    },
                    {
                        x: 354,
                        y: 836
                    },
                    {
                        x: 408,
                        y: 850
                    },
                    {
                        x: 463,
                        y: 856
                    },
                    {
                        x: 521,
                        y: 852
                    },
                    {
                        x: 575,
                        y: 841
                    },
                    {
                        x: 626,
                        y: 818
                    },
                    {
                        x: 670,
                        y: 782
                    },
                    {
                        x: 708,
                        y: 740
                    },
                    {
                        x: 733,
                        y: 691
                    },
                    {
                        x: 749,
                        y: 641
                    },
                    {
                        x: 759,
                        y: 592
                    },
                    {
                        x: 762,
                        y: 540
                    },
                    {
                        x: 759,
                        y: 488
                    },
                    {
                        x: 749,
                        y: 436
                    },
                    {
                        x: 724,
                        y: 378
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'o-trema-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 392,
                        y: 105
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'o-trema-majuscule-step-3.png',
                checkpoints: [
                    {
                        x: 554,
                        y: 105
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'u-trema-minuscule': {
        backgroundImagePath: 'u-trema-minuscule.png',
        steps: [
            {
                imagePath: 'u-trema-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 310,
                        y: 301
                    },
                    {
                        x: 310,
                        y: 475
                    },
                    {
                        x: 310,
                        y: 526
                    },
                    {
                        x: 310,
                        y: 577
                    },
                    {
                        x: 316,
                        y: 628
                    },
                    {
                        x: 337,
                        y: 677
                    },
                    {
                        x: 379,
                        y: 706
                    },
                    {
                        x: 430,
                        y: 719
                    },
                    {
                        x: 483,
                        y: 715
                    },
                    {
                        x: 533,
                        y: 699
                    },
                    {
                        x: 577,
                        y: 668
                    },
                    {
                        x: 619,
                        y: 629
                    },
                    {
                        x: 643,
                        y: 579
                    },
                    {
                        x: 643,
                        y: 526
                    },
                    {
                        x: 643,
                        y: 473
                    },
                    {
                        x: 643,
                        y: 420
                    },
                    {
                        x: 643,
                        y: 367
                    },
                    {
                        x: 643,
                        y: 299
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'u-trema-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 645,
                        y: 317
                    },
                    {
                        x: 645,
                        y: 485
                    },
                    {
                        x: 645,
                        y: 536
                    },
                    {
                        x: 645,
                        y: 587
                    },
                    {
                        x: 645,
                        y: 638
                    },
                    {
                        x: 645,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'u-trema-minuscule-step-3.png',
                checkpoints: [
                    {
                        x: 397,
                        y: 155
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'u-trema-minuscule-step-4.png',
                checkpoints: [
                    {
                        x: 559,
                        y: 155
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'u-trema-majuscule': {
        backgroundImagePath: 'u-trema-majuscule.png',
        steps: [
            {
                imagePath: 'u-trema-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 257,
                        y: 253
                    },
                    {
                        x: 257,
                        y: 421
                    },
                    {
                        x: 257,
                        y: 472
                    },
                    {
                        x: 257,
                        y: 523
                    },
                    {
                        x: 257,
                        y: 574
                    },
                    {
                        x: 257,
                        y: 625
                    },
                    {
                        x: 261,
                        y: 676
                    },
                    {
                        x: 273,
                        y: 727
                    },
                    {
                        x: 296,
                        y: 775
                    },
                    {
                        x: 330,
                        y: 814
                    },
                    {
                        x: 375,
                        y: 839
                    },
                    {
                        x: 425,
                        y: 854
                    },
                    {
                        x: 477,
                        y: 857
                    },
                    {
                        x: 529,
                        y: 855
                    },
                    {
                        x: 579,
                        y: 843
                    },
                    {
                        x: 625,
                        y: 819
                    },
                    {
                        x: 662,
                        y: 783
                    },
                    {
                        x: 688,
                        y: 739
                    },
                    {
                        x: 701,
                        y: 690
                    },
                    {
                        x: 708,
                        y: 639
                    },
                    {
                        x: 708,
                        y: 587
                    },
                    {
                        x: 708,
                        y: 535
                    },
                    {
                        x: 708,
                        y: 483
                    },
                    {
                        x: 708,
                        y: 431
                    },
                    {
                        x: 708,
                        y: 379
                    },
                    {
                        x: 708,
                        y: 327
                    },
                    {
                        x: 708,
                        y: 256
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'u-trema-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 404,
                        y: 106
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'u-trema-majuscule-step-3.png',
                checkpoints: [
                    {
                        x: 568,
                        y: 106
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'eszett-minuscule': {
        backgroundImagePath: 'eszett-minuscule.png',
        steps: [
            {
                imagePath: 'eszett-minuscule-step-1.png',
                checkpoints: [
                    {
                        x: 315,
                        y: 895
                    },
                    {
                        x: 315,
                        y: 728
                    },
                    {
                        x: 315,
                        y: 677
                    },
                    {
                        x: 315,
                        y: 626
                    },
                    {
                        x: 315,
                        y: 575
                    },
                    {
                        x: 315,
                        y: 524
                    },
                    {
                        x: 315,
                        y: 473
                    },
                    {
                        x: 315,
                        y: 422
                    },
                    {
                        x: 315,
                        y: 371
                    },
                    {
                        x: 315,
                        y: 320
                    },
                    {
                        x: 315,
                        y: 269
                    },
                    {
                        x: 318,
                        y: 218
                    },
                    {
                        x: 334,
                        y: 165
                    },
                    {
                        x: 370,
                        y: 124
                    },
                    {
                        x: 417,
                        y: 103
                    },
                    {
                        x: 468,
                        y: 96
                    },
                    {
                        x: 519,
                        y: 102
                    },
                    {
                        x: 568,
                        y: 121
                    },
                    {
                        x: 607,
                        y: 154
                    },
                    {
                        x: 626,
                        y: 202
                    },
                    {
                        x: 629,
                        y: 253
                    },
                    {
                        x: 612,
                        y: 304
                    },
                    {
                        x: 575,
                        y: 347
                    },
                    {
                        x: 500,
                        y: 379
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'eszett-minuscule-step-2.png',
                checkpoints: [
                    {
                        x: 495,
                        y: 376
                    },
                    {
                        x: 609,
                        y: 416
                    },
                    {
                        x: 643,
                        y: 455
                    },
                    {
                        x: 660,
                        y: 504
                    },
                    {
                        x: 661,
                        y: 556
                    },
                    {
                        x: 654,
                        y: 607
                    },
                    {
                        x: 633,
                        y: 654
                    },
                    {
                        x: 596,
                        y: 690
                    },
                    {
                        x: 549,
                        y: 712
                    },
                    {
                        x: 484,
                        y: 718
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'eszett-majuscule': {
        backgroundImagePath: 'eszett-majuscule.png',
        steps: [
            {
                imagePath: 'eszett-majuscule-step-1.png',
                checkpoints: [
                    {
                        x: 300,
                        y: 692
                    },
                    {
                        x: 297,
                        y: 535
                    },
                    {
                        x: 297,
                        y: 484
                    },
                    {
                        x: 297,
                        y: 433
                    },
                    {
                        x: 297,
                        y: 382
                    },
                    {
                        x: 297,
                        y: 331
                    },
                    {
                        x: 300,
                        y: 274
                    },
                    {
                        x: 312,
                        y: 218
                    },
                    {
                        x: 334,
                        y: 165
                    },
                    {
                        x: 370,
                        y: 124
                    },
                    {
                        x: 417,
                        y: 103
                    },
                    {
                        x: 468,
                        y: 96
                    },
                    {
                        x: 519,
                        y: 102
                    },
                    {
                        x: 570,
                        y: 117
                    },
                    {
                        x: 612,
                        y: 146
                    },
                    {
                        x: 640,
                        y: 190
                    },
                    {
                        x: 647,
                        y: 239
                    },
                    {
                        x: 636,
                        y: 290
                    },
                    {
                        x: 605,
                        y: 333
                    },
                    {
                        x: 562,
                        y: 363
                    },
                    {
                        x: 500,
                        y: 379
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'eszett-majuscule-step-2.png',
                checkpoints: [
                    {
                        x: 511,
                        y: 381
                    },
                    {
                        x: 625,
                        y: 421
                    },
                    {
                        x: 661,
                        y: 458
                    },
                    {
                        x: 681,
                        y: 506
                    },
                    {
                        x: 682,
                        y: 558
                    },
                    {
                        x: 673,
                        y: 609
                    },
                    {
                        x: 650,
                        y: 655
                    },
                    {
                        x: 614,
                        y: 692
                    },
                    {
                        x: 567,
                        y: 713
                    },
                    {
                        x: 496,
                        y: 721
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'un': {
        backgroundImagePath: 'un.png',
        steps: [
            {
                imagePath: 'un-step-1.png',
                checkpoints: [
                    {
                        x: 362,
                        y: 311
                    },
                    {
                        x: 468,
                        y: 213
                    },
                    {
                        x: 505,
                        y: 177
                    },
                    {
                        x: 562,
                        y: 128
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'un-step-2.png',
                checkpoints: [
                    {
                        x: 559,
                        y: 134
                    },
                    {
                        x: 559,
                        y: 280
                    },
                    {
                        x: 559,
                        y: 331
                    },
                    {
                        x: 559,
                        y: 382
                    },
                    {
                        x: 559,
                        y: 433
                    },
                    {
                        x: 559,
                        y: 484
                    },
                    {
                        x: 559,
                        y: 535
                    },
                    {
                        x: 559,
                        y: 586
                    },
                    {
                        x: 559,
                        y: 637
                    },
                    {
                        x: 559,
                        y: 702
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'deux': {
        backgroundImagePath: 'deux.png',
        steps: [
            {
                imagePath: 'deux-step-1.png',
                checkpoints: [
                    {
                        x: 308,
                        y: 250
                    },
                    {
                        x: 393,
                        y: 141
                    },
                    {
                        x: 442,
                        y: 125
                    },
                    {
                        x: 494,
                        y: 124
                    },
                    {
                        x: 545,
                        y: 133
                    },
                    {
                        x: 592,
                        y: 155
                    },
                    {
                        x: 631,
                        y: 189
                    },
                    {
                        x: 654,
                        y: 236
                    },
                    {
                        x: 660,
                        y: 287
                    },
                    {
                        x: 651,
                        y: 338
                    },
                    {
                        x: 628,
                        y: 384
                    },
                    {
                        x: 595,
                        y: 424
                    },
                    {
                        x: 559,
                        y: 462
                    },
                    {
                        x: 522,
                        y: 498
                    },
                    {
                        x: 485,
                        y: 534
                    },
                    {
                        x: 446,
                        y: 568
                    },
                    {
                        x: 406,
                        y: 601
                    },
                    {
                        x: 370,
                        y: 638
                    },
                    {
                        x: 326,
                        y: 703
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'deux-step-2.png',
                checkpoints: [
                    {
                        x: 325,
                        y: 705
                    },
                    {
                        x: 502,
                        y: 705
                    },
                    {
                        x: 553,
                        y: 705
                    },
                    {
                        x: 604,
                        y: 705
                    },
                    {
                        x: 668,
                        y: 705
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'trois': {
        backgroundImagePath: 'trois.png',
        steps: [
            {
                imagePath: 'trois-step-1.png',
                checkpoints: [
                    {
                        x: 298,
                        y: 248
                    },
                    {
                        x: 383,
                        y: 139
                    },
                    {
                        x: 432,
                        y: 123
                    },
                    {
                        x: 484,
                        y: 122
                    },
                    {
                        x: 535,
                        y: 131
                    },
                    {
                        x: 582,
                        y: 153
                    },
                    {
                        x: 618,
                        y: 190
                    },
                    {
                        x: 633,
                        y: 240
                    },
                    {
                        x: 634,
                        y: 291
                    },
                    {
                        x: 617,
                        y: 339
                    },
                    {
                        x: 582,
                        y: 375
                    },
                    {
                        x: 510,
                        y: 408
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'trois-step-2.png',
                checkpoints: [
                    {
                        x: 503,
                        y: 407
                    },
                    {
                        x: 628,
                        y: 460
                    },
                    {
                        x: 651,
                        y: 507
                    },
                    {
                        x: 659,
                        y: 558
                    },
                    {
                        x: 653,
                        y: 609
                    },
                    {
                        x: 630,
                        y: 655
                    },
                    {
                        x: 594,
                        y: 692
                    },
                    {
                        x: 548,
                        y: 715
                    },
                    {
                        x: 497,
                        y: 726
                    },
                    {
                        x: 445,
                        y: 726
                    },
                    {
                        x: 394,
                        y: 716
                    },
                    {
                        x: 348,
                        y: 694
                    },
                    {
                        x: 312,
                        y: 659
                    },
                    {
                        x: 287,
                        y: 603
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'quatre': {
        backgroundImagePath: 'quatre.png',
        steps: [
            {
                imagePath: 'quatre-step-1.png',
                checkpoints: [
                    {
                        x: 537,
                        y: 127
                    },
                    {
                        x: 460,
                        y: 246
                    },
                    {
                        x: 434,
                        y: 290
                    },
                    {
                        x: 407,
                        y: 334
                    },
                    {
                        x: 380,
                        y: 378
                    },
                    {
                        x: 353,
                        y: 423
                    },
                    {
                        x: 327,
                        y: 468
                    },
                    {
                        x: 309,
                        y: 547
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'quatre-step-2.png',
                checkpoints: [
                    {
                        x: 312,
                        y: 548
                    },
                    {
                        x: 451,
                        y: 548
                    },
                    {
                        x: 502,
                        y: 548
                    },
                    {
                        x: 553,
                        y: 548
                    },
                    {
                        x: 605,
                        y: 548
                    },
                    {
                        x: 668,
                        y: 548
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'quatre-step-3.png',
                checkpoints: [
                    {
                        x: 560,
                        y: 442
                    },
                    {
                        x: 560,
                        y: 643
                    },
                    {
                        x: 560,
                        y: 707
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'cinq': {
        backgroundImagePath: 'cinq.png',
        steps: [
            {
                imagePath: 'cinq-step-1.png',
                checkpoints: [
                    {
                        x: 631,
                        y: 138
                    },
                    {
                        x: 476,
                        y: 138
                    },
                    {
                        x: 424,
                        y: 138
                    },
                    {
                        x: 353,
                        y: 138
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'cinq-step-2.png',
                checkpoints: [
                    {
                        x: 350,
                        y: 138
                    },
                    {
                        x: 334,
                        y: 248
                    },
                    {
                        x: 327,
                        y: 299
                    },
                    {
                        x: 321,
                        y: 350
                    },
                    {
                        x: 312,
                        y: 426
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'cinq-step-3.png',
                checkpoints: [
                    {
                        x: 328,
                        y: 422
                    },
                    {
                        x: 447,
                        y: 377
                    },
                    {
                        x: 499,
                        y: 375
                    },
                    {
                        x: 549,
                        y: 388
                    },
                    {
                        x: 593,
                        y: 416
                    },
                    {
                        x: 626,
                        y: 456
                    },
                    {
                        x: 645,
                        y: 504
                    },
                    {
                        x: 649,
                        y: 556
                    },
                    {
                        x: 639,
                        y: 607
                    },
                    {
                        x: 618,
                        y: 654
                    },
                    {
                        x: 582,
                        y: 691
                    },
                    {
                        x: 536,
                        y: 715
                    },
                    {
                        x: 485,
                        y: 724
                    },
                    {
                        x: 433,
                        y: 723
                    },
                    {
                        x: 383,
                        y: 708
                    },
                    {
                        x: 339,
                        y: 681
                    },
                    {
                        x: 302,
                        y: 622
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'six': {
        backgroundImagePath: 'six.png',
        steps: [
            {
                imagePath: 'six-step-1.png',
                checkpoints: [
                    {
                        x: 564,
                        y: 121
                    },
                    {
                        x: 454,
                        y: 181
                    },
                    {
                        x: 414,
                        y: 214
                    },
                    {
                        x: 378,
                        y: 252
                    },
                    {
                        x: 349,
                        y: 295
                    },
                    {
                        x: 328,
                        y: 342
                    },
                    {
                        x: 314,
                        y: 392
                    },
                    {
                        x: 305,
                        y: 442
                    },
                    {
                        x: 299,
                        y: 493
                    },
                    {
                        x: 297,
                        y: 545
                    },
                    {
                        x: 304,
                        y: 596
                    },
                    {
                        x: 322,
                        y: 644
                    },
                    {
                        x: 355,
                        y: 684
                    },
                    {
                        x: 398,
                        y: 712
                    },
                    {
                        x: 448,
                        y: 724
                    },
                    {
                        x: 500,
                        y: 725
                    },
                    {
                        x: 550,
                        y: 714
                    },
                    {
                        x: 593,
                        y: 686
                    },
                    {
                        x: 627,
                        y: 647
                    },
                    {
                        x: 647,
                        y: 599
                    },
                    {
                        x: 654,
                        y: 548
                    },
                    {
                        x: 648,
                        y: 497
                    },
                    {
                        x: 632,
                        y: 448
                    },
                    {
                        x: 599,
                        y: 409
                    },
                    {
                        x: 555,
                        y: 383
                    },
                    {
                        x: 504,
                        y: 372
                    },
                    {
                        x: 452,
                        y: 375
                    },
                    {
                        x: 403,
                        y: 391
                    },
                    {
                        x: 344,
                        y: 416
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'sept': {
        backgroundImagePath: 'sept.png',
        steps: [
            {
                imagePath: 'sept-step-1.png',
                checkpoints: [
                    {
                        x: 314,
                        y: 138
                    },
                    {
                        x: 453,
                        y: 138
                    },
                    {
                        x: 504,
                        y: 138
                    },
                    {
                        x: 555,
                        y: 138
                    },
                    {
                        x: 629,
                        y: 138
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'sept-step-2.png',
                checkpoints: [
                    {
                        x: 650,
                        y: 138
                    },
                    {
                        x: 586,
                        y: 271
                    },
                    {
                        x: 564,
                        y: 317
                    },
                    {
                        x: 543,
                        y: 364
                    },
                    {
                        x: 521,
                        y: 411
                    },
                    {
                        x: 496,
                        y: 457
                    },
                    {
                        x: 473,
                        y: 503
                    },
                    {
                        x: 452,
                        y: 550
                    },
                    {
                        x: 429,
                        y: 597
                    },
                    {
                        x: 405,
                        y: 643
                    },
                    {
                        x: 372,
                        y: 707
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }, {
                imagePath: 'sept-step-3.png',
                checkpoints: [
                    {
                        x: 426,
                        y: 403
                    },
                    {
                        x: 613,
                        y: 403
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'huit': {
        backgroundImagePath: 'huit.png',
        steps: [
            {
                imagePath: 'huit-step-1.png',
                checkpoints: [
                    {
                        x: 560,
                        y: 141
                    },
                    {
                        x: 433,
                        y: 129
                    },
                    {
                        x: 384,
                        y: 145
                    },
                    {
                        x: 342,
                        y: 176
                    },
                    {
                        x: 319,
                        y: 223
                    },
                    {
                        x: 311,
                        y: 274
                    },
                    {
                        x: 326,
                        y: 323
                    },
                    {
                        x: 361,
                        y: 361
                    },
                    {
                        x: 405,
                        y: 387
                    },
                    {
                        x: 455,
                        y: 399
                    },
                    {
                        x: 506,
                        y: 407
                    },
                    {
                        x: 556,
                        y: 419
                    },
                    {
                        x: 602,
                        y: 442
                    },
                    {
                        x: 640,
                        y: 477
                    },
                    {
                        x: 656,
                        y: 526
                    },
                    {
                        x: 659,
                        y: 578
                    },
                    {
                        x: 650,
                        y: 630
                    },
                    {
                        x: 621,
                        y: 673
                    },
                    {
                        x: 582,
                        y: 706
                    },
                    {
                        x: 533,
                        y: 723
                    },
                    {
                        x: 481,
                        y: 728
                    },
                    {
                        x: 429,
                        y: 722
                    },
                    {
                        x: 380,
                        y: 706
                    },
                    {
                        x: 337,
                        y: 677
                    },
                    {
                        x: 305,
                        y: 636
                    },
                    {
                        x: 289,
                        y: 586
                    },
                    {
                        x: 292,
                        y: 534
                    },
                    {
                        x: 311,
                        y: 486
                    },
                    {
                        x: 345,
                        y: 447
                    },
                    {
                        x: 387,
                        y: 416
                    },
                    {
                        x: 437,
                        y: 402
                    },
                    {
                        x: 487,
                        y: 400
                    },
                    {
                        x: 536,
                        y: 389
                    },
                    {
                        x: 583,
                        y: 367
                    },
                    {
                        x: 618,
                        y: 330
                    },
                    {
                        x: 639,
                        y: 283
                    },
                    {
                        x: 638,
                        y: 232
                    },
                    {
                        x: 618,
                        y: 185
                    },
                    {
                        x: 576,
                        y: 148
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'neuf': {
        backgroundImagePath: 'neuf.png',
        steps: [
            {
                imagePath: 'neuf-step-1.png',
                checkpoints: [
                    {
                        x: 639,
                        y: 206
                    },
                    {
                        x: 521,
                        y: 132
                    },
                    {
                        x: 469,
                        y: 126
                    },
                    {
                        x: 417,
                        y: 134
                    },
                    {
                        x: 370,
                        y: 157
                    },
                    {
                        x: 332,
                        y: 192
                    },
                    {
                        x: 308,
                        y: 238
                    },
                    {
                        x: 297,
                        y: 289
                    },
                    {
                        x: 297,
                        y: 341
                    },
                    {
                        x: 308,
                        y: 392
                    },
                    {
                        x: 334,
                        y: 437
                    },
                    {
                        x: 375,
                        y: 469
                    },
                    {
                        x: 425,
                        y: 484
                    },
                    {
                        x: 477,
                        y: 487
                    },
                    {
                        x: 528,
                        y: 478
                    },
                    {
                        x: 575,
                        y: 455
                    },
                    {
                        x: 614,
                        y: 422
                    },
                    {
                        x: 643,
                        y: 379
                    },
                    {
                        x: 655,
                        y: 328
                    },
                    {
                        x: 655,
                        y: 260
                    }
                ],
                validColors: ['rgb(230,239,245)']
            },
            {
                imagePath: 'neuf-step-2.png',
                checkpoints: [
                    {
                        x: 654,
                        y: 260
                    },
                    {
                        x: 654,
                        y: 388
                    },
                    {
                        x: 654,
                        y: 440
                    },
                    {
                        x: 654,
                        y: 492
                    },
                    {
                        x: 654,
                        y: 544
                    },
                    {
                        x: 649,
                        y: 596
                    },
                    {
                        x: 633,
                        y: 646
                    },
                    {
                        x: 601,
                        y: 687
                    },
                    {
                        x: 557,
                        y: 715
                    },
                    {
                        x: 507,
                        y: 727
                    },
                    {
                        x: 455,
                        y: 729
                    },
                    {
                        x: 404,
                        y: 720
                    },
                    {
                        x: 339,
                        y: 683
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    },
    'zero': {
        backgroundImagePath: 'zero.png',
        steps: [
            {
                imagePath: 'zero-step-1.png',
                checkpoints: [
                    {
                        x: 600,
                        y: 175
                    },
                    {
                        x: 484,
                        y: 126
                    },
                    {
                        x: 432,
                        y: 132
                    },
                    {
                        x: 383,
                        y: 151
                    },
                    {
                        x: 346,
                        y: 188
                    },
                    {
                        x: 320,
                        y: 233
                    },
                    {
                        x: 302,
                        y: 282
                    },
                    {
                        x: 291,
                        y: 333
                    },
                    {
                        x: 286,
                        y: 385
                    },
                    {
                        x: 286,
                        y: 437
                    },
                    {
                        x: 289,
                        y: 489
                    },
                    {
                        x: 295,
                        y: 541
                    },
                    {
                        x: 306,
                        y: 592
                    },
                    {
                        x: 325,
                        y: 640
                    },
                    {
                        x: 355,
                        y: 682
                    },
                    {
                        x: 398,
                        y: 711
                    },
                    {
                        x: 448,
                        y: 726
                    },
                    {
                        x: 500,
                        y: 726
                    },
                    {
                        x: 550,
                        y: 711
                    },
                    {
                        x: 591,
                        y: 679
                    },
                    {
                        x: 622,
                        y: 637
                    },
                    {
                        x: 643,
                        y: 589
                    },
                    {
                        x: 654,
                        y: 538
                    },
                    {
                        x: 661,
                        y: 487
                    },
                    {
                        x: 662,
                        y: 435
                    },
                    {
                        x: 661,
                        y: 383
                    },
                    {
                        x: 658,
                        y: 331
                    },
                    {
                        x: 651,
                        y: 280
                    },
                    {
                        x: 635,
                        y: 231
                    },
                    {
                        x: 608,
                        y: 182
                    }
                ],
                validColors: ['rgb(230,239,245)']
            }
        ]
    }
};

