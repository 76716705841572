import {LayoutConfig} from 'fuse-core/types';

export const layoutConfig: LayoutConfig = {
    layout: {
        style: 'vertical-layout-1',
        width: 'fullwidth',
        navbar: {
            background: 'navbar-background-color',
            folded: false,
            hidden: false,
            position: 'left',
            variant: 'vertical-style-2'
        },
        toolbar: {
            background: 'top-main-toolbar',
            hidden: false,
            position: 'below-static'
        },
        footer: {
            background: 'mat-fuse-dark-900-bg',
            hidden: true,
            position: 'below-fixed'
        },
        sidepanel: {
            hidden: false,
            position: 'right'
        }
    },
    customScrollbars: true
};