import {PdfPatternInterface} from './pdf-pattern.interface';
import {PatternBatch} from './patterns';
import {FontInterface} from './fonts/font.interface';

/**
 * It's a pdf representation used to build a pdf
 * it's designed to use blocs of elements
 */
export class PdfPattern implements PdfPatternInterface {
    public margin = {
        top: 14,
        bottom: 14,
        left: 14,
        right: 14,
    };

    public size = {
        width: 210,
        height: 297
    };

    public orientation: 'portrait' | 'landscape' = 'portrait';
    public unit: 'pt' | 'px' | 'in' | 'mm' | 'cm' | 'ex' | 'em' | 'pc' = 'mm';

    public font: FontInterface = null;
    public fontSize: number = null;

    public filename = '';

    constructor(options?: Partial<PdfPatternInterface>) {
        Object.assign(this, options);
    }

    public readonly header: PatternBatch = new PatternBatch({disposition: 'row'});
    public content: PatternBatch = new PatternBatch({disposition: 'column'});
}