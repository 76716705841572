import {Component, Input, OnInit} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector   : 'blocked-account',
    templateUrl: './blocked-account.component.html',
    styleUrls  : ['./blocked-account.component.scss'],
    animations : fuseAnimations
})
export class BlockedAccountComponent
{

    constructor()
    {
    }
}
