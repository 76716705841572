import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OnCreateDirective} from '@modules/activities/core/directive/onCreate/on-create.directive';
import { FocusDirective } from '@modules/activities/core/directive/focus/focus.directive';
import { ApplyWidthDelayDirective } from './apply-width-delay.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        OnCreateDirective,
        FocusDirective,
        ApplyWidthDelayDirective
    ],
    exports: [
        OnCreateDirective,
        FocusDirective,
        ApplyWidthDelayDirective
    ]

})
export class ActivitiesDirectiveModule {
}
