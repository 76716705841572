import { MatIconRegistry } from '@angular/material/icon';
import { Injectable } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable()
export class SvgIconListBase {

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {}

    mergeIconList(
        themeList: Object,
        defaultList: Object
    ): object {
        const newIconList = defaultList;

        Object.keys(themeList).map(k => {
            newIconList[k] = themeList[k];
        });
        return newIconList;
    }

    iconRegister( iconObj: Object | Object[], pathIconSvg: string ): void {
        for ( const key in iconObj ) {
            if ( key in iconObj ) {
                this.matIconRegistry.addSvgIcon(
                    key,
                    this.domSanitizer.bypassSecurityTrustResourceUrl(pathIconSvg + iconObj[key] + '.svg')
                );
            }
        }
    }

}
