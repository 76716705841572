export class ErrorCases {
    /*static WAITING_FOR_RESPONSE = 'waiting_for_response';
    static HAS_RESPONSE = 'has_response';
    static FATAL_ERROR = 'fatal_error';
    static IN_MAINTENANCE = 'in_maintenance';*/

    static REDIRECTION = 'redirection';
    static MESSAGE = 'message';
    static RELOAD_APP = 'reload_app';
    static RELOAD_PAGE = 'reload_page';
    static WAITING = 'waiting';
    static OK = 'ok';
}
