import {DynamicGraphFilters} from '@modules/graph-humanum/core/model/dynamic-graph-filters';
import * as moment from 'moment';

export const ProgressFilters: DynamicGraphFilters = {
    always: [{
        label: 'learner',
        value: null,
        custom: {
            rules: ['required']
        }
    }],
    hidden: [{
        label: 'startDate',
        value: moment().add(-2, 'weeks').startOf('day').toDate(),
        custom: {
            rules: ['required']
        }
    }, {
        label: 'endDate',
        value: moment().endOf('day').toDate(),
        custom: {
            rules: ['required']
        }
    }, {
        label: 'concept',
        value: null,
        custom: {
            rules: ['autofill:multiLesson']
        }
    }, {
        label: 'educationalLevel',
        value: null,
        custom: {
            rules: ['allowEmpty', 'autofill:multiLesson']
        }
    }, {
        label: 'chapter',
        value: null,
        custom: {
            rules: ['allowEmpty', 'autofill:multiLesson']
        }
    }, {
        label: 'multiLesson',
        value: null,
        custom: {
            rules: ['required', 'autofill:multiSubLesson']
        }
    }, {
        label: 'multiSubLesson',
        value: null,
        custom: {
            rules: ['disabledIfEmpty:multiLesson', 'required']
        }
    }]
};
