import {take, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {Observable} from 'rxjs';
import {LicencingImportComponent} from '@modules/configuration/core/components/licencing-import/licencing-import.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {defaultApiURL} from 'app/settings';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../settings';

const settingsStructure: ModelSchema = new ModelSchema({
    role: Structures.array(),
    show: Structures.boolean(),
    showOtherSync: Structures.boolean(true),
    showUploadExercice: Structures.boolean(false),
    uploadExoConfig: Structures.object({maxSize: 2, formatAllowed: '.zip', pathDownload: ''})

});

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {
    userData: DataEntity;

    migrationCollection: Observable<DataCollection>;

    analitycsCollection: Observable<DataCollection>;
    analyticsDetailsEntity: DataEntity;
    public settings: { [key: string]: any };


    constructor(private communicationCenter: CommunicationCenterService,
                private octopusConnect: OctopusConnectService,
                private dialog: MatDialog) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                this.userData = data;
                if (data) {
                    this.postAuthentication();
                }
            });
    }

    public synchronize(ids: string = ''): void {
        this.octopusConnect.createEntity('migrations', {'sync': true, 'ids': ids});
    }

    public importLicense(uploadedFid: number, methodId: string): void {
        this.octopusConnect.createEntity('licenses-import', {fid: uploadedFid, method: methodId})
            .subscribe((data) => {
                this.dialog.open(LicencingImportComponent, {
                    data: data
                });
            });

    }

    /**
     we use the POST method to retrieve the list of analytics by passing {'instance': 'erasme'} in data
     when we get the response, we use the data in the dashboard
     */
    private loadAnalyticsDetails(): void {
        this.octopusConnect.createEntity('analytics-listing', {'instance': 'erasme'})
            .subscribe((data: DataEntity) => {
                if (this.analyticsDetailsEntity !== data) {
                    this.analyticsDetailsEntity = data;
                    this.communicationCenter.getRoom('configuration')
                        .next('analytics-details', data);
                }
            });
    }

    public loadAnalyticsListing(): Observable<DataEntity[]> {
        this.analitycsCollection = this.octopusConnect.loadCollection('analytics-listing');
        return this.analitycsCollection.pipe(map((collection) => collection.entities));
    }

    public getMigrations(): Observable<DataEntity[]> {
        this.migrationCollection = this.octopusConnect.loadCollection('migrations');
        return this.migrationCollection.pipe(map((collection) => collection.entities));
    }

    public getFileFromUrl(url): any {
        return window.location.href = url;
    }

    public get apiUrl(): string {
        return defaultApiURL;
    }

    postAuthentication(): void {
        this.settings = settingsStructure.filterModel(modulesSettings.configuration);
        this.loadAnalyticsDetails();

        this.communicationCenter.getRoom('configuration')
            .getSubject('refresh-analytics-details')
            .subscribe(() => {
                this.loadAnalyticsDetails();
            });
    }
}
