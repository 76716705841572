import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-in-maintenance-modal',
  templateUrl: './in-maintenance-modal.component.html',
  styleUrls: ['./in-maintenance-modal.component.scss']
})
export class InMaintenanceModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
