import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-my-current-progress-thumbnail',
  templateUrl: './my-current-progress-thumbnail.component.html'
})
export class MyCurrentProgressThumbnailComponent implements OnInit {
    @Input() valueToShow = 0;
    @Input() icon = '';
    @Input() assetType = '';
    @Input() useAvatar = false;
    @Input() showStarAnimation = false;

    constructor() {
    }

    ngOnInit(): void {
    }
}
