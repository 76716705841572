import {DataCollection} from 'octopus-connect';
import {Observable} from 'rxjs';
import {TypedDataEntityInterface} from './typed-data-entity.interface';

export interface TypedDataCollectionInterface<T, U extends TypedDataEntityInterface<T> = TypedDataEntityInterface<T>> extends DataCollection {
    entities: U[];
    entitiesObservables: Observable<U>[];

    registerEntity(entity: U, entityObservable: Observable<U>): void;
}
