import {Injectable} from '@angular/core';
import {AssignationService, AssignmentComponentsService} from "@modules/assignation";
import {OnboardingService} from "@modules/assignation/core/onboarding/onboarding.service";
import {ContextualService} from "@modules/assignation/core/services/contextual.service";

@Injectable({
    providedIn: 'root'
})
export class ServicesInstantiatorService {

    constructor(
        private assignationService: AssignationService,
        private assignmentComponentsService: AssignmentComponentsService,
        private onboardingService: OnboardingService,
        private contextualActions: ContextualService,
    ) {
    }
}
