import { Injectable } from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {combineLatest, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SharedEntitiesService {
  constructor(private communicationCenter: CommunicationCenterService) {
  }

  public loadLearners(): Observable<any[]> {
      return this.communicationCenter
          .getRoom('groups-management')
          .getSubject('learnerList');
  }

  public loadClasses(): Observable<any[]> {
      return this.communicationCenter
          .getRoom('groups-management')
          .getSubject('groupsList');
  }

  public loadWorkgroups(): Observable<any[]> {
      return this.communicationCenter
          .getRoom('groups-management')
          .getSubject('workgroupsList');
  }
}

