import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import {FuseSidebarModule} from 'fuse-core/components';
import {GraphModule} from 'fuse-core/components/graph/graph.module';
import {NewsModule} from 'fuse-core/news/news.module';
import {FlashcardComponent} from 'fuse-core/components/flashcard/flashcard.component';
import {SharedVideoComponent} from 'fuse-core/components/shared-video/shared-video.component';
import {TranslateModule} from '@ngx-translate/core';
import {SharedMaterialModule} from '../app/shared/shared-material.module';
import {BannerInfoComponent} from "fuse-core/components/banner-info/banner-info.component";
import {TipsComponent} from 'fuse-core/components/tips/tips.component';
import {FormDialogComponent} from './components/form-dialog/form-dialog.component';
import {MatLegacySliderModule} from "@angular/material/legacy-slider";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FuseDirectivesModule,
        FusePipesModule,
        FuseSidebarModule,
        GraphModule,
        NewsModule,
        TranslateModule,
        SharedMaterialModule,
        MatLegacySliderModule,
    ],
    exports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        FuseDirectivesModule,
        FusePipesModule,
        FuseSidebarModule,

        GraphModule,
        NewsModule,

        FlashcardComponent,
        SharedVideoComponent,
        BannerInfoComponent,

        TipsComponent
    ],
    declarations: [
        FlashcardComponent,
        SharedVideoComponent,
        BannerInfoComponent,
        TipsComponent,
        FormDialogComponent
    ]
})
export class FuseSharedModule
{
}
