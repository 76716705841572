import {Component, Inject, OnInit, Optional, TemplateRef, ViewChild} from '@angular/core';
import {MindmapService} from '@modules/mindmap/core/mindmap.service';
import {DataCollection, DataEntity} from 'octopus-connect';
import {filter, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {CollectionPaginator} from 'octopus-connect';
import {CollectionOptionsInterface} from 'octopus-connect';
import * as _ from 'lodash-es';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Action} from '@modules/mindmap/core/mindmap-card/mindmap-card.component';
import {SettingsService} from "@modules/mindmap/core/settings.service";
import {ContextualService} from "@modules/mindmap/core/services/contextual.service";
import {AutoUnsubscribeTakeUntilClass} from "../../../../shared/models";

const ACTION_SHOULD_CLOSE_DIALOG: Action[] = ['edit', 'goToLesson'];

/**
 * list of filters. Could be a setting
 */
const filters = ['metadatas_title'];
/**
 * Max items per page
 */
const mindmapListRange = 12;

export interface MindmapListDialogData {
    forceAssociationOnSave: DataEntity;
}

@Component({
    selector: 'app-mindmap-list',
    templateUrl: './mindmap-list.component.html',
})
export class MindmapListComponent extends AutoUnsubscribeTakeUntilClass implements OnInit {

    /**
     * Define if component is ready or not
     */
    public isLoadingDone = false;
    /**
     * List of mindmap given by BasicSearch endpoint it's not activities/granule but seach/granule entities
     */
    public mindmaps: DataEntity[] = [];
    /**
     * Should be {@link listBlock} if there are note or {@link emptyBlock}.
     * It's used for dynamically load more than 2 templates (there are here 3 templates: loading, empty and list)
     * Inspired by the angular documentation.
     */
    public thenBlock: TemplateRef<any> | null = null;
    /**
     * Obtain the current page data relative to the pagination (count, page index, etc.)
     */
    public paginator: CollectionPaginator;
    /**
     * list of filters for {@link SearchFiltersComponent}
     */
    public filters: string[] = filters;
    /**
     * Max number of mindmap per page
     */
    public mindmapListRange = mindmapListRange;
    /**
     * Mindmap listing
     */
    @ViewChild('listBlock', {static: true})
    private listBlock: TemplateRef<any> | null = null;
    /**
     * Empty message for help user to create a mindmap
     */
    @ViewChild('emptyBlock', {static: true})
    private emptyBlock: TemplateRef<any> | null = null;

    constructor(private mindmapSvc: MindmapService,
                private dialog: MatDialog,
                private selfDialogRef: MatDialogRef<MindmapListComponent>,
                private settingsService: SettingsService,
                private contextualService: ContextualService,
                @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: Partial<MindmapListDialogData>) {
        super();
    }

    public get embedded(): boolean {
        return 'id' in this.selfDialogRef;
    };

    public get forceAssociationOnSave(): DataEntity {
        return this.dialogData?.forceAssociationOnSave;
    }

    /**
     * Go to the mindmap creation interface by calling the service and refresh the mindmap after it
     * @param $event
     */
    public createNote($event: MouseEvent): void {
        this.close();
        const defaultAssociation = this.forceAssociationOnSave;
        const defaultValues = defaultAssociation ? {associatedLessonIds: [defaultAssociation.id]} : null;
        this.mindmapSvc.goToMindmapDataCreation(defaultValues, null, this.embedded)
            .afterClosed()
            .pipe(
                filter(resource => !!resource),
                mergeMap(() => this.refreshNotes())
            )
            .subscribe();
    }

    /**
     * Triggered when a mindmap has changed, used to refresh the mindmap data.
     *
     * @param _mindmap BasicSearch granule of mindmap
     */
    public onMindmapChanged(_mindmap: DataEntity): void {
        this.refreshNotes().subscribe();
    }

    /**
     * Triggered when user change page index from paginator
     * @param event
     */
    public onPaginateChange(event): void {
        this.paginator.page = event.pageIndex + 1;
        this.refreshNotes().subscribe();
    }

    ngOnInit(): void {
        this.refreshNotes().subscribe();
        this.setupContextual();
    }

    /**
     * Set the component in loading and get/load the notes
     * By default, only the authenticated user's note are loaded by the service
     *
     */
    refreshNotes(optionsInterface: CollectionOptionsInterface = {}): Observable<DataCollection> {
        const mergedOptionsInterface = _.merge({
            page: this.paginator ? this.paginator.page : 1,
            range: mindmapListRange
        }, optionsInterface);

        this.isLoadingDone = false;
        return this.mindmapSvc.getCurrentUserPaginatedMindmaps(mergedOptionsInterface).pipe(
            tap(paginatedCollection => this.paginator = paginatedCollection.paginator),
            mergeMap(paginatedCollection => paginatedCollection.collectionObservable),
            tap(dataCollection => {
                this.mindmaps = [...dataCollection.entities];
                this.thenBlock = this.mindmaps.length > 0 ? this.listBlock : this.emptyBlock;
                this.isLoadingDone = true;
            }),
            take(1)
        );
    }
    
    private setupContextual(): void {
        this.contextualService.actionMindmapAdd$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(() => this.createNote(null));
    }

    public close(): void {
        if('close' in this.selfDialogRef) {
            this.selfDialogRef.close();
        }
    }

    public onActionTriggered($event: Action): void {
        if (ACTION_SHOULD_CLOSE_DIALOG.includes($event)) {
            this.close();
        }
    }

    public get rolesCanShowBannerInfo(): string[] {
        return this.settingsService.rolesCanShowBannerInfo;
    }

}
