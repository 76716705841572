import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-create-teacher-news',
  templateUrl: './create-teacher-news.component.html',
  styleUrls: ['./create-teacher-news.component.scss']
})
export class CreateTeacherNewsComponent  {

    constructor(public router: Router) {
    }

    public goToInstitutionManagementPage(): void {
        this.router.navigate(['/groups/list/learners']);
    }

}
