import {Injectable} from '@angular/core';
import {CommunicationCenterService} from "@modules/communication-center";
import {Subject} from "rxjs";

/**
 * This service's scope is to :
 *   - receive messages on rooms 'contextualActions', 'contextualConditions' and 'contextualData'
 *   - expose Observables for the components of the module to subscribe to
 *   - have one Observable per action, condition and data handled in the module
 *   - have no other logic implemented. The components and services of the module handle the logic
 */
@Injectable({
    providedIn: 'root'
})
export class ContextualService {
    public actionLessonOpenAssign$ = new Subject<void>();
    public actionLessonPlay$ = new Subject<void>();
    public actionLessonPreview$ = new Subject<void>();
    public actionLessonRestart$ = new Subject<void>();
    public actionLessonSummary$ = new Subject<void>();

    public conditionLessonAssignable$ = new Subject<(value: boolean) => void>();
    public conditionLessonAvailable$ = new Subject<(value: boolean) => void>();
    public conditionLessonCanRestart$ = new Subject<(value: boolean) => void>();
    public conditionLessonHasDocument$ = new Subject<(value: boolean) => void>();

    public dataLessonDocumentCount$ = new Subject<(value: string) => void>();
    public dataLessonCollectionName$ = new Subject<(value: string) => void>();
    public dataLessonNextLessonName$ = new Subject<(value: string) => void>();
    public dataLessonFirstDocumentName$ = new Subject<(value: string) => void>();
    public dataLessonFirstDocumentURL$ = new Subject<(value: string) => void>();
    public dataLessonFilteredCount$ = new Subject<(value: string) => void>();

    constructor(
        private communicationCenter: CommunicationCenterService,
    ) {
        this.communicationCenter
            .getRoom('contextualActions')
            .getSubject('lesson')
            .subscribe((action) => {
                switch (action) {
                    case 'preview':
                        this.actionLessonPreview$.next();
                        break;
                    case 'play':
                        this.actionLessonPlay$.next();
                        break;
                    case 'openAssign':
                        this.actionLessonOpenAssign$.next();
                        break;
                    case 'restart':
                        this.actionLessonRestart$.next();
                        break;
                    case 'summary':
                        this.actionLessonSummary$.next();
                        break;
                }
            });

        this.communicationCenter
            .getRoom('contextualConditions')
            .getSubject('lesson')
            .subscribe(({condition, callback}) => {
                switch (condition) {
                    case 'assignable':
                        this.conditionLessonAssignable$.next(callback);
                        break;
                    case 'available':
                        this.conditionLessonAvailable$.next(callback);
                        break;
                    case 'canRestart':
                        this.conditionLessonCanRestart$.next(callback);
                        break;
                    case 'hasDocument':
                        this.conditionLessonHasDocument$.next(callback);
                        break;
                }
            });

        this.communicationCenter
            .getRoom('contextualData')
            .getSubject('lesson')
            .subscribe(({data, callback}) => {
                switch (data) {
                    case 'documentCount':
                        this.dataLessonDocumentCount$.next(callback);
                        break;
                    case 'collectionName':
                        this.dataLessonCollectionName$.next(callback);
                        break;
                    case 'nextLessonName':
                        this.dataLessonNextLessonName$.next(callback);
                        break;
                    case 'firstDocumentName':
                        this.dataLessonFirstDocumentName$.next(callback);
                        break;
                    case 'firstDocumentURL':
                        this.dataLessonFirstDocumentURL$.next(callback);
                        break;
                    case 'filteredCount':
                        this.dataLessonFilteredCount$.next(callback);
                        break;
                }
            });
    }
}
