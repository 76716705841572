import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import {Observable} from 'rxjs';
import {AuthorizationService} from '@modules/authorization';
import {SyncRules} from '@modules/groups-management/core/models/rules';
import {defaultRoute} from '../../../../settings';

@Injectable({
    providedIn: 'root'
})
export class IsUserCanEditLicensesGuard  {
    constructor(private authorization: AuthorizationService, private router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const canActivate = this.authorization.currentUserCan(SyncRules.AccessTrainersAndDirectorsManagementPanel);
        if (canActivate === false) {
            this.router.navigate([defaultRoute]);
        }

        return canActivate;
    }

}
