import {Inject, Injectable, InjectionToken} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {Platform} from '@angular/cdk/platform';
import {Observable, ReplaySubject} from 'rxjs';
import {filter, mergeMap, take} from 'rxjs/operators';
import {LayoutConfig} from 'fuse-core/types';
import {RecursivePartial} from '../../app/shared/utils';
import {cloneDeep, isEqual, merge} from 'lodash-es';

// Create the injection token for the custom settings
export const LAYOUT_CONFIG = new InjectionToken('layoutCustomConfig');

@Injectable({
    providedIn: 'root'
})
export class LayoutConfigService {
    // Private
    private configSubject$ = new ReplaySubject<LayoutConfig>(1);
    private readonly defaultConfig: LayoutConfig;

    /**
     * Constructor
     *
     * @param {Platform} _platform
     * @param {Router} _router
     * @param _config
     */
    constructor(
        private _platform: Platform,
        private _router: Router,
        @Inject(LAYOUT_CONFIG) private _config: LayoutConfig
    ) {
        // Set the default config from the user provided config (from forRoot)
        this.defaultConfig = _config;

        // Initialize the service
        this._init();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set and get the config
     */
    set config(value: LayoutConfig) {
        this.setConfig(value, {emitEvent: false});
    }

    get config$(): Observable<LayoutConfig> {
        return this.configSubject$.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Initialize
     *
     * @private
     */
    private _init(): void {
        /**
         * Disable custom scrollbars if browser is mobile
         */
        if (this._platform.ANDROID || this._platform.IOS) {
            this.defaultConfig.customScrollbars = false;
        }

        // Set the config from the default config
        this.configSubject$.next(cloneDeep(this.defaultConfig));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set config
     *
     * @param value
     * @param {{emitEvent: boolean}} opts
     */
    setConfig(value: RecursivePartial<LayoutConfig>, opts = {emitEvent: true}): void {
        this.configSubject$.pipe(
            take(1),
        ).subscribe((config) => {
            config = merge({}, config, value);
            this.configSubject$.next(config);

            if (opts.emitEvent === true) {
                // Notify the observers
                this.configSubject$.next(config);
            }
        });
    }
}

