import {filter, takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash-es';

import {LayoutConfigService} from 'fuse-core/services/layout-config.service';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';

import {navigation} from 'app/navigation/navigation';

import {AuthenticationService} from '@modules/authentication/core/authentication.service';
import {AccountManagementProviderService} from '@modules/account-management/core/account-management-provider.service';
import {DataEntity} from 'octopus-connect';
import {NotificationsService} from '@modules/notification/core/notifications.service';
import {brand, modulesSettings, langs, brandLogoSvg, tralalereBar} from '../../../settings';
import {ModelSchema, Structures} from 'octopus-model';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {DynamicNavigationService} from "../../../navigation/dynamic-navigation.service";
import {Roles, RolesOrDefault} from 'shared/roles';
import {IsAllowedBySettings} from "../../../guards/is-allowed-by-settings.class";

const settingsToolbar: ModelSchema = new ModelSchema({
    displayRole: Structures.boolean(false),
    showContact: Structures.object({ default: false }),
    showContactAsGar: Structures.object({ default: false }),
    feedbackMenu: Structures.object({ default: false }),
    feedbackMenuAsGar: Structures.object({ default: false }),
    isGoToLoginPageShortcutAllowed: Structures.boolean(true),
    helpMenu: Structures.object({default: true}),
    notificationButtonAccess: Structures.boolean(),
    showTechnicalProblemMessage: Structures.boolean(false),
    showWebAppBtn: Structures.boolean(false),
    translationAccess: Structures.object({show: false, withFlag: true}),
    showLangChoiceWhenPlayLesson: Structures.boolean(true),
});
const settingsNotification: ModelSchema = new ModelSchema({
    show: Structures.boolean(),
});
const settingsLicensing: ModelSchema = new ModelSchema({
    visible: Structures.boolean(false),
});

const settingsTralalereToolbar: ModelSchema = new ModelSchema({
    displayed: Structures.boolean(false),
});

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})

export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: {id: string}[];
    navigation: unknown;
    selectedLanguage: unknown;
    userStatusOptions: unknown[];
    userInformation: DataEntity;
    public brand = brand;
    public brandLogoSvg = brandLogoSvg;
    public langs = langs;

    /** @deprecated TODO utiliser un service de configuration */
    public settings: {
        showLangChoiceWhenPlayLesson: boolean;
        showTechnicalProblemMessage: boolean;
        helpMenu: { [k in RolesOrDefault]: boolean };
        translationAccess: { show: boolean; withFlag: boolean };
        isGoToLoginPageShortcutAllowed: boolean;
        showContactAsGar: { [k in RolesOrDefault]: boolean };
        showContact: { [k in RolesOrDefault]: boolean };
        feedbackMenu: { [k in RolesOrDefault]: boolean };
        feedbackMenuAsGar: { [k in RolesOrDefault]: boolean };
    };
    /** @deprecated TODO utiliser un service de configuration */
    public settingsForNotification: {
        show: boolean;
    };
    /** @deprecated TODO utiliser un service de configuration */
    public showTranslateMenu: boolean;
    public showFlagImg: boolean;
    public showNotification: boolean;
    /** @deprecated TODO utiliser un service de configuration */
    public settingsTralereToolbar: { };
    public isHelpPage = false;
    public hideLangueMenu = false;

    // Private
    private _unsubscribeAll: Subject<void>;

    /**
     * Constructor
     */
    constructor(
        private dynamicNavigation: DynamicNavigationService,
        private layoutConfigService: LayoutConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        public authenticationService: AuthenticationService,
        public accountService: AccountManagementProviderService,
        private notificationsService: NotificationsService,
        private isRouteAccessible: IsAllowedBySettings,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        this.settings = <any> settingsToolbar.filterModel(modulesSettings.featureAccess);
        this.settingsForNotification = <any> settingsNotification.filterModel(modulesSettings.notification);
        this.settingsTralereToolbar = <any> settingsTralalereToolbar.filterModel(tralalereBar);

        // Set the defaults
        this.userStatusOptions = [
            {
                'title': 'Online',
                'icon': 'icon-checkbox-marked-circle',
                'color': '#4CAF50'
            },
            {
                'title': 'Away',
                'icon': 'icon-clock',
                'color': '#FFC107'
            },
            {
                'title': 'Do not Disturb',
                'icon': 'icon-minus-circle',
                'color': '#F44336'
            },
            {
                'title': 'Invisible',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#BDBDBD'
            },
            {
                'title': 'Offline',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#616161'
            }
        ];

        this.languages = this.langs;

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject<void>();

        router.events.subscribe(() => {
            this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd))
                .subscribe(event => {
                    this.isHelpPage = event.url.indexOf('/home') > -1;
                    this.hideLangueMenu = !this.settings.showLangChoiceWhenPlayLesson && event.url.indexOf('/assignment/lessons') > -1;
                });
        });
    }

    public get showTechnicalProblemMessage(): boolean {
        return this.settings.showTechnicalProblemMessage && this._translateService.currentLang &&
            this._translateService.translations[this._translateService.currentLang] &&
            this._translateService.translations[this._translateService.currentLang].generic &&
            this._translateService.translations[this._translateService.currentLang].generic.technical_problem;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    public get showHelp(): boolean {
        if (Object.keys(this.settings.helpMenu).includes(this.authenticationService.accessLevel)) {
            return this.settings.helpMenu[this.authenticationService.accessLevel];
        }

        return this.settings.helpMenu['default'];
    }

    public get showLicensing(): boolean {
        return settingsLicensing.filterModel(modulesSettings.licensing).visible && !this.authenticationService.isManager();
    }

    get notificationsCount(): number {
        return this.notificationsService.unreadNotificationsCount;
    }

    public get username(): string {
        if (this.userInformation) {
            if (this.userInformation.get('nickname')
                && this.userInformation.get('nickname') !== this.userInformation.get('label')) {
                return this.userInformation.get('nickname');
            }

            if (this.userInformation.get('label')) {
                return this.userInformation.get('label');
            }
        }

        return '';
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.showTranslateMenu = this.settings.translationAccess.show;
        this.showFlagImg = this.settings.translationAccess.withFlag;
        this.showNotification = this.settingsForNotification.show;
        // Subscribe to the config changes
        this.layoutConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {'id': this._translateService.currentLang});

        this.accountService.data.subscribe(_userInformation => {
            this.userInformation = this.accountService.loggedUser;
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;
        localStorage.setItem('lang', lang.id);

        // Use the selected language for translations
        this._translateService.use(lang.id);

        // Update dynamic menu with new language
        this.dynamicNavigation.generateMenu(this.dynamicNavigation.currentMenuId, true);
        
        // check route with access settings for new language
        this.isRouteAccessible.checkRouteAllowedOrRedirect(this.route.snapshot, this.router.routerState.snapshot);
    }

    logOut(): void {
        this.authenticationService.logoutFrom('http');
    }

    goToLoginPage(): void {
        this.router.navigate(['/login']);
    }

    public goToSite(pathKey: string): void {
        this._translateService.get(pathKey).subscribe((translation: string) => {
            window.open(translation, '_blank');
        });
    }

    public isRoleDisplayable(): boolean {
        return _.get(this.settings, 'displayRole', false);
    }

    public getRole(): string {
        return this.authenticationService.accessLevel;
    }

    public shouldDisplayGoToLoginPage(): boolean {
        const isAuthenticated = this.authenticationService.isAuthenticated === false;
        const isButtonAllowedByDefault = this.settings.isGoToLoginPageShortcutAllowed === true;

        return isAuthenticated && isButtonAllowedByDefault;
    }

    private getSettingForRole<T, U extends {[k in RolesOrDefault] : T}>(role: Roles, setting: U): T {
        return setting[role] || setting.default;
    }

    public showContact() {
        const role = <Roles> this.authenticationService.accessLevel;

        if(this.authenticationService.isGAR()) {
            return this.getSettingForRole(role, this.settings.showContactAsGar);
        } else {
            return this.getSettingForRole(role, this.settings.showContact);
        }
    }

    public showFeedBack() {
        if(this.authenticationService.isGAR() === false) {
            const role = <Roles> this.authenticationService.accessLevel;
            return this.getSettingForRole(role, this.settings.feedbackMenu);
        }

        return false;
    }

    public showFeedBackGar() {
        if(this.authenticationService.isGAR()) {
            const role = <Roles> this.authenticationService.accessLevel;
            return this.getSettingForRole(role, this.settings.feedbackMenuAsGar);
        }

        return false;
    }

    public navigateToDefaultRoute(): void {
        this.authenticationService.overrideDefaultRouteInRegardOfRole(true);
    }

    public navigateToContact() {
        this.router.navigate(['/feedback']);
    }

    public navigateToFeedback(): void {
        this.router.navigate(['/page/feedback']);
    }

    public navigateToFeedbackGar() {
        this.router.navigate(['/page/feedback-gar']);
    }
}
