import {ChangeDetectorRef, Component} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {BaseActivityComponent} from '../base-activity.component';
import {ActivityReferenceInterface} from '@modules/activities/core/models/activity-reference.interface';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '../../activities.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {v4 as uuidv4} from 'uuid';
import {FlashCardInterface} from 'fuse-core/components/flashcard/flash-card.interface';
import {LessonNavigationService} from '@modules/activities/core/lesson-navigation.service';
import {LessonsService} from '@modules/activities';
import {uniqBy} from 'lodash-es';
import {ContextualService} from "@modules/activities/core/services/contextual.service";

// format de l'activity_content
interface AwarenessActivityContentInterface {
    flashcards: FlashCardInterface[];
}


// format du champ config de l'activité
interface AwarenessActivityConfigInterface {
    instructionMode?: 'picture' | undefined;
}

// format du champ référence de l'activité
type AwarenessActivityReferenceInterface = ActivityReferenceInterface<AwarenessActivityContentInterface[], AwarenessActivityConfigInterface>;

@Component({
    selector: 'app-awareness',
    templateUrl: './awareness.component.html'
})
export class AwarenessComponent extends BaseActivityComponent<AwarenessActivityContentInterface[], AwarenessActivityConfigInterface> {
    public uuid: string = uuidv4();
    public availableFlashCards: FlashCardInterface[] = [];
    public flashCardStopPlayingOrderSubject?: Subject<string[]> = new Subject();
    private referenceActivityGranule: AwarenessActivityReferenceInterface;
    private selectedFlashCards: FlashCardInterface[] = [];

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected activitiesService: ActivitiesService,
        protected lessonsService: LessonsService,
        private ref: ChangeDetectorRef,
        protected communicationCenter: CommunicationCenterService,
        protected contextualService: ContextualService,
        protected lessonNavigationService: LessonNavigationService,
    ) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, contextualService, lessonNavigationService);
    }

    public onStateChange($event: 'is-playing' | 'is-selected', flashcard: FlashCardInterface): void {
        if ($event === 'is-selected') {
            this.selectedFlashCards = uniqBy([...this.selectedFlashCards, flashcard], 'id');
        }
        if ($event === 'is-playing') {
            this.flashCardStopPlayingOrderSubject.next(this.availableFlashCards.filter(fc => fc.id !== flashcard.id).map(fc => fc.id));
        }
    }

    public isDone(): boolean {
        return this.selectedFlashCards.length === this.availableFlashCards.length || this.isAwarenessWithoutAudioMedia();
    }
    public get numberOfFlashCards(): number|null {
        return this.availableFlashCards.length;
    }

    public isAwarenessWithoutAudioMedia(): boolean {
        return !!this.availableFlashCards.find(f => !(f?.audio?.uri));
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected setAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected getGrade(): { oldGrade: number; newGrade: number; } {
        throw new Error('Method not implemented.');
    }

    protected setContentData(data): void {
        this.selectedFlashCards = [];
        this.isTTSSpeaking = null;
        this.referenceActivityGranule = data.reference;
        this.instruction = this.referenceActivityGranule.instruction;
        this.wording = this.referenceActivityGranule.wording;
        this.instructionAudio = this.referenceActivityGranule.instructionAudio;
        this.wordingAudio = this.referenceActivityGranule.wordingAudio;
        this.availableFlashCards = this.referenceActivityGranule.activity_content.reduce((acc, ac) => [...acc, ...ac.flashcards], []);
        if (this.isAwarenessWithoutAudioMedia()) {
            // if no audio media button must appear automatically after a few time
            // we change default setting in this case
            super.addExceptionsForButtonsFromActivity([
                {
                    type: 'next',
                    display: true,
                    disable: true,
                    options: {
                        awareness: {
                            disable: {
                                case: 'disableWithDelay',
                                value: 3000,
                            },
                        }
                    }
                }
            ]);
        }
    }

    // API Save Answer functionality
    protected saveAnswer(): Observable<null> {
        return of(null);
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }


    /**
     * change state of TTS
     * TODO better to move in base-activity, need to add ref (ChangeDetectorRef) in contructor
     * @param data
     */
    public speakStateChanged(data: { id: string, value: boolean }): void {
        this.isTTSSpeaking = data;
        this.ref.detectChanges();
        if (!this.wordingAlreadyReadWithTts && this.isTTSSpeaking && !this.isTTSSpeaking.value && this.uuid !== this.isTTSSpeaking.id) {
            this.clickToTts();
        }
        if (this.isTTSSpeaking && !this.isTTSSpeaking.value && this.uuid !== this.isTTSSpeaking.id) {
            this.wordingAlreadyReadWithTts = true;
        }
    }

    protected validate(): void {
        throw new Error('Method not implemented.');
    }

    /**
     * return the main class needed to style the flashcard
     * @param flashCard
     */
    public flashCardClass(flashCard: FlashCardInterface): string {
        const isAudio = flashCard?.audio?.uri ? ' is-audio' : 'no-audio';
        return flashCard?.image ? ' button-image ' + isAudio : '' + isAudio;
    }

}