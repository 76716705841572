import {Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { ReplaceTextPipe } from '../../pipes/replace-text.pipe';
import {TranslateService} from '@ngx-translate/core';

interface DayRelativeNameToStringOptions {
    language_key?: string;
}

@Directive({
    selector: '[appDayRelativeNameToString]',
})
export class DayRelativeNameToStringDirective implements OnChanges, OnInit {
    targetMapping = {
        ['{today}']: '{today}',
        ['{tomorrow}']: '{tomorrow}',
        ['{yesterday}']: '{yesterday}',
        ['{after-tomorrow}']: '{after-tomorrow}',
    };

    @Input() appDayRelativeNameToString: DayRelativeNameToStringOptions = {
        language_key: null
    }

    constructor(
        private replaceTextPipe: ReplaceTextPipe,
        private translate: TranslateService,
        private element: ElementRef
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.updateText();
    }

    ngOnInit(): void {
        this.updateMapping();
        this.updateText();
    }

    updateMapping(): void {
        let today = new Date();
        let tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);
        let yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        let aftertomorrow = new Date();
        aftertomorrow.setDate(today.getDate() + 2);

        const toText = (d: Date) =>
            d.toLocaleDateString('en', { weekday: 'long' }).toLowerCase();

        const languageKey = this.appDayRelativeNameToString.language_key ? this.appDayRelativeNameToString.language_key : 'default';

        this.targetMapping['{today}'] = this.translate.instant(`day_relative.${languageKey}_${toText(today)}`);
        this.targetMapping['{tomorrow}'] = this.translate.instant(`day_relative.${languageKey}_${toText(tomorrow)}`);
        this.targetMapping['{yesterday}'] = this.translate.instant(`day_relative.${languageKey}_${toText(yesterday)}`);
        this.targetMapping['{after-tomorrow}'] = this.translate.instant(`day_relative.${languageKey}_${toText(aftertomorrow)}`);
    }

    updateText(): void {
        const oldValue = this.element.nativeElement.innerHTML;
        const newValue = this.replaceTextPipe.transform(oldValue, this.targetMapping);
        this.element.nativeElement.innerHTML = newValue;
    }
}
