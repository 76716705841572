import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';

/**
 * format d'une réponse pour les activités
 * Todo: faire en sorte que chaque activité ait sa propre interface pour faciliter la comprehension exemple: QcmItemAnswerInterface extends ItemAnswerInterface {}
 */
export interface ItemAnswerInterface {
    state: ItemAnswerStateEnum;
    id: string;
    answer: string;
    // for all activity with user-save's endpoint qcm-save
    correct_answer?: boolean;
    select?: boolean;
    image?: string;
    // Champ utilisé pour former la réponse (text-matching)
    target?: string;
    // champ utilisé pour former la consigne (text-matching)
    source?: string;
    // optionnal help to show on bad answers
    feedback?: string;
    // optionnal audio file join to answer
    audio?: IMediaInterface;
    // optionnal icon for button
    icon?: string;
}

export interface IMediaInterface {
    // id of media
    id: string;
    // url of content
    uri: string;
    // alternative value for accessibility
    alt: string;
}
