import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";

@Injectable()
export class CorpusMenuResolve  {
  constructor() {}

  resolve(route: ActivatedRouteSnapshot) {

    let checkRoute = route;

    while (checkRoute.parent && !checkRoute.params['projectId']) {
      checkRoute = checkRoute.parent;
    }

    if (checkRoute.params['projectId']) {
      return 'project' + checkRoute.params['projectId'];
    } else {
      return 'level0';
    }
  }
}
