
import {take, map, combineLatest} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {CommunicationCenterService} from '../../communication-center/index';
import {Observable, Subscription, ReplaySubject} from 'rxjs';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../settings';
import {AuthenticationService} from '@modules/authentication';
import {Graph} from './progression/progression-display/graph';

const projectSettingsStructure: ModelSchema = new ModelSchema(({
    accessProject: Structures.boolean()
}));

const graphSettingsStructure: ModelSchema = new ModelSchema(({
    learner: Structures.array(),
    default: Structures.array(['group', 'learner', 'startDate', 'dueDate'])
}));

@Injectable({
    providedIn: 'root'
})
export class GraphAssignationService {

    public assignmentGroupListPaginated: any;
    private assignmentsGroupListPaginatedObs: Observable<DataEntity[]>;
    private assignments = [];
    followedSubscription: Subscription;
    learnersList: any[];
    groupsList: any[];
    workgroupsList: any[];
    selectedProject: any;
    userData: DataEntity;
    public projectSettings: { [key: string]: any };
    public graphSettings: { [key: string]: any };

    constructor(private communicationCenter: CommunicationCenterService,
                private octopusConnect: OctopusConnectService,
                private authService: AuthenticationService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                this.userData = data;
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
        this.projectSettings = projectSettingsStructure.filterModel(modulesSettings.projectsManagement);
        this.graphSettings = graphSettingsStructure.filterModel(modulesSettings.graphAssignation);
    }

    loadPaginatedAssignmentGroupList(filterOptions = {}): Observable<Object[]> {
        this.assignmentGroupListPaginated = this.octopusConnect.paginatedLoadCollection('assignations-group', filterOptions);
        this.assignmentsGroupListPaginatedObs = this.assignmentGroupListPaginated.collectionObservable.pipe(map(collection => collection['entities']));

        return this.assignmentsGroupListPaginatedObs;
    }


    private postLogout(): void {
        if (this.followedSubscription) {
            this.followedSubscription.unsubscribe();
            this.followedSubscription = null;
        }
    }

    private postAuthentication(): void {
        const learners = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('learnerList');
        const groups = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('groupsList');


        if (this.projectSettings && this.projectSettings.accessProject) {
            const project = this.communicationCenter
                .getRoom('project-management')
                .getSubject('selectedProject');

            project.pipe(combineLatest(learners, groups)).subscribe((data: [any, any, any]) => {
                this.selectedProject = data[0];
                this.learnersList = data[1];
                this.groupsList = data[2].filter((group) => !group.archived && group.projects.find((project) => +project === +this.selectedProject.id));
            });

        } else {
            learners.pipe(combineLatest(groups)).subscribe((data: [any, any]) => {
                this.learnersList = data[0];
                this.groupsList = data[1].filter((group) => !group.archived);
            });
        }
    }

    public set setAssignments(data) {
        this.assignments = [];
        this.assignments.push(...data);
    }

    public get isUserTrainer(): boolean {
        return this.authService.isAtLeastTrainer();
    }

    loadAssignationsGroupTypes(): Observable<any[]> {
        return this.octopusConnect.loadCollection('variables/instance').pipe(
            map((collection: DataCollection) => collection.entities[0].get('assignationsGroupTypes')),
            take(1),);
    }
}
