export const authentication = {
    activeChangePasswordStrategy: true,
    validateEmailStrategyActivated: false,
    displayGARHelper: false,
    displayLoginLogo: true,
    enableGAR: true,
    enableSSO: false,
    firstConnexionRedirection: {
        trainer: '/groups/list/groups'
    },
    overrideDefaultRouteByRole: {
        learner: '/home',
        trainer: '/home',
    },
};
