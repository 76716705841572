import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from 'app/app.module';
import { environment } from 'environments/environment';
import { hmrBootstrap } from 'hmr';
import * as Sentry from "@sentry/angular-ivy";


if ( environment.production )
{
    enableProdMode();
} else {
    Sentry.init({
        environment: "preprod",
        dsn: "https://5ac1afc30bf94eec833fabc9ca856c23@o4503947987189760.ingest.sentry.io/4503959858905088",
        integrations: [
            new Sentry.BrowserTracing({
                tracingOrigins: ["localhost", "https://preprod.lms-futureengineer.tralalere.com", "https://preprod.lms.tralalere.com"],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
        ],
        enabled: false,
        enableTracing: false,
        tracesSampleRate: 1.0,
    });
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if ( environment.hmr )
{
    if ( module['hot'] )
    {
        hmrBootstrap(module, bootstrap);
    }
    else
    {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
}
else
{
    bootstrap().catch(err => console.log(err));
}
