import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {fuseAnimations} from 'fuse-core/animations';
import {Router} from '@angular/router';

import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'fuse-app-tags-list',
    templateUrl: './tags-selection-modal-wrapper.component.html',
    styleUrls: ['./tags-selection-modal-wrapper.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class TagsSelectionModalWrapperComponent implements OnInit {
    public displayedColumns = ['label'];
    public dataSource = new MatTableDataSource([]);
    public methods: any;
    public tags: any;
    public entity: any = {};
    public type: string;
    public tagsSelected: object[] = [];
    entityForm: UntypedFormGroup;
    public titleFilter: UntypedFormControl;

    loadTags: any;
    public tagsChanged: BehaviorSubject<any> = new BehaviorSubject([]);

    constructor(
        public dialog: MatDialog,
        private router: Router,
        public dialogRef: MatDialogRef<TagsSelectionModalWrapperComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
    ) {
        if (data.data && data.data.length > 0) {
            this.dataSource.data = this.tagsSelected = data.data;
        }
        this.type = data.type;
        this.tagsChanged = data.tagsChanged;
        this.tags = data.tags;
        this.loadTags = data.loadTags;
        this.getTags();

    }

    ngOnInit(): void {
        this.titleFilter = new UntypedFormControl('');
        this.titleFilter.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(query => {
                if (query.trim()) {
                    this.dataSource.data = this.filter(query);
                } else {
                    this.dataSource.data = this.tagsSelected;
                }
            });

    }

    getTags(): void {
        this.loadTags(this.type);
        this.tagsChanged
            .subscribe((data) => {
                if (data) {
                    this.tags = data;
                }
            });
    }

    filter(name: string): Array<string> {
        return this.tags.filter(chip =>
            chip.name.toLowerCase().indexOf(name.trim().toLowerCase()) > -1);
    }
}

