import {ConceptAttributes, ConceptEntity, TypedDataEntityInterface} from '../../../../shared/models';

export interface FormatEntityAttributes {
    'id': string,
    'label': string,
}

export interface LessonMetaEntityAttributes {
    id: string,
    title: string,
    language: 'fr',
    description: string,
    indexation: unknown[],
    chapters: unknown[],
    chaptersParent: unknown[],
    licenseContent: unknown[],
    concepts: ConceptAttributes[],
    skills: unknown[],
    difficulty: unknown[],
    typology: unknown[],
    educationalLevel: {id: string, label: string},
    lessonType: {id: string, label: string} | {id: string, label: string}[],
    assignation_type: { label: string },
    typicallearningtime: unknown[],
    // timestamp
    created: string,
    // timestamp
    changed: string,
    // id
    author: string,
    "source-author": string,
    thumbnail: unknown,
    files: unknown,
}

export interface LessonReference {
    'id': string,
    'type': 'activity' | 'lesson',
    'title': string,
}

export interface LessonEntityAttributes {
    // ID
    lesson_id: string,
    promoted: unknown,
    format: FormatEntityAttributes,
    metadatas: LessonMetaEntityAttributes,
    reference: LessonReference[],
    infos: {
        containLessons: boolean,
        activities: unknown,
        lessons: unknown,
        others: unknown
    },
    // ID
    owner: string
    "owner-name": string,
    "owner-role": string,
    bookmarks: boolean,
    consulted: boolean | {flagging_id: unknown},
    evaluation: boolean,
    archived: boolean,
    locked: boolean,
    shared: boolean,
    model: boolean,
    changed: Date,
    weight: unknown[],
    created: Date,
    assignatedCount: number,
    multiStep: boolean,
    duplicationCount: number,
    file: unknown,
    theme: unknown[],
    usage: unknown[],
    original: string,
    autocorrection: unknown,
    "bookmarks-theme": boolean,
    requirementsAccess: undefined | {access: unknown},
}

export type LessonEntity = TypedDataEntityInterface<LessonEntityAttributes>