import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from '@modules/authentication/core/authentication.service';
import {Router} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {ReplaySubject} from 'rxjs';
import {CustomTooltipSetting} from 'fuse-core/components/graph/graph-details-modal/custom-tooltip.setting';

@Component({
  selector: 'app-graph-details-modal',
  templateUrl: './graph-details-modal.component.html',
})
export class GraphDetailsModalComponent implements OnInit {

    @Input() infoSettings: ReplaySubject<{}>;
    public customSettingsTooltip: CustomTooltipSetting;

    constructor() { }

    ngOnInit(): any {
        this.infoSettings.subscribe((data: CustomTooltipSetting) => {
            this.customSettingsTooltip = data;
        });
    }
}
