import {Directive, ElementRef, Input, OnChanges, OnInit} from '@angular/core';

/**
 * use to apply a width after a delay to use css transition effect
 * to see an effect width must have a previous value and change
 * example css to apply transition: width 300ms ease 0s
 */
@Directive({
    selector: '[appApplyWidthDelay]'
})
export class ApplyWidthDelayDirective implements OnInit, OnChanges {
    @Input() width = 0;
    @Input() delay = 100; // default delay in ms

    constructor(private el: ElementRef) {
    }

    ngOnInit(): void {
        this.applyWidthAfterDelay();
    }

    private applyWidthAfterDelay(): void {
        setTimeout(() => {
            this.el.nativeElement.style.width = this.width + '%';
        }, this.delay);

    }

    ngOnChanges(): void {
        this.applyWidthAfterDelay();
    }
}
