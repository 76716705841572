// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
}
:host * {
  box-sizing: border-box;
}
:host .hidden {
  display: none;
}
:host .animal-container {
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
:host .animal-container app-animal-controls {
  position: absolute;
  width: 100%;
  height: 100%;
}
:host .animal-container canvas {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/gamification/core/animal-canvas/animal-canvas.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AACI;EACI,sBAAA;AACR;AAEI;EACI,aAAA;AAAR;AAGI;EACI,YAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;AADR;AAGQ;EACI,kBAAA;EACA,WAAA;EACA,YAAA;AADZ;AAIQ;EACI,YAAA;AAFZ","sourcesContent":[":host {\r\n    height: 100%;\r\n\r\n    * {\r\n        box-sizing: border-box;\r\n    }\r\n\r\n    .hidden {\r\n        display: none;\r\n    }\r\n\r\n    .animal-container {\r\n        height: 100%;\r\n        overflow: hidden;\r\n        display: flex;\r\n        justify-content: center;\r\n\r\n        app-animal-controls {\r\n            position: absolute;\r\n            width: 100%;\r\n            height: 100%;\r\n        }\r\n\r\n        canvas {\r\n            height: 100%;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
