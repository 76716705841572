import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';


@Directive({
  selector: '[ariaLabelTranslate]'
})
export class AriaLabelTranslateDirective implements OnInit {

  @Input('ariaLabelTranslate') translation: string;

  constructor(
      private element: ElementRef,
      private translate: TranslateService
  ) { }

  ngOnInit() {
    this.element.nativeElement.setAttribute('aria-label', this.translation);

      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        setTimeout(() => {
          this.element.nativeElement.setAttribute('aria-label', this.translation);
        }, 200);
      });
  }


}
