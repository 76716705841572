export interface ActivityReferenceInterface<T, U> {
    wording: string;
    wordingAudio: string;
    activity_content: T;
    instruction: string;
    instructionAudio: string;
    config: U;
    feedback?: any;
    finalFeedback?: string;
    typology?: {
        label: string
    };
}
