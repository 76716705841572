import {Component, Inject, ElementRef, ViewChild, NgZone} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {AuthenticationService} from '@modules/authentication';

export interface OpenBadge {
    id: string;
    name: string;
    image: string;
    type: string;
}

@Component({
    selector: 'rewards-dialog',
    templateUrl: './rewards-dialog.component.html',
    styleUrls: ['./rewards-dialog.component.scss']
})
export class RewardsDialogComponent {
    public authorizeExit = true;
    public titleDialog: string;
    public bodyDialog: string;
    public labelExit: string;
    public labelNext: string;
    public coinsName: string;
    public image: string;
    public firstTimeExperience: boolean;
    public coins: number;
    public achievements: OpenBadge[];

    /**
     * Constructor
     *
     * @param domSanitizer
     * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
     * @param data
     */
    constructor(
        private domSanitizer: DomSanitizer,
        public dialogRef: MatDialogRef<RewardsDialogComponent>,
        private authService: AuthenticationService,
        private zone: NgZone,
        @Inject(MAT_DIALOG_DATA) data) {

        this.authorizeExit = data.authorizeExit;
        this.titleDialog = data.titleDialog;
        this.bodyDialog = data.bodyDialog;
        this.labelNext = data.labelNext;
        this.labelExit = data.labelExit;
        this.coins = data.userSave.get('config')?.points || 0;
        this.coinsName = data.coinsName;
        this.achievements = data.userSave.get('config')?.achievements || [];
        this.image = data.image;
        this.firstTimeExperience = data.firstTimeExperience;

        // quick fix : modal is on top of iframe and angular wait for focus to resume
        this.zone.run(() => {});

    }

    public anyRewards(): boolean {
        return (-this.coins > 0 || this.achievements.length > 0 || this.firstTimeExperience) && this.authService.isLearner();
    }
}
