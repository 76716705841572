import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {CollectionPaginator, DataEntity} from 'octopus-connect';
import {InstitutionUsersService} from '@modules/groups-management/core/services/institution-users-service/institution-users.service';
import {filter, mergeMap, takeUntil, tap} from 'rxjs/operators';
import {CollectionOptionsInterface} from 'octopus-connect/lib/models/collection-options.interface';
import {fuseAnimations} from 'fuse-core/animations';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {EditUserComponent} from '@modules/groups-management/core/institution-management/institution-users/edit-user/edit-user.component';
import {Observable, of} from 'rxjs';
import * as _ from 'lodash-es';
import {AuthorizationService} from '@modules/authorization';
import {SyncRules} from '@modules/groups-management/core/models/rules';
import {Subject} from 'rxjs';
import {User} from '@modules/groups-management/core/models/user-search-data-entity';
import {TranslateService} from '@ngx-translate/core';
import {GroupManagementConfigurationService} from '@modules/groups-management/core/services/group-management-configuration.service';

@Component({
    selector: 'app-institution-users',
    templateUrl: './institution-users.component.html',
    animations: fuseAnimations
})
export class InstitutionUsersComponent implements OnInit, OnDestroy {
    public displayedColumns: string[] = [];
    public countEntities = 0;
    public pageIndex = 1;
    public pageRange = 10;
    public pageRangeOptions: number[] = [10];
    public pending: boolean;
    public selectAll: false;
    public checkboxes: { [key: string]: boolean } = {};
    public usersInTable: User[];

    private paginator: CollectionPaginator;
    private unsubscribeTakeUntil: Subject<void>;

    constructor(private institutionUsersService: InstitutionUsersService,
                private dialog: MatDialog,
                private authorizationService: AuthorizationService,
                private translate: TranslateService,
                private groupManagementConfigurationService: GroupManagementConfigurationService,
                @Inject(MAT_DIALOG_DATA) private data: any) {

    }

    ngOnInit(): void {
        this.unsubscribeTakeUntil = new Subject<void>();
        this.initialise();
    }

    ngOnDestroy(): void {
        this.unsubscribeTakeUntil.next();
        this.unsubscribeTakeUntil.complete();
    }

    public displayButton(buttonType: string): boolean {
        return true;
    }

    public edit(user: { [key: string]: any }, assignOnly = false): void {
        const refDialog: MatDialogRef<EditUserComponent> = this.dialog.open(EditUserComponent, {
            panelClass: 'edit-user-Component-form-dialog',
            width: '60vw',
            data: {
                user,
                classes: this.institutionUsersService.classes,
                workgroups: this.institutionUsersService.workgroups,
                assignOnly,
                modalTitle: 'projects-management.target_class',
                save: (data) => this.saveUserData(data)
            }
        });
        refDialog.afterClosed().pipe(
            tap(() => this.loadUserAndPaginator(null))
        ).subscribe();
    }

    private initialise(): void {
        this.displayedColumns = ['name', 'email', 'classes', 'workgroups', 'types', 'buttons'];
        this.loadUsers();
    }

    private loadUsers(filterOptions?: CollectionOptionsInterface): void {
        this.pending = true;
        this.institutionUsersService.loadInstitutionAndGroup().pipe(
            mergeMap(() => this.loadUserAndPaginator(filterOptions))
        ).subscribe();
    }

    private loadUserAndPaginator(filterOptions?: CollectionOptionsInterface): Observable<{ entities: DataEntity[], paginator: CollectionPaginator }> {
        return this.institutionUsersService.getUsersAndPaginator(filterOptions)
            .pipe(
                takeUntil(this.unsubscribeTakeUntil),
                tap((data: { entities: DataEntity[], paginator: CollectionPaginator }) => this.paginator = data.paginator),
                tap((data: { entities: DataEntity[], paginator: CollectionPaginator }) => this.institutionUsersService.usersEntities = data.entities.slice()),
                tap((data: { entities: DataEntity[], paginator: CollectionPaginator }) => this.usersInTable = this.institutionUsersService.associateUserWithRole(data.entities)),
                tap(() => this.setPaginator()),
                tap(() => this.pending = false)
            );
    }

    private setPaginator(): void {
        if (this.paginator) {
            this.countEntities = this.paginator.count;
            this.pageIndex = this.paginator.page - 1;
            this.pageRange = this.paginator.range;
        }
    }

    public onPaginateChange(event): void {
        this.paginator.page = event.pageIndex + 1;
    }

    public filterBy(type: string, row: {
        id: number,
        name: string,
        mail: string,
        og_user_node: { [key: string]: any }[],
        roles: { [key: string]: string },
        rolesInInstitution: { [key: string]: string }
    }): string[] {
        return row.og_user_node
            .filter((group) => group.type.toLowerCase() === type)
            .map((group) => group.title);
    }

    private saveUserData(data: { [key: string]: any }): Observable<DataEntity> {
        const user = _.cloneDeep(this.institutionUsersService.usersEntities.find((userEntity: DataEntity) => +userEntity.id === +data.id));
        return this.institutionUsersService.saveUser(data, user).pipe(
            mergeMap((userEntity: DataEntity) => this.institutionUsersService.saveUserRoleInInstitution(data, userEntity))
        );
    }

    public isUserHasRole(roles: { [key: string]: any }, role): boolean {
        let isUserHasRole = false;
        if (roles && roles.rolesInInstitution) {
            for (const field in roles.rolesInInstitution) {
                if (roles.rolesInInstitution[field] === role) {
                    isUserHasRole = true;
                }
            }
        }
        return isUserHasRole;
    }

    public updateCheck(event): void {
        this.selectAll = event.checked;
        for (const user of this.usersInTable) {
            this.checkboxes[user.id] = event.checked;
        }
    }

    public createUser(): void {
        const refDialog: MatDialogRef<EditUserComponent> = this.dialog.open(EditUserComponent, {
            panelClass: 'add-user-Component-form-dialog',
            width: '60vw',
            data: {
                user: null,
                classes: this.institutionUsersService.classes,
                workgroups: this.institutionUsersService.workgroups,
                assignOnly: false,
                passwordPolicy: this.groupManagementConfigurationService.getPasswordPolicy(),
                save: (data) => this.saveUserData(data)
            }
        });
        refDialog.afterClosed().pipe(
            tap(() => this.loadUserAndPaginator(null))
        ).subscribe();
    }

    public canAssignUser(user: User): boolean {
        return this.authorizationService.currentUserCan(SyncRules.EditGroup, undefined, user);
    }

    public canEditUser(user: User): boolean {
        return this.authorizationService.currentUserCan(SyncRules.EditTrainer, undefined, user);
    }

    public canDeleteUser(user: User): boolean {
        return this.authorizationService.currentUserCan(SyncRules.DeleteTrainer, undefined, user);
    }

    /**
     * deassigner un utilisateur de l'institution
     * @param user
     */
    public detachUserFromInstitution(user: User): void {
        this.institutionUsersService.detachUserFromInstitution(user).pipe(
            tap(() => this.loadUserAndPaginator(null))
        ).subscribe();
    }
}
