import {Component, Injectable} from '@angular/core';
import {filter, map, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../../settings';
import {AuthenticationService} from '@modules/authentication';
import {CreateGroupNewsComponent} from './components/create-group-news/create-group-news.component';
import {CreateLeanerNewsComponent} from './components/create-leaner-news/create-leaner-news.component';
import {CreateTeacherNewsComponent} from './components/create-teacher-news/create-teacher-news.component';
import {AlertLearnersComeFromSsoNewsComponent} from './components/alert-learners-come-from-sso-news/alert-learners-come-from-sso-news.component';
import {AlertTeachersComeFromSsoNewsComponent} from './components/alert-teachers-come-from-sso-news/alert-teachers-come-from-sso-news.component';
import {NewsInterface} from 'fuse-core/news/news.interface';
import {InstitutionUsersService} from '@modules/groups-management/core/services/institution-users-service/institution-users.service';
import {SyncRules} from '@modules/groups-management/core/models/rules';
import {AuthorizationService} from '@modules/authorization';
import {AssociationLearnerToGroupComponent} from '@modules/groups-management/core/onboarding/components/association-learner-to-group/association-learner-to-group.component';
import {Group} from '@modules/groups-management/core/definitions';
import {TypedDataEntityInterface} from '../../../../shared/models/octopus-connect/typed-data-entity.interface';
import {LessonAuthorizationService} from '@modules/activities/core/lessons/services/lesson-authorization.service';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';

const settingsStructure: ModelSchema = new ModelSchema({
    displayNews: Structures.object({
        default: []
    }),
    hideNewsOnFirstConnexion: Structures.object({
        default: []
    }),
});

interface NewsSettingsInterface {
    displayNews: {
        [role: string]: string[]
    };
    hideNewsOnFirstConnexion: {
        [role: string]: string[]
    };
}

@Injectable({
    providedIn: 'root'
})
export class OnboardingService {

    /**
     * A news about the fact to create a group if it's not already done.
     */
    static createTeacherNews: Partial<NewsInterface> = {
        id: 'createTeacher',
        component: CreateTeacherNewsComponent as Component,
        weight: 140
    };

    /**
     * A news about the fact to create a group if it's not already done.
     */
    static createGroupNews: Partial<NewsInterface> = {
        id: 'createGroup',
        component: CreateGroupNewsComponent as Component,
        weight: 130
    };

    /**
     * A news about the fact to create a learner if it's not already done.
     */
    static createLearnerNews: Partial<NewsInterface> = {
        id: 'createLearner',
        component: CreateLeanerNewsComponent as Component,
        weight: 120
    };

    /**
     * A news about the fact the user have group but all groups are empty
     */
    static associationLearnerToGroupNews: Partial<NewsInterface> = {
        id: 'associationLearnerToGroup',
        component: AssociationLearnerToGroupComponent as Component,
        weight: 115
    };

    /**
     * A news about the fact to advise a teacher that he should ask the learners to connect through sso
     */
    static alertLearnerComeFromSso: Partial<NewsInterface> = {
        id: 'alertLearnersSso',
        component: AlertLearnersComeFromSsoNewsComponent as Component,
        weight: 140
    };

    /**
     * A news about the fact to advise a teacher that he should ask the learners to connect through sso
     */
    static alertTeacherComeFromSso: Partial<NewsInterface> = {
        id: 'alertTeacherSso',
        component: AlertTeachersComeFromSsoNewsComponent as Component,
        weight: 140
    };

    private onLogout = new Subject<void>();
    private currentUser: UserDataEntity;
    private settings: NewsSettingsInterface;
    private shouldDisplayAlertLearnerFromSso = true;
    private shouldDisplayAlertTeacherFromSso = true;

    private getUserGroups$: Observable<Group[]> = this.communicationCenter
        .getRoom('groups-management')
        .getSubject('groupsList');

    private userLicences$: Observable<TypedDataEntityInterface<{ type: { label: string } }>[]> = this.communicationCenter
        .getRoom('groups-management')
        .getSubject('userLicenses');

    private isUserHasInstitutionLicence$: Observable<boolean> = this.userLicences$.pipe(
        map((userLicenses) =>
            userLicenses.length > 0 && userLicenses.some(l => l.get('type')?.label === 'Institution')
        )
    );

    private userInstitutionLicences$: Observable<{ license: { type: string } }[]> = this.communicationCenter
        .getRoom('groups-management')
        .getSubject('institutionList');

    private isUsersInstitutionOwnInstitutionLicence$: Observable<boolean> =
        this.userInstitutionLicences$
            .pipe(
                map(institutionList => institutionList.length > 0 && institutionList.some(i => i.license.type === 'Institution'))
            );

    private isUserUnderInstitutionLicence$ = combineLatest([
        this.isUserHasInstitutionLicence$,
        this.isUsersInstitutionOwnInstitutionLicence$
    ]).pipe(
        map(([isUserHasInstitutionLicence, isUsersInstitutionOwnInstitutionLicence]) =>
            isUserHasInstitutionLicence || isUsersInstitutionOwnInstitutionLicence)
    );

    constructor(
        private authService: AuthenticationService,
        private authorization: AuthorizationService,
        private lessonAuthorization: LessonAuthorizationService,
        private communicationCenter: CommunicationCenterService,
        private institutionUsersService: InstitutionUsersService,
    ) {

        this.settings = (settingsStructure.filterModel(modulesSettings.groupsManagement) as NewsSettingsInterface);

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((currentUser: UserDataEntity) => {
                this.currentUser = currentUser;
                if (!!currentUser) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    /**
     * Emit list news to the communication center according to the app state and the settings
     *
     * For now there are only one rule :
     * If the user is teacher not at his first connect and don't have groups emit {@link createGroupNews}
     *
     * @remarks this.currentUser must be the authenticated current user
     */
    private emitNews(): void {

        if (this.currentUser.get('sso') === false) {

            if (this.isThisNewsAllowed(OnboardingService.createGroupNews)) {
                this.emitCreateGroupNews();
            }

            if (this.isThisNewsAllowed(OnboardingService.createLearnerNews)) {
                this.emitCreateLearnerNews();
            }

            if (this.isThisNewsAllowed(OnboardingService.createTeacherNews)
                && this.authorization.currentUserCan(SyncRules.AccessTrainersAndDirectorsManagementPanel)) {
                this.emitCreateTeacherNews();
            }

            if (this.isThisNewsAllowed(OnboardingService.associationLearnerToGroupNews)) {
                this.emitAssociationLearnerToGroupNews();
            }

        } else {

            if (this.isThisNewsAllowed(OnboardingService.alertLearnerComeFromSso)) {
                this.emitTeacherComeFromSsoNews();
            }

            if (this.isThisNewsAllowed(OnboardingService.alertTeacherComeFromSso)) {
                this.emitLearnerComeFromSsoNews();
            }

        }
    }

    /**
     * If the business rule accept it, emit the CreateLearnerNews
     * The rule : If it's not the first connection and there are no learners
     */
    private emitCreateLearnerNews(): void {
        if (this.isThisNewsHiddenOnFirstConnexion(OnboardingService.createGroupNews)
            && this.authService.isFirstConnexion()) {
            return;
        }

        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('learnerList')
            .pipe(
                takeUntil(this.onLogout),
                tap(() => this.removeNews([OnboardingService.createLearnerNews])),
                filter((learners) => learners && learners.length === 0),
                tap(() => this.communicationCenter.getRoom('news').next('add', [OnboardingService.createLearnerNews]))
            ).subscribe();
    }

    /**
     * If the business rule accept it, emit the alertLearnerComeFromSso
     * The rule : If this news has never been deactivated and it's not the first connection and there are no learners
     */
    private emitTeacherComeFromSsoNews(): void {
        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('learnerList')
            .pipe(
                takeUntil(this.onLogout),
                tap(() => this.removeNews([OnboardingService.alertLearnerComeFromSso])),
                filter(() => this.shouldDisplayAlertLearnerFromSso === true),
                filter((learners) => learners && learners.length === 0),
                tap(() => this.communicationCenter.getRoom('news').next('add', [OnboardingService.alertLearnerComeFromSso]))
            ).subscribe();
    }

    /**
     * If the business rule accept it, emit the alertTeacherComeFromSso
     * The rule : If this news has never been deactivated and it's not the first connection and there are no learners
     */
    private emitLearnerComeFromSsoNews(): void {
        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('groupsList')
            .pipe(
                takeUntil(this.onLogout),
                tap(() => this.removeNews([OnboardingService.alertTeacherComeFromSso])),
                filter(() => this.shouldDisplayAlertTeacherFromSso === true),
                filter((learners) => learners && learners.length === 0),
                tap(() => this.communicationCenter.getRoom('news').next('add', [OnboardingService.alertTeacherComeFromSso]))
            ).subscribe();
    }

    /**
     * If the business rule accept it, emit the CreateGroupNews
     * The rule : If it's not the first connection and there are no groups
     */
    private emitCreateGroupNews(): void {
        if (this.isThisNewsHiddenOnFirstConnexion(OnboardingService.createGroupNews)
            && this.authService.isFirstConnexion()) {
            return;
        }

        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('groupsList')
            .pipe(
                takeUntil(this.onLogout), // Si l'utilisateur se déconnecte, on ne veut pas mettre a jour cette news
                tap(() => this.removeNews([OnboardingService.createGroupNews])),
                filter((groups) => groups.length === 0), // Si on a pas encore de classe
                tap(() => this.communicationCenter.getRoom('news').next('add', [OnboardingService.createGroupNews])),
            ).subscribe();
    }

    /**
     * Remove all news managed by this service.
     * @remarks If the news are not displayed it's not a problem.
     */
    private removeNews(news = []): void {
        if (news.length === 0) {
            news = [
                OnboardingService.createGroupNews,
                OnboardingService.createLearnerNews,
                OnboardingService.createTeacherNews,
                OnboardingService.associationLearnerToGroupNews,
                OnboardingService.alertLearnerComeFromSso,
                OnboardingService.alertTeacherComeFromSso
            ];
        }
        this.communicationCenter.getRoom('news').next('delete', news);
    }

    /**
     * execute all needs to prepare the app for the current user
     * @private
     */
    private postAuthentication(): void {
        this.communicationCenter
            .getRoom('GroupsManagementNews')
            .getSubject('alertLearnersComeFromSsoUnderstand')
            .subscribe((userHasUnderstand: boolean) => {
                if (userHasUnderstand) {
                    this.shouldDisplayAlertLearnerFromSso = false;
                    this.removeNews([OnboardingService.alertLearnerComeFromSso]);
                }
            });

        this.communicationCenter
            .getRoom('GroupsManagementNews')
            .getSubject('alertTeachersComeFromSsoUnderstand')
            .subscribe((userHasUnderstand: boolean) => {
                if (userHasUnderstand) {
                    this.shouldDisplayAlertTeacherFromSso = false;
                    this.removeNews([OnboardingService.alertTeacherComeFromSso]);
                }
            });

        this.emitNews();
    }

    /**
     * execute all needs to clean the app on logout
     * @private
     */
    private postLogout(): void {
        this.onLogout.next();
        this.onLogout.complete();
        this.shouldDisplayAlertLearnerFromSso = true;
        this.shouldDisplayAlertTeacherFromSso = true;
        this.removeNews();
    }

    private isThisNewsAllowed(news: Partial<NewsInterface>): boolean {
        return this.settings.displayNews.hasOwnProperty(this.authService.accessLevel) ?
            this.settings.displayNews[this.authService.accessLevel].includes(news.id) :
            this.settings.displayNews['default'].includes(news.id);
    }

    private isThisNewsHiddenOnFirstConnexion(news: Partial<NewsInterface>): boolean {
        return this.settings.hideNewsOnFirstConnexion.hasOwnProperty(this.authService.accessLevel) ?
            this.settings.displayNews[this.authService.accessLevel].includes(news.id) :
            this.settings.displayNews['default'].includes(news.id);
    }

    private emitCreateTeacherNews(): void {
        this.institutionUsersService
            .getUsersAndPaginator()
            .pipe(
                takeUntil(this.onLogout),
                tap(() => this.removeNews([OnboardingService.createTeacherNews])),
                filter(({entities}) => entities.length === 0)
            ).subscribe(() => {
            this.communicationCenter.getRoom('news').next('add', [OnboardingService.createTeacherNews]);
        });
    }

    private emitAssociationLearnerToGroupNews(): void {
        combineLatest([
            of(this.lessonAuthorization.isUserHasManagerRightsInHisInstitutions(this.currentUser)),
            this.isUserUnderInstitutionLicence$,
            this.getUserGroups$
        ]).pipe(
            takeUntil(this.onLogout),
            tap(() => this.removeNews([OnboardingService.associationLearnerToGroupNews])),
            filter(([isUserHasManagementRights, _userIsUnderInstitutionLicence, _userGroups]) => isUserHasManagementRights),
            filter(([_isUserHasAdminRights, userIsUnderInstitutionLicence, _userGroups]) => userIsUnderInstitutionLicence),
            filter(([_isUserHasAdminRights, _userIsUnderInstitutionLicence, userGroups]) => userGroups.length > 0 && userGroups.every(g => g.learnersIds.length === 0)),
        ).subscribe(() =>
            this.communicationCenter.getRoom('news').next('add', [OnboardingService.associationLearnerToGroupNews])
        );
    }
}
