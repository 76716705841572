import {Component} from '@angular/core';
import {EMPTY, Observable, Subject} from 'rxjs';
import {ActivityAttributesInterface} from '../../models/activity-attributes.interface';
import {BaseActivityComponent} from '../base-activity.component';
import {cloneDeep} from 'lodash-es';
import {FlashCardInterface} from 'fuse-core/components/flashcard/flash-card.interface';

@Component({
    selector: 'app-voice-recorder-activity',
    templateUrl: 'voice-recorder-activity.component.html'
})
export class VoiceRecorderActivityComponent extends BaseActivityComponent<any, any> {
    public pauseIncomingAudio$ = new Subject<void>();
    public forceReset$ = new Subject<void>();
    public flashcard: FlashCardInterface;
    public isActivityIsDone = false;

    nextActivity(): void {
        this.pauseIncomingAudio$.next();
        this.doAction('next');
    }

    public activityIsDone($event: void): void {
        this.isActivityIsDone = true;
    }

    protected setContentData(
        attributes: ActivityAttributesInterface<any, any, any>
    ): void {
        this.forceReset$.next();
        this.instructionAudio = attributes?.reference.instructionAudio;
        this.wordingAudio = attributes?.reference.wordingAudio;
        this.flashcard = attributes?.reference?.activity_content[0]?.flashcards?.map((flashcard) => {
            if (flashcard?.image === '' || !flashcard?.image) {
                const flashcardDuplicated = cloneDeep(flashcard);
                flashcardDuplicated.image = {
                    uri: '',
                    title: ''
                };
                return flashcardDuplicated;
            }
            return flashcard;
        })[0];
        if (!this.flashcard) {
            throw new Error('A flashcard is necessary');
        }
            this.wording =
                (this.preview
                    && !this.activitiesService.settings['hiddenFieldActivityPreview'].find((field) => field === 'wording'))
                || !this.preview
                    ? attributes.reference.wording
                    : '';
    }

    protected saveAnswer(): Observable<number[]> {
        return EMPTY;
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected getGrade(): { oldGrade: number; newGrade: number } {
        throw new Error('Method not implemented.');
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }

    protected validate(): void {
        throw new Error('Method not implemented.');
    }
}
