import {Component, HostBinding, OnInit} from '@angular/core';
import {SnackbarNewsService} from 'fuse-core/news/snackbar/snackbar-news.service';
import {AutoUnsubscribeTakeUntilClass} from '../../../../app/shared/models/auto-unsubscribe-take-until.class';
import {takeUntil, tap} from 'rxjs/operators';

@Component({
    selector: 'app-snackbar-recall-button',
    templateUrl: './snackbar-recall-button.component.html',
    styleUrls: ['./snackbar-recall-button.component.scss']
})
export class SnackbarRecallButtonComponent extends AutoUnsubscribeTakeUntilClass implements OnInit {
    @HostBinding('style.display') private shouldDisplayRecallButton = 'none';

    constructor(private snackbarNewsService: SnackbarNewsService) {
        super();
    }

    ngOnInit(): void {
        this.snackbarNewsService
            .shouldDisplayRecallButton$
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil),
            )
            .subscribe(shouldDisplay => this.shouldDisplayRecallButton = shouldDisplay ? 'block' : 'none');
    }

    recall($event: MouseEvent): void {
        $event.preventDefault();
        $event.stopPropagation();

        this.snackbarNewsService.recallLastNews();
    }
}
