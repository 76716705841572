import {filter, takeUntil, tap} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {UserActionsService} from '@modules/activities/core/services/user-actions.service';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {UserActionExecutor} from '@modules/activities/core/shared-components/user-action-button/user-action-executor.interface';
import {UserActionButtonLabels} from '@modules/activities/core/services/user-action-button-labels.enum';
import { v4 as uuidv4 } from 'uuid';
import {LessonNavigationService} from '../../lesson-navigation.service';

@Component({
    selector: 'app-user-action-button',
    templateUrl: './user-action-button.component.html'
})
export class UserActionButtonComponent implements OnChanges, OnInit, OnDestroy, UserActionExecutor {
    @Input() private activityId: any; // TODO ça s'appelle ActivityId mais ca peut etre l'id d'une resource
    @Input() private isMedia: boolean;
    @Input() public activityTitle: string;
    @Input() public isShowResetAnswerAllowed = true;
    @Input() public isShowSaveButton: boolean;
    @Input() public nextActivityInMulti: boolean;
    @Input() public previousActivityInMulti: boolean;
    @Input() public showButtons = true;
    @Input() public ShowPrevNextBtns = true;
    @Input() public ShowSolutionBtn = true;
    @Input() public ShowTestBtn = true;

    public readonly UserActionButtonLabels = UserActionButtonLabels;
    public checkAnswer: boolean;
    public displayActions = true;
    public enabledUserActionButtons: boolean;
    public isSaved = false;
    public isSeeingSolution = false;
    public isTestedAnswer = false;
    public reviewResponseBtn: boolean;
    public seeSolutionBtn: boolean;
    public withoutAnyUserResponse: boolean;

    private answerStatus: number;
    private finalSolutionObject: object;
    private initSubscription: Subscription;
    private isTestDone: boolean;
    private unsubscribeInTakeUntil = new Subject<void>();
    private userResponsed = false;
    private uuid = uuidv4();

    constructor(
        private userActionsService: UserActionsService,
        private activityService: ActivitiesService,
        private lessonsService: LessonsService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private lessonNavigationService: LessonNavigationService,
    ) {
        this.activityService.doesUserResponsed.pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(respond => {
                this.enabledUserActionButtons = respond;
                this.userResponsed = respond;
            });

        this.activityService.checkAnswers.pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(tempValue => {

                if (tempValue['lessonCorrected']) {
                    // when lesson's state is 'corrected' enable seeSolution Button.
                    if (this.answerStatus !== 1) {
                        this.isTestDone = true;
                    }
                } else {
                    this.checkAnswer = tempValue['showAnswers'];
                    this.withoutAnyUserResponse = tempValue['withoutAnyUserResponse'];

                    if (this.lessonsService.isLessonTraining()) {
                        this.isTestDone = this.checkAnswer;
                    }
                }

            });

        this.activityService.activityActionsHandler.pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(data => {
                if (data['resetAll']) {
                    this.activityService.unsetAnswerTempSave();
                }
            });

        this.activityService.isUserAnswerStatus.pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(data => {
                if (data.index === this.activityService.presentArrayElementIndex) {
                    this.answerStatus = data.status;
                    if (this.isLessonEvaluation) {
                        if (data.status !== 2) {
                            this.isSaved = true;
                        }
                    } else {
                        this.isTestedAnswer = true;
                        this.checkAnswer = true;
                    }
                    if (data.status === 3) {
                        this.seeSolutionBtn = true;
                    }
                }
            });

        this.activityService.displayActions.pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((displayActions: boolean) => {
                this.displayActions = displayActions;
            });

        this.userActionsService.getActionExecutedSubject(this.uuid).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((action) => {
                // Action should be define by UserAction interface
                if (typeof this[action.actionLabel] !== 'function') {
                    throw new Error('Action not implemented');
                }
                this[action.actionLabel](action.endSubject);
            });
    }

    public get disableBtnSeeSolution(): boolean {
        if ((this.lessonsService.isAtLeastTrainerAndAssignmentExist && this.answerStatus !== 1) || this.isLessonTest) {
            return !this.isSeeingSolution;
        } else {
            return this.checkAnswer || (this.isTestDone && this.answerStatus !== 1);
        }
    }

    onButtonChange(): void {
        this.seeSolutionBtn = this.reviewResponseBtn = false;

        if (this.lessonsService.isLessonTraining()) {
            this.isTestDone = this.checkAnswer;
        }

        if (!this.checkAnswer && !this.withoutAnyUserResponse) {
            this.answerStatus = 0;
        }

        if (!this.withoutAnyUserResponse) {
            this.seeSolutionBtn = true;
        }

        if (this.withoutAnyUserResponse) {
            this.reviewResponseBtn = true;
        }

        if (this.isLessonEvaluation) {
            if (this.answerStatus !== 3) {
                this.seeSolutionBtn = false;
            }
        }
    }

    ngOnInit(): void {
        this.initialize();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.resetState(new Subject<void>());
    }

    ngOnDestroy(): void {
        this.userActionsService.destroyActionExecutionSubject(this.uuid);
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    get isLessonTest(): boolean {
        return this.lessonsService.isLessonTest();
    }

    get isLessonTraining(): boolean {
        return this.lessonsService.isLessonTraining();
    }

    get isLessonEvaluation(): boolean {
        return this.lessonsService.isLessonEvaluation();
    }

    get isLessonValidated(): boolean {
        return this.lessonsService.isLessonValidated();
    }

    get isLessonCorrected(): boolean {
        return this.lessonsService.isLessonCorrected();
    }

    private initialize(): void {
        this.resetState(new Subject<void>());

        if (!this.lessonsService.isLessonLaunched()) {
            console.error('No mode to run lesson in');
        }

        this.activityService.activityActionHandler(false, false, false, false);
        this.resentFinalAnswerParametersProperties(false);

        if (this.isLessonTest) {
            if (this.userResponsed) {
                this.enabledUserActionButtons = true;
            }
            this.isTestDone = true;
        }

        this.onButtonChange();

        if (!this.initSubscription) {
            this.initSubscription = this.router.events.pipe(
                filter(event => event instanceof NavigationEnd),
                takeUntil(this.unsubscribeInTakeUntil),)
                .subscribe(() => {
                    this.initialize();
                });
        }
    }

    public get displayTestAnswer(): boolean {
        return this.displayActions
            && this.ShowTestBtn
            && (this.isLessonTest || this.isLessonTraining)
            && !this.isTestedAnswer
            && !this.isSeeingSolution
            && !this.lessonsService.isAtLeastTrainerAndAssignmentExist;
    }

    public get displayRegister(): boolean {
        const activity = this.activityService.activitiesArray.find((act) => act.id === this.activityId);
        return (this.displayActions &&
            this.isLessonEvaluation && !(this.isLessonValidated || this.isLessonCorrected) &&
            !this.isSaved && !this.lessonsService.isAtLeastTrainer())
            || this.isButtonDisplayedBySettings('save') && !this.isLessonValidated && activity && activity.type === 'activity';

    }

    public get displayRegistered(): boolean {
        return this.displayActions &&
            this.isLessonEvaluation &&
            this.isSaved;
    }

    public get disableBtnRegister(): boolean {
        return !this.userResponsed && !this.isShowSaveButton;
    }

    public get loading(): boolean {
        return this.activityService.saving || this.activityService.isSaveReady === false;
    }

    public get displayReset(): boolean {
        return this.isShowResetAnswerAllowed && this.displayActions &&
            !this.isSeeingSolution &&
            this.isTestedAnswer && !this.lessonsService.isAtLeastTrainerAndAssignmentExist;
    }

    public get displayModify(): boolean {
        return this.displayActions &&
            this.isLessonEvaluation && !(this.isLessonValidated || this.isLessonCorrected) &&
            this.isSaved && !this.lessonsService.isAtLeastTrainerAndAssignmentExist;
    }

    public get displaySeeSolution(): boolean {
        return this.displayActions && this.ShowSolutionBtn &&
            (this.isLessonTest || this.isLessonTraining ||
                this.isLessonEvaluation && (this.lessonsService.isAtLeastTrainer() || this.isLessonCorrected)) &&
            !this.isSeeingSolution && this.answerStatus !== 1;
    }

    public get displaySeeAnswer(): boolean {
        return this.displayActions &&
            (this.isLessonTest || this.isLessonTraining || (this.isLessonEvaluation && (this.lessonsService.isAtLeastTrainer() || this.isLessonCorrected))) &&
            this.isSeeingSolution;
    }

    private resentFinalAnswerParametersProperties(showAnswers: boolean, showWithoutResponse?: boolean, resetValue?: boolean): void {
        this.finalSolutionObject = {
            showAnswers: showAnswers,
            withoutAnyUserResponse: showWithoutResponse,
            reinitializeOptions: resetValue
        };
    }

    public canLaunchPrevious(): boolean {
        return +this.indexInSublesson > 0;
    }

    public canLaunchNext(): boolean {
        return +this.indexInSublesson < this.lessonsService.subLessonContentEdited.length - 1;
    }

    public get indexInSublesson(): number {
        if (this.lessonsService.currentActivityInSubLesson) {
            return this.lessonsService.subLessonContentEdited
                .findIndex((activity) => +activity.id === +this.lessonsService.currentActivityInSubLesson.id);
        }
        return null;
    }

    public get showPreviousButton(): boolean {
        return this.activityService.presentArrayElementIndex > 0;
    }

    public allowErrorReporting(): boolean {
        return this.lessonsService.isAtLeastTrainer() && this.activityService.settings.allowErrorReporting;
    }

    public get isShowButtonPreviousInMulti(): boolean {
        return this.previousActivityInMulti && this.canLaunchPrevious();
    }

    public get isShowButtonNextInMulti(): boolean {
        return this.nextActivityInMulti && this.canLaunchNext();
    }

    public get multiTitleButton(): string {
        const activity = this.lessonsService.subLessonContentEdited[+this.indexInSublesson + 1];
        return activity
        && activity.get('metadatas').typology && activity.get('metadatas').typology.label === 'summary' ? 'activities.go_to_poll_result' : 'activities.next_question';
    }

    /**
     * @inheritDoc UserActionExecutor.onClick
     */
    public onClick(actionLabel: UserActionButtonLabels): void {
        event.stopPropagation();
        this.userActionsService.executeUserAction(this.uuid, actionLabel);
    }

    /**
     * @inheritDoc UserActionExecutor.launchNextActivity
     */
    public launchNextActivity(endSubject: Subject<void>): void {
        if (this.canLaunchNext()) {
            let currentActivityIndex = +this.indexInSublesson;
            currentActivityIndex++;
            this.lessonsService.editCurrentActivityInSubLesson = this.lessonsService.subLessonContentEdited[currentActivityIndex];
            this.lessonNavigationService.navigateToAnActivityInsideALessonActuallyPlayed(this.lessonsService.subLessonContentEdited[currentActivityIndex], false, '../..');
        }

        endSubject.next();
    }

    /**
     * @inheritDoc UserActionExecutor.launchPreviousActivity
     */
    public launchPreviousActivity(endSubject: Subject<void>): void {
        if (this.canLaunchPrevious()) {
            let currentActivityIndex = +this.indexInSublesson;
            currentActivityIndex--;
            this.lessonsService.editCurrentActivityInSubLesson = this.lessonsService.subLessonContentEdited[currentActivityIndex];
            this.lessonNavigationService.navigateToAnActivityInsideALessonActuallyPlayed(this.lessonsService.subLessonContentEdited[currentActivityIndex], false, '../..');
        }

        endSubject.next();
    }

    /**
     * @inheritDoc UserActionExecutor.goBack
     */
    public goBack(endSubject: Subject<void>): void {
        const subEndSubject = new Subject<void>();
        subEndSubject.subscribe(() => {
            this.withoutAnyUserResponse = false;
            this.activityService.loadPreviousActivity();
            this.userActionsService.resentFinalAnswerParametersProperties(false, false, false);
            this.lessonsService.lessonButtonClicked.next(true);
            endSubject.next();
        });

        this.resetState(subEndSubject);
    }

    /**
     * @inheritDoc UserActionExecutor.gotoNext
     */
    public gotoNext(endSubject: Subject<void>): void {
        const subEndSubject = new Subject<void>();

        subEndSubject.subscribe(() => {
            this.activityService.playScreenStatus = 0;
            if (this.lessonsService.isAssignmentWithMetacognition()) {
                this.activityService.metacognition();
            } else {
                if (this.activityService.activitiesArray.length <= this.activityService.presentArrayElementIndex + 1) {
                    this.lessonNavigationService.navigateToRecap(this.activityService.currentLesson.id);
                } else {
                    this.activityService.loadNextActivity();
                }
            }
            this.withoutAnyUserResponse = false;
            this.userActionsService.resentFinalAnswerParametersProperties(false, false, false);
            this.lessonsService.lessonButtonClicked.next(true);
            endSubject.next();
        });

        this.resetState(subEndSubject);
    }

    /**
     * @inheritDoc UserActionExecutor.gotoNextLesson
     */
    public gotoNextLesson(endSubject: Subject<void>): void {
        this.activityService.loadNextLesson();
        endSubject.next();
    }

    /**
     * @inheritDoc UserActionExecutor.resetState
     */
    public resetState(endSubject: Subject<void>): void {
        const tempActivityId = this.activityService.getLessonActivityID();

        if (tempActivityId) {
            this.activityId = tempActivityId;
        }

        if (this.activityService.downloadedActivity && +this.activityId === +this.activityService.downloadedActivity.id &&
            this.activityService.downloadedActivity.get('format').label === 'lesson' && this.lessonsService.currentActivityInSubLesson) {
                const index = this.activityService.presentArrayElementIndex.toString();
                const activityKey: string = this.lessonsService.currentActivityInSubLesson.id + '-' + index;
                this.isTestedAnswer = this.lessonsService.currentActivityInSubLesson && !!this.activityService.lessonsAnswers[activityKey];
        } else {
            this.isTestedAnswer = this.activityId &&
                !!this.activityService.lessonsAnswers[this.activityId.toString() + '-' + this.activityService.presentArrayElementIndex.toString()];
        }

        if (this.isSeeingSolution) {
            this.seeAnswer(endSubject);
        } else {
            endSubject.next();
        }

        this.isSaved = false;
        this.isSeeingSolution = false;
        this.checkAnswer = false;
    }

    /**
     * @inheritDoc UserActionExecutor.testAnswer
     */
    public testAnswer(endSubject: Subject<void>): void {
        this.isTestedAnswer = true;
        this.activityService.doesUserResponsed.next(false);

        if (this.enabledUserActionButtons) {
            this.checkAnswer = true;
            this.resentFinalAnswerParametersProperties(true, false, false);
            this.activityService.activityActionHandler(true, false, false, false);
        }

        this.userActionsService.clickToTestResponses('checkTheAnswers').pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(actionsResponse => {
                this.checkAnswer = actionsResponse['checkAnswer'];
                this.withoutAnyUserResponse = actionsResponse['withoutAnyUserResponse'];
                this.enabledUserActionButtons = true;
            });
    }

    /**
     * @inheritDoc UserActionExecutor.saveAnswer
     */
    public saveAnswer(endSubject: Subject<void>): void {
        this.isSaved = true;
        this.activityService.doesUserResponsed.next(false);

        this.userActionsService.clickToTestResponses('checkTheAnswers').pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(actionsResponse => {
                this.checkAnswer = actionsResponse['checkAnswer'];
                this.withoutAnyUserResponse = actionsResponse['withoutAnyUserResponse'];
                this.enabledUserActionButtons = true;
                if (this.lessonsService.isAssignmentWithMetacognition()) {
                    this.onClick(this.UserActionButtonLabels.gotoNext);
                }
            });
    }

    /**
     * @inheritDoc UserActionExecutor.modifyAnswer
     */
    public modifyAnswer(endSubject: Subject<void>): void {
        this.isSaved = false;
        this.activityService.activityActionHandler(false, false, true, false);
    }

    /**
     * @inheritDoc UserActionExecutor.seeSolution
     */
    public seeSolution(endSubject: Subject<void>): void {
        this.isSeeingSolution = true;
        this.withoutAnyUserResponse = true;
        this.checkAnswer = false;
        this.activityService.activityActionHandler(false, true, false, false);
        this.userActionsService.resentFinalAnswerParametersProperties(false, true, false);
    }

    /**
     * @inheritDoc UserActionExecutor.seeAnswer
     */
    public seeAnswer(endSubject: Subject<void>): void {
        this.isSeeingSolution = false;
        this.userActionsService.clickToTestResponses('displayTheSolution').pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(actionsResponse => {
                this.checkAnswer = actionsResponse['checkAnswer'];
                this.withoutAnyUserResponse = actionsResponse['withoutAnyUserResponse'];
            });
    }

    /**
     * @inheritDoc UserActionExecutor.resetAnswer
     */
    public resetAnswer(endSubject: Subject<void>): void {
        this.isTestedAnswer = false;
        this.activityService.activityActionHandler(false, false, true, false);
        this.userActionsService.resentFinalAnswerParametersProperties(false, false, true);
        this.enabledUserActionButtons = false;
        this.checkAnswer = false;
        if (this.lessonsService.isLessonTraining()) {
            this.enabledUserActionButtons = this.checkAnswer;
            this.isTestDone = this.checkAnswer;
        }
    }

    public isButtonDisplayedBySettings(button): boolean {
        return this.userActionsService.settings['displaySpecificUserActionButton'].includes(button);
    }

    /**
     * check if it is last activity in lesson
     * @returns {boolean}
     */
    public get isLastStepInLesson(): boolean {
        const activitiesArray = this.activityService.activitiesArray;
        return activitiesArray.length && +activitiesArray[activitiesArray.length - 1].id === +this.activityId;
    }

    /**
     * in multiassignment lesson is there another lesson to launch after the current one
     */
    public get isNextLesson(): boolean {
        const currentAssignment  = this.activityService.currentAssignment;
        const currentLesson = this.activityService.currentLesson;
        const lessons = currentAssignment && currentAssignment.get('assignated_nodes') ? currentAssignment.get('assignated_nodes') : [];
        return lessons.findIndex((lesson) => lesson.id === currentLesson.id) + 1 < lessons.length;
    }
}
