
import {of, Observable} from 'rxjs';
import {Component, Inject, OnInit, Optional} from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {NotepadGenericAlertModalComponent} from '../notepad-generic-alert-modal/notepad-generic-alert-modal.component';
import {NotepadService} from '../notepad.service';
import {ActivatedRoute} from '@angular/router';
import {mergeMap, map, take, tap} from 'rxjs/operators';
import {DataEntity} from 'octopus-connect';
import {NotepadRepositoryService} from '@modules/notepad/core/notepad-repository.service';
import * as _ from 'lodash';

const EditorConfig = {
    toolbar: [
        'bold', 'italic', 'underline',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'undo',
        'redo'
    ]
};

@Component({
    selector: 'app-notepad-content-editor',
    templateUrl: './notepad-content-editor.component.html',
})
export class NotepadContentEditorComponent implements OnInit {
    /**
     * wysiwyg imported data needed
     */
    public editor = ClassicEditor;
    /**
     * Define if component are currently in saving. Useful to disabled another edition/save.
     */
    public isSaving = false;
    /**
     * wysiwyg configuration
     */
    public editorConfig = {...EditorConfig, isReadOnly: () => this.isSaving};
    /**
     * Notepad content
     */
    public text = '';
    /**
     * Notepad title used as header
     */
    public notepadTitle = '';

    /**
     * Current notepad granule (from granule endpoint)
     */
    private notepadGranule: DataEntity = null;

    constructor(private activatedRoute: ActivatedRoute,
                private dialog: MatDialog,
                private notepadSvc: NotepadService,
                private notepadRepoSvc: NotepadRepositoryService,
                private route: ActivatedRoute,
                private selfDialogRef: MatDialogRef<NotepadContentEditorComponent>,
                @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: Partial<{notepadGranule: DataEntity, forceAssociationOnSave: string|number}>) {
    }

    /**
     * Defined if the component a ready
     */
    public get isLoadingDone(): boolean {
        return this.notepadGranule !== null;
    }

    ngOnInit(): void {
        if (this.dialogData?.notepadGranule) {
            this.initialize(this.dialogData?.notepadGranule);
        } else {
            this.route.params.pipe(
                mergeMap(params => this.notepadRepoSvc.getNotepad(params['notepadId'])),
                tap(notepadGranule => {
                    this.initialize(notepadGranule);
                })
            ).subscribe();
        }
    }

    /**
     * Check if close can be done or not.
     * If content is modified, before returned, a modal will be displayed for ask the user to confirm.
     */
    public tryToClose(): void {
        if (this.isDirty()) {
            this.openDialogAlert({contentKey: 'notepad.leave_before_saving_alert'}).afterClosed().subscribe(isConfirmed => {
                if (isConfirmed) {
                    return this.close();
                }
            });
        } else {
            this.close();
        }
    }

    /**
     * Save the content and reload component.
     * Save and reload are done only if it's different than default value.
     * @param $event
     */
    public save($event): void {
        $event.preventDefault();
        this.isSaving = true;

        let done$: Observable<DataEntity | any>;

        if(this.isDirty()) {
            const associatedLessons = _.get(
                this.notepadGranule.get('reference'),
                'activity_content[0].associated_nodes',
            ) || [];
            const associatedLessonsIds = associatedLessons.map(lesson => lesson.id);
            const forcedLessonId = this.dialogData?.forceAssociationOnSave;
            const toAssociate = [forcedLessonId, ...associatedLessonsIds].filter(id => !!id).map(id => +id);
            const distinctToAssociate = _.uniq(toAssociate);

            done$ = this.notepadRepoSvc.updateNotepad(this.notepadGranule.id, {text: this.text, associatedLessonIds: distinctToAssociate}).pipe(
                tap(notepadGranule => this.initialize(notepadGranule)),
                take(1))
        } else {
            done$ = of([]);
        }

        done$.subscribe(() => {
            this.isSaving = false;
        });
    }

    /**
     * Define if content can be saved (if user makes change and actually not in saved)
     */
    public canBeSave(): boolean {
        return this.isDirty() && this.isSaving === false;
    }

    /**
     * Define if content reset is allowed (not in saving and not empty content)
     */
    public canBeReset(): boolean {
        return this.isSaving === false && this.text !== '';
    }

    /**
     * This methods will erase all notepad content.
     * Before erased, a modal will be displayed for ask the user to confirm
     * @param $event
     */
    public reset($event: MouseEvent): void {
        $event.preventDefault();
        this.openDialogAlert({contentKey: 'notepad.reset_content_alert'}).afterClosed().subscribe(isConfirm => {
            if (!!isConfirm) {
                this.text = '';
                return this.save($event);
            }
        });
    }

    /**
     * Close the component by redirecting to the notepadList or by emit on the `Complete Subject`.
     * To access to the emit subject, the component need to have a subject unique id.
     * This unique id is used by the {@link NotepadService.onNotepadDataEditionCompleteSubject} to reference the subject.
     *
     * @remaks Sometimes, like by reloading component from nowhere but with the `onComplete queryParams` the on complete subject id can be not associated to a subject.
     */
    private close(): void {
        this.activatedRoute.queryParams.pipe(
            map(params => {
                if (params.hasOwnProperty('onComplete')) {
                    if (this.notepadSvc.onNotepadDataEditionCompleteSubject.hasOwnProperty(params['onComplete'])) {
                        this.notepadSvc.onNotepadDataEditionCompleteSubject[params['onComplete']].next(this.notepadGranule);
                        return;
                    }
                }
                if ('close' in this.selfDialogRef) {
                    this.selfDialogRef.close();
                    return;
                }

                this.notepadSvc.goToNotepadList();
            })
        ).subscribe();
    }

    /**
     * Useful for mutualize the modal opening
     * @param data localisation key to display to the user
     */
    private openDialogAlert(data: { contentKey: string }): MatDialogRef<NotepadGenericAlertModalComponent, any> {
        return this.dialog.open(NotepadGenericAlertModalComponent, {data: data});
    }

    /**
     * Define if content are modified
     * Useful for know if user need to save
     */
    private isDirty(): boolean {
        return this.text !== this.notepadGranule.get('reference').activity_content[0].text;
    }

    /**
     * Reset the component with default values defined by the granule.
     * Different than reset the content.
     * @param notepadGranule
     */
    private initialize(notepadGranule: DataEntity): void {
        this.notepadGranule = notepadGranule;
        this.text = this.notepadGranule.get('reference').activity_content[0].text;
        this.notepadTitle = this.notepadGranule.get('metadatas').title;
    }
}
