import {Pipe, PipeTransform} from '@angular/core';
import {combineLatest, Observable, of} from "rxjs";
import {DataService} from "fuse-core/services/data.service";

@Pipe({
    name: 'loadTemplateData$'
})
export class LoadTemplateDataPipe implements PipeTransform {
    private static matchTemplate = /{([^}]*)}/g;

    constructor(
        private dataService: DataService,
    ) {
    }

    transform(value: string, ...args: unknown[]): Observable<string> {
        return new Observable<string>((observer) => {
            const observables: Observable<string | string[]>[] = [];

            if (value) {
                const matches = value.matchAll(LoadTemplateDataPipe.matchTemplate);

                for (let match of matches) {
                    // {component(.*)} is handled in LoadTemplateComponentPipe, we still need to remove it here
                    if (match[1].indexOf('component') === 0) {
                        observables.push(of(''));
                    } else {
                        observables.push(this.dataService.getData$(match[1]));
                    }
                }
            }

            combineLatest(observables).subscribe((resolvedData: string[]) => {
                const valueSplit = value.split(LoadTemplateDataPipe.matchTemplate);

                resolvedData.forEach((data: string, index: number) => {
                    valueSplit[1 + index * 2] = data;
                });

                observer.next(valueSplit.join(''));
            });

            if (observables.length === 0) {
                observer.next(value);
            }
        });
    }

}
