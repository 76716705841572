import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {OptionsComponent} from '@modules/activities/core/shared-components/options/options.component';
import {Observable, of} from 'rxjs';
import {AnswerResultInterface} from '@modules/activities/core/models';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {ActivityReferenceInterface} from '@modules/activities/core/models/activity-reference.interface';
import {CommunicationCenterService} from '@modules/communication-center';
import {LessonNavigationService} from '../../lesson-navigation.service';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {ItemAnswerInterface} from '@modules/activities/core/models/item-answer.interface';
import {
    BaseActivityComponent,
    TIME_DELAY_BEFORE_SAVE,
    TIME_DISPLAYING_CORRECTION
} from '@modules/activities/core/player-components/base-activity.component';
import {answerStatusEnum} from '@modules/activities/core/models/answer-status.enum';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {FullscreenService} from 'fuse-core/services/fullscreen.service';
import {shuffle} from '../../../../../shared/utils';
import {ContextualService} from "@modules/activities/core/services/contextual.service";


// format de l'activity_content
export interface QcuActivityContentInterface {
    answers: ItemAnswerInterface[];
}

// format du champ config de l'activité
export interface QcuActivityConfigInterface {
    direction?: string;
    shuffle?: boolean;
    language?: {
        instruction?: string;
        wording?: string;
    }
}

// format du champ référence de l'activité
export type QcuActivityReferenceInterface = ActivityReferenceInterface<QcuActivityContentInterface, QcuActivityConfigInterface>;

@Component({
    selector: 'app-qcu-base',
    templateUrl: './qcu-base.component.html'
})

/**
 * !!! be carreful WhatIsMissingComponent is similar at qcu so it just extend this component
 * so if you change thing here check if there is no repercussion
 * on WhatIsMissingComponent !!!
 */
export class QcuBaseComponent extends BaseActivityComponent<QcuActivityContentInterface, QcuActivityConfigInterface> {
    @ViewChild(OptionsComponent, {static: true}) optionsComponent: OptionsComponent;

    public displaySaveBtn = false; // Boolean know if we display checkbox or button, data fetched from the api
    public isTwoColumns = false;
    public referenceActivityGranule: QcuActivityReferenceInterface;
    public showSubInstruction: boolean;
    public title: string;
    public type = '';
    public isTTSReading: { id: string, value: boolean };
    public waitUntilCorrectionFinished: boolean;
    constructor(
        protected activatedRoute: ActivatedRoute,
        protected activitiesService: ActivitiesService,
        public config: LessonsConfigurationService,
        public fullscreenService: FullscreenService,
        protected lessonsService: LessonsService,
        protected communicationCenter: CommunicationCenterService,
        protected contextualService: ContextualService,
        protected ref: ChangeDetectorRef,
        protected lessonNavigationService: LessonNavigationService
    ) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, contextualService, lessonNavigationService);
    }

    protected setContentData(activityAttributes): void {
        this.waitUntilCorrectionFinished = false;
        this.answersSelected = [];
        this.showSubInstruction = this.activitiesService.settings.showSubInstruction;
        if (activityAttributes.reference.config) {
            this.isTwoColumns = activityAttributes.reference.config.doubleColumn !== 0;
            this.displaySaveBtn = activityAttributes.reference.config.displaySaveBtn === true;
        }
        this.referenceActivityGranule = activityAttributes.reference;
        try {
            this.type = activityAttributes.reference.config.type ? activityAttributes.reference.config.type : '';
        } catch (ex) {
        }
        this.instruction = this.referenceActivityGranule.instruction;
        this.instructionAudio = this.referenceActivityGranule.instructionAudio;
        this.wording = (this.preview && !this.activitiesService.settings['hiddenFieldActivityPreview'].find(field => field === 'wording'))
        || !this.preview ? this.referenceActivityGranule.wording : '';
        this.wordingAudio = (this.preview && !this.activitiesService.settings['hiddenFieldActivityPreview'].find(field => field === 'wordingAudio'))
        || !this.preview ? this.referenceActivityGranule.wordingAudio : '';
        this.displayForSummary ? this.seeAnswerSolution() : this.setDefaultAnswersAvailable(this.referenceActivityGranule.activity_content.answers);
        this.loadUserSave();
    }


    /**
     * permet d'initialisé le tableau des réponses selectionnés
     * @param answers
     * @param resetSelectEd reset answer selected to false
     * @private
     */
    private setDefaultAnswersAvailable(answers, resetSelectEd?: boolean): void {
        this.availableAnswers = answers.map((answer) => {
            return {
                id: answer.id,
                answer: answer.answer,
                image: answer.image,
                select: resetSelectEd ? false : !!+answer.select,
                correct_answer: !!+answer.correct_answer,
                state: ItemAnswerStateEnum.pristine,
                feedback: answer.feedback ? answer.feedback : '',
                audio: answer.audio ? answer.audio : null
            };
        });
        this.availableAnswers = shuffle(this.availableAnswers);
    }

    protected reset(resetAllSubscribe: boolean = false, type = null): Observable<boolean> {
        this.setDefaultAnswersAvailable(this.availableAnswers, true);
        return super.reset(resetAllSubscribe, type);
    }

    protected setAnswer(): void {
        if (this.userSave?.get('userActivity')?.entitySave?.answers && !this.displayForSummary) {
            const answers = this.userSave.get('userActivity').entitySave.answers;
            this.answersSelected.forEach((option: ItemAnswerInterface, index: number) => {
                option.select = answers[index] && !!+answers[index].select && option.id === answers[index].id;
            });
            if (!this.lessonsService.isTrainerSeeCorrection()) {
                this.checkAnswer();
                this.activitiesService.userAnswer.next(answers);
                this.testAnswer = true;
            }
        }
    }

    protected checkAnswer(): void {
        this.waitUntilCorrectionFinished = true;
        this.answerStatus = answerStatusEnum.missing;
        if (this.autoCorrection) {
            const answerResult: AnswerResultInterface = {
                id: +this.activity.id,
                state: ItemAnswerStateEnum.missing,
                isLast: undefined
            };
            this.answersSelected.forEach((option: ItemAnswerInterface) => {
                if (option.select) {
                    if (option.correct_answer) {
                        option.state = ItemAnswerStateEnum.currentlyCorrect;
                        answerResult.state = ItemAnswerStateEnum.currentlyCorrect;
                    } else {
                        option.state = ItemAnswerStateEnum.incorrect;
                        answerResult.state = ItemAnswerStateEnum.incorrect;
                    }
                }
            });
            answerResult.isLast = this.allAnswerCorrect();
            // TODO faire une meilleure animation angular
            {
                setTimeout(() => {
                    super.manageProgressBarEventToSend(answerResult);
                    this.answersSelected.filter(a => a.state === ItemAnswerStateEnum.incorrect).forEach(a => a.state = ItemAnswerStateEnum.pristine);
                    this.answersSelected.filter(a => a.state === ItemAnswerStateEnum.currentlyCorrect).forEach(a => a.state = ItemAnswerStateEnum.wasCorrect);
                    if (this.allAnswerCorrect()) {
                        setTimeout(() => {
                            this.doAction('next', ['save']);
                        }, TIME_DELAY_BEFORE_SAVE);
                    } else {
                        this.waitUntilCorrectionFinished = false;
                    }
                }, this.type === 'isWordSelecting' && (answerResult.state === ItemAnswerStateEnum.currentlyCorrect || answerResult.state === ItemAnswerStateEnum.wasCorrect) ? 0 : TIME_DISPLAYING_CORRECTION);
                // for selecting text version we don't want any animation for true answers
            }
        }
        if (this.answersSelected.some((option: ItemAnswerInterface) => option.select)) {
            this.answerStatus = this.answersSelected.find((option: ItemAnswerInterface) =>
                option.select && option.correct_answer) ? answerStatusEnum.correct : answerStatusEnum.wrong;
        }
        if (this.lessonsService.isLessonTraining() && !this.lessonsService.isAtLeastTrainer()) {
            this.displayFeedback = (this.answerStatus === 3)
                && this.activitiesService.settings.displayFeedback;
        }
        this.activitiesService.isUserAnswerStatus
            .next({status: this.answerStatus, index: this.activityStepIndex});
    }

    /**
     * add class to parent with the current state of the answer correct not correct
     * to apply style to parent
     */
    public answerStateClass(): string {
        const notCorrect = this.answersSelected.find(a => a.state === ItemAnswerStateEnum.incorrect);
        const correct = this.answersSelected.find(a => a.state === ItemAnswerStateEnum.wasCorrect);
        if (!correct && !notCorrect) {
            return '';
        }
        if (notCorrect) {
            return 'not-correct';
        }
        if (correct) {
            return 'correct';
        }
    }

    /**
     * verifie à bien selectionné la/les bonne(s) réponse(s)
     * @private
     */
    private allAnswerCorrect(): boolean {
        return this.answersSelected.some((option: ItemAnswerInterface) => option.select && option.correct_answer);
    }

    /**
     * get the grade calculated with answer correctly answered
     */
    protected getGrade(): { newGrade: number, oldGrade: number } {
        return {
            newGrade: this.answersSelected.filter((answer: ItemAnswerInterface) => answer.select && answer.correct_answer).length,
            oldGrade: this.userSave ?
                this.userSave.get('userActivity').entitySave.answers.filter((answer: ItemAnswerInterface) =>
                    answer.select && answer.correct_answer).length : 0
        };
    }

    /**
     * récupere dans la config de l'activité la mise en page.
     */
    public get columnClass(): string {
        if (this.type === 'isWordSelecting' || this.type === 'isLetterSelecting') {
            return '' + ' ' + this.answerStateClass();
        } else {
            return this.availableAnswers && this.availableAnswers.length > 3 ? 'columns-2' : 'columns-1';
        }

    }

    public validate(option: ItemAnswerInterface, waitForValidationBeforeCheckAnswer?: boolean): void {
        if (!this.waitUntilCorrectionFinished) {
            this.onOptionChange(true);
            this.answersSelected = [{
                id: option.id,
                answer: option.answer,
                image: option.image,
                select: true,
                correct_answer: option.correct_answer,
                state: ItemAnswerStateEnum.pristine,
                feedback: option.feedback,
                audio: option.audio
            }];
            if (this.autoCorrection && !waitForValidationBeforeCheckAnswer) {
                this.checkAnswer();
            }
        }
    }

    public get valueChecked(): string {
        const answerSelected = this.answersSelected && this.answersSelected.find(option => option.select);
        return answerSelected && answerSelected.answer || null;
    }

    public set valueChecked(value: string) {
        this.answersSelected.forEach((item: ItemAnswerInterface) => {
            item.select = (value === item.answer);
        });
    }

    public get isDisabledOptions(): boolean {
        if (this.displaySaveBtn) {
            return this.displayForSummary || this.answersSelected.some((answer: ItemAnswerInterface) => answer.state === ItemAnswerStateEnum.wasCorrect || answer.state === ItemAnswerStateEnum.currentlyCorrect);
        } else {
            return this.displayForSummary || this.displaySolution || // solution displayed
                this.testAnswer || // answer tested
                (this.answerSaved && this.lessonsService.isLessonEvaluation()) || // answer saved and assignment is assessment or homework
                this.lessonsService.isLessonCorrected(); // assignment exist and due date finished
        }
    }

    /**
     * sert aux css pour afficher l'état d'une reponse
     * TODO: on doit faire en sorte que les class css utilise le "state" d'une réponse cf: ItemAnswerInterface
     * pour permettre de retourner simplement le "state" qui sera la également la class css
     * @param option
     */
    public optionState(answer: ItemAnswerInterface): string {
        const isAnswerSelected = this.answersSelected?.length && answer.id === this.answersSelected[0].id;
        if (this.displaySolution) {
            if (answer.correct_answer) {
                return 'correctAnswer';
            }
        }

        if (this.testAnswer) {
            if (isAnswerSelected) {
                if (answer.correct_answer) {
                    return 'correctAnswer';
                } else {
                    return 'wrongAnswer';
                }
            } else {
                if (answer.correct_answer) {
                    return 'answerMissed';
                }
            }
        }

        if (isAnswerSelected && this.autoCorrection) {
            return this.answersSelected[0].state;
        }

        if (isAnswerSelected) {
            if (this.lessonsService.isLessonEvaluation() && this.answerSaved) {
                return 'validated';
            } else {
                return 'selected';
            }
        }
        return '';
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        this.availableAnswers = this.referenceActivityGranule.activity_content.answers.map((answer) => {
            return {
                id: answer.id,
                answer: answer.answer,
                image: answer.image,
                select: !!+answer.correct_answer,
                correct_answer: !!+answer.correct_answer,
                state: ItemAnswerStateEnum.wasCorrect,
                feedback: answer.feedback ? answer.feedback : '',
                audio: answer.audio ? answer.audio : null
            };
        });
        this.displaySolution = true;
    }

    /**
     * set if Text to Speach is currently reading or not
     * @param evt
     */
    public ttsReadingEvent(evt: { id: string, value: boolean }): void {
        this.isTTSReading = evt;
        this.ref.detectChanges();
    }

    /**
     * récupere dans la config de l'activité la mise en page.
     * cas du 3 élément on le gère dans ce cas aussi sur deux lignes
     */
    public getColumnClass(): string {
        return this.availableAnswers.length < 3 ? 'columns-1' : 'columns-2';
    }

    /**
     * for 3 answers we have four place
     */
    public getGridSize(): number {
        return this.availableAnswers.length === 3 ? 4 : this.availableAnswers.length;
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }

    /**
     * return the state of itemAnswer (in ngFor)
     * @param option: ItemAnswerInterface
     */
    public getStateAnswer(itemAnswer: ItemAnswerInterface): string {
        const answerSelected = this.answersSelected.find((answer) => answer.id === itemAnswer.id);
        return answerSelected ? answerSelected.state : ItemAnswerStateEnum.pristine;
    }

    /**
     * know if answer contain IMG, (used to display answer img)
     */
    public get answerContainImg(): boolean {
        const regexp = /<img/gi;
        return this.availableAnswers.some((answer) =>
            (!!answer.image && answer.image !== '') || !!answer.answer && answer.answer.search(regexp) !== -1 );
    }

    public get answersCounting(): string {
        return 'answers-counting-' + this.availableAnswers.length;
    }
}

