import {Injectable} from '@angular/core';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {DataEntity, OctopusConnectService} from "octopus-connect";
import {FormData, FormQuestion} from "fuse-core/components/form-dialog/form.model";
import {Observable} from "rxjs";
import {map, take} from "rxjs/operators";
import {FormDialogComponent} from "fuse-core/components/form-dialog/form-dialog.component";

@Injectable({
    providedIn: 'root'
})
export class FormDialogService {

    constructor(
        private dialog: MatDialog, 
        private octopusConnect: OctopusConnectService,
    ) {
    }
    
    public openForm(): void {
        this.dialog.open(FormDialogComponent, {
            disableClose: true,
        });
    }
    
    public loadForm(): Observable<FormQuestion[]> {
        return this.octopusConnect
            .loadCollection('survey-config')
            .pipe(
                take(1),
                map((surveyConfig) => {
                    return surveyConfig.entities.map((question) => {
                        return {
                            id: question.id.toString(),
                            type: question.get('type'),
                            key: question.get('key'),
                            question: question.get('question'),
                            options: question.get('options'),
                            required: question.get('required'),
                        } as FormQuestion;
                    });
                }),
            );
    }
    
    public loadSubmissions(): Observable<DataEntity[]> {
        return this.octopusConnect
            .loadCollection('survey')
            .pipe(
                take(1),
                map((survey) => {
                    return survey.entities;
                }),
            )
    }
    
    public submitForm(formData: FormData): Observable<DataEntity> {
        return this.octopusConnect.createEntity('survey', formData);
    }
}
