import {Directive, HostListener, Input} from '@angular/core';
import {ngxCsv} from 'ngx-csv';
import {TranslatePipe} from '@ngx-translate/core';

@Directive({
    selector: '[appGraphAssignationExport]',
    providers: [TranslatePipe]
})

export class DataVisualizationExportDirective{

    @Input() assignments: any[] = [];
    @Input() learners: any[] = [];

    @HostListener('click') onClick(): any {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        new ngxCsv(this.getDataToExport(), 'extracted-assignment-graph', {showLabels: true, headers: this.getHeaders(), fieldSeparator: ';'});
    }

    private getHeaders(): string[] {
        const headers = [
            'generic.learner',
            'generic.progress',
            'generic.grade',
            'assignment.rating_base_on',
            'graph-assignation.assignment_average',
            'generic.label_group',
            'generic.label_workgroup',
            'assignment.title',
            'graph-assignation.assignment_type',
            'graph-assignation.correction_date'
        ].map(value => this.translate.transform(value));
        return headers;
    }

    constructor(private translate: TranslatePipe) {}

    private getDataToExport(): any[] {
        const result = [];
        this.assignments.forEach(assignment => {
            Object.keys(assignment.attributes.ratings).forEach((learnerId, index, keys) => {
                const currentLearner = this.learners.find(l => l.id.toString() === learnerId);
                const currentAssignmentType = this.translate.transform(`assignment.type.${assignment.get('type_term').label}`);
                const data = {
                    learner: currentLearner.username,
                    progression: 'null', // TODO
                    grade: assignment.attributes.ratings[learnerId],
                    coefficient: assignment.get('rating_base'),
                    average: assignment.get('average'),
                    classe: currentLearner.groups.join(', '),
                    group: currentLearner.workgroups.join(', '),
                    assignationLabel: assignment.get('lesson'),
                    type: currentAssignmentType,
                    correctionDate: new Date(assignment.get('endDate') * 1000).toLocaleString((navigator.languages && navigator.languages[0]) || navigator.language, {})
                };
                result.push(data);
            });
        });

        return result;
    }
}