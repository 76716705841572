import {NgModule} from '@angular/core';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatExpansionModule} from '@angular/material/expansion';

import {DashWidgetComponent} from 'fuse-core/components/dash-widget/dash-widget.component';
import {RouterModule} from '@angular/router';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {SharedTranslateModule} from '../../../app/shared/shared-translate.module';

@NgModule({
    declarations: [
        DashWidgetComponent
    ],
    imports: [
        MatIconModule,
        MatToolbarModule,
        RouterModule,
        FuseSharedModule,
        SharedTranslateModule,
        MatCardModule,
        MatExpansionModule
    ],
    exports: [
        DashWidgetComponent
    ]
})
export class DashWidgetModule { }
