import {ChangeDetectorRef, Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities';
import {CommunicationCenterService} from '@modules/communication-center';
import {ItemAnswerInterface} from '@modules/activities/core/models/item-answer.interface';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {FullscreenService} from 'fuse-core/services/fullscreen.service';
import {LessonNavigationService} from '@modules/activities/core/lesson-navigation.service';
import {
    QcuActivityConfigInterface,
    QcuActivityContentInterface,
    QcuBaseComponent
} from '@modules/activities/core/player-components/qcu-base/qcu-base.component';
import {
    missingRandomizeTools
} from '@modules/activities/core/player-components/what-is-missing/missing-randomize-tools';
import {ActivityAttributesInterface} from '@modules/activities/core/models/activity-attributes.interface';
import {TypedDataEntityInterface} from '../../../../../shared/models/octopus-connect/typed-data-entity.interface';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {ContextualService} from "@modules/activities/core/services/contextual.service";

@Component({
    selector: 'app-what-is-missing-audio',
    templateUrl: './what-is-missing-audio.component.html',
})
/**
 * this component extend qcubasecomponent
 * be carefull to not implement ngoninit or other element that can overide
 * the default behaviour of parent class without reason
 */
export class WhatIsMissingAudioComponent extends QcuBaseComponent {

    public start = false;
    public availableAnswersForButton: ItemAnswerInterface[] = [];
    public hideElement: boolean;

    constructor(protected activatedRoute: ActivatedRoute,
                protected activitiesService: ActivitiesService,
                protected lessonsService: LessonsService,
                protected communicationCenter: CommunicationCenterService,
                protected contextualService: ContextualService,
                protected ref: ChangeDetectorRef,
                public config: LessonsConfigurationService,
                public fullscreenService: FullscreenService,
                protected lessonNavigationService: LessonNavigationService
    ) {
        super(activatedRoute, activitiesService, config, fullscreenService, lessonsService, communicationCenter, contextualService, ref, lessonNavigationService);
    }

    protected initialize() {
        this.start = false;
        this.hideElement = false;
        this.answersSelected = [];
        this.availableAnswersForButton = [];
        super.initialize();
        this.availableAnswers.forEach((answer: ItemAnswerInterface) => {
            answer.state = ItemAnswerStateEnum.pristine;
            answer.correct_answer = false;
        });
        if (this.displayForSummary) {
            this.start = true;
        }
    }

    /**
     * set the order of answers and of buttons
     * @private
     */
    private setAnswersAndButtonOrder(): void {
        this.availableAnswers.forEach((answer: ItemAnswerInterface, index) => {
            answer.icon = 'equalizer_' + (index + 1);
        });

        const answers = missingRandomizeTools.shuffleAnswers(this.availableAnswers, this.availableAnswersForButton, true);
        this.availableAnswers = answers.availableAnswers;
        this.availableAnswersForButton = answers.availableAnswersForButton.map((availableAnswer) => {
            if (this.displayForSummary && availableAnswer.correct_answer) {
                availableAnswer.state = ItemAnswerStateEnum.currentlyCorrect;
            } else {
                availableAnswer.state = ItemAnswerStateEnum.pristine;
            }
            return availableAnswer;
        });
    }

    public dynamicImgContainerClass(option: ItemAnswerInterface, cardPosition: number): string {
        // only 3 carte and it s the last we add class to center it
        const centerLastCard = this.availableAnswers.length === 3 && cardPosition === 2 ? ' center-last-card' : '';
        const isHide = !this.showContent(option) ? ' to-hide' : ' not-to-hide';
        return option.state + isHide + centerLastCard;
    }

    public startActivity(): void {
        this.hideElement = true;
        this.setAnswersAndButtonOrder();
        this.start = true;
        setTimeout(() => {
            this.hideElement = false;
        }, 3000);
    }

    public showContent(option: ItemAnswerInterface): boolean {
        return !this.start
            || !this.hideElement && !option.correct_answer
            || this.answersSelected.some((answer) =>
                answer.id === option.id && (answer.state === ItemAnswerStateEnum.currentlyCorrect || answer.state === ItemAnswerStateEnum.wasCorrect));
    }

    public validateAnswer(): void {
        if (this.isAlmostOneAnswer() && !super.waitUntilCorrectionFinished) {
            this.checkAnswer();
        }
    }

    public isAlmostOneAnswer(): boolean {
        return this.answersSelected.filter(a => a.select === true).length > 0;
    }

    public classToApplyOnAvailableAnswer(option): string {
        return this.answersSelected?.find(a => option.id === a.id)?.state || option.state;
    }

    public classToApplyOnValidateButton(): string {
        return this.answersSelected?.find(a => a.select === true)?.state;
    }

    public currentAudio(): string {
        return this.answersSelected.find(a => a.select === true)?.audio?.uri;
    }

    /**
     * is answer available selected
     * @param option
     */
    public isSelected(option: ItemAnswerInterface) {
        return !!option && this.answersSelected[0]?.id === option?.id;
    }
}
