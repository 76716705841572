import {takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {fuseAnimations} from 'fuse-core/animations';
import {LayoutConfigService} from 'fuse-core/services/layout-config.service';
import {AuthenticationService} from '@modules/authentication/core/authentication.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {brand, brandLogoSvg, modulesSettings} from 'app/settings';
import {ModelSchema, Structures} from 'octopus-model';

const settingsAuthStructure: ModelSchema = new ModelSchema({
    displayLoginLogo: Structures.boolean(false),
});


@Component({
    selector: 'fuse-forgot-password',
    templateUrl: './forgot-password.component.html',
    animations: fuseAnimations
})
export class FuseForgotPasswordComponent implements OnInit, OnDestroy {
    forgotPasswordForm: UntypedFormGroup;
    forgotPasswordFormErrors: any;
    public emailNotValidated: boolean = false;
    public emailNotFound: boolean = false;
    public brand = brand;
    public brandLogoSvg = brandLogoSvg;
    settingsAuth: { [key: string]: boolean };
    private unsubscribeInTakeUntil = new Subject<void>();
    public showInfoTokenNotValid = false;

    constructor(
        private layoutConfig: LayoutConfigService,
        private formBuilder: UntypedFormBuilder,
        private authenticationService: AuthenticationService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        this.forgotPasswordFormErrors = {
            email: {}
        };

        this.settingsAuth = settingsAuthStructure.filterModel(modulesSettings.authentication);
        this.settingsAuth = settingsAuthStructure.filterModel(modulesSettings.authentication);
    }

    ngOnInit(): void {
        this.route.params.subscribe((params: Params) => {
            const paramValue = params?.params === 'errorTokenNotValid';
            if (paramValue) {
                this.showInfoTokenNotValid = true;
            }
        });
        this.forgotPasswordForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });

        this.forgotPasswordForm.valueChanges.subscribe(() => {
            this.onForgotPasswordFormValuesChanged();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    onForgotPasswordFormValuesChanged(): void {
        this.emailNotValidated = false;
        this.emailNotFound = false;
        for (const field in this.forgotPasswordFormErrors) {
            if (!this.forgotPasswordFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.forgotPasswordFormErrors[field] = {};

            // Get the control
            const control = this.forgotPasswordForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.forgotPasswordFormErrors[field] = control.errors;
            }
        }
    }

    sendResetLink(): void {
        if (!this.forgotPasswordForm.invalid) {
            this.authenticationService.forgotPassword(this.forgotPasswordForm.value['email']).pipe(
                takeUntil(this.unsubscribeInTakeUntil))
                .subscribe(res => {
                    this.router.navigate(['password-reset-email']);
                }, error => {
                    if (error.code === 403) {
                        this.emailNotValidated = true;
                    } else if (error.code === 422) {
                        this.emailNotFound = true;
                    }
                });
        }
    }
}
