import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent<T> implements OnInit {
    public btnTitle: string;
    public innerHtmlContent: string;
    public title: string;
    public content: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: {
                    btnTitle?: string;
                    title: string;
                    content: string;
                    innerHtmlContent: string;
                    callback: () => T;
                },
                public dialogRef: MatDialogRef<FeedbackComponent<T>>) { }

    ngOnInit(): void {
        this.btnTitle = this.data.btnTitle;
        this.title = this.data.title;
        this.content = this.data.content;
        this.innerHtmlContent = this.data.innerHtmlContent;
    }

    public close(): void {
       this.dialogRef.close();
    }

}
