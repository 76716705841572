import {dateTimeOptions} from '../settings';
import {Observable, Observer} from 'rxjs';
import * as _ from 'lodash-es';

/**
 * extract in an array all url contain in a text
 * @param fullText : string with or without url inside
 */
export const extractUrls = function (fullText: string): string[] {
    return fullText ? fullText.match(/\bhttps?:\/\/\S+/gi) : null;
};

/**
 * Load the image, transform and return it as a base64 string
 * @param url
 */
export const getBase64ImageFromURL = (url: string): Observable<string> => {
    return Observable.create((observer: Observer<string>) => {
        // create an image object
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.src = url;
        if (!img.complete) {
            // This will call another method that will create image from url
            img.onload = () => {
                observer.next(getBase64Image(img));
                observer.complete();
            };
            img.onerror = err => {
                observer.error(err);
            };
        } else {
            observer.next(getBase64Image(img));
            observer.complete();
        }
    });
};

/**
 * Translate an image to a base64 string
 * @param img
 */
export const getBase64Image = (img: HTMLImageElement): string => {
    // We create a HTML canvas object that will create a 2d image
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    // This will draw image
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    const dataURL: string = canvas.toDataURL('image/png');
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
};

/**
 * Set opacity of rgb color
 * @param color RBG value
 * @param factor
 */
export const rgbOpacity = (color: string, factor: string) => {
    const [r, g, b, ..._tail] = color.substring(4, color.length - 1).split(',');
    return `rgb(${r},${g},${b},${factor})`;
};

/**
 * Return a copy of the array in another order
 * @param arr the array to shuffle
 * @param forceChange prevent the rare case to return the same order if true
 */
export const shuffle = <T>(arr: T[], forceChange: boolean = false) => {
    let shuffled = _.shuffle(arr.slice());

    if (forceChange && shuffled.length > 1 && _.isEqual(arr, shuffled)) {
        shuffled = shuffle(arr, true);
    }

    return shuffled;
};


export const localizedDate = function (date: number, lang?: string): string {
    return new Date(date * 1000).toLocaleDateString(lang ? lang : navigator.language, dateTimeOptions);
};

export const localizedTime = function (date: number, lang?: string): string {
    return new Date(date * 1000).toLocaleTimeString(lang ? lang : navigator.language, dateTimeOptions);
};

export const currentTimestamp = function (): number {
    const current = new Date();
    return Math.round(current.getTime() / 1000);
};

export const isEmpty = function (object: object): boolean {
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
};

export const secondsToHoursMinutesSeconds = (length: number): string => {
    let duration = '';

    if (length) {
        const seconds = length;
        const d = Math.floor(seconds / (3600 * 24));
        const h = Math.floor(seconds % (3600 * 24) / 3600) + d * 24;
        const m = Math.floor(seconds % 3600 / 60);
        const s = Math.floor(seconds % 60);

        const hDisplay = h > 0 ? (h < 10 ? '0' : '') + h + ':' : '' ;
        const mDisplay = m > 0 ? (m < 10 ? '0' : '') + m : "00" ;
        const sDisplay = s > 0 ? ':' + (s < 10 ? '0' : '') + s : ":00" ;
        duration = hDisplay + mDisplay + sDisplay;
    }

    return duration;
};
// return the current os like Windows 10
export const currentOs = function (): string {
    try {
        var osName = 'Unknown';
        if (window.navigator.userAgent.indexOf('Windows NT 10.0') !== -1) {
            osName = 'Windows 10';
        }
        if (window.navigator.userAgent.indexOf('Windows NT 6.3') !== -1) {
            osName = 'Windows 8.1';
        }
        if (window.navigator.userAgent.indexOf('Windows NT 6.2') !== -1) {
            osName = 'Windows 8';
        }
        if (window.navigator.userAgent.indexOf('Windows NT 6.1') !== -1) {
            osName = 'Windows 7';
        }
        if (window.navigator.userAgent.indexOf('Windows NT 6.0') !== -1) {
            osName = 'Windows Vista';
        }
        if (window.navigator.userAgent.indexOf('Windows NT 5.1') !== -1) {
            osName = 'Windows XP';
        }
        if (window.navigator.userAgent.indexOf('Windows NT 5.0') !== -1) {
            osName = 'Windows 2000';
        }
        if (window.navigator.userAgent.indexOf('Mac') !== -1) {
            osName = 'Mac/iOS';
        }
        if (window.navigator.userAgent.indexOf('X11') !== -1) {
            osName = 'UNIX';
        }
        if (window.navigator.userAgent.indexOf('Linux') !== -1) {
            osName = 'Linux';
        }
        return osName;
    } catch (ex) {
        return '';
    }
};
// return the current navigtor
export const currentNavigator = function (): string {
    try {
        const ua = navigator.userAgent;
        let tem;
        let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) {
                return tem.slice(1).join(' ').replace('OPR', 'Opera');
            }
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        // eslint-disable-next-line no-cond-assign
        if ((tem = ua.match(/version\/(\d+)/i)) != null) {
            M.splice(1, 1, tem[1]);
        }
        return M.join(' ');
    } catch (ex) {
        return '';
    }
};

export type RecursivePartial<T> = {
    [P in keyof T]?:
    T[P] extends (infer U)[] ? RecursivePartial<U>[] :
        T[P] extends object ? RecursivePartial<T[P]> :
            T[P];
};

export const twoDecimalIfNeeded = (value: number): string => {
    return value % 1 === 0 ? value.toString() : value.toFixed(2);
}