// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
:host .fuse-countdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}
:host .fuse-countdown .time {
  display: flex;
  flex-direction: column;
  padding: 0 12px;
}
:host .fuse-countdown .time .value {
  font-size: 34px;
  line-height: 34px;
  padding-bottom: 8px;
}
:host .fuse-countdown .time .title {
  color: rgba(0, 0, 0, 0.54);
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/countdown/countdown.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AACR;AACQ;EACI,aAAA;EACA,sBAAA;EACA,eAAA;AACZ;AACY;EACI,eAAA;EACA,iBAAA;EACA,mBAAA;AAChB;AAEY;EACI,0BAAA;AAAhB","sourcesContent":[":host {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    justify-content: center;\r\n\r\n    .fuse-countdown {\r\n        display: flex;\r\n        flex-direction: row;\r\n        align-items: center;\r\n        justify-content: center;\r\n        text-align: center;\r\n\r\n        .time {\r\n            display: flex;\r\n            flex-direction: column;\r\n            padding: 0 12px;\r\n\r\n            .value {\r\n                font-size: 34px;\r\n                line-height: 34px;\r\n                padding-bottom: 8px;\r\n            }\r\n\r\n            .title {\r\n                color: rgba(0, 0, 0, 0.54);\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
