export interface ButtonComponentConfigInterface {
    classCss?: string[];
    classCssIcon?: string[];
    disable?: boolean;
    display?: boolean;
    svgIcon?: string;
    title?: string;
    type: string;
    preActionMatrix?: string[];
    options?: { [key: string]: any };
}
