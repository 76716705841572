import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule} from '@angular/forms';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {AuthenticationService} from '@modules/authentication';
import {DataEntity} from 'octopus-connect';
import {MindmapService} from './mindmap.service';
import {MindmapListComponent} from './mindmap-list/mindmap-list.component';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {SharedModule} from '../../../shared/shared.module';
import {MindmapCardComponent} from './mindmap-card/mindmap-card.component';
import {MindmapDataEditorModalComponent} from './mindmap-data-editor-modal/mindmap-data-editor-modal.component';
import {MindmapContentEditorComponent} from './mindmap-content-editor/mindmap-content-editor.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {MindmapGenericAlertModalComponent} from './mindmap-generic-alert-modal/mindmap-generic-alert-modal.component';
import {MindmapRepositoryService} from './mindmap-repository.service';
import { MindmapContentViewerModalComponent } from './mindmap-content-viewer-modal/mindmap-content-viewer-modal.component';
import {SearchFiltersModule} from 'fuse-core/components';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';

const routes: Routes = [
    {
        path: 'mindmap',
        canActivate: [IsUserLogged],
        children: [
            {
                path: 'list',
                component: MindmapListComponent,
            },
            {
                path: ':mindmapId/edit',
                component: MindmapContentEditorComponent
            }
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatCardModule,
        BrowserAnimationsModule,
        FuseSharedModule,
        SharedModule,
        CKEditorModule,
        SearchFiltersModule,
        MatChipsModule,
        MatDialogModule,
    ],
    declarations: [
        MindmapDataEditorModalComponent,
        MindmapCardComponent,
        MindmapListComponent,
        MindmapContentEditorComponent,
        MindmapGenericAlertModalComponent,
        MindmapContentViewerModalComponent,
    ],
})
export class MindmapModule {
    private static isMenuSet = false;

    constructor(private dynamicNavigation: DynamicNavigationService,
                private communicationCenter: CommunicationCenterService,
                private authService: AuthenticationService) {

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    static forRoot(): ModuleWithProviders<MindmapModule> {
        return {
            ngModule: MindmapModule,
            providers: [
                MindmapService,
                MindmapRepositoryService
            ]
        };
    }

    private postAuthentication(): void {
        if (!MindmapModule.isMenuSet) {
            this.dynamicNavigation.registerModuleMenu('level0', {
                'id': 'tools',
                'title': 'Outils',
                'translate': 'generic.tools',
                'type': 'collapse',
                'icon': 'outils2',
            });

            this.dynamicNavigation.addChildTo('level0', 'tools', {
                'id': 'mindmapList',
                'title': 'mindmapList',
                'translate': 'mindmap.title',
                'type': 'item',
                'url': '/mindmap/list',
                'weight': 2
            });
            MindmapModule.isMenuSet = true;
        }
    }

    private postLogout(): void {
        MindmapModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'mindmapList');
    }
}
