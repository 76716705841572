import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {IdeasWallService} from '@modules/ideas-wall/core/ideas-wall.service';

@Component({
    selector: 'app-ideas-walls-root',
    templateUrl: './ideas-walls-root.component.html',
    styleUrls: ['./ideas-walls-root.component.scss']
})
export class IdeasWallsRootComponent implements OnInit {


    constructor(
        private route: ActivatedRoute,
        private wallsService: IdeasWallService
    ) { }

    ngOnInit() {

    }

}
