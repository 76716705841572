// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  padding: 8px;
  background: #263238;
  cursor: text;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/highlight/highlight.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,YAAA;EACA,mBAAA;EACA,YAAA;EACA,cAAA;EACA,iCAAA;AACJ","sourcesContent":[":host {\r\n    display: block;\r\n    padding: 8px;\r\n    background: #263238;\r\n    cursor: text;\r\n    overflow: auto;\r\n    -webkit-overflow-scrolling: touch;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
