import {Component, Inject, OnInit} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-assignment-adaptative-popin',
    templateUrl: './assignment-adaptative-popin.component.html',
    styleUrls: ['./assignment-adaptative-popin.component.scss']
})
/**
 * pop in to show a dialog when lesson is adaptive at begin and at end
 */
export class AssignmentAdaptativePopinComponent implements OnInit {
    public data: { svg: string, body: string, button: string } = {svg: '', body: '', button:''};

    constructor(public dialogRef: MatDialogRef<AssignmentAdaptativePopinComponent>,
                @Inject(MAT_DIALOG_DATA) data) {
        this.data = data;
    }

    ngOnInit(): void {
    }
}
