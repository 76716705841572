import {Component, Input, OnInit} from '@angular/core';
import {IUserProgress} from '@modules/activities/core/my-progress/models/user-progress';

@Component({
    selector: 'app-my-statistic-progress',
    templateUrl: './my-statistic-progress.component.html'
})
export class MyStatisticProgressComponent implements OnInit {

    @Input() statistic: IUserProgress = {
        stars: 0,
        diamonds: 0,
        exercicesCompleted: 0,
        lessonsCompleted: 0
    };

    constructor() {
    }

    ngOnInit(): void {
    }
}
