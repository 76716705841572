import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {of, combineLatest, Observable, ReplaySubject } from 'rxjs';
import {DataCollection, DataEntity} from 'octopus-connect';
import {FieldListOption} from 'fuse-core/components/search-filters/generic-search-filters/field-list.option';
import {allowedFieldType} from 'fuse-core/components/search-filters/generic-search-filters/generic-search-filters.component';
import {filter, mergeMap, map, tap} from 'rxjs/operators';
import {CollectionOptionsInterface} from 'octopus-connect';
import * as _ from 'lodash-es';
import {CommunicationCenterService} from '@modules/communication-center';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {InfoMetadataDialogComponent} from '@modules/graph-assignation/core/info-metadata-dialog/info-metadata-dialog.component';

interface Group {
    id: string | number;
    archived: boolean;
    groupname: string;
    learnersIds: (string | number)[];
}


@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
})
export class FiltersComponent implements OnInit, OnChanges {
    private dialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    private lessons: DataEntity[] = [];
    private currentSelectedLesson: DataEntity;
    private groups: Group[] = [];
    private loadPaginatedLessons: (type?: string, roles?: number[], searchValue?, filterOptions?: { [p: string]: object }) => Observable<DataEntity[]>;
    private loadActivity: (id: (string | number)) => Observable<DataEntity>;
    private loadPaginatedAssignments: (filters?: {}) => Observable<DataCollection>;

    private lessonFieldOnChanges = new ReplaySubject(1);
    private groupFieldOnChanges = new ReplaySubject(1);
    private lessonField: FieldListOption = {
        id: 'lessons',
        label: 'generic.lessons',
        list: [],
        type: 'FieldListOption',
        onChange: this.lessonFieldOnChanges,
        disabled: true,
        showInfoIcon: true
    };
    private groupField: FieldListOption = {
        id: 'groups',
        label: 'generic.groups',
        list: [],
        type: 'FieldListOption',
        onChange: this.groupFieldOnChanges,
        disabled: true,
    };

    private activityField: FieldListOption = {
        id: 'activities',
        label: 'graph.progression_activities_type',
        list: [],
        type: 'FieldListOption',
        disabled: true,
        hidden: false
    };
    public fields: allowedFieldType[] = [
        this.groupField,
        this.lessonField,
        this.activityField
    ];

    @Output('currentLessonsAndIdGroup') currentLessonsAndIdGroup = new EventEmitter<{ idLesson: number, idGroup: string }>();
    @Input('fieldsAllowedInInfoPopUp') fieldsAllowedInInfoPopUp: string[] = [];
    @Input('tabIndex') tabIndex: number = 0; // index of the tab of parent

    constructor(private communicationCenter: CommunicationCenterService,
                private dialog: MatDialog) {
        // listen for change on lesson filter to show information corresponding in info pop up
        this.lessonFieldOnChanges.subscribe(idLesson => {
            this.currentSelectedLesson = this.lessons.find(l => l.id === idLesson);
        });
    }

    ngOnInit(): void {
        combineLatest(this.lessonFieldOnChanges, this.groupFieldOnChanges).pipe(
            mergeMap(([selectedLessonId, selectedGroupId]) => {
                const lesson = this.lessons.find(someLesson => someLesson.id === selectedLessonId);
                const group = this.groups.find(someGroup => someGroup.id === selectedGroupId);

                if (!!lesson && !!group) {
                    this.setSelectedValue(+group.id, lesson.id.toString());
                    this.currentLessonsAndIdGroup.emit({idLesson: +lesson.id, idGroup: group.groupname});
                    return this.refreshActivityFilter(lesson, group);
                } else {
                    return of(null);
                }
            })
        ).subscribe();

        const lessons$ = this.communicationCenter.getRoom('activities')
            .getSubject('loadPaginatedLessonsCallback')
            .pipe(
                map((callback: (type?: string, roles?: number[], searchValue?, filterOptions?: { [p: string]: object }) => Observable<DataEntity[]>) => {
                    this.loadPaginatedLessons = callback;
                })
            );

        const activity$ = this.communicationCenter.getRoom('activities')
            .getSubject('loadActivitiesFromIdCallback')
            .pipe(
                map((callback: (id: string | number) => Observable<DataEntity>) => {
                    this.loadActivity = callback;
                })
            );

        const groups$ = this.communicationCenter.getRoom('groups-management')
            .getSubject('groupsList')
            .pipe(
                tap((groups: Group[]) => {
                    this.groups = groups;
                    return this.groupField.list = groups.filter(group => group.archived === false)
                        .map(group => ({id: group.id, label: group.groupname}));
                })
            );

        const assignments$ = this.communicationCenter.getRoom('assignment').getSubject('loadPaginatedAssignmentsCallback')
            .pipe(
                tap((callback: (filters?: CollectionOptionsInterface) => Observable<DataCollection>) => {
                    this.loadPaginatedAssignments = callback;
                })
            );

        combineLatest(lessons$, groups$, activity$, assignments$).pipe(
            mergeMap(() => this.refreshLessonFilter()),
            tap(() => this.refreshGroupFilter()),
            tap(() => this.refreshFiltersForProvokeGenericFilterComponentReload())
        ).subscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        // case of tab index 2 error type => activities list must be hidden
        if (this.tabIndex === 2) {
            this.activityField.hidden = true;
        } else {
            this.activityField.hidden = false;
        }
    }

    public search($event: CollectionOptionsInterface): void {
        this.setSelectedValue($event.filter.groups, $event.filter.lessons);
        // groupname label is use instead of id for filtering : legacy code
        const group = this.groups.find(someGroup => someGroup.id === $event.filter.groups);
        this.currentLessonsAndIdGroup.emit({idLesson: $event.filter.lessons, idGroup: group.groupname});
    }

    /**
     * open a pop up with the information on the current selected lesson
     */
    public openInfoPopUp(id: string): void {
        if (id !== 'lessons') {
            return;
        }

        const data = this.lessons.find(l => l.id === this.currentSelectedLesson.id).get('metadatas');
        this.dialog.open(InfoMetadataDialogComponent, {
            data: {
                metadatas: data,
                settings: {metadataDialogFields: this.fieldsAllowedInInfoPopUp}
            }
        });
    }

    /**
     * set the previous selected value to restore them in filter componenent
     * when he reload when list item change ex: activity add after lesson
     * @param idGroup : id of group number
     * @param idLesson : id of lesson in string format
     */
    private setSelectedValue(idGroup: number, idLesson: string): void {
        this.groupField.selectedValue = idGroup;
        this.lessonField.selectedValue = idLesson;
    }

    private refreshFiltersForProvokeGenericFilterComponentReload(): void {
        this.fields = this.fields.slice();
    }

    private refreshLessonFilter(): Observable<DataEntity[]> {
        return this.loadPaginatedLessons('all', null, '', {}).pipe(
            tap(lessons => {
                this.lessons = lessons;
                this.lessonField.list = lessons.map(lesson => ({id: lesson.id, label: _.get(lesson, 'attributes.metadatas.title', '')}));
                this.lessonField.disabled = false;
                if (this.lessonField.list.length > 0) {
                    this.lessonField.defaultValue = _.get(_.orderBy(this.lessonField.list, 'label'), '[0].id', '');
                    this.lessonFieldOnChanges.next(this.lessonField.defaultValue);
                }
            })
        );
    }

    private refreshActivityFilter(lesson: DataEntity, group: Group): Observable<DataEntity[]> {
        return this.loadPaginatedAssignments({
            'assignated_lesson_id': +lesson.id,
            'assignated_user': group.learnersIds,
        }).pipe(
            filter(collection => collection.entities.length > 0),
            mergeMap(() => this.getActivitiesFromLesson(lesson)),
            tap((activities: DataEntity[]) =>
                this.activityField.list = activities.map(activity => {
                    try {
                        const {typology: {id, localized}} = activity.get('metadatas');
                        return {id: id, label: localized};
                    } catch (e) {
                        return {id: 0, label: ''};
                    }
                })),
            tap(() => {
                this.activityField.disabled = false;
                this.activityField.defaultValue = 'all';
                this.refreshFiltersForProvokeGenericFilterComponentReload();
            })
        );
    }

    private getActivitiesFromLesson(lesson: DataEntity): Observable<DataEntity[]> {
        const data = (<DataEntity[]> lesson.get('reference')).map(({id}) => this.loadActivity(id));
        return combineLatest(data);
    }

    private refreshGroupFilter(): void {
        this.groupField.disabled = false;
        if (this.lessonField.list.length > 0) {
            this.groupField.defaultValue = _.get(_.orderBy(this.groupField.list, 'label'), '[0].id', '');
            this.groupFieldOnChanges.next(this.groupField.defaultValue);
        }
    }
}
