import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
    selector: 'app-my-current-progress',
    templateUrl: './my-current-progress.component.html'
})
export class MyCurrentProgressComponent implements OnInit, AfterViewInit {
    @ViewChild('myCurrenProgress') myCurrenProgress: ElementRef; // to get width of container
    private minProgressBar = 0; // 23 default value in most case // setting min value on bar corresponding to zero use to match dot and progress bar
    private maxProgressBar = 0; // 76 default value in most case was setting max value on bar corresponding to 100% use to match dot and progress bar
    public pourcentToShow = 23; // default value to use on progress bar by default 20% = 0% to put default state under dot effect of progress begin after this value
    @Input() progressState = {
        progressBarValue: 0, // real value of user to convert in pourcentToShow waiting real data for now 80 by default
        currentScore: 0, // current user score
        scoreTodDo: 0, // score to do to win next item
        imageToWinUrl: '', // image to win,
        showStarAnimation: false, // show animation under reward
        assetType: '' // asset type
    };


    constructor() {
    }

    ngOnInit(): void {

    }

    /**
     * make an increase effect of the progress bar
     */
    public increaseProgressBarEffect(): void {
        this.setMinAndMaxPourcentValueForProgressBar();
        const inter = setInterval(() => {
            if (this.pourcentToShow < this.pourcentToUse() - 2) {
                this.pourcentToShow = this.pourcentToShow + 1;
            } else {
                this.pourcentToShow = this.pourcentToUse();
                clearInterval(inter);
            }
        }, 20);
    }

    /**
     * set min and max value for calcul of pourcent in progress bar
     * @private
     */
    private setMinAndMaxPourcentValueForProgressBar(): void {
        // in css we have 10 px margin + 135 px square icon/avatar => so dot is at 10 + 135/2 + 21/2 = 88px of left (21/2 = dot size/2)
        // 100% equal total width => pourcent progress bar must begin at 88*100/width
        // the progress bar must be full until this.minPorgressBarValue
        this.minProgressBar = 8800 / this.myCurrenProgress.nativeElement.offsetWidth;
        // for calcul we also set max pourcent who is the other side same calcul but from 100 * (77+ 34/2)=9400 ( second dot is more biger 34px)
        // because of radius we remove a little less not 34/2 but 30/2 to be outside dot => 9200
        this.maxProgressBar = 100 - (9200 / this.myCurrenProgress.nativeElement.offsetWidth);
    }

    /**
     * calcul pourcent to use beetwen max and min
     */
    private pourcentToUse(): number {
        return this.progressState.progressBarValue * (this.maxProgressBar - this.minProgressBar) / 100 + this.minProgressBar;
    }

    ngAfterViewInit(): void {
        this.increaseProgressBarEffect();

    }
}
