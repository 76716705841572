import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-end-screen-score',
    templateUrl: './end-screen-score.component.html'
})
export class EndScreenScoreComponent implements OnInit {
    @Input() coins: { label: string, position: number }[] = []; // label and position of +1 +2 +3 +10 coin
    @Input() reward = 0; // number of stars 1 2 or 3,  4 equal diamond

    constructor() {
    }

    ngOnInit(): void {
    }

    /**
     * return text to use
     * @param position 1 to 4 (4 = diamond)
     */
    coin(position: number): string {
        return this.coins.find(c => c.position === position) ? this.coins.find(c => c.position === position).label : undefined;
    }
}
