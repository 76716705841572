
import {takeUntil} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {LessonsService} from '../../services/lessons.service';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {ActivitiesService} from '../../../activities.service';
import {DragulaService} from 'ng2-dragula';
import {ActivatedRoute, Router} from "@angular/router";
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {Subject} from "rxjs";

@Component({
  selector: 'app-form-activities',
  templateUrl: './form-activities.component.html',
  providers: [DragulaService]
})
export class FormActivitiesComponent implements OnInit {
    public selectedLessonContent: DataEntity[] = [];
    public orderedContent: DataEntity[] = [];

    private selectedLesson: DataEntity;
    private unsubscribeInTakeUntil = new Subject<void>();

    constructor(
        private octopusConnect: OctopusConnectService,
        private lessonsService: LessonsService,
        private activitiesService: ActivitiesService,
        private dragulaService: DragulaService,
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog
    ) {
        this.dragulaService.createGroup('itemsBAG', {});
    }

    ngOnDestroy() {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    ngOnInit() {
        this.lessonsService.getFormObs(this.lessonsService.selectedLessonId).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(entity => {
                this.selectedLesson = entity;
                const lessonContentArray = entity.get('reference');

                lessonContentArray.forEach((activity) => {
                    this.selectedLessonContent.push(
                        new DataEntity(
                            'granule-activity',
                            activity,
                            this.octopusConnect,
                            activity.id,
                            {
                                format: 'granule-format',
                                metadatas: 'metadatas',
                                reference: 'activity'
                            }
                    ));
                });

                this.orderedContent = this.selectedLessonContent.slice();
            });
    }

    createActivity(type: string) {
        this.router.navigate(['activity', type], {relativeTo: this.route})
    }

    editActivity(item: any) {
        if (item) {
            this.lessonsService.setCurrentActivity(item);
            this.router.navigate(['activity', item.get('metadatas').typology.label.toLowerCase()], {relativeTo: this.route});
        }
    }

    deleteActivity(item: any) {
      let activityIndex = this.selectedLessonContent.findIndex((element) => +element.id === +item.id);
      if (activityIndex > -1) {
          this.selectedLessonContent.splice(activityIndex, 1);
      }

      activityIndex = this.orderedContent.findIndex((element) => +element.id === +item.id);
      if (activityIndex > -1) {
          this.orderedContent.splice(activityIndex, 1);
      }

      this.lessonsService.removeActivityFromForm(item);
    }

    saveForm() {
        const lessonContent = this.orderedContent.map((activity) => {
            let activityData = activity.attributes;
            activityData.id = activity.id.toString();
            return activityData;
        });

        this.selectedLesson.set('reference', lessonContent);
        this.lessonsService
            .saveFormActivities(+this.selectedLesson.id).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((entity) => {
                this.exit();
            });
    }

    exit() {
        this.router.navigate(['forms', 'list']);
    }

  public getContentTypeIcon(data): object {
    return this.activitiesService.getContentTypeIcon(data);
  }

}
