import { Component, OnInit, OnDestroy, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { GamificationService } from '../gamification.service';
import { Creature } from '../definitions';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GalleryComponent implements OnInit {

  @Output()
  openCreature = new EventEmitter<Creature>();

  get userPoints(): any {
    return this.gamificationService.userPoints;
  }

  constructor(private gamificationService: GamificationService) {
  }

  ngOnInit(): void {
  }

  get items(): Array<Creature> {
    return this.gamificationService.badges.creatures;
  }

  onClick(creature: Creature): void {

    if (creature.creature.attributes.unLocked === false) {
      if (this.userPoints >= creature.creature.attributes.price) {
        this.gamificationService.openBuyPopup(creature.creature, (newCreature) => {
        });
      }
    } else {
      this.openCreature.emit(creature);
    }
  }

}
