// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content.p-24 mat-form-field {
  margin-bottom: 25px;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/corpus/core/components/resource-upload-modal-step2/resource-upload-modal-step2.component.scss"],"names":[],"mappings":"AAEA;EACI,mBAAA;AADJ","sourcesContent":["@import '../resource-upload-modal-step1/resource-upload-modal-step1.component.scss';\r\n\r\n.content.p-24 mat-form-field{\r\n    margin-bottom: 25px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
