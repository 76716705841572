// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  white-space: nowrap;
}

.main-container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.columns-container {
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
  position: relative;
}

.add-button {
  border-radius: 50%;
  border: 1px solid black;
  background-color: white;
  width: 20px;
  height: 20px;
  cursor: pointer;
  text-align: center;
  font-size: 24px;
  line-height: 22px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 0;
  z-index: 99;
}

.column {
  width: 200px;
  display: inline-block;
  vertical-align: top;
  height: 1200px;
  bottom: 0;
  position: relative;
}

.column-placeholder {
  border: 2px dashed lightgray;
  border-radius: 4px;
  margin: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/ideas-wall/core/ideas-view/ideas-view.component.scss"],"names":[],"mappings":"AAAA;EAEE,mBAAA;AAAF;;AAGA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;EACA,OAAA;EACA,SAAA;AAAF;;AAGA;EACE,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,uBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EAEA,kBAAA;EAEA,YAAA;EACA,WAAA;EACA,UAAA;EACA,WAAA;AAFF;;AAKA;EACE,YAAA;EACA,qBAAA;EACA,mBAAA;EACA,cAAA;EACA,SAAA;EACA,kBAAA;AAFF;;AAKA;EACE,4BAAA;EACA,kBAAA;EACA,YAAA;AAFF","sourcesContent":[":host {\r\n  //position: relative;\r\n  white-space: nowrap;\r\n}\r\n\r\n.main-container {\r\n  width: 100%;\r\n  height: 100%;\r\n  max-height: 100%;\r\n  overflow: hidden;\r\n  position: absolute;\r\n  top: 0;\r\n  right: 0;\r\n  left: 0;\r\n  bottom: 0;\r\n}\r\n\r\n.columns-container {\r\n  padding-left: 40px;\r\n  padding-right: 40px;\r\n  width: 100%;\r\n  height: 100%;\r\n  max-height: 100%;\r\n  overflow: auto;\r\n  position: relative;\r\n}\r\n\r\n.add-button {\r\n  border-radius: 50%;\r\n  border: 1px solid black;\r\n  background-color: white;\r\n  width: 20px;\r\n  height: 20px;\r\n  cursor: pointer;\r\n  text-align: center;\r\n  font-size: 24px;\r\n  line-height: 22px;\r\n  //margin: 10px;\r\n  position: absolute;\r\n\r\n  bottom: 20px;\r\n  right: 20px;\r\n  padding: 0;\r\n  z-index: 99;\r\n}\r\n\r\n.column {\r\n  width: 200px;\r\n  display: inline-block;\r\n  vertical-align: top;\r\n  height: 1200px;\r\n  bottom: 0;\r\n  position: relative;\r\n}\r\n\r\n.column-placeholder {\r\n  border: 2px dashed lightgray;\r\n  border-radius: 4px;\r\n  margin: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
