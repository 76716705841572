import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ContestService} from '@modules/contest';
import {combineLatest, debounceTime, startWith, switchMap} from 'rxjs';
import {DataCollection, DataEntity} from 'octopus-connect';
import {Observable} from 'rxjs/internal/Observable';
import {EstablishmentService} from '@modules/contest/core/services/establishment.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Contest, ContestRegistrationEntity} from '@modules/contest/core/model/contest';

export interface RegistrationDialogData {
    contest: Contest;
    registrations?: ContestRegistrationEntity[];
}

@Component({
    selector: 'app-registration-dialog',
    templateUrl: './registration-dialog.component.html'
})
export class RegistrationDialogComponent implements OnInit {
    registrationForm: FormGroup;
    classes: DataEntity[] = [];

    filteredEstablishments: DataEntity[];
    public requireConsent = false;
    public error: string;
    
    private registrationInProgress = false;
    private contest: Contest;
    private registrations: ContestRegistrationEntity[] = [];

    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<RegistrationDialogComponent>,
        private contestService: ContestService,
        private establishmentService: EstablishmentService,
        @Inject(MAT_DIALOG_DATA) public data: RegistrationDialogData,
    ) {
        this.contest = data.contest;
        this.registrations = data.registrations || [];
        
        this.contestService.getGroups().subscribe((groups) => {
            this.classes = groups.entities.filter((group) => group.get('type') === '2');
            this.classes.forEach((group) => {
                const checked = this.isEditing && this.registrations.some((registration) => +registration.get('group') === +group.id);
                const control = this.fb.control(checked);
                
                if (checked) {
                    control.disable();
                }
                
                (this.registrationForm.controls.selectedClasses as FormArray).push(control);
            });
        });

        this.registrationForm = this.fb.group({
            selectedClasses: new FormArray([]),
            postalCode: ['', Validators.required],
            establishmentName: ['', Validators.required],
            shareableCode: [''],
        });
        
        if (this.isEditing) {
            this.registrationForm.removeControl('postalCode');
            this.registrationForm.removeControl('establishmentName');
            this.registrationForm.removeControl('shareableCode');
        }

        // Set control for consent only if there is only one group selected
        this.registrationForm.controls.selectedClasses.valueChanges
            .subscribe((value) => {
                this.requireConsent = value.some(checked => checked);

                if (this.requireConsent) {
                    this.registrationForm.addControl('consent', this.fb.control(false, Validators.requiredTrue));
                } else {
                    this.registrationForm.removeControl('consent');
                }
            });
    }

    ngOnInit() {
        if (!this.isEditing) {
            combineLatest([
                this.registrationForm.get('postalCode').valueChanges.pipe(startWith('')),
                this.registrationForm.get('establishmentName').valueChanges.pipe(startWith(''))
            ]).pipe(
                debounceTime(500),
                switchMap(([postalCode, establishmentName]) =>
                    this.searchEstablishmentNames(postalCode, establishmentName)
                )
            )
                .subscribe(results => this.filteredEstablishments = results.entities);
        }
    }
    
    public get isEditing(): boolean {
        return this.registrations.length > 0;
    }
    
    public get isRegistering(): boolean {
        return this.registrationInProgress;
    }

    searchEstablishmentNames(postalCode: string, establishmentName: string): Observable<DataCollection> {
        return this.establishmentService.searchEstablishmentNames(postalCode, establishmentName);
    }

    public displayFn(etab: DataEntity): string {
        return etab && etab.get('name') ? etab.get('name') : '';
    }

    public handleRegister(): void {
        this.registrationInProgress = true;
        const selectedClassIds = (this.registrationForm.get('selectedClasses') as FormArray).controls
            .map((control: FormControl, index: number) => control.value ? +this.classes[index].id : null)
            .filter((value: number | string) => value !== null);

        const establishmentId = this.isEditing ? null : this.registrationForm.value.establishmentName?.id ?? null;

        const shareableCodeControl = this.isEditing ? null : this.registrationForm.get('shareableCode');

        this.contestService.registerToContest(this.contest.id, selectedClassIds, establishmentId, this.registrationForm.value.shareableCode || null)
            .subscribe({
                next: (data) => {
                    this.dialogRef.close(true);
                    this.error = null;
                    shareableCodeControl.setErrors(null);
                    this.registrationInProgress = false;
                },
                error: (error) => {
                    this.error = error.data.response.title;
                    shareableCodeControl.setErrors({'erreur': true});
                    this.registrationInProgress = false;
                }
            });
    }

}
