
import {take} from 'rxjs/operators';
import {
    Component,
    OnInit,
    ViewChildren,
    QueryList,
    ViewEncapsulation,
    OnDestroy
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {QcmComponent} from '@modules/activities/core/player-components/qcm/qcm.component';
import {QcuComponent} from '@modules/activities/core/player-components/qcu/qcu.component';
import {ShortAnswerComponent} from '@modules/activities/core/player-components/short-answer/short-answer.component';
import {TrainerFeedbackComponent} from '@modules/activities/core/shared-components/trainer-feedback/trainer-feedback.component';
import {AuthenticationService} from '@modules/authentication';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {Subscription, combineLatest, Observable} from 'rxjs';
import {DataEntity} from 'octopus-connect';
import {Location} from '@angular/common';
import {CommunicationCenterService} from '@modules/communication-center';
import {answerStatusEnum} from '@modules/activities/core/models/answer-status.enum';
@Component({
    selector: 'app-form-player',
    templateUrl: './form-player.component.html'
})
export class FormPlayerComponent implements OnInit, OnDestroy {
    public formSubscription: Subscription;
    savingSubscribe: Subscription;
    formPlayerActivitiesTable: any;
    contextId: string;
    crt: any;
    form: DataEntity;
    formTitle: string;
    formDescription: string;
    formOwnerName: string;
    public overallActivitiesComments: string;

    @ViewChildren(QcuComponent) public qcuComp: QueryList<QcuComponent>;
    @ViewChildren(QcmComponent) public qcmComp: QueryList<QcmComponent>;
    @ViewChildren(ShortAnswerComponent) public crtComp: QueryList<ShortAnswerComponent>;
    @ViewChildren(TrainerFeedbackComponent) public trainerFeedback: QueryList<TrainerFeedbackComponent>;

    constructor(
        private activatedRoute: ActivatedRoute,
        public authService: AuthenticationService,
        private router: Router,
        private activitiesService: ActivitiesService,
        private lessonsService: LessonsService,
        private location: Location,
        private communicationCenter: CommunicationCenterService
    ) {
    }

    ngOnInit(): void {
        if (this.authService.isLearner() && !this.hasAssignment) {
            this.router.navigate(['followed', 'list']);
        }
        this.communicationCenter
            .getRoom('assignment')
            .next('savingDone', false);
        this.formSubscription = this.lessonsService
            .getFormObs(this.lessonsService.selectedLessonId).pipe(
            take(1))
            .subscribe((form) => {
                this.form = form;
                this.activitiesService.pushValueIntoSubscriber(this.form.get('reference'));
                this.formPlayerActivitiesTable = this.form.get('reference');
                this.formTitle = form.get('metadatas').title;
                this.formDescription = form.get('metadatas').description;
                this.formOwnerName = form.get('owner-name');
            });

        this.savingSubscribe = this.communicationCenter
            .getRoom('assignment')
            .getSubject('savingDone')
            .subscribe((savingDone) => {
                if (savingDone){
                    this.goToAssignment();
                }
            });

        // todo fix when this.lessonsService.currentAssignment is undefined
        if (this.lessonsService.currentAssignment) {
            this.overallActivitiesComments = this.lessonsService.currentAssignment.get('comment');
        }
    }

    ngOnDestroy(): void {
        this.formSubscription.unsubscribe();
        this.savingSubscribe.unsubscribe();
    }

    public get hasAssignment(): boolean {
        return !!this.lessonsService.currentAssignment;
    }

    public get assignmentId(): string {
        return this.hasAssignment ? this.lessonsService.currentAssignment.id.toString() : '';
    }

    public get showFeedback(): boolean {
        return this.hasAssignment && (this.formMode === 'feedback' || this.formMode === 'display');
    }

    public get formMode(): string {
        if (this.hasAssignment) {
            const state = (this.lessonsService.currentAssignment.get('state_term') && this.lessonsService.currentAssignment.get('state_term').label)
                            || this.lessonsService.currentAssignment.get('state');

            if (state === 'closed') {
                return 'display';
            } else if (this.authService.isLearner() && (state === 'assigned' || state === 'pending')) {
                return 'answer';
            } else if (this.authService.isTrainer() && state === 'pending') {
                return 'feedback';
            }
        }

        return '';
    }

    public get canSave(): boolean {
        const mode = this.formMode;
        return mode === 'answer' || mode === 'feedback';
    }

    public getBack(): void {
        this.location.back();
    }

    public saveAnswers(): void {
        const assignator = this.lessonsService.currentAssignment.get('assignator');
        const assignated_user = this.lessonsService.currentAssignment.get('assignated_user');

        switch (this.formMode) {
            case 'display':
                this.goToAssignment();
                break;
            case 'feedback':
                const activitiesComponents = [
                    ...this.qcuComp.toArray(),
                    ...this.qcmComp.toArray(),
                    ...this.crtComp.toArray()
                ];
                this.trainerFeedback.toArray().forEach((component: TrainerFeedbackComponent) => {
                    const activityComponent = activitiesComponents.find((item) => +item.activity.id === +component.activity.id);
                    if (activityComponent) {
                        this.lessonsService.saveFeedbackUserSave(component.chooseActionForActivity, activityComponent['userSave']);
                    }
                });

                this.communicationCenter
                    .getRoom('assignment')
                    .next('comment', {id: this.assignmentId, comment: this.overallActivitiesComments});
                this.communicationCenter
                    .getRoom('assignment')
                    .next('changeState', {id: this.assignmentId, state: 'closed'});

                this.communicationCenter.getRoom('notifications')
                    .getSubject('sendNotification')
                    .next({
                        recipient : assignated_user.uid,
                        type: 'FEEDBACK_FORM',
                        content: {
                            author: assignator.name,
                            formName: this.formTitle,
                            projectName: 'project', // todo fix  when we know the project
                            formId: this.lessonsService.currentAssignment.id
                        }
                    });
                break;
            case 'answer':
                const activities = [];
                const saveObservables: Observable<DataEntity>[] = [];
                this.formPlayerActivitiesTable.forEach((item) => {
                    if (item.metadatas.typology.label) {
                        if (!activities.includes(item.metadatas.typology.label)) {
                            activities.push(item.metadatas.typology.label);

                            switch (item.metadatas.typology.label) {
                                // changer .save par ['save'] car le linter comprends pas que la methode existe dans la class abstraite BaseActivityComponent
                                case 'QCMU':
                                    saveObservables.push(...this.qcuComp.toArray().map(qcu => qcu['save'](answerStatusEnum.correct)));
                                    break;
                                case 'QCM':
                                    saveObservables.push(...this.qcmComp.toArray().map(qcm => qcm['save'](answerStatusEnum.correct)));
                                    break;
                                case 'CRT':
                                    saveObservables.push(...this.crtComp.toArray().map(crt => crt['save'](answerStatusEnum.correct)));
                                    break;
                            }
                        }
                    }
                });

                combineLatest(saveObservables).subscribe((userSaves: DataEntity[]) => {
                    this.communicationCenter
                        .getRoom('assignment')
                        .next('changeState', {id: this.assignmentId, state: 'pending'});

                    this.communicationCenter.getRoom('notifications')
                        .getSubject('sendNotification')
                        .next({
                            recipient : assignator.uid,
                            type: 'LEARNER_ANSWER_FORM_SENDED',
                            content: {
                                author: assignated_user.name,
                                formName: this.formTitle,
                                projectName: 'project', // todo fix  when we know the project
                                formId: this.lessonsService.currentAssignment.id
                            }
                        });
                });
                break;
        }
    }

    private goToAssignment(): void {
        this.router.navigate(['../../../..', 'list'], {relativeTo: this.activatedRoute});
    }
}

