import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {map, take, tap} from 'rxjs/operators';
import {CollectionOptionsInterface, DataCollection, DataEntity} from 'octopus-connect';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {Observable} from 'rxjs';
import {CommunicationCenterService} from '@modules/communication-center';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Component({
    selector: 'app-assignments-list',
    templateUrl: './assignments-list.component.html'
})
export class AssignmentsListComponent implements OnInit {

    @Input() chaptersChildrenIds: Array<string | number> = [];
    @Input() chaptersParentsIds: Array<string | number> = [];
    @Input() conceptId: string;
    @Output() assignmentsListEvent = new EventEmitter<DataEntity[]>();
    @Output() autoAssignmentsListEvent = new EventEmitter<DataEntity[]>();
    @Output() closedAssignmentsListEvent = new EventEmitter<DataEntity[]>();

    public showSpinner = true;
    public assignments: Array<DataEntity> = [];
    public conceptAssignments: Array<DataEntity> = [];
    public conceptAutoAssignments: Array<DataEntity> = [];
    public conceptClosedAssignments: Array<DataEntity> = [];
    private optionsInterface: CollectionOptionsInterface = {
        filter: {},
        page: 1,
    };
    public loadPaginatedAssignments$: (filters?: CollectionOptionsInterface) => Observable<DataCollection>;
    private idOfAssignmentSelected: string | number = null;


    constructor(
        private activitiesService: ActivitiesService,
        private communicationCenter: CommunicationCenterService,
        private lessonsService: LessonsService,
        private router: Router,
        private route: ActivatedRoute) {
        this.communicationCenter.getRoom('assignment').getSubject('loadPaginatedAssignmentsCallback')
            .pipe(
                tap((callback: (filters?: CollectionOptionsInterface) => Observable<DataCollection>) => {
                    this.loadPaginatedAssignments$ = callback;
                })
            ).subscribe();
        this.route.queryParams.pipe(
            tap((params: Params) => {
                if (params['assignmentId']) {
                    this.idOfAssignmentSelected = params['assignmentId'];
                }
            })
        ).subscribe();
    }

    ngOnInit(): void {
        this.initialize();
    }

    private initialize(): void {
        this.optionsInterface.filter['assignated_user'] = this.activitiesService.userData.id;
        this.getAssignments().pipe(take(1)).subscribe(data => {
            this.assignments = data;
            if (this.chaptersChildrenIds.length > 0){
                // check if assignment has a chapter of current and is not closed and is not an auto assignment
                this.conceptAssignments = this.assignments.filter(e =>
                    e['attributes']['assignated_node'] ? e['attributes']['assignated_node']['chaptersTag'].some(c => this.chaptersChildrenIds.includes(c.id))
                        && e['attributes']['state_term']['label'] !== 'closed' && e['attributes']['type_term']['label'] !== 'auto' : '');
                // get auto assignments
                this.conceptAutoAssignments = this.assignments.filter(e =>
                    e['attributes']['assignated_node'] ? e['attributes']['assignated_node']['chaptersTag'].some(c => this.chaptersChildrenIds.includes(c.id))
                        && e['attributes']['type_term']['label'] === 'auto' : '');
                // get closed assignments
                this.conceptClosedAssignments = this.assignments.filter(e =>
                    e['attributes']['assignated_node'] ? e['attributes']['assignated_node']['chaptersTag'].some(c => this.chaptersChildrenIds.includes(c.id))
                        && e['attributes']['state_term']['label'] === 'closed' : '');
            } else if (this.chaptersParentsIds.length > 0){
                // check if assignment has a chapter of current and is not closed and is not an auto assignment
                this.conceptAssignments = this.assignments.filter(e =>
                    e['attributes']['assignated_node'] ? e['attributes']['assignated_node']['chaptersTag'].some(c => this.chaptersParentsIds.includes(c.id))
                        && e['attributes']['state_term']['label'] !== 'closed' && e['attributes']['type_term']['label'] !== 'auto' : '');
                // get auto assignments
                this.conceptAutoAssignments = this.assignments.filter(e =>
                    e['attributes']['assignated_node'] ? e['attributes']['assignated_node']['chaptersTag'].some(c => this.chaptersParentsIds.includes(c.id))
                        && e['attributes']['type_term']['label'] === 'auto' : '');
                // get closed assignments
                this.conceptClosedAssignments = this.assignments.filter(e =>
                    e['attributes']['assignated_node'] ? e['attributes']['assignated_node']['chaptersTag'].some(c => this.chaptersParentsIds.includes(c.id))
                        && e['attributes']['state_term']['label'] === 'closed' : '');
            } else {
                this.conceptAssignments = this.assignments;
                this.conceptAutoAssignments = this.assignments;
                this.conceptClosedAssignments = this.assignments;
            }
            // send assignments to parent chapters list component
            this.assignmentsListEvent.emit(this.conceptAssignments);
            this.autoAssignmentsListEvent.emit(this.conceptAutoAssignments);
            this.closedAssignmentsListEvent.emit(this.conceptClosedAssignments);
            this.showSpinner = false;
            const assignment = this.assignments.find((a) => +a.id === +this.idOfAssignmentSelected);
            if (assignment) {
                this.launchModal(assignment);
            }
        });
    }

    public getAssignments(): Observable<DataEntity[]> {
        return this.loadPaginatedAssignments$(this.optionsInterface.filter).pipe(
            take(1),
            map(collection => collection.entities),
        );
    }

    public launchModal(assignment: DataEntity): void {
        this.lessonsService.loadLessonById(assignment.get('assignated_node').id).pipe(take(1))
            .pipe(
                tap((lesson) => this.lessonsService.openLessonModal(lesson, assignment))
            )
            .subscribe();
    }

    public pourcent(assignation): number {
        return assignation.get('progress') || 0;
    }

    /**
     * if assignator is a teacher we show a text for inform teacher of who assign it to her/him
     * @param assignment
     */
    public notAutoAssignation(assignment: DataEntity): boolean {
        const assignatedUser = assignment.get('assignated_user');
        const assignator = assignment.get('assignator');
        return assignator && assignatedUser && (assignator.uid !== assignatedUser.uid || assignator.name !== assignatedUser.name);
    }

}
