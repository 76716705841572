// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: relative;
  white-space: nowrap;
}

.add-button {
  border-radius: 50%;
  border: 1px solid black;
  background-color: white;
  width: 20px;
  height: 20px;
  cursor: pointer;
  text-align: center;
  font-size: 24px;
  line-height: 22px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 0;
  z-index: 99;
}

.main-container {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.categories-scrollable {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/ideas-wall/core/categories-view/categories-view.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,uBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EAEA,kBAAA;EAEA,YAAA;EACA,WAAA;EACA,UAAA;EACA,WAAA;AADF;;AAIA;EACE,kBAAA;EACA,YAAA;EACA,gBAAA;AADF;;AAIA;EACE,MAAA;EACA,SAAA;EACA,QAAA;EACA,OAAA;EACA,kBAAA;EACA,cAAA;AADF","sourcesContent":[":host {\r\n  position: relative;\r\n  white-space: nowrap;\r\n}\r\n\r\n.add-button {\r\n  border-radius: 50%;\r\n  border: 1px solid black;\r\n  background-color: white;\r\n  width: 20px;\r\n  height: 20px;\r\n  cursor: pointer;\r\n  text-align: center;\r\n  font-size: 24px;\r\n  line-height: 22px;\r\n  //margin: 10px;\r\n  position: absolute;\r\n\r\n  bottom: 20px;\r\n  right: 20px;\r\n  padding: 0;\r\n  z-index: 99;\r\n}\r\n\r\n.main-container {\r\n  position: relative;\r\n  height: 100%;\r\n  overflow: hidden;\r\n}\r\n\r\n.categories-scrollable {\r\n  top: 0;\r\n  bottom: 0;\r\n  right: 0;\r\n  left: 0;\r\n  position: absolute;\r\n  overflow: auto;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
