// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .content form {
  width: 100%;
  max-width: 800px !important;
}
:host .content .form-errors-model {
  flex: 1;
}
:host .content .form-errors-model code {
  background: none !important;
}
:host .content .horizontal-stepper-wrapper,
:host .content .vertical-stepper-wrapper {
  max-width: 800px;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/account-management/core/profile/tabs/edit-profile/forms.component.scss"],"names":[],"mappings":"AAIQ;EACI,WAAA;EACA,2BAAA;AAHZ;AAMQ;EACI,OAAA;AAJZ;AAMY;EACI,2BAAA;AAJhB;AAQQ;;EAEI,gBAAA;AANZ","sourcesContent":[":host {\r\n\r\n    .content {\r\n\r\n        form {\r\n            width: 100%;\r\n            max-width: 800px !important;\r\n        }\r\n\r\n        .form-errors-model {\r\n            flex: 1;\r\n\r\n            code {\r\n                background: none !important;\r\n            }\r\n        }\r\n\r\n        .horizontal-stepper-wrapper,\r\n        .vertical-stepper-wrapper {\r\n            max-width: 800px;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
