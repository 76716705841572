import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ILauncherButtonParam} from '@modules/activities/core/lessons/lessons-list/launcher/models/ilauncher';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {Router} from '@angular/router';
import {DataEntity} from 'octopus-connect';
import {NavigateToLessonOptions} from '@modules/activities/core/models/lessonsActivityRoutes';
import {CommunicationCenterService} from '@modules/communication-center';

@Component({
    selector: 'app-launcher-button',
    templateUrl: './launcher-button.component.html'
})
/**
 * unit component of one line of a question set is iterate by app-launcher
 * data comme from app-launcher too
 * 0 = no start 1 = 1 star 2 = 2 star 3 = 3 star 4 = best win : diamond
 */
export class LauncherButtonComponent implements OnInit {
    @Input() step: ILauncherButtonParam;
    @Input() lessonParent: DataEntity;
    @Input() assignment: DataEntity;
    @Input() index: number;
    @Output() launchQuestionSet = new EventEmitter<string | number>();
    stars = [];
    empty = ['', '', ''];
    diamond = 0;


    constructor(
        public lessonsService: LessonsService,
        private router: Router,
        private communicationCenter: CommunicationCenterService,
    ) {}

    ngOnInit(): void {
        if (this.step.reward === 4) {
            this.stars = [];
            this.empty = [];
            this.diamond = 1;
        } else {
            if (this.step.reward) {
                this.stars = this.createArray(this.step.reward);
                this.empty = this.createArray(3 - this.step.reward);
            }
        }
    }

    /**
     * create an array of the size pass in steps
     * use to iterate stars with an ngfor in regard of how was wins
     * @param length : size of the array
     */
    public createArray(length): any[] {
        return new Array(length);
    }

    /**
     * send id of current line (question set) selected to parent
     * @param id
     */
    public launch(id: string, index: number, assignment?: DataEntity): void {
        // hide all app make a blanck screen to avoid seeing list and exo before all is loading
        this.communicationCenter
            .getRoom('app')
            .getSubject('visibility')
            .next(false);
        const exitLessonUrl = this.router.url;
        const navigationOptions: NavigateToLessonOptions = {
            exitLessonUrl: exitLessonUrl,
            startOnStepIndex: index,
        };
        if (assignment) {
            // todo remove useless code in assignment Service when we 'next' communication center subject : launchAssignementInPlayer
            this.communicationCenter
                .getRoom('assignment')
                .next('current', assignment);
            navigationOptions.assignmentId = assignment.id;

            this.lessonsService.loadAndNavigateToLessonFromAssignment(assignment, navigationOptions);
        } else {
            // if no assignment navigate to lessons to create auto assignment and launch activity
            this.lessonsService.loadAndNavigateToLesson(id, navigationOptions);
        }
        // timeout to see button effect when click inside before closing
        setTimeout(() => {
            this.launchQuestionSet.emit(id);
        }, 100);
    }
}
