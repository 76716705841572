import {Component} from '@angular/core';
import {DataCollection, DataEntity} from "octopus-connect";
import {ContestService} from "@modules/contest";
import {Contest} from "@modules/contest/core/model/contest";
import {LessonEntity} from "@modules/contest/core/model/lesson.entity";
import {Observable, ReplaySubject, skipWhile} from "rxjs";
import {map, mergeMap, takeUntil, tap} from "rxjs/operators";
import {AutoUnsubscribeTakeUntilClass} from "shared/models";
import {ActivityDataEntity} from "@modules/contest/core/model/activity.data-entity";
import {brand} from "../../../../../../settings/amazon_python";
import {ActivatedRoute, Router} from "@angular/router";
import {AssignationService} from "@modules/contest/core/services/assignation.service";
import {ActivitiesService} from "@modules/contest/core/services/activities.service";
import {NavigateToLessonOptions} from "@modules/contest/core/model/lessonsActivityRoutes";
import {AuthorizationService} from "@modules/authorization";
import {SyncRules} from "@modules/contest/core/model/rules";

@Component({
    selector: 'app-contest-lessons',
    templateUrl: './contest-lessons.component.html'
})
export class ContestLessonsComponent extends AutoUnsubscribeTakeUntilClass {
    private userSaves: DataEntity[] = [];
    private canCompeteInContest: boolean;
    private nextActivityIndex: number;
    private selectedTabIndex: number;

    public contest: Contest;
    public loading = true;
    public activities: ActivityDataEntity[][] = [];
    public subLessons: LessonEntity[] = [];

    constructor(
        private contestService: ContestService,
        private activitiesService: ActivitiesService,
        private assignationService: AssignationService,
        private authorization: AuthorizationService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        super();

        this.route.parent.params.subscribe(params => {
            if (params['id']) {
                this.loading = true;
                this.contestService
                    .getContestById(params['id'])
                    .subscribe((contest: Contest) => {
                        this.contest = contest;
                        this.initialize();
                    });
            }
        });
    }

    private initialize(): void {
        this.loadLesson(this.contest.lessons[0])
            .pipe(
                tap(() => this.canCompeteInContest = this.authorization.currentUserCan(SyncRules.CompeteInContest)),
                skipWhile(() => this.canCompeteInContest === false),
                mergeMap(() => this.assignationService.getAssignmentsByLessonId(this.contest.lessons[0])),
                map((assigmentCollection: DataCollection) => {
                    return assigmentCollection.entities.filter((assignment: DataEntity) => {
                        return +assignment.get('assignated_user').uid === this.assignationService.currentUserId;
                    });
                }),
                tap((assignments: DataEntity[]) => {
                    if (assignments && assignments.length) {
                        this.loadAssignment(assignments[0]);
                    } else {
                        this.assignationService.createAssignment(this.contest.lessons[0], this.loadAssignment.bind(this));
                    }
                }),
            ).subscribe();
    }

    private loadLesson(lessonId: number): Observable<DataEntity[]> {
        const callbackLesson$ = new ReplaySubject<Observable<LessonEntity>>(1);

        this.activitiesService.loadLesson(lessonId, callbackLesson$);

        return callbackLesson$.pipe(
            mergeMap((lesson$: Observable<LessonEntity>) => lesson$),
            takeUntil(this.unsubscribeInTakeUntil),
            mergeMap((lesson: LessonEntity) => {
                this.subLessons = [lesson];

                return this.loadActivities(lesson);
            })
        );
    }

    private loadActivities(lesson: LessonEntity): Observable<DataEntity[]> {
        const callbackActivities$ = new ReplaySubject<Observable<DataEntity[]>>(1);
        this.activitiesService.loadActivities(lesson, callbackActivities$);

        return callbackActivities$.pipe(
            mergeMap((activities$: Observable<DataEntity[]>) => activities$),
            takeUntil(this.unsubscribeInTakeUntil),
            tap((activities: DataEntity[]) => {
                if (activities[0].get('format').label === 'lesson') {
                    this.subLessons = activities as LessonEntity[]
                    activities.forEach((activity: LessonEntity) => {
                        this.loadActivities(activity).subscribe();
                    });
                } else {
                    this.activities[lesson.id] = activities as ActivityDataEntity[]
                    this.onTabChange(0);

                    if (!this.canCompeteInContest) {
                        this.loading = false;
                    }
                }
            })
        );
    }

    private loadAssignment(assignment: DataEntity): void {
        this.assignationService.setCurrentAssignment(assignment);
        this.loadUserSaves();
    }

    private loadUserSaves(): void {
        this.activitiesService
            .loadUserSaves()
            .subscribe((userSaves: DataEntity[]) => {
                this.userSaves = userSaves;
                this.loading = false;
            });
    }

    private findFirstAvailableActivity(lesson: LessonEntity): void {
        this.nextActivityIndex = -1;

        this.activities[lesson.id].forEach((activity: ActivityDataEntity, index: number) => {
            if (this.nextActivityIndex === -1 && !this.isActivityDone(activity)) {
                this.nextActivityIndex = index;
            }
        });
    }

    private playActivity(lesson: LessonEntity, index: number): void {
        const options: NavigateToLessonOptions = {
            isActivitiesListMustBeDisplayed: false,
            startOnStepIndex: index || 0,
            exitLessonUrl: this.router.url,
        };
        const preview = !this.canCompeteInContest; // navigate as preview if user can't compete in contest

        this.activitiesService.navigateToLesson(lesson, options, preview);
    }

    public get canCompete(): boolean {
        return this.canCompeteInContest;
    }

    public onTabChange(tabIndex: number): void {
        this.selectedTabIndex = tabIndex;
        this.findFirstAvailableActivity(this.subLessons[tabIndex]);
    }

    public isActivityDone(activity: ActivityDataEntity): boolean {
        return this.userSaves.some((userSave: DataEntity) => {
            return +userSave.get('granule')[0] === +activity.id
                && +userSave.get('grade') > 0;
        });
    }

    public getActivityTypeImage(activity: ActivityDataEntity): string {
        if (activity.get('metadatas').lessonType.length && activity.get('metadatas').lessonType[0]['image']) {
            return activity.get('metadatas').lessonType[0]['image'];
        }
        return '/assets/' + brand + '/images/thumbs/contest.png';
    }

    public playEvent(data: { subLesson: LessonEntity, i: number }): void {
        this.playActivity(data.subLesson, data.i);
    }
}