import {ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {Observable, ReplaySubject} from 'rxjs';
import {map, mergeMap, take} from 'rxjs/operators';
import {CollectionOptionsInterface, DataCollection, DataEntity, OctopusConnectService, PaginatedCollection} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {AuthenticationService} from '@modules/authentication';
import {AssignationService} from '@modules/assignation';
import {AssignationConfigurationService} from "@modules/assignation/core/services/assignation-configuration.service";
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-usersaves-list',
    templateUrl: './usersaves-list.component.html',
    styleUrls: ['./usersaves-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UsersavesListComponent implements OnInit {
    @Input('isAssignationClosedActive') isAssignationClosedActive = false;
    public dataSource = new MatTableDataSource();
    public isLoading = true;
    public displayedColumns: string[] = ['lesson', 'questionSet', 'activity', 'errors', 'context'];

    private optionsInterface: CollectionOptionsInterface;
    private lessonsIds: string[] = [];
    private assignationIds: string[] = [];
    private lessons: any[] = [];
    private rows: any[] = [];
    private logsPaginated: PaginatedCollection;

    countEntities = 10;
    pageIndex = 0;
    pageRange = 10;
    pageRangeOptions = [10];

    constructor(
        private octopusConnect: OctopusConnectService,
        private cdr: ChangeDetectorRef,
        private communicationCenter: CommunicationCenterService,
        private authService: AuthenticationService,
        private assignationService: AssignationService,
        private config: AssignationConfigurationService,
        private ref: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
    ) {
    }

    public get displayedFilters(): string[] {
        const role = this.authService.accessLevel;
        let fields = this.assignationService.settings.userSavesList[role];
        if (fields === undefined) {
            fields = this.assignationService.settings.userSavesList['default'];
        }
        return fields;
    }

    public get rolesCanShowBannerInfo(): string[] {
        return this.config.rolesCanShowBannerInfo();
    }

    public get rolesCanShowBannerInfoClosedAssignment(): string[] {
        return this.config.rolesCanShowBannerInfoClosedAssignment();
    }

    ngOnInit(): void {
        this.optionsInterface = {
            filter: {grade: 500},
            page: 1,
            range: 10,
        };

        if(this.authService.isAtLeastTrainer()) {
            this.optionsInterface.filter['showLearners'] = true;
            this.displayedColumns = ['learner', ...this.displayedColumns];
        }

        if(!this.activatedRoute?.snapshot?.queryParams?.learnerId){
            this.loadUserSave();
        }
    }

    /**
     * reload data in regard of new filter
     * @param options : optionsInterface
     */
    launchSearch(options: CollectionOptionsInterface = null): void {
        this.optionsInterface = options;
        if(this.authService.isAtLeastTrainer()) {
            this.optionsInterface.filter['showLearners'] = true;
        }
        this.isLoading = true;
        this.loadUserSave();
    }

    public shouldDisplayFilters(): boolean {
        return this.displayedFilters.length > 0;
    }

    setPaginator(): void {
        if (this.logsPaginated.paginator) {
            this.countEntities = this.logsPaginated.paginator.count;
            this.pageIndex = this.logsPaginated.paginator.page - 1;
            this.pageRange = this.logsPaginated.paginator.range;
        }
    }

    public loadUserSave(): void {
        this.logsPaginated = this.octopusConnect
            .paginatedLoadCollection('user-save', this.optionsInterface);
        this.logsPaginated.collectionObservable.pipe()
            .subscribe((dataEntities) => {
                if(dataEntities.entities.length > 0) {
                    this.extractLessonIds(dataEntities.entities);
                    this.getLessonByIds(this.lessonsIds).subscribe((lessons) => {
                        this.lessons = this.formatLessons(lessons);
                        this.rows = this.formatRows(dataEntities.entities, this.lessons);
                        this.dataSource.data = this.rows;
                        this.ref.detectChanges();
                        this.setPaginator();
                        this.isLoading = false;
                    });
                } else {
                    this.isLoading = false;
                }
            });

    }

    private extractLessonIds(entities: DataEntity[]): void {
        entities.forEach((entity) => {
            this.lessonsIds.push(entity.get('lesson'));
            this.assignationIds.push(entity.get('context'));
        });
        this.lessonsIds = [...new Set(this.lessonsIds)];
    }

    private formatLessons(lessons: DataEntity[]): any[] {
        return lessons.map((item) => ({
            id: item.id,
            activities: item.get('reference'),
            questionSet: item.get('metadatas').title,
        }));
    }

    private formatRows(entities: DataEntity[], lessons: any[]): any[] {
        return entities.reduce((acc, entity) => {
            const lesson = lessons.find((item) => item.id === entity.get('lesson'));
            const activity = lesson.activities.find((item) => entity.get('granule')[0] === item.id);

            if (!activity) return acc; // Si activity est undefined, retournez le tableau accumulé tel quel

            const newRow = {
                grade: entity.get('grade'),
                errorsCount: entity.get('errorsCount'),
                id: entity.id,
                uid: entity.get('assignation').assignatedUser,
                questionSet: lesson.questionSet,
                activity: activity?.title,
                lesson: entity.get('assignation').title,
                context: entity.get('assignation').uid === entity.get('uid') ? 'auto-assignment' : 'assignment'
            };

            acc.push(newRow); // Ajoutez la nouvelle ligne au tableau accumulé
            return acc; // Retournez le tableau accumulé mis à jour
        }, []);
    }


    private getActivities(lesson: DataEntity): Observable<DataEntity[]> {
        const lesson$ = new ReplaySubject<Observable<DataEntity[]>>(1);
        this.communicationCenter.getRoom('lessons').next('getActivities', {
            lesson: lesson,
            callbackSubject: lesson$,
        });
        return lesson$.pipe(mergeMap((obs) => obs));
    }

    private getLessonByIds(ids: string[] | number[]): Observable<DataEntity[]> {
        const lesson$ = new ReplaySubject<Observable<DataEntity[]>>(1);
        this.communicationCenter.getRoom('lessons').next('getLessons', {
            lessonIds: ids,
            callbackSubject: lesson$
        });
        return lesson$.pipe(
            mergeMap(obs => obs)
        );
    }

    public onPaginateChange(event): void {
        this.logsPaginated.paginator.page =
            event.pageIndex + 1;
    }
}
