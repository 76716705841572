import {CommunicationRoom} from './communication-room.class';

export class EventListener {

    /**
     * Creates a communication EventListener
     * @param {CommunicationRoom} room Reference to the room
     * @param {string} eventName Name of the event
     * @param {Function} callback Callback to execute when event is triggered
     */
    constructor(
        private room: CommunicationRoom,
        public eventName: string,
        public callback: Function
    ) {}

    /**
     * Stops to listen the event
     */
    stopListen() {
        this.room.deleteListenerReference(this);
    }
}
