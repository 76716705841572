export const groupsManagement = {
    accessGroupsManager: ['manager', 'administrator'],
    accessGroupsTrainer: ['trainer', 'administrator'],
    authorizedRoles: ['learner', 'trainer', 'manager', 'administrator'],
    canBeArchived: false,
    canBeDelete: {
        workgroup: true,
        institution: false,
        learner: true,
        learners: false,
    },
    canBeJoined: {
        group: true
    },
    collapsibleFields: [],
    displayedFiltersLearners: {
        default: [],
    },
    displayedFiltersTrainers: {
        default: []
    },
    displayedFiltersGroups: {
        default: []
    },
    displayedFiltersWorkGroups: {
        default: []
    },
    displayedFiltersInstitutions: {
        default: []
    },
    displayHeader: false,
    displayNews: {
        default: [],
        learner: ['alertTeacherSso'],
        trainer: ['createGroup', 'createLearner']
    },
    newGroupField: ["color", "groupname", "educationalLevel"],
    editGroupField: ["id","color", "groupname", "educationalLevel"],
    group: {
        columns: {
            default: ["color", "groupname", "level", "code", "buttons"],
        },
    },
    groupType: 2,
    // communication center room of the graph module
    graphRoomId: "graphMathia",
    idDefiTaxonomy: "5427", // id of the defi in list activity type
    idActivityToHideInProgressTab: [], // ex :['5445'] use because somme activity in mathia must be hide in progress and not in activity tab
    institutionGroupType: 52,
    isPasswordRequired: true, // when teacher create student account is password require
    isLearnersListExportEnabled: ['administrator', 'manager', 'director', 'authenticated'],
    learner: {
        columns: {
            default: [
                "nickname",
                "pseudo",
                "level",
                "groups",
                "assignation_tab_results",
                "buttons",
            ],
        },
        createFields: {
            default: [
                "avatar",
                "nickname",
                "password",
                "groups",
            ],
        },
        editFields: {
            default: [
                "id",
                "avatar",
                "nickname",
                "password",
                "groups",
                "educationalLevel",
            ],
        }, // do not remove 'id' because it's needed to update data
        joinFields: {
            default: [
                "avatar",
                "nickname",
                "password",
                "groups",
                "educationalLevel",
            ],
        },
    },
    levelsWidget: "select",
    primaryColors: [
        "#C5629D",
        "#FF9EA1",
        "#EEDA78",
        "#6FF2C5",
        "#6DE6E2",
        "#305792",
    ],
    requiredFields: ['nickname', 'password', 'groupname'],
    showGlobalAddButton: false, // show main button to add class group or user that open modal dialog if false it's inline add
    workgroupType: 3,
    importInClasses: false,
    setTeacherRegionWhenAddNewLearner: true, // set by default the teacher value when adding a new learner
    rulesForGARRoles: {
        default: [
            'group-management.create-group',
            'group-management.see-learners-in-group',
            'group-management.see-own-group',
            'group-management.see-all-group',

            'group-management.create-workgroup',
            'group-management.edit-workgroup',
            'group-management.delete-workgroup',
            'group-management.see-learners-in-workgroup',
            'group-management.attach-learner-to-workgroup',
            'group-management.be-attached-to-workgroup',
            'group-management.see-own-workgroup',
            'group-management.see-all-workgroup',
        ],
        learner: [],
    },
};
