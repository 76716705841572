import { Component } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-create-leaner-news',
  templateUrl: './create-leaner-news.component.html',
})
export class CreateLeanerNewsComponent {

    constructor(public router: Router) {
    }

    public goToGroupManagementPage(): void {
        this.router.navigate(['/groups/list/learners']);
    }
}
