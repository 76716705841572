import {Observable, of, ReplaySubject, Subject} from 'rxjs';
import {mergeMap, take, takeUntil} from 'rxjs/operators';
import {AfterViewChecked, Component, Input} from '@angular/core';
import {DataEntity} from 'octopus-connect';
import Keyboard from 'latex-keyboard';
import {
    BaseActivityComponent,
    FeedbackInterface,
    TIME_DELAY_BEFORE_SAVE,
    TIME_DISPLAYING_CORRECTION
} from '../base-activity.component';
import {AnswerResultInterface, answerStatusEnum, ItemAnswerInterface, ItemAnswerStateEnum} from '@modules/activities/core/models';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {ActivatedRoute} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {ActivityReferenceInterface} from '@modules/activities/core/models/activity-reference.interface';
import {v4 as uuidv4} from 'uuid';
import {TranslateService} from '@ngx-translate/core';
import {LessonNavigationService} from '../../lesson-navigation.service';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {ContextualService} from '@modules/activities/core/services/contextual.service';

// format de l'activity_content
interface ShortAnswerActivityContentInterface {
    answers: ItemAnswerInterface[];
}

// format du champ config de l'activité
interface ShortAnswerActivityConfigInterface {
    displaySaveBtn?: boolean;
    doubleColumn?: boolean;
    caseSensitive?: boolean;
    isWordingImage?: boolean;
    latexKeyboard?: boolean;
    keyboardType?: string;
    poll?: boolean;
    longAnswer?: boolean;
}

// format du champ référence de l'activité
type ShortAnswerActivityReferenceInterface = ActivityReferenceInterface<ShortAnswerActivityContentInterface, ShortAnswerActivityConfigInterface>;

@Component({
    selector: 'app-short-answer',
    templateUrl: './short-answer.component.html'
})
export class ShortAnswerComponent extends BaseActivityComponent<ShortAnswerActivityContentInterface, ShortAnswerActivityConfigInterface>
    implements AfterViewChecked {
    public static activityLabel = 'shortAnswer';

    @Input() public disabled: boolean;
    @Input() hideCorrection: boolean;

    public answerFeedback: string;
    public assignatedCount = 20;
    public displaySaveBtn: boolean;
    public disable: boolean;
    public generateArrayToIterateAndDisplayKeyboard = [];
    public isCaseSensitive = true;
    public isFormula = false;
    public isLongAnswer: boolean;
    public isPoll = false;
    public isWordingImage = false;
    public referenceActivityGranule: ShortAnswerActivityReferenceInterface;
    public reply: ItemAnswerInterface;
    public uuid: string;
    public validated = false;
    public symbols: any;
    public optionsFromSettings: any;
    public blacklistTabs: string[] = [];
    public answerCorrected: ReplaySubject<ItemAnswerInterface> = new ReplaySubject<ItemAnswerInterface>(1);
    public isTTSReading: { id: string; value: boolean };
    public readSecondConsigne = new Subject<boolean>();
    private callbackToTriggerOnAfterViewChecked: Function;
    private latexAnswer: string;
    private latexKeyboard: Keyboard;
    private userAccessCorrection = false;
    private isCheckingAnswer = false; // is user currently check an answer boolean use to avoid multi true click for only one answer
    private userTry = 0;
    private waitUntilCorrectionFinished: Boolean;

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected activitiesService: ActivitiesService,
        protected communicationCenter: CommunicationCenterService,
        protected contextualService: ContextualService,
        protected lessonsService: LessonsService,
        private translate: TranslateService,
        protected lessonNavigationService: LessonNavigationService,
        private config: LessonsConfigurationService
    ) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, contextualService, lessonNavigationService);
        document.getElementsByTagName('body')[0].classList.add('short-answer');
        // TODO bad fix but bad layout behaviour and no time (header get out with latex keyboard)
    }

    public get isLessonWithStep(): boolean {
        return (
            this.lessonsService.settings && this.lessonsService.settings.lessonStep
        );
    }

    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        super.ngOnDestroy();

        const haveToSaveOnDestroy = this.activitiesService.settings[
            'saveOnDestroy'
            ].includes(ShortAnswerComponent.activityLabel);

        let todoBeforeDestroy: Observable<any>;
        if (haveToSaveOnDestroy) {
            this.checkAnswer();
            todoBeforeDestroy = this.saveAnswer();
        } else {
            todoBeforeDestroy = of([]);
        }

        todoBeforeDestroy.pipe(
            take(1))
            .subscribe(() => {
                if (this.latexKeyboard) {
                    this.latexKeyboard.destroy();
                }
            });

        document.getElementsByTagName('body')[0].classList.remove('short-answer');
    }

    public answerState(): string {
        if (this.displaySolution) {
            return 'correctReply';
        } else {
            if (!this.hideCorrection && this.testAnswer) {
                if (this.isReplyCorrect()) {
                    return 'correctReply';
                } else if (!this.reply.answer) {
                    return 'missingReply';
                } else {
                    return 'wrongReply';
                }
            }

            if (this.validated) {
                return 'validated';
            }
        }

        return '';
    }

    checkAnswer() {
        if (this.isFormula && this.latexKeyboard) {
            this.reply.answer = this.latexKeyboard.getLatex();
        }
        const answerResult: AnswerResultInterface = {
            id: +this.activity.id,
            state: ItemAnswerStateEnum.missing,
            isLast: undefined,
            forceStateToCurrentlyCorrect: this.isLongAnswer,
            answer: this.reply
        };
        if (this.availableAnswers?.length || !this.isLongAnswer) {
            if (this.reply?.answer) {
                if (this.isReplyCorrect()) {
                    answerResult.state = ItemAnswerStateEnum.currentlyCorrect;
                    answerResult.isLast = true;
                    this.reply.state = ItemAnswerStateEnum.currentlyCorrect;
                    this.answerStatus = answerStatusEnum.correct;
                } else {
                    this.isCheckingAnswer = false; // we change the answer so reset
                    answerResult.state = ItemAnswerStateEnum.incorrect;
                    this.reply.state = ItemAnswerStateEnum.incorrect;
                    this.answerStatus = answerStatusEnum.wrong;
                    // feedback in case of bad answer is store in the good answer in field feedback in other exo it's in bad answers but in this case whe have only one answer
                }
            } else {
                this.isCheckingAnswer = false; // we change the answer so reset
                this.reply.state = ItemAnswerStateEnum.missing;
                this.answerStatus = answerStatusEnum.missing;
            }

            if (this.autoCorrection) {
                this.waitUntilCorrectionFinished = true;
                if (!(answerResult.state === ItemAnswerStateEnum.currentlyCorrect || answerResult.state === ItemAnswerStateEnum.wasCorrect) && this.referenceActivityGranule.activity_content.answers.length > 0 && this.referenceActivityGranule.activity_content.answers[0].feedback) {
                    super.feedbackEndExoOpenModal({
                        title: 'activities.title_modal_bad_answer_help',
                        content: this.referenceActivityGranule.activity_content.answers[0].feedback,
                        callback: () => this.animateAnswerState()
                    });
                } else {
                    this.animateAnswerState();
                }
            }

            if (this.lessonsService.isLessonTraining() && !this.lessonsService.isAtLeastTrainer()) {
                if (!this.hideCorrection && this.answerStatus === answerStatusEnum.wrong) {
                    this.displayFeedback = this.activitiesService.settings.displayFeedback;
                }
            }

            this.activitiesService.isUserAnswerStatus.next({
                status: this.answerStatus,
                index: this.activityStepIndex
            });

            if (this.userAccessCorrection) {
                this.activitiesService.checkAnswers.next({lessonCorrected: true});
            }
        } else if (this.autoCorrection) {
            answerResult.isLast = true;
            this.reply.state = ItemAnswerStateEnum.currentlyCorrect;
            this.answerStatus = answerStatusEnum.correct;
            this.animateAnswerState(true);
        }
        super.manageProgressBarEventToSend(answerResult);
    }

    public onNgModelChange(event): void {
        this.activitiesService.doesUserResponsed.next(event !== '');
        this.onOptionChange(event !== '');
    }

    public ngAfterViewChecked(): void {
        if (this.callbackToTriggerOnAfterViewChecked) {
            this.callbackToTriggerOnAfterViewChecked();
            this.callbackToTriggerOnAfterViewChecked = null;
        }
    }

    public isReplyCorrect(): boolean {
        return this.availableAnswers.some(option => option.answer.replace(/ /g, '') === this.reply.answer.toString().replace(/ /g, '') ||
            (this.reply && this.reply.answer &&
                !this.isCaseSensitive && option.answer.replace(/ /g, '').toLowerCase() === this.reply.answer.toString().replace(/ /g, '').toLowerCase()));
    }

    /**
     * set the value to save in the field of the short answer
     * @param sliderPosition : value of slider equal nbre of vote
     */
    public onMoveSlider(sliderPosition: number): void {
        this.reply.answer = sliderPosition.toString();
    }

    /**
     * set the value to save in the field of the short answer
     * @param sliderPosition : value of slider equal nbre of vote
     */
    public onClickSlider(sliderPosition: number): void {
        this.reply.answer = sliderPosition.toString();
    }

    public getStateAnswer(buttonType: string): string {
        return buttonType === 'validate' ? this.reply.state : '';
    }

    public doesUserResponded(val: boolean): void {
        this.activitiesService.doesUserResponsed.next(val);
    }

    public onKeyboardReady($event: Keyboard): void {
        this.latexKeyboard = $event;
        if (this.displayForSummary) {
            this.seeAnswerSolution();
        }
    }

    protected initialize(): void {
        this.generateArrayToIterateAndDisplayKeyboard = [];
        this.isCheckingAnswer = false;
        super.initialize();
    }

    /**
     * permet d'afficher la solution de l'exercice
     */
    protected seeAnswerSolution(): void {
        if (!this.availableAnswers[0] || this.isLongAnswer && this.userSave) {
            this.setAnswer();
        } else {
            if (this.isFormula && this.latexKeyboard) {
                this.latexAnswer = this.latexKeyboard.getLatex();
                this.latexKeyboard.pushLatex(this.availableAnswers[0]?.answer);
            } else {
                this.reply.answer = this.availableAnswers[0]?.answer;
            }
            this.displaySolution = true;
            this.disable = true;
        }

    }

    protected reviewAnswer(): void {
        if (this.isFormula && this.latexKeyboard) {
            this.latexKeyboard.pushLatex(this.latexAnswer);
        }
        // todo: ajouter revoir reponse si pas de clavier latex
        // this.disable = this.lessonsService.isAtLeastTrainer() && !this.lessonsService.isLessonTest();
    }

    protected setContentData(activityAttributes): void {
        this.waitUntilCorrectionFinished = false;
        this.userTry = 0;
        this.isCheckingAnswer = false; // we change the answer so reset
        this.referenceActivityGranule = activityAttributes.reference;
        if (this.referenceActivityGranule.config) {
            this.displaySaveBtn = this.referenceActivityGranule.config.displaySaveBtn === true;
            this.isTwoColumns = !!this.referenceActivityGranule.config.doubleColumn;
            this.isCaseSensitive = !!this.referenceActivityGranule.config.caseSensitive;
            this.isWordingImage = !!this.referenceActivityGranule.config.isWordingImage;
            this.isFormula = !!this.referenceActivityGranule.config.latexKeyboard && this.config.displayLatexKeyboard();
            this.isLongAnswer = !!this.referenceActivityGranule.config.longAnswer
                || !this.referenceActivityGranule.activity_content?.answers?.length;
        }

        if (this.referenceActivityGranule?.config?.poll) {
            /**
             * endpoint "assignation_search" called if user came from assignment list.
             * endpoint "assignations" called if user came from player (lesson).
             */
            if (
                this.activitiesService.currentAssignment &&
                this.activitiesService.currentAssignment.get('assignatedCount')
            ) {
                this.assignatedCount =
                    this.activitiesService.currentAssignment.get('assignatedCount');
            } else {
                this.assignatedCount = 20;
            }

            this.isTwoColumns = false;
            this.reply = {
                id: uuidv4(),
                answer: '',
                state: ItemAnswerStateEnum.pristine
            };
            this.isPoll = true;
        }

        if (this.isFormula) {
            const keyboardType: string = this.referenceActivityGranule.config['keyboardType'] || 'alphabetic';
            this.symbols = this.referenceActivityGranule && this.referenceActivityGranule.config
                && this.activitiesService.settings.symbolsForLatexKeyboard
                && this.activitiesService.settings.symbolsForLatexKeyboard[keyboardType]
                && this.activitiesService.settings.symbolsForLatexKeyboard[keyboardType].data;

            this.optionsFromSettings = this.activitiesService.settings.symbolsForLatexKeyboard
                && this.activitiesService.settings.symbolsForLatexKeyboard[keyboardType]
                && this.activitiesService.settings.symbolsForLatexKeyboard[keyboardType].options || {};

            if (this.optionsFromSettings.validateButton) {
                this.translate.get('generic.validate').subscribe((translation: string) => this.optionsFromSettings.validateButtonText = translation);
            }

            if (this.activitiesService.isPrimary(this.referenceActivityGranule)) {
                this.blacklistTabs.push('123 secondaire');
            } else {
                this.blacklistTabs.push('123 primaire');
            }
            this.generateArrayToIterateAndDisplayKeyboard.push(this.referenceActivityGranule);
        }

        if (this.referenceActivityGranule.feedback) {
            this.answerFeedback = activityAttributes.reference.feedback;
        }

        this.instruction = this.referenceActivityGranule.instruction;
        this.wording = this.referenceActivityGranule.wording;
        this.instructionAudio = this.referenceActivityGranule.instructionAudio;
        this.wordingAudio = this.referenceActivityGranule.wordingAudio;
        this.availableAnswers = this.referenceActivityGranule.activity_content?.answers.slice()
            .filter((answer) => !!answer?.answer && answer.answer !== '')
            .map((answer) => ({
                answer: answer?.answer,
                audio: answer?.audio,
                correct_answer: answer.correct_answer,
                feedback: answer.feedback,
                id: answer.id,
                image: answer.image,
                state: ItemAnswerStateEnum.pristine
            }));

        this.loadUserSave();

        if (this.lessonsService.isTrainerSeeCorrection()) {
            this.userAccessCorrection = true;
        }

        this.activityStepIndex = this.lessonsService.activityIndex;
        // read the secondConsigne when switch to next instruction
        this.readSecondConsigne.next(true);
        if (this.isFormula) {
            super.addExceptionsForButtonsFromActivity([
                {
                    type: 'validate',
                    display: false,
                    options: {
                        CRT: {
                            display: {
                                case: 'force',
                                value: false
                            }
                        }
                    }
                }
            ]);
        }
        if (this.displayForSummary) {
            this.seeAnswerSolution();
        }
    }

    protected loadUserSave(): void {
        this.isSaving = true;
        // sert dans le cas ou il n'y a pas d'assignation, retrouve l'usersave dans les saves locales.
        const step = this.lessonsService.subLessonContentEdited.length ?
            this.lessonsService.subLessonContentEdited.findIndex((activity) => +activity.id === +this.activity.id)
            : this.lessonsService.currentLesson.get('reference').findIndex((activity) => +activity.id === +this.activity.id);
        this.activitiesService.getUserSave(this.activity.id, this.assignmentId, null, step, this.activatedRoute?.snapshot?.queryParams?.assignatedUserId).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(userSave => {
                if (userSave) {
                    this.userSave = userSave;
                    if (this.isLongAnswer) {
                        this.setAnswer();
                    }
                    this.activityPercentile = Math.round(this.getGrade().oldGrade * 100);
                } else if (this.lessonsService.isMyAssignment()) {
                    this.save();
                }
                this.isSaving = false;
                this.initializeButtons();
            });
    }

    protected setAnswer(): void {
        if (this.userSave?.get('userActivity')?.entitySave?.answers) {
            const answers = this.userSave.get('userActivity').entitySave.answers;
            this.reply.answer = answers[0]?.answer || '';
        } else if (this.displayForSummary) {
            const answerStockedInLocal: AnswerResultInterface = this.activitiesService.answersProgressBarMultiZone.find((answerResult => !!answerResult.answer));
            if (answerStockedInLocal.answer) {
               this.reply.answer = answerStockedInLocal.answer.answer;
            }
        }
        if (this.isFormula && this.latexKeyboard) {
            this.latexKeyboard.pushLatex(this.reply.answer);
        }
    }

    protected getGrade(): {
        newGrade: number,
        oldGrade: number
    } {
        let oldGrade = 0;
        let grade = 0;

        for (const option of this.availableAnswers) {
            if (this.userSave) {
                const answer = this.userSave.get('userActivity').entitySave.answers[0];
                if (answer && answer.answer === option.answer) {
                    oldGrade += 1;
                }
            }
            if (option.answer === this.reply.answer) {
                grade += 1;
            }
        }

        return {
            newGrade: grade,
            oldGrade: oldGrade
        };
    }

    protected saveAnswer(): Observable<any> {
        return this.activitiesService
            .saveAnswer({answer: this.reply.answer}, 'answer')
            .pipe(
                take(1),
                mergeMap((answer: DataEntity) => of(answer && [answer.id.toString()] || []))
            );
    }

    protected reset(resetAllSubscribe: boolean = false, type = null): Observable<boolean> {
        this.userTry = 0;
        this.isPoll = false;
        if (this.isFormula && this.latexKeyboard) {
            this.latexKeyboard.pushLatex('');
        }
        this.answerFeedback = null;
        this.validated = false;
        // this.disable = this.lessonsService.isAtLeastTrainer() && !this.lessonsService.isLessonTest();
        this.reply = {
            id: uuidv4(),
            answer: '',
            state: ItemAnswerStateEnum.pristine
        };
        return super.reset(resetAllSubscribe, type);
    }

    /**
     * userClick button validate/register
     */
    protected validate(): void {
        if (!this.waitUntilCorrectionFinished) {
            this.userTry++;
            if (!this.isCheckingAnswer) {
                this.isCheckingAnswer = true;
                this.checkAnswer();
            }
        }
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }

    protected initializeButtons() {
        super.initializeButtons();
        // manage validate button
        const validateBtn = this.buttons.find(b => b.type === 'validate');

        if (!!validateBtn && this.answerStatus === answerStatusEnum.missing) {
            validateBtn.disable = true;
        } // pas d'else, on force le disable mais volontairement on le laisse par defaut si on n'en a pas besoin
    }

    private animateAnswerState(ignoreCorrection?: boolean): void {
        // TODO faire une meilleure animation angular
        this.answerCorrected.next(this.reply);
        setTimeout(() => {
            if (this.reply.state !== ItemAnswerStateEnum.currentlyCorrect) {
                this.reply.state = ItemAnswerStateEnum.pristine;
                this.reply.answer = '';
            }
            this.answerCorrected.next(this.reply);

            if (ignoreCorrection || this.isReplyCorrect() || !this.isReplyCorrect() && this.userTry === this.config.numberOfTryBeforeDisplayCorrection()) {
                const options: FeedbackInterface<any> = {};
                if (!ignoreCorrection && !this.isReplyCorrect() && this.userTry === this.config.numberOfTryBeforeDisplayCorrection()) {
                    if (this.isFormula && this.latexKeyboard) {
                        this.latexKeyboard.pushLatex(this.availableAnswers[0].answer);
                    }
                    options.title = 'activities.answer_is_incorrect';
                    this.activitiesService.translatedText('activities.correct_answer_was').subscribe((correct_answer_was) => {
                        options.innerHtmlContent = '<label>' + correct_answer_was + ': ' + this.availableAnswers[0].answer + '</label><br/>';
                    });
                }
                if (this.feedbackEndExo) {
                    if (options.innerHtmlContent) {
                        options.innerHtmlContent += '<label>' + this.feedbackEndExo + '</label>';
                    } else {
                        options.innerHtmlContent = '<label>' + this.feedbackEndExo + '</label>';
                    }
                }
                setTimeout(() => {
                    this.doAction('next', ['save'], false, options);
                }, TIME_DELAY_BEFORE_SAVE);
            } else {
                this.waitUntilCorrectionFinished = false;
            }
        }, TIME_DISPLAYING_CORRECTION);
    }
}
