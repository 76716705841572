import {TextOptionsLight} from 'jspdf';
import {VisualPatternInterface} from './visual-pattern.interface';
import {FontInterface} from '../../fonts/font.interface';

/**
 * Should be used to implement a text bloc to add the a pdf
 */
export interface TextPatternInterface extends VisualPatternInterface {
    text: string;
    font: FontInterface;
    fontSize: number;
    options: TextOptionsLight;
}
