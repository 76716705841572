import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {ActivatedRoute, Router} from '@angular/router';
import {tap} from 'rxjs/operators';

@Component({
    selector: 'app-reward-page',
    templateUrl: './reward-page.component.html'
})
/**
 * reward page use at end of question set
 */
export class RewardPageComponent implements OnInit, AfterViewInit, OnDestroy {
    rewardTitle = '';
    reward = 0; // 1 to 4
    coins: { label: string, position: number }[] = [];
    public ready = false;
    private newScore = 0; // percentage 0 to 100
    private previousScore = 0;

    constructor(private communicationCenter: CommunicationCenterService,
                private lessonsService: LessonsService,
                private activatedRoute: ActivatedRoute,
                private router: Router) {
        this.communicationCenter
            .getRoom('gamification')
            .getSubject('loadAvatar').next(true);
        // during reward page we doesn't show progress bar and header of exo
        this.communicationCenter.getRoom('progress-bar-exo').getSubject('hide').next(true);
        this.communicationCenter.getRoom('header-exo').getSubject('show').next(false);
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.pipe(
            tap((data: {subLessonId: string}) => {
                this.previousScore = +this.lessonsService.subLessonProgressScoreList[data.subLessonId] || 0;
                this.newScore = this.lessonsService.currentAssignment.get('config') && JSON.parse(this.lessonsService.currentAssignment.get('config'))[data.subLessonId]?.score;
            })
        ).subscribe();
        this.initData();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.ready = true;
        }, 1000);
    }

    next(): void {
        // will navigate to summary component
        this.router.navigate(['../', 'summary'], {relativeTo: this.activatedRoute});
    }

    /**
     * set title in regard of score
     * set coin in regard of score and if score is better or first
     * set reward 0 to 4 4 equal diamond
     * @private
     */
    private initData(): void {
            if (this.newScore < 50) {
                this.reward = 0;
                this.rewardTitle = 'activities.no-reward';
            } else if (this.newScore >= 50 && this.newScore < 70) {
                this.addCoin({label: '+1', position: 1});
                this.reward = 1;
                this.rewardTitle = 'activities.one-star';
            } else if (this.newScore < 90) {
                this.addCoin({label: '+1', position: 1});
                this.addCoin({label: '+2', position: 2});
                this.reward = 2;
                this.rewardTitle = 'activities.two-star';
            } else if (this.newScore <= 99) {
                this.addCoin({label: '+1', position: 1});
                this.addCoin({label: '+2', position: 2});
                this.addCoin({label: '+3', position: 3});
                this.reward = 3;
                this.rewardTitle = 'activities.three-stars';
            } else if (this.newScore === 100) {
                this.rewardTitle = 'activities.diamonds';
                this.addCoin({label: '+10', position: 4});
                this.reward = 4;
            }
    }

    /**
     * if is new or better score show coins so put it into array used to show good data
     * @private
     */
    private addCoin(coin: { label: string, position: number }): void {
        if (this.previousScore < this.newScore) {
            this.coins.push(coin);
        }
    }

    ngOnDestroy(): void {
        this.communicationCenter.getRoom('progress-bar-exo').getSubject('hide').next(false);
        this.communicationCenter.getRoom('header-exo').getSubject('show').next(true);
        this.communicationCenter.getRoom('activities').getSubject('backToLesson').next( {forceUrl: [], doAction: false});
    }
}
