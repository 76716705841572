import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';

@Component({
    selector: 'app-upload-exercice',
    templateUrl: './upload-exercice.component.html',
})
export class UploadExerciceComponent implements OnInit, OnChanges {
    error = {
        sizeTooBig: false,
        isNotFormatAllowed: false,
        tooManyFiles: false
    };


    fileSelected = '';
    progress = 0;
    private interval: any;

    @Input() fileUploaded = false;
    @Input() uploadError = '';
    @Input() config: { maxSize: 2, formatAllowed: ['.zip', '.csv'], pathDownload: '' };
    @Output() fileToUpload = new EventEmitter<File>();

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        clearInterval(this.interval);
        if (this.fileUploaded) {
            this.progress = 100;
        }
    }

    /**
     * get the file that was drop or selected on the zone
     * @param files
     */
    public dropped(files: NgxFileDropEntry[]): void {
        this.resetData();
        if (!this.isAllowed(files)) {
            return;
        }
        // check size before emiting upload if size good
        const fileEntry = files[0].fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
            if (this.isFileSizeTooBig(file)) {
                this.error.sizeTooBig = true;
            } else {
                // show name of the file selected
                this.fileSelected = files[0].fileEntry.name;
                this.launchEmulateProgressionCalcul();
                setTimeout(() => {
                    this.emitFileToUpload(files);
                }, 5000);
            }
        });
    }

    /**
     * emit the file to the parent who will send it
     * @param files
     * @private
     */
    private emitFileToUpload(files: NgxFileDropEntry[]): void {
        for (const droppedFile of files) {
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    this.fileToUpload.emit(file);
                });
            }
        }
    }

    /**
     * control rules about size format and mono file
     * message will be show if an error occured
     * @param files
     * @private
     */
    private isAllowed(files: NgxFileDropEntry[]): boolean {
        if (files.length > 1) {
            this.error.tooManyFiles = true;
            return false;
        }

        if (!this.isFileAllowed(files[0].fileEntry.name)) {
            this.error.isNotFormatAllowed = true;
            return false;
        }
        return true;
    }

    /**
     * is file respect the size limit
     * @param file
     * @private
     */
    private isFileSizeTooBig(file: File): boolean {
        return file.size > +(this.config.maxSize + '000000');
    }

    /**
     * isFile as good format
     * @param fileName
     * @private
     */
    private isFileAllowed(fileName: string): boolean {
        let isFileAllowed = false;
        const allowedFilesFormat = this.config.formatAllowed;
        const regex = /(?:\.([^.]+))?$/;
        const extension = regex.exec(fileName);
        if (undefined !== extension && null !== extension) {
            for (const ext of allowedFilesFormat) {
                if (ext === extension[0]) {
                    isFileAllowed = true;
                }
            }
        }
        return isFileAllowed;
    }

    /**
     * download doc
     */
    public download(): void {
        window.open(this.config.pathDownload);
    }

    /**
     * reset all alert flag
     */
    private resetData(): void {
        this.uploadError = '';
        this.fileUploaded = false;
        this.progress = 0;
        this.error.sizeTooBig = false;
        this.error.isNotFormatAllowed = false;
        this.error.tooManyFiles = false;
        this.fileSelected = '';

    }

    /**
     * do like if we know where back is of intégration
     */
    launchEmulateProgressionCalcul(): void {
        this.interval = setInterval(() => {
            if (this.progress < 50) {
                this.progress = this.progress + 1;
            }
            if (this.progress >= 50 && this.progress < 80) {
                this.progress = this.progress + 0.5;
            }

            if (this.progress >= 80 && this.progress < 95) {
                this.progress = this.progress + 0.1;
            }
        }, 1000);
    }
}
