import {Pipe, PipeTransform} from '@angular/core';
import {Observable, of} from "rxjs";

@Pipe({
    name: 'loadTemplateComponent'
})
export class LoadTemplateComponentPipe implements PipeTransform {
    private static matchTemplate = /{component\/([^}]*)}/g;

    transform(value: string, ...args: unknown[]): Observable<any[]> {
        const components = [];
        const matches = value.matchAll(LoadTemplateComponentPipe.matchTemplate);

        for (let match of matches) {
            // match[0] = "{component/<module>/<component>}" - match[1] = "<module>/<component>"
            const [module, component] = match[1].split('/');
            components.push({module, component});
        }

        return of(components);
    }
}
