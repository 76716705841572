import { Component, OnInit } from '@angular/core';
import {fuseAnimations} from 'fuse-core/animations';


@Component({
  selector: 'fuse-password-reset-email',
  templateUrl: './password-reset-email.component.html',
  styleUrls: ['./password-reset-email.component.scss'],
  animations : fuseAnimations
})
export class FusePasswordResetEmailComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {}

}
