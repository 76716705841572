import {Component, Input, OnInit} from '@angular/core';
import {FollowedListComponentOptionsInterface} from '@modules/assignation/core/components/followed-list/followed-list-component-options.interface';
import {AssignationConfigurationService} from '@modules/assignation/core/services/assignation-configuration.service';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from "@modules/communication-center";
import {DataEntity} from "octopus-connect";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-generic-assignments-list',
    templateUrl: './generic-assignments-list.component.html',
    styleUrls: ['./generic-assignments-list.component.scss']
})
export class GenericAssignmentsListComponent implements OnInit {
    public readonly options: FollowedListComponentOptionsInterface;
    @Input('isAssignationClosedActive') isAssignationClosedActive = false;
    @Input('showAllStates') showAllStates = false;

    constructor(
        private assignationConfigurationService: AssignationConfigurationService,
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
        private route: ActivatedRoute,
    ) {
        const initialRequestFilters = this.authService.accessLevel === 'learner'
            ? {'assignated_user': this.authService.userData.id}
            : {'assignator': this.authService.userData.id};

        this.options = {
            ...this.assignationConfigurationService.getAssignmentsListOptions('generic'),
            initialRequestFilters,
        };

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.route.data.subscribe((data) => {
            this.isAssignationClosedActive = !!data.isAssignationClosedActive;
        });
    }

    ngOnInit(): void {
    }

    private postAuthentication(): void {
        // Faut pas faire ça, c'est pas parce que t'es prof que tu dois avoir ou pas changer les filtres
        // Il faut creer un composant qui liste un tableau d'assignation
        // Ensuite il faut creer un composant par besoin (assignation, assignation closed, mes propres assignations seulement, etc)
        // Enfin il faut créer un chemin pour chacun de ces composants et donner l'acces a l'utilisateur en fonction par exemple de son role
        this.showAllStates = this.authService.isAtLeastTrainer();
    }

    private postLogout(): void {
        this.showAllStates = false;
    }
}
