import {Injectable} from '@angular/core';
import {CommunicationCenterService} from "@modules/communication-center";
import {mergeMap, Observable, of, ReplaySubject, Subject} from "rxjs";
import {DataCollection, DataEntity, OctopusConnectService} from "octopus-connect";
import {map, take} from "rxjs/operators";
import {LessonEntity} from "@modules/contest/core/model/lesson.entity";
import {NavigateToLessonOptions} from "@modules/contest/core/model/lessonsActivityRoutes";

@Injectable({
    providedIn: 'root'
})
export class ActivitiesService {
    private currentAssignment: DataEntity;

    constructor(
        private communicationCenter: CommunicationCenterService,
        private octopusConnect: OctopusConnectService,
    ) {
        this.communicationCenter
            .getRoom('assignment')
            .getSubject('current')
            .subscribe((assignment: DataEntity) => {
                this.currentAssignment = assignment;
            });
    }

    public loadLesson(lessonId: number, callbackSubject$: Subject<Observable<LessonEntity>>): void {
        this.communicationCenter
            .getRoom('lessons')
            .next('getLesson', {
                lessonId: lessonId,
                callbackSubject: callbackSubject$,
            });
    }

    public loadLesson$(lessonId: number): Observable<LessonEntity> {
        const callbackLesson$ = new ReplaySubject<Observable<LessonEntity>>(1);
        
        this.communicationCenter
            .getRoom('lessons')
            .next('getLesson', {
                lessonId: lessonId,
                callbackSubject: callbackLesson$,
            });
        
        return callbackLesson$.pipe(
            mergeMap(lesson$ => lesson$),
        );
    }
    
    public loadActivities(lesson: LessonEntity, callbackSubject$: Subject<Observable<DataEntity[]>>): void {
        this.communicationCenter
            .getRoom('lessons')
            .next('getActivities', {
                lesson: lesson,
                callbackSubject: callbackSubject$
            });
    }
    
    public loadUserSaves(): Observable<DataEntity[]> {
        if (this.currentAssignment) {
            return this.octopusConnect.loadCollection('user-save', {context: this.currentAssignment.id})
                .pipe(
                    take(1),
                    map((dataCollection: DataCollection) => dataCollection.entities)
                )
        }
        
        return of([]);
    }
    
    public navigateToLesson(lesson: LessonEntity, options: NavigateToLessonOptions, preview = false): void {
        this.communicationCenter
            .getRoom('lessons')
            .next('navigateToLesson', {
                lesson: lesson,
                navigateOptions: options,
                preview: preview,
            });
    }
}
