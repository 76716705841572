import {take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {Router} from '@angular/router';
import {DataEntity, InterfaceError, OctopusConnectService} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication/core/authentication.service';
import {captcha, defaultApiURL, defaultRoute} from '../../../settings';
import {CommunicationCenterService} from '@modules/communication-center';
import {HttpClient} from '@angular/common/http';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {
    AccountManagementAuthorizationService
} from '@modules/account-management/core/services/account-management-authorization.service';
import {NewUser} from "@modules/account-management/core/model/new-user.model";
import {StandardInterfaceError} from "shared/models/octopus-connect/typed-interface-error.interface";

@Injectable()
export class AccountManagementProviderService {
    private userIsLogged$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    loggedUser: UserDataEntity;
    userData: UserDataEntity;
    public data: BehaviorSubject<UserDataEntity> = new BehaviorSubject(null);
    captcha: string = captcha;
    public settings: { [key: string]: any };
    public authenticationSettings: { [key: string]: any };

    constructor(
        private router: Router,
        private connector: OctopusConnectService,
        private authenticationService: AuthenticationService,
        private accountAuthorization: AccountManagementAuthorizationService,
        private communicationCenter: CommunicationCenterService,
        private http: HttpClient
    ) {
        this.settings = this.accountAuthorization.settings;

        this.authenticationSettings = this.accountAuthorization.authenticationSettings;
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: UserDataEntity) => {
                this.userData = data;
                if (data) {
                    this.postAuthentication();
                    this.connector.createEntity('authenticated', {id: this.userData.id, myType: 'authenticated'}).pipe(take(1));
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('account-management')
            .getSubject('refreshUser')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.setLoggedUser();
                }
            });
        this.communicationCenter
            .getRoom('authentication').next('userAccessToken', this.userAccessToken);
    }

    private postLogout(): void {
        // Execute here what you want to do after logout
    }

    private postAuthentication(): void {
        this.setLoggedUser();
    }

    public createUser(user: NewUser, callback: (data: DataEntity) => void, callbackError: (error: StandardInterfaceError) => void): void {
        this.connector.createEntity('user-registration', user).subscribe({
            next: (userData) => {
                if (this.accountAuthorization.settings.validateEmailStrategyActivated) {
                    callback(userData);
                } else {
                    this.userData = <UserDataEntity>userData;
                    this.autoConnectUser(user, userData, callback);
                }
            },
            error: (error: StandardInterfaceError) => {
                if (callbackError) {
                    callbackError(error);
                } else {
                    console.error(error);
                }
            }
        });
    }

    /**
     * auto connect user after create account without verifying mail before
     * old process Marque blanche common process not use it anymore
     * @param user : user data
     * @param registration
     * @param callback callback : pass screen to info saying email confirmation was send
     * @private
     */
    private autoConnectUser(user: NewUser, registration: DataEntity, callback: (data: DataEntity) => void): void {
        let login = user['email'];
        let password = user['password'];

        if (registration.get('codeid')) {
            login = `tralacodeid${registration.get('label')}`;
            password = registration.get('codeid');
        }

        if (!login) {
            login = user['label'];
        }

        this.authenticationService.authenticateIn('http', login, password).subscribe({
            next: (userData) => {
                this.loggedUser = userData;
                callback(registration);
                this.authenticationService.navigateAfterLogin();
            },
            error: (error) => {
                console.log(error);
            }
        });
    }

    public sendMessage(message, callback: () => void): void {
        this.connector.createEntity('contact-message', message).subscribe(() => {
            callback();
        });
    }

    private setLoggedUser(): void {
        const userData$ = <Observable<UserDataEntity>>this.connector.authenticated('http');

        userData$.subscribe({
            next: (userData) => {
                this.updateUser(userData);
                this.userIsLogged$.next(true);

            },
            error: () => {
                this.userIsLogged$.next(false);
            }
        });
    }

    public get getCurrentUserObs(): Observable<boolean> {
        return this.userIsLogged$;
    }

    /**
     * Save user data
     * @param user data
     * @param callback with true if success, else false
     */
    public editUser(user, callback: (success: boolean) => void): void {
        this.loggedUser.set('label', user.label);
        this.loggedUser.set('lastName', user.lastName);
        this.loggedUser.set('firstName', user.firstName);
        this.loggedUser.set('email', user.email);
        this.loggedUser.set('institution', user.institution);
        this.loggedUser.set('you_are', user.you_are);
        this.loggedUser.set('find_us', user.find_us);
        this.loggedUser.set('level', user.level);
        this.loggedUser.set('region', user.region);
        this.loggedUser.set('newsletter', user.newsletter);
        this.loggedUser.set('_picture', user.picture);
        this.loggedUser.set('groups', user.groups);
        // this.loggedUser.set('role', user.role );
        if (user.password) {
            this.loggedUser.set('password', user.password);
        }
        this.loggedUser.save().subscribe({
            next: (userEdited) => {
                this.updateUser(userEdited);
                callback(true);
            },
            error: () => callback(false)
        });
    }

    public setUserNewsletter(newsletter: boolean, callback: (success: boolean) => void): void {
        this.loggedUser.set('newsletter', newsletter);
        this.loggedUser.save().subscribe({
            next: (userEdited) => {
                this.updateUser(userEdited);
                callback(true);
            },
            error: () => callback(false)
        });
    }

    public editUserValidateEmail(): void {
        if (this.loggedUser) {
            this.loggedUser.set('email_status', true);
            this.loggedUser.save();
        } else {
            this.router.navigate(['/']);
        }
    }

    public get userAccessToken(): string {
        return JSON.parse(localStorage.getItem('http_accessToken'));
    }

    /**
     * Remove current logged user
     */
    public deleteCurrentLoggedUser(): Observable<boolean> {
        return this.loggedUser.remove();
    }

    /**
     * return default route instance
     * @returns {string}
     */
    public get defaultRoute(): string {
        return defaultRoute.substring(0, 1) === '/' ? defaultRoute : '/' + defaultRoute;
    }

    public verifyToken(token: string): Observable<object> {
        switch (this.captcha) {
            case 'recaptcha':
                return this.http
                    .post(defaultApiURL + 'api/recaptchav3', {'response': token});
            case 'friendlyCaptcha':
                return this.http
                    .post(defaultApiURL + 'api/friendlycaptcha', {'solution': token});
            default:
                console.log('no captcha in setting');
        }
    }

    private updateUser(user: UserDataEntity): void {
        this.loggedUser = user;
        this.data.next(this.loggedUser);

        this.communicationCenter
            .getRoom('account-management')
            .next('userUpdate', this.loggedUser);
    }
}
