import {Component, Input, OnInit} from '@angular/core';
import {StepperOptions} from '@modules/activities/core/shared-components/generic-stepper/generic-stepper.component';
import {StepperPosition} from '@modules/activities/core/shared-components/basic-stepper-container/stepper.position';
import {CommunicationCenterService} from '@modules/communication-center';
import {tap} from 'rxjs/operators';

@Component({
    selector: 'app-stepper-wrapper',
    templateUrl: './stepper-wrapper.component.html'
})
export class StepperWrapperComponent {

    @Input() stepperOptions: StepperOptions|null ;
    @Input() lessonMode = false; // by default preview mode but in reality it depend if there is an assignment or not
    public isDisplayStepper = true;

    constructor(private communicationCenter: CommunicationCenterService) {
        this.communicationCenter
            .getRoom('stepper')
            .getSubject('display')
            .pipe(
                tap((isDisplayStepper: boolean) => this.isDisplayStepper = isDisplayStepper)
            ).subscribe();
    }
    public get displayStepper(): boolean {
        return this.stepperOptions !== null && this.isDisplayStepper && this.stepperOptions.isListFormatStepper;
    }

    public get customStepperOptions(): StepperOptions {
        this.stepperOptions.stepperLayout.arrowPosition = 3 - this.stepperOptions.stepperLayout.position;
        return this.stepperOptions;
    }

    public layout(): string {
        let row, inverse = false;
        switch (this.stepperOptions?.stepperLayout?.position) {
            case StepperPosition.top : {
                row = false;
                inverse = false;
                break;
            }
            case StepperPosition.bottom: {
                row = false;
                inverse = true;
                break;
            }
            case StepperPosition.right: {
                row = true;
                inverse = true;
                break;
            }
            case StepperPosition.left:
            default : {
                row = true;
                inverse = false;
            }
        }

        return (row ? 'row' : 'column') + (inverse ? '-reverse' : '');
    }
}
