import {FontInterface} from './fonts/font.interface';

export interface PdfPatternInterface {
    margin: {
        top: number,
        bottom: number,
        left: number,
        right: number,
    };

    size: {
        width: number,
        height: number
    };

    orientation: 'portrait' | 'landscape';
    unit: 'pt' | 'px' | 'in' | 'mm' | 'cm' | 'ex' | 'em' | 'pc';

    font: FontInterface;
    fontSize: number;
}