import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {BaseActivityComponent} from '../base-activity.component';
import {Observable, of} from 'rxjs';

@Component({
    selector: 'app-poll-review',
    templateUrl: './poll-review.component.html',
})
export class PollReviewComponent extends BaseActivityComponent<any, any> {

    @ViewChild('chart') chart: ElementRef;

    @Input() poll1: number;
    @Input() poll2: number;
    @Input() outsidePollColor: string;
    @Input() insidePollColor: string;
    @Input() title: string;
    @Input() backgroundColor = 'white';
    @Input() fontColor = 'white';
    @Input() label: string;

    public chartType = 'doughnut';
    public chartData: any[] = [];
    public chartLabels: any[] = [];
    public chartOptions: {} = {};
    public chartColors: any[] = [];

    protected initialize(): void {
        throw new Error('Method not implemented.');
    }

    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnInit(): void {
        this.chartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: true,
                labels: {
                    fontColor: '#fff'
                }
            },
            tooltips: {
                enabled: false
            },
        };

        this.chartData = [
            {
                backgroundColor: [this.outsidePollColor, this.insidePollColor, this.backgroundColor],
                data: [this.poll1, 0, 100 - this.poll1]
            },
            {
                backgroundColor: [this.outsidePollColor, this.insidePollColor, this.backgroundColor],
                data: [0, this.poll2, 100 - this.poll2]
            }
        ];
        this.chartLabels = [this.poll1, this.poll2];
        this.chartColors = [this.outsidePollColor, this.insidePollColor, this.backgroundColor,this.outsidePollColor, this.insidePollColor, this.backgroundColor];
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setContentData(): void {
        throw new Error('Method not implemented.');
    }

    protected  getGrade(): {oldGrade: number, newGrade: number} {
        throw new Error('Method not implemented.');
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }

    protected validate(): void {
        throw new Error('Method not implemented.');
    }
}



