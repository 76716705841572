import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {OctopusConnectService} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {ConceptDataCollection, ConceptEntity} from '../../../../shared/models';

@Injectable({
    providedIn: 'root'
})

export class ConceptsService {
    constructor(
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService
    ) {
        this.communicationCenter
            .getRoom('concepts')
            .next('getConceptsCallback', () => {
                return this.getConcepts();
            })
    }

    public getConcepts(): Observable<ConceptDataCollection> {
        return this.octopusConnect.loadCollection('concepts') as Observable<ConceptDataCollection>;
    }
}
