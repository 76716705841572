import {Component} from '@angular/core';
import {GraphBayardService} from '../../services/graph-bayard.service';
import {GraphBayardAuthorizationService} from "@modules/graph-bayard/core/services/graph-bayard-authorization.service";

@Component({
    selector: 'app-multi-graph-view',
    templateUrl: './multi-graph-view.component.html',
})
export class MultiGraphViewComponent {
    constructor(
        private graphService: GraphBayardService,
        private graphBayardAuthorizationService: GraphBayardAuthorizationService,) {
    }

    graphsAreAvailable(): boolean {
        return this.graphService.graphsAreAvailable();
    }

    public get rolesCanShowBannerInfo(): boolean {
        return this.graphBayardAuthorizationService.isRolesCanShowBannerInfo;
    }

}
