import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';
import {DataEntity} from 'octopus-connect';
import {debounceTime, mergeMap, take, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {CommunicationCenterService} from '@modules/communication-center';
import {ReplaySubject} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';

const ID_TYPE_INSTITUTION = '52';
const REGEX = /^[0-9]+$/;
@Component({
    selector: 'app-join-school',
    templateUrl: './join-school.component.html'
})

/**
 * permit to join a school
 * good to know : school code is in reality store as a group
 * but not send with groups or workgroups list but with institution.
 * to store ans save it we change the group list of the user and back dispatch in regard of if
 * id is a group an institution or a workgroup
 */
export class JoinSchoolComponent implements OnInit {
    public hasJoinASchool = false;
    public userSchool: DataEntity;
    public errorMessage = '';
    public loading = false;
    public codeControl: UntypedFormControl;
    @Output() schoolJoin: EventEmitter<string> = new EventEmitter<string>();

    constructor(private profileService: ProfileService,
                private communicationCenter: CommunicationCenterService) {
        this.getUserSchoolCode();
    }

    ngOnInit(): void {
        this.codeControl = new UntypedFormControl();
        this.codeControl.valueChanges.pipe(
            debounceTime(200),
            tap((value: string) => {
                this.codeControl.setErrors(null);
                if (!REGEX.test(value)) {
                    this.codeControl.markAsTouched();
                    this.codeControl.markAsDirty();
                    this.codeControl.setErrors({invalidFormat: true});
                } else {
                    this.codeControl.setErrors(null);
                }
            })
        ).subscribe();
    }

    /**
     * prepare data to store it in a friendly format (code class id and label) for the different class of user
     * use data already existing be carefull join and leave a group need to force a refresh
     * because this data are loaded only when app is launched
     * @private
     */
    private getUserSchoolCode(): void {
        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('institutionEntitiesList')
            .pipe(take(1))
            .subscribe(inst => {
                if (inst && inst.length > 0) {
                    this.userSchool = inst[0];
                    this.hasJoinASchool = true;
                    this.schoolJoin.emit(inst[0].institutiongroupname);
                }
            });
    }

    public joinSchool(): void {
        if (!this.codeControl.errors && REGEX.test(this.codeControl.value)) {
            this.loading = true;
            this.errorMessage = '';
            // load group id and label and code class corresponding to code class use
            this.loadGroupsByCode(this.codeControl.value)
                .pipe(take(1))
                .subscribe((institution: DataEntity) => {
                    if (institution && institution.get('type') === ID_TYPE_INSTITUTION) {
                        // if not use [... change is already made without set directly in original list(because of copy by ref) and request is not send.
                        const newList = [...this.profileService.userInformation.get('groups').slice().filter(groupId => {
                            if (this.userSchool && this.userSchool.id) {
                                return +groupId !== +institution.id && +groupId !== +this.userSchool.id;
                            }
                            return +groupId !== +institution.id;
                        }), institution.id.toString()];
                        this.profileService.userInformation.set('groups', newList);
                        this.profileService.userInformation.save(true)
                            .pipe(
                                take(1),
                                mergeMap(() => this.editInstitutionRight(institution))
                            )
                            .subscribe(res => {
                                // on success show school name
                                this.hasJoinASchool = true;
                                this.userSchool = institution;
                                this.schoolJoin.emit(this.userSchool.get('label'));
                                this.codeControl.setValue(null,  {emitEvent: false});
                                this.codeControl.setErrors(null);
                                this.loading = false;
                                this.refreshData();

                            }, error => {
                                this.codeControl.setErrors({cantJoinInstitution: true});
                                this.loading = false;
                            });
                    } else {
                        this.codeControl.setErrors({invalidCode: true});
                        this.loading = false;
                    }
                }, error => {
                    this.codeControl.setErrors({cantJoinInstitution: true});
                    this.loading = false;
                });
        }
    }

    /**
     * load the groups by code data are formated and contain id label and code
     * @param code id of group in string format
     * @private
     */
    private loadGroupsByCode(code: string): Observable<DataEntity> {
        const subject = new ReplaySubject<Observable<DataEntity>>(1);
        this.communicationCenter.getRoom('groups-management')
            .next('loadEntityInstitution', {
                id: code,
                callback: (institution: Observable<DataEntity>): void => {
                    subject.next(institution);
                }
            });
        // for reduce to only a simple observable
        return subject.pipe(mergeMap(obs => obs));
    }


    /**
     * force refresh data of list of institution (school)
     * because this data are loaded only when app is launched and only at this time
     * except if we force to reload them
     * @private
     */
    private refreshData(): void {
        this.communicationCenter
            .getRoom('groups-management')
            .getSubject<void>('refresh-institutionList')
            .next();
    }

    private editInstitutionRight(institution: DataEntity): Observable<DataEntity> {
       const admins = institution.get('admins').slice();
       const userRightInInstitution = admins.find((user) => +user.uid === +this.profileService.userInformation.id);
       if (userRightInInstitution) {
           userRightInInstitution.roles = {4: 'educator'};
       } else {
           admins.push({
               uid: this.profileService.userInformation.id.toString(),
               roles: {
                   4: 'educator',
               }
           });
       }
       institution.set('admins', admins);
       return institution.save(true);
    }
}
