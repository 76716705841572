import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import {combineLatest, Observable, of} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';
import * as _ from 'lodash-es';
import {IBdFormOptions} from '@modules/bdtool/core/bd.service';

interface IBdDataInterface {
    title: string;
    associatedLessonId?: string | number;
}

interface ModalDataInterface {
    availableLessons$: Observable<DataEntity[]>;
    defaultValues: IBdDataInterface;
    getLessonById: (lessonId: string | number) => Observable<DataEntity>;
    options: IBdFormOptions;
    saveBd: (data: IBdDataInterface) => Observable<DataEntity>;
    submitButtonText: string;
}

@Component({
    selector: 'app-bd-data-editor-modal',
    templateUrl: './bd-data-editor-modal.component.html',
})
export class BdDataEditorModalComponent implements OnInit {
    /**
     * Define if the component is currently loaded for initialization
     */
    public isInitializing = true;
    /**
     * Define if the component is currently in saving (should be used for temporally disable component)
     */
    public isSaving = false;

    /**
     * List of available lessons (not associated lessons and current note already associated lesson)
     */
    public lessons: DataEntity[] = [];

    /**
     * Reactive form used to receive and control user inputs
     */
    public noteForm: UntypedFormGroup;

    /**
     * Used to set or get the associated lesson
     */
    public selectedLesson: DataEntity = null;
    public availableLessonsAreLoading = true;

    public get lessonIsDisabled(): boolean {
        return this.isSaving || <boolean>_.get(this.modalData.options, 'associatedLessonId.disable', false);
    }

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) private modalData: ModalDataInterface,
        private communicationCenter: CommunicationCenterService,
        private dialog: MatDialog,
        private formBuilder: UntypedFormBuilder,
        public selfDialogRef: MatDialogRef<BdDataEditorModalComponent>
    ) {
    }

    ngOnInit(): void {
        let defaultSelectedLesson$: Observable<DataEntity[]>;

        if (!!_.get(this.modalData, 'defaultValues.associatedLessonId')) {
            defaultSelectedLesson$ = this.modalData.getLessonById(this.modalData.defaultValues.associatedLessonId).pipe(
                map(dataEntity => [dataEntity]) // To be like the availableLesson& type :-)
            );
        } else {
            defaultSelectedLesson$ = of([]);
        }

        let availableLessons$: Observable<DataEntity[]>;
        if (_.get(this.modalData.options, 'associatedLessonId.disable', false) === false) {
            availableLessons$ = this.modalData.availableLessons$;
        } else {
            availableLessons$ = of([]);
        }

        combineLatest([defaultSelectedLesson$, availableLessons$]).pipe(
            tap(([[defaultLesson], [...availableLessons]]) => {
                if (!!defaultLesson) {
                    this.selectedLesson = defaultLesson;
                    this.lessons.push(this.selectedLesson);
                    // If there is a default lesson while creating a new bd, this lessons are also in availables lessons
                    availableLessons = availableLessons.filter(lesson => lesson.id !== defaultLesson.id);
                }

                this.lessons.push(...availableLessons);
                this.availableLessonsAreLoading = false;
            }),
            take(1)
        ).subscribe();

        this.noteForm = this.formBuilder.group({
            title: [_.get(this.modalData, 'defaultValues.title', ''), Validators.required],
        });

        this.isInitializing = false;
    }

    /**
     * (un)set the associated lesson
     * @param lesson
     */
    public toggleLesson(lesson: DataEntity): void {
        if (this.selectedLesson === lesson) {
            this.selectedLesson = null;
        } else {
            this.selectedLesson = lesson;
        }
    }

    /**
     * Save the bd. No need to know if it's a path or a creation.
     * @param value
     */
    public onSubmit(value: IBdDataInterface): void {
        this.isSaving = true;
        this.noteForm.disable();

        const bdData = _.merge(value, {
            associatedLessonId: this.selectedLesson ? this.selectedLesson.id : null
        });

        this.modalData.saveBd(bdData).pipe(
            tap((dataEntity) => {
                this.selfDialogRef.close(dataEntity);
            }),
            take(1)
        ).subscribe();
    }
}
