export const groupsManagement = {
    accessGroupsManager: ['manager', 'administrator'],
    accessGroupsTrainer: ['trainer', 'administrator'],
    authorizedRoles: ['learner', 'trainer', 'manager', 'administrator'],
    canBeDelete: {
        workgroup: true,
        institution: false,
        learner: true,
        learners: false,
    },
    canBeJoined: {
        group: true
    },
    collapsibleFields: ['school', 'button-video-help', 'nickname'],
    displayedFiltersLearners: {
        default: ['workgroup', 'groups', 'button-video-help', 'nickname', 'workgroupAssociate']
    },
    displayedFiltersTrainers: {
        default: []
    },
    displayedFiltersGroups: {
        default: []
    },
    displayedFiltersWorkGroups: {
        default: []
    },
    displayedFiltersInstitutions: {
        default: []
    },
    displayHeader: false,
    rolesCanShowBannerInfo: ['administrator', 'manager', 'director', 'trainer', 'learner'],
    displayNews: {
        default: [],
        learner: ['alertTeacherSso'],
        trainer: ['createGroup', 'createLearner']
    },
    group: {
        columns: {
            default: ['groupname', 'level', 'learners', 'buttons']
        }
    },
    workgroupColumns: {
        default: ['workgroupname', 'learnerslist', 'buttons']
    },
    groupType: 2,
    idDefiTaxonomy: '5427', // id of the defi in list activity type
    idActivityToHideInProgressTab: [], // ex :['5445'] use because somme activity in mathia must be hide in progress and not in activity tab
    institutionGroupType: 52,
    isLearnersListExportEnabled: [],  // Allow user to export learners in csv file
    isPasswordRequired: true, // when teacher create student account is password require
    learner: {
        columns: {
            default: ['checkbox', 'avatar', 'nickname', 'pseudo', 'groups', 'workgroups', 'buttons'],
            externalSource: ['avatar', 'nickname', 'pseudo',  'groups', 'workgroups', 'buttons'],
        },
        createFields: {default: [ 'nickname', 'password', 'groups', 'workgroups']},
        editFields: {default: ['id', 'nickname', 'password', 'groups', 'workgroups']}, // do not remove 'id' because it's needed to update data
        joinFields: {default: ['nickname', 'password', 'groups', 'workgroups']},
    },
    levelsWidget: 'select',
    primaryColors: ['#C5629D', '#FF9EA1', '#EEDA78', '#6FF2C5', '#6DE6E2', '#305792'],
    showGlobalAddButton: false, // show main button to add class group or user that open modal dialog if false it's inline add
    workgroupType: 3,
    importInClasses: false,
    newGroupField: ['groupname', 'level'],
    editGroupField: ['id', 'groupname', 'level'],
    setTeacherRegionWhenAddNewLearner: true, // set by default the teacher value when adding a new learner
    rulesForGARRoles: {
        default: [
            'group-management.create-group',
            'group-management.see-learners-in-group',
            'group-management.see-own-group',
            'group-management.see-all-group',

            'group-management.create-workgroup',
            'group-management.edit-workgroup',
            'group-management.delete-workgroup',
            'group-management.see-learners-in-workgroup',
            'group-management.attach-learner-to-workgroup',
            'group-management.be-attached-to-workgroup',
            'group-management.see-own-workgroup',
            'group-management.see-all-workgroup',
        ],
        learner: [],
    },
};
