import {Directive, ElementRef, OnInit} from '@angular/core';
import { Router } from '@angular/router';

@Directive({
    selector: '[appLinkHandler]',
})
export class LinkHandlerDirective implements OnInit {
    constructor(private elementRef: ElementRef, private router: Router) {}

    ngOnInit() {
        this.elementRef.nativeElement.addEventListener('click', (event) => {
            const target = event.target;
            if (target.tagName === 'A' && target.getAttribute('href').startsWith('/')) {
                event.preventDefault();
                const url = target.getAttribute('href');

                this.router.navigateByUrl(url);
            }
        });
    }
}