import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {InstitutionManagementRoutes} from '@modules/groups-management/core/institution-management/institution-management.routes';
import {AsyncRules} from '@modules/groups-management/core/models/rules';
import {AuthorizationService} from '@modules/authorization';
import {combineLatest, Observable} from 'rxjs';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-tab-selector',
  templateUrl: './tab-selector.component.html'
})
export class TabSelectorComponent implements OnInit {

  public institutionLinks: {url: string, label: string }[] = [];

  constructor(
      private router: Router, private authorization: AuthorizationService
  ) { }

  ngOnInit(): void {
      combineLatest([this.authorization.currentUserCan<Observable<boolean>>(AsyncRules.AccessInstitutionUsersManagement),
          this.authorization.currentUserCan<Observable<boolean>>(AsyncRules.AccessInstitutionInformation)])
          .pipe(take(1))
          .subscribe(([hasAccessInstitutionUsersManagement, hasAccessInstitutionInformation]) => {
              this.institutionLinks = [];
              if (hasAccessInstitutionUsersManagement) {
                  this.institutionLinks.push({url: '/institution/multi/' + InstitutionManagementRoutes.Users, label: 'institution_management.users'});
              }
              if (hasAccessInstitutionInformation) {
                  this.institutionLinks.push({url: '/institution/multi/' + InstitutionManagementRoutes.Information, label: 'institution_management.information'});
              }
          });
  }

  public isRouteActive(url: string): boolean {
    return this.router.isActive(url, true);
  }

}
