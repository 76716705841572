import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorReportingModalComponent } from './error-reporting-modal/error-reporting-modal.component';
import {ReactiveFormsModule} from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import {TranslateModule} from '@ngx-translate/core';
import { ErrorReportingTriggerButtonComponent } from './error-reporting-trigger-button/error-reporting-trigger-button.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        MatDialogModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        TranslateModule,
        MatCardModule,
        MatTooltipModule
    ],
    declarations: [
        ErrorReportingModalComponent,
        ErrorReportingTriggerButtonComponent]
    ,
    exports: [
        ErrorReportingTriggerButtonComponent,
    ],
})
export class ErrorReportingModule { }
