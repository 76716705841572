import { Pipe, PipeTransform } from '@angular/core';
import {snakeCase} from 'lodash-es';

@Pipe({
  name: 'snakeCase'
})
export class SnakeCasePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    return snakeCase(value);
  }

}
