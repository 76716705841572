// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-chapter-selector, .table-container {
  max-height: 350px;
}
app-chapter-selector .tabulation, .table-container .tabulation {
  padding-left: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/corpus/core/components/resource-upload-modal-step3/chapter-selector/chapter-selector.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;AACI;EACI,kBAAA;AACR","sourcesContent":["app-chapter-selector, .table-container {\r\n    max-height: 350px;\r\n\r\n    .tabulation {\r\n        padding-left: 24px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
