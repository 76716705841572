export const assignation = {
    assignableLesson: true,
    columnsDashboardWidget: {
        default: ['assigned_node_title', 'start_date', 'assigned_user'],
    },
    completionStartDateOnly: ['*'],
    defaultType: 'training',
    rolesCanShowBannerInfo: ['administrator', 'manager', 'director', 'trainer', 'learner'],
    displayNews: {
        default: [],
        trainer: ['createNoAssignmentForLongTimeAgo'],
    },
    editableAssignmentFields: {
        default: ['title', 'comment'],
    },
    followedLogBookFields: {
        default: [
            'group',
            'type',
            'exoName',
            'learner',
            'beginDate',
            'endDate',
        ],
        learner: [],
    },
    followedTabsList: [
        'assignation.self_assignments_list',
        'translationTabLabelList',
        'assignment.followed_data_closed'
    ],
    formFields: {
        default: ['group', 'workgroup', 'learner', 'comment', 'start_date'],
    },
    genericAssignmentListAllowedActions: {
        trainer: ['startPreview', 'unassign'],
        default: ['unassign'],
    },
    genericAssignmentListFilters: {
        default: ['title', 'group', 'workgroup', 'learner', 'state', 'beginDate', 'endDate', 'concepts'],
        learner: [],
    },
    genericConditionalFilters: {
        default: ['chapters'],
    },
    genericAssignmentListShowHours: false,
    genericAssignmentsListCheckDefaultFiltersInUrl: true,
    genericAssignmentListColumns: {
        default: [
            'assigned_node_title',
            'created',
            'assigned_user',
            'comment',
            'state',
            'buttons',
        ],
        learner: [
            'assigned_node_title',
            'created',
            'comment',
            'state',
            'buttons',
        ],
    },
    hasCompletionDate: false,
    hasCompletionTime: false,
    isAssignationIsLimitedByUsage: true,
    isAutoAssignmentForced: true,
    noFilterforAssignmentsWidget: true,
    selfAssignmentListAllowedActions: {
        trainer: ['startPreview', 'editAssign', 'unassign'],
        default: ['unassign', 'editAssign'],
    },
    selfAssignmentListActiveAvailableRole: ['trainer', 'manager'],
    selfAssignmentListFilters: {
        default: ['title', 'beginDate', 'endDate', 'concepts'],
        learner: [],
    },
    selfConditionalFilters: {
        default: ['chapters'],
    },
    selfAssignmentListColumns: {
        default: [
            'assignment_title',
            'created',
            'classOrGroups',
            'state',
            'buttons',
        ],
    },
    shouldRedirectoToApp: true,
    navigateDirectlyToSummary: true,
    isAssignationClosedActive: ['learner'],
    rolesCanShowBannerInfoClosedAssignment: ['learner'],
    maxCommentLenght: 140,
    assignmentsStateIds: {closed: '2682', assigned: '2681', pending: '2683', valid: '4270'},
    selfShowAllStates: true,
    genericShowAllStates: true,
};
