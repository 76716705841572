import {GraphData} from './graph-data';
import * as _ from 'lodash-es';

export interface ExerciseAttendanceGraphData {
    [learnerId: number]: {
        [chapterId: number]: {
            activitiesDone: number,
            chapterId: string,
            // detail: RawProgressDotDetail[],
            label: string,
            learnerId: string,
            localized: string
        }[]
    };
}

export class AttendanceData extends GraphData {
    public get entitiesGroupedByLearner () {
        return _.groupBy(this.entities, 'attributes.uid');
    }

}
