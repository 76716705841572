import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {Observable} from 'rxjs';
import {DataEntity} from 'octopus-connect';
import {map, tap} from 'rxjs/operators';

@Component({
    selector: 'app-bd-content-viewer-modal',
    templateUrl: './bd-content-viewer-modal.component.html',
})
export class BdContentViewerModalComponent implements OnInit {
    public isLoading = true;
    public text: string;
    public bdTitle: string;
    private bdGranule: DataEntity;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public modalData: { bd$: Observable<DataEntity> },
        public selfDialogRef: MatDialogRef<BdContentViewerModalComponent>) {
    }

    ngOnInit(): void {
        this.modalData.bd$.pipe(
            tap(bdGranule => {
                this.bdGranule = bdGranule;
                this.text = this.bdGranule.get('reference').activity_content[0].text;
                this.bdTitle = this.bdGranule.get('metadatas').title;
                this.isLoading = false;
            })
        ).subscribe();
    }

    public close(): void {
        this.selfDialogRef.close();
    }
}
