import {Injectable} from '@angular/core';
import {ContextualService} from "@modules/notepad/core/services/contextual.service";
import {NotepadService} from "@modules/notepad";

@Injectable({
    providedIn: 'root'
})
export class ServicesInstantiatorService {
    
    constructor(
        private contextualService: ContextualService,
        private notepadService: NotepadService,
    ) {
    }
}
