
import {map} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {Observable} from 'rxjs';
import {ModelSchema, Structures} from 'octopus-model';
import {tralalereBar} from '../../../settings';
const settingsStructure: ModelSchema = new ModelSchema({
    tralalereBar: Structures.object({
        displayed: true,
        displayTralabarIcons: false
    }),
});
@Component({
  selector: 'app-tralalere-bar',
  templateUrl: './tralalere-bar.component.html',
  styleUrls: ['./tralalere-bar.component.scss']
})
export class TralalereBarComponent implements OnInit {

  public settings: { [key: string]: any };
  private linksObs: Observable<DataEntity[]>;
  public links: Array<object>;
  public displayTralabarIcons;

  constructor(
      private translate: TranslateService,
      private octopusConnect: OctopusConnectService,
  ) {}

  ngOnInit(): void {
      this.settings = settingsStructure.filterModel(tralalereBar)
      if (!this.linksObs) {
          this.linksObs = this.octopusConnect.loadCollection('tralabar_links').pipe(
              map((links: DataCollection) => links.entities));
      }

      this.linksObs.subscribe((data: DataEntity[]) => {
          this.links = data;
      });
      this.displayTralabarIcons = this.settings.tralalereBar.displayTralabarIcons;
  }

  public goToSite(pathKey: string): void{
      this.translate.get(pathKey).subscribe((translation: string) => window.open(translation, '_blank'));
  }

}
