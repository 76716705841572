import {Component, Inject, OnInit} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-screen-size-modal',
  templateUrl: './screen-size-modal.component.html',
  styleUrls: ['./screen-size-modal.component.scss']
})
export class ScreenSizeModalComponent implements OnInit {

  public param: object;

  constructor(
      public dialogRef: MatDialogRef<ScreenSizeModalComponent>,
      @Inject(MAT_DIALOG_DATA) private data: Object
  ) { }

  ngOnInit() {
    this.param = {
      minHeight: this.data['minHeight'],
      minWidth: this.data['minWidth']
    };
  }

}
