import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import {modulesSettings} from "../settings";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class IsAllowedBySettings {
    private routesConfigured: {regExp: RegExp, settings: boolean|{[key:string]:string[]} }[] = [];
    
    constructor(
        private router: Router,
        private translate: TranslateService,
    ) {
        Object.keys(modulesSettings.routesAccess || {}).forEach((route: string) => {
            this.routesConfigured.push({
                regExp: new RegExp(route),
                settings: modulesSettings.routesAccess[route]
            });
        });
    }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.checkRouteAllowedOrRedirect(route, state);
    }
    
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.checkRouteAllowedOrRedirect(route, state);
    }
    
    public checkRouteAllowedOrRedirect(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        for (let {regExp, settings} of this.routesConfigured) {
            if (regExp.test(state.url)) {
                let allowed = true;
                
                if (typeof settings === 'boolean') {
                    allowed = settings;
                } else {
                    if (settings.hasOwnProperty('onlyForLocalizations')) {
                        allowed &&= settings.onlyForLocalizations.includes(this.translate.currentLang);
                    }
                    
                    // we can add more rules (based on role for exemple) here, based on the same system as for localization
                }
                
                if (!allowed) {
                    // redirect to root to let the application then redirect to default route
                    this.router.navigate(['/'], {skipLocationChange: true});
                }
                
                return allowed;
            }
        }
        
        return true;
    }
}