// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-gallery .grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  height: 100%;
  padding: 20px;
  background: url('fondgalerie.jpg') no-repeat center center fixed;
  background-size: cover;
}
app-gallery .grid .item {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 280px;
  min-width: 280px;
}
app-gallery .ps__rail-y {
  opacity: 1;
}
app-gallery .ps__rail-y .ps__thumb-y {
  background-color: #24B7C7;
}
app-gallery .ps__rail-y:hover .ps__thumb-y {
  width: 16px;
  background-color: #24B7C7;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/gamification/core/gallery/gallery.component.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,qCAAA;EACA,cAAA;EACA,YAAA;EACA,aAAA;EACA,gEAAA;EACA,sBAAA;AAAR;AAEQ;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,gBAAA;AAAZ;AAII;EACI,UAAA;AAFR;AAIQ;EACI,yBAAA;AAFZ;AAMY;EACI,WAAA;EACA,yBAAA;AAJhB","sourcesContent":["app-gallery {\r\n    .grid {\r\n        display: grid;\r\n        grid-template-columns: repeat(4, 1fr);\r\n        grid-gap: 10px;\r\n        height: 100%;\r\n        padding: 20px;\r\n        background: url('../../assets/fondgalerie.jpg') no-repeat center center fixed;\r\n        background-size: cover;\r\n\r\n        .item {\r\n            display: flex;\r\n            justify-content: center;\r\n            align-items: center;\r\n            min-height: 280px;\r\n            min-width: 280px;\r\n        }\r\n    }\r\n\r\n    .ps__rail-y {\r\n        opacity: 1;\r\n\r\n        .ps__thumb-y {\r\n            background-color: #24B7C7;\r\n        }\r\n\r\n        &:hover {\r\n            .ps__thumb-y {\r\n                width: 16px;\r\n                background-color: #24B7C7;\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
