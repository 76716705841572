import {Injectable} from '@angular/core';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../../settings';
import * as _ from 'lodash-es';
import {DataEntity} from 'octopus-connect';
import {ReplaySubject} from 'rxjs';
import {PluginSetting} from '../models/plugin.setting';

export interface LessonToolDataCommunicationCenterInterface {
    lesson: DataEntity;
    onComplete: ReplaySubject<DataEntity>;
}

/**
 * List of type of plugins should be more in the future
 */
export enum PluginType {
    lessonTool = 'lessonTool'
}

/**
 * Structure of a plugin setting
 * @remarks it's a copy of {@link PluginSetting} but used for {@link ModelSchema}
 */
const genericPluginSettingsStructure = new ModelSchema({
    label: Structures.string(),
    octopusConnectRoom: Structures.string(),
    pluginType: Structures.string(),
    iconIdentifier: Structures.string()
});

export interface IGenericPlugin {
    toolIdentifier: string;
    setting: PluginSetting;
}

@Injectable({
    providedIn: 'root'
})
export class GenericPluginsService {
    /**
     * List of each plugin settings currently active
     */
    public settings: { [pluginLabel: string]: PluginSetting } = {};
    /**
     * Map list to link type of plugin to type of activity to create
     * @remarks not sure if here it's a better place than {@link LessonsService} or {@link ActivitiesService}
     */
    public readonly pluginTypeToActivityType = {
        [PluginType.lessonTool]: 'Tool'
    };

    constructor() {
        this.initializeSettings();
    }

    /**
     * Obtains a filtered list of plugins settings from {@link settings} defined by a type.
     * @param type
     */
    public getPluginsByType(type: PluginType): IGenericPlugin[] {
        return Object.keys(this.settings)
            .filter(pluginLabel => this.settings[pluginLabel].pluginType === type)
            .map(pluginLabel => {
                return {toolIdentifier: pluginLabel, setting: this.settings[pluginLabel]};
            });
    }

    /**
     * Get from the module activities settings the list of plugins settings to fill in {@link settings}
     */
    private initializeSettings(): void {
        const pluginsSettings = _.get(modulesSettings.activities, 'plugins', {});
        Object.keys(pluginsSettings)
            .map((pluginLabel => {
                    this.settings[pluginLabel] = <PluginSetting>genericPluginSettingsStructure.filterModel(pluginsSettings[pluginLabel]);
                })
            );
    }
}
