import {Injectable} from '@angular/core';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {Observable} from 'rxjs';
import * as _ from 'lodash-es';
import {CommunicationCenterService} from '@modules/communication-center';
import {Roles} from '../../../../shared/roles';

type RuleResponse = boolean | Observable<boolean>;
type RuleCallback = (user: UserDataEntity, ...options: any[]) => RuleResponse;

const RULE_NOT_FOUND_ERROR = 'Authorization rule ({{rule}}) not found';
const RULE_DUPLICATE_ERROR = 'Authorization rule with the same identifier ({{rule}}) already exists';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationService {
    // Role names list associated to the role id
    private reverseRoleMapping: { [roleNumber: number]: string } = {};
    // List of active rules
    private rules: { [identifier: string]: RuleCallback } = {};
    // Current authenticated user (or null if not logged)
    private user: UserDataEntity = null;

    constructor(private communicationCenter: CommunicationCenterService) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((user: UserDataEntity | null) => {
                if (!!user) {
                    this.postAuthentication(user);
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter.getRoom('authentication')
            .getSubject('roles')
            .subscribe((roleMapping: { [roleIdentifier: string]: number }) => {
                this.reverseRoleMapping = Object.keys(roleMapping).reduce((obj, roleName) => {
                    const roleId = roleMapping[roleName];
                    obj[roleId] = roleName;
                    return obj;
                }, {});
            });
    }

    /**
     * Add a rule to authorize the user based on their roles
     * @param ruleIdentifier unique identifier of the rule
     * @param allowedRoleNames List of allowed roles as name ('trainer', 'manager', etc.).
     * @example
     * service.addRoleRule('onlyForTrainerRule', ['trainer']);
     */
    public addRoleRule(ruleIdentifier: string, allowedRoleNames: Roles[]): void {
        return this.addRule(ruleIdentifier, (user: UserDataEntity) => {
            const userRoleNames = user.get('role').map(id => this.reverseRoleMapping[id]);
            return allowedRoleNames.some(allowedRole => userRoleNames.includes(allowedRole));
        });
    }

    /**
     * Create a rule, will automatically be active.
     * @param ruleIdentifier unique identifier of the rule
     * @param callback the function to test when the rule is called (with the user tested as parameter)
     * @example
     * service.addRule('onlyConnectedUser', (user) => !!user);
     * // Works with Observable<boolean>
     * service.addRule('someAsyncThings', (user) => someObs$.pipe(map(data => {/- something returning a boolean -/}));
     */
    public addRule(ruleIdentifier: string, callback: RuleCallback): void {
        if (this.hasRule(ruleIdentifier)) {
            throw new Error(RULE_DUPLICATE_ERROR.replace('{{rule}}', ruleIdentifier));
        }

        this.rules[ruleIdentifier] = callback;
    }

    /**
     * Remove an existing/active rule (remove and recreate a rule is the only way to update a rule)
     * @param ruleIdentifier unique identifier of the rule
     */
    public removeRule(ruleIdentifier: string): void {
        if (this.hasRule(ruleIdentifier)) {
            delete this.rules[ruleIdentifier];
        }
    }
    
    public hasRule(ruleIdentifier: string): boolean {
        return _.has(this.rules, ruleIdentifier);
    }

    /**
     * Execute a rule test for the given user
     * @param ruleIdentifier unique identifier of the rule
     * @param user User tested for the rule
     * @param defaultValue default value if the rule is not found
     * @param options specific data potentially needed by the rule
     * @example
     * const isAuthorized: boolean = service.can<boolean>('myRule');
     * // Or if it's an async rule
     * const isAuthorized$: Observable<boolean> = service.can<Observable<boolean>>('myAsyncRule');
     */
    public can<T extends RuleResponse>(ruleIdentifier: string, user: UserDataEntity, defaultValue?: T, ...options: any[]): T {
        if (this.hasRule(ruleIdentifier) === false) {
            if (defaultValue !== undefined && defaultValue !== null) {
                return defaultValue;
            }

            throw new Error(RULE_NOT_FOUND_ERROR.replace('{{rule}}', ruleIdentifier));
        }
        return this.rules[ruleIdentifier](user, ...options) as T;
    }

    /**
     * Execute a rule test for the current authenticated user
     * @param ruleIdentifier unique identifier of the rule
     * @param defaultValue default value if the rule is not found
     * @param options specific data potentially needed by the rule ...options: any[]
     * @example
     * const isAuthorized: boolean = service.currentUserCan<boolean>('myRule');
     * const isAuthorized: boolean = service.currentUserCan<boolean>('myRuleWithSpecificObjects', SomeObjects);
     * // Or if it's an async rule
     * const isAuthorized$: Observable<boolean> = service.currentUserCan<Observable<boolean>>('myAsyncRule');
     * const isAuthorized$: Observable<boolean> = service.currentUserCan<Observable<boolean>>('myAsyncRuleAboutSpecificObjects', SomeObjects);
     */
    public currentUserCan<T extends RuleResponse>(ruleIdentifier: string, defaultValue ?: T, ...options: any[]): T {
        return this.can<T>(ruleIdentifier, this.user, defaultValue, ...options);
    }

    private postAuthentication(user: UserDataEntity): void {
        this.user = user;
    }

    private postLogout(): void {
        this.user = null;
    }
}
