import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { FuseSharedModule } from '@fuse/shared.module';
import {FuseQuickPanelComponent} from "./quick-panel.component";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [
        FuseQuickPanelComponent
    ],
    imports     : [
        MatDividerModule,
        MatListModule,
        MatSlideToggleModule,
        MatIconModule,
        MatTableModule,
        MatTooltipModule,
        MatButtonModule,
        FuseSharedModule,
        TranslateModule
    ],
    exports: [
        FuseQuickPanelComponent
    ]
})
export class QuickPanelModule
{
}
