import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {ExtendedFlashCardInterface} from '@modules/activities/core/player-components/memory/memory.component';
import {v4 as uuidv4} from 'uuid';

/**
 * it's an array of item who can be visible or not
 * the logic is on the parent the component only show or not item
 * and send event to parent
 */
@Component({
    selector: 'app-memory-find-zone',
    templateUrl: './memory-find-zone.component.html',
})
export class MemoryFindZoneComponent implements OnChanges {
    @Input() data: ExtendedFlashCardInterface[] = []; // data to show
    @Input() disable: boolean; // data to show
    @Input() selectedItem: number[] = []; // list of element currently selected to change in data
    @Input() stateOfLastSelectedItems: { uid: number, state: ItemAnswerStateEnum }[]; // state to change in data
    @Output() idItemClicked: EventEmitter<number> = new EventEmitter(); // send to parent id of item selected
    @Output() playState = new EventEmitter<string>();
    public soundIsPlaying = false;
    private sound: HTMLAudioElement;
    public idDivPlaySound = uuidv4();
    public currentSoundUid: number;

    /**
     * if data change we update the items
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        this.updateSelectedItem();
    }

    /**
     * update data : change the select state and the current state : it's used because array change are not directly detected by input entry
     * @private
     */
    private updateSelectedItem(): void {
        this.updateState();
        this.updateSelectState();
    }

    /**
     * update the state of item pristine currently-correct incorrect etc.
     * @private
     */
    private updateSelectState(): void {
        if (this.selectedItem.length === 0) {
            this.data.map(item => item.selected = false);
        } else {
            this.selectedItem.forEach(item => {
                this.data.find(card => card.uid === item).selected = true;
            });
        }
    }

    /**
     * update the select state is item currently selected or not
     * @private
     */
    private updateState(): void {
        if (this.stateOfLastSelectedItems && this.stateOfLastSelectedItems.length > 0) {
            this.stateOfLastSelectedItems.forEach(itemState => {
                this.data.find(card => card.uid === itemState.uid).state = itemState.state;
            });
        }
    }

    /**
     * emit the id of element clicked
     * @param uid id unique
     */
    public changeState(uid: number): void {

        if (this.soundIsPlaying || this.data.find(d => d.state === 'incorrect' || d.state === 'was-correct')) {
            // do nothing : 1 / already playing a song or 2 / making a visual return because wrong answer or find two good item
            return;
        }
        this.idItemClicked.emit(uid);
        this.launchPlay(uid);
    }

    /**
     * launch play on click if is not playing and if image exist and if card is active
     */
    public launchPlay(itemUid: number): void {
        if (!this.soundIsPlaying) { //&& this.card.isActive && this.card.image) {
            this.playSound(itemUid);
        }

    }

    /**
     * launch read of the sound and listen the event of end of play
     * @private
     */
    private playSound(itemUid: number): void {
        this.currentSoundUid = itemUid;
        this.sound = document.getElementById(this.idDivPlaySound) as HTMLAudioElement;
        this.sound.src = this.data.find(d => d.uid === itemUid)?.audio?.uri.toString();

        this.sound.play();
        this.soundIsPlaying = true;
        this.playState.emit(PlayState.play);
        this.sound.onended = () => {
            this.playState.emit(PlayState.stop);
            this.soundIsPlaying = false;
        };
        this.sound.onpause = () => {
            this.playState.emit(PlayState.pause);
            this.soundIsPlaying = false;
        };
    }

    public getTitle(uid: number): string {
        return this.data.find(d => d.uid === uid).title;
    }
}

export enum PlayState {
    play = 'play',
    pause = 'pause',
    stop = 'stop'
}
