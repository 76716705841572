import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FlashCardInterface} from 'fuse-core/components/flashcard/flash-card.interface';
import {CommunicationCenterService} from '@modules/communication-center';

@Component({
    selector: 'app-language-lab-card-player',
    templateUrl: './language-lab-card-player.component.html',
})

export class LanguageLabCardPlayerComponent implements OnDestroy, OnInit {
    @Input() cards: FlashCardInterface[] = [];
    @Input() mainCardTitle = '';
    @Output() close = new EventEmitter<boolean>();
    currentCardIndex = 0;
    showSummary = false;
    allUserAudio: { id: string, blob: Blob }[] = [];
    enableNextButton = false;
    currentCardSelectedInSummary: FlashCardInterface;
    progress = 0;

    constructor(private communicationCenter: CommunicationCenterService) {
        // hide header
        this.communicationCenter
            .getRoom('skeleton')
            .next('addClass', 'is-player-active');
    }

    ngOnInit(): void {
        this.currentCardSelectedInSummary = this.cards[0];
        this.progress = 100 / this.cards.length;
    }

    closeActivity(): void {
        this.currentCardIndex = 0;
        this.showSummary = false;
        this.allUserAudio = [];
        this.progress = 0;
        this.close.emit(true);
    }

    next(): void {
        if (this.currentCardIndex < this.cards.length - 1) {
            this.currentCardIndex = this.currentCardIndex + 1;
            this.enableNextButton = false;
            this.progress = this.progress + 100 / this.cards.length;
        } else {
            /* console.log('finish a summary will be fired next for moment just reset to first and close');
             this.showSummary = true;
             this.progress = 0;*/
            // for moment just close the lesson
            this.progress = 100;
            setTimeout(() => {
                this.closeActivity();
            }, 500);

        }
    }

    get card(): FlashCardInterface {
        return this.cards[this.currentCardIndex];
    }

    public activityDone(): void {
        this.enableNextButton = true;
    }

    ngOnDestroy(): void {
        this.allUserAudio = [];
        this.currentCardIndex = 0;
        this.showSummary = false;
        this.communicationCenter
            .getRoom('skeleton')
            .next('removeClass', 'is-player-active');
    }

    userAudio(audio: Blob): void {
        if (!this.allUserAudio.find(c => c.id === this.cards[this.currentCardIndex].id)) {
            this.allUserAudio.push({id: this.cards[this.currentCardIndex].id, blob: audio});
        } else {
            this.allUserAudio.filter(c => c.id === this.cards[this.currentCardIndex].id)[0].blob = audio;
        }
    }

    selectCard(card: FlashCardInterface): void {
        this.currentCardSelectedInSummary = card;
    }

    currentCardAudio(): Blob {
        return this.allUserAudio.filter(c => c.id === this.currentCardSelectedInSummary.id)[0].blob;
    }
}
