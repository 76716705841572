import { Component, OnInit } from '@angular/core';
import {NotificationsService} from '@modules/notification/core/notifications.service';
import {Router} from '@angular/router';
import {defaultLoginRoute} from '../../../settings';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

    constructor(private notificationService: NotificationsService, private router: Router) {
    }

  ngOnInit() {
      if (!this.notificationService.settings.show) {
          this.router.navigate([defaultLoginRoute]);
      }
  }

}
