import {Observable, Subject} from 'rxjs';

/**
 * Permet d'automatiquement mettre a jour l'état "chargement" d'un observable
 * @param loadingVariable Soit un observable de boolean directement exploitable dans la vue, soit un callback qui reçoit l'état (true : en cours, false : fini)
 *
 * @example
 * const loading$ = new Subject<boolean>();
 * const lessons$ = getLesson().pipe(usingLoading(loading$)).subscribe(); // loading$ est mis à true au début de l'appel et à false à la fin de la requête
 *
 * @example
 * const loading = false;
 * const lessons$ = getLesson().pipe(usingLoading((state) => loading = state)).subscribe(); // même chose
 *
 * @example
 * @example
 * const lessons$ = getLesson().pipe(delay(10000), usingLoading(state => loading$.next(state))).subscribe(); // loading$ est mis à true au début de l'appel et à false 10 secondes après la fin de la requète
 * const lessons$ = getLesson().pipe(usingLoading(loading$), delay(10000)).subscribe(); // loading$ est mis à true au début de l'appel, à false à la fin de la requête et n'attend pas 10 secondes
 * @example
 * const lessons$ = getLesson().pipe(usingLoading(loading$)); // loading$ restera a son état initial car il n'y a pas de souscription
 *
 * L'état est mis à `true` à la première souscription et à false à chaque fois que l'opérateur sera déclanché.
 *  - Donc il ne sera pas remis à `true` si l'on emet une nouvelle fois sur un subject antérieur.
 *  - Donc il n'a aucun impact sur les opérateurs qui seront listé après celui-ci.
 */
export function usingLoading<T>(loadingVariable: (Subject<boolean> | ((state: boolean) => void))): (source: Observable<T>) => Observable<T> {
    const changeLoadingState = typeof loadingVariable === 'function'
        ? loadingVariable
        : (state: boolean) => loadingVariable.next(state);

    return function (source: Observable<T>) {
        return new Observable(subscriber => {
            changeLoadingState(true);
            source.subscribe({
                next(value) {
                    subscriber.next(value);
                    changeLoadingState(false);
                },
                error(error) {
                    subscriber.error(error);
                },
                complete() {
                    subscriber.complete();
                }
            });
        });
    };
}