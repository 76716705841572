import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'addInput'
})
export class AddInputPipe implements PipeTransform {

    constructor() {
    }

    transform(value: any, answer_text: string, answers?: any): any {
        answer_text = answer_text.toString().replace(/\\/g, '');
        for (const item of answers) {
            answer_text = answer_text.replace(/\[]/g, '##-#input##-');
        }
        return answer_text;
    }
}
