import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MultipleChapters} from '@modules/corpus/core/corpus-interfaces.interface';

@Component({
    selector: 'app-chapter-recap',
    templateUrl: './chapter-recap.component.html',
    styleUrls: ['./chapter-recap.component.scss']
})
export class ChapterRecapComponent implements OnInit {

    @Input() selections: any[]
    @Output() onRemove = new EventEmitter<MultipleChapters>();

    constructor() { }

    ngOnInit(): void {
    }

    public remove(selection: any): void {
        this.onRemove.emit(selection);
    }
}
