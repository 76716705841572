import {AfterViewInit, Component, inject, OnDestroy, OnInit} from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {map, take} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {defaultValue} from '../../../../settings';
import {UserDataEntity} from "@modules/authentication";
import {combineLatest} from "rxjs";
import {LessonsService} from "@modules/activities";
import {NavigateToLessonOptions} from "@modules/activities/core/models";

@Component({
    selector: 'app-activities-home',
    templateUrl: './activities-home.component.html'
})
export class ActivitiesHomeComponent implements OnInit, AfterViewInit, OnDestroy {
    public defaultEducationalLevelId = defaultValue['grade'] ? defaultValue['grade'] : "";
    public defaultConceptId = defaultValue['concept'] ? defaultValue['concept'] : "";
    public config: Partial<{ educational_level: string; concept: string }>;
    private communicationCenter = inject(CommunicationCenterService);
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private lessonsService = inject(LessonsService);

    ngOnInit(): void {
        const forceLaunchLessonOrNull$ = this.route.queryParams.pipe(
            map((params) => {
                if (params.forceAssignmentId) {
                    const options: NavigateToLessonOptions = !!params.options ? JSON.parse(params.options) : undefined
                    options.exitLessonUrl = this.router.url.split('?')[0]; // si on laisse les query params, on va relancer le parcours
                    return () => this.lessonsService.loadAndNavigateToLessonFromAssignmentId(params.forceAssignmentId, options);
                }
                return null;
            })
        )

        const redirectToGradeAndConceptOrNull$ = this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData').pipe(
                take(1),
                map((user: UserDataEntity) => {
                    this.config = user.get('config');
                    // set default if saved datas exist
                    if (this.config) {
                        if (this.config.educational_level) {
                            this.defaultEducationalLevelId = this.config.educational_level;
                        }
                        if (this.config.concept) {
                            this.defaultConceptId = this.config.concept;
                        }
                    }

                    if (defaultValue['grade'] && defaultValue['concept']) {
                        return () => this.router.navigate(['lessons', 'grade', this.defaultEducationalLevelId, 'concept', this.defaultConceptId]);
                    }
                    return null
                })
            );

        combineLatest([
            forceLaunchLessonOrNull$,
            redirectToGradeAndConceptOrNull$])
            .pipe(
                map(([forceLaunchLessonOrNull, redirectToGradeAndConceptOrNull]) => {
                    if (forceLaunchLessonOrNull !== null) {
                        forceLaunchLessonOrNull();
                    } else if (redirectToGradeAndConceptOrNull !== null) {
                        redirectToGradeAndConceptOrNull();
                    }
                })
            ).subscribe()
    }

    ngAfterViewInit(): void {
        this.communicationCenter
            .getRoom('skeleton')
            .next('addClass', 'position-top-lower-for-content');
    }

    ngOnDestroy(): void {
        this.communicationCenter
            .getRoom('skeleton')
            .next('removeClass', 'position-top-lower-for-content');
    }
}
