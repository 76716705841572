import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarRecallButtonComponent } from './snackbar/snackbar-recall-button/snackbar-recall-button.component';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';

@NgModule({
    declarations: [
        SnackbarRecallButtonComponent
    ],
    exports: [
        SnackbarRecallButtonComponent
    ],
    imports: [
        CommonModule,
        MatSnackBarModule
    ]
})
export class NewsModule { }
