import {GraphData} from '@modules/graph-bayard/core/model/graph-data';

export interface FlattenedProgressData {
    date: number,
    lessonId: number,
    lessonLabel: string,
    questionSetLabel: string,
    badAnswers: number,
    goodAnswers: number,
    goodAnswersPercent: number,
    total: number,
    questionSetId: number,
}

export class ProgressData extends GraphData {
    public get dots(): FlattenedProgressData[] {
        return this.getDataOfLearnerById(this.graphFilters.learner)
            .sort((a, b) => +a.date - +b.date);
    }

    private getDataOfLearnerById(id: string): FlattenedProgressData[] {
        return this.entities
            .filter(pd => pd.attributes.uid.toString() === id.toString() && pd.attributes.total > 0)
            .map(pd =>
                pd.attributes.qs.map(questionSet =>
                    ({
                        ...(questionSet.answers),
                        questionSetId: questionSet.id,
                        date: questionSet.date,
                        lessonId: questionSet.lessonId,
                        lessonLabel: questionSet.lessonLabel,
                        questionSetLabel: questionSet.label
                    })
                )
            )
            .flat();
    }
}
