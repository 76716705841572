import {Component, OnInit} from '@angular/core';
import {Graph} from './graph';
import {DetailsData} from '../details-list/details-data';
import * as _ from 'lodash-es';
import {TranslateService} from '@ngx-translate/core';
import {mergeMap, take, tap} from 'rxjs/operators';
import {ProgressionService} from '@modules/graph-assignation/core/progression/progression.service';
import {Observable} from 'rxjs';
import {ChartConfiguration} from 'chart.js/dist/types';

@Component({
    selector: 'app-progression-display',
    templateUrl: './progression-display.component.html',
})
export class ProgressionDisplayComponent implements OnInit {
    public chartData: ChartConfiguration['data']['datasets'] = [];
    public chartLabels: string[] = [];
    public graphs: Graph[];
    public selectedGraph: Graph;
    public index: number = 0;
    public details: DetailsData;
    public graphIsLoading = true;
    private selectedIndex: number | null = null;
    private translations: { [p: string]: string } = {};
    public detailsIsLoading = false;
    private currentIdLesson: number = 0;
    private currentIdIdGroup: string = '';
    public fieldsAllowedInInfoPopUp: string[] = [];

    constructor(private progressionSvc: ProgressionService,
                private translateSvc: TranslateService) {
        this.fieldsAllowedInInfoPopUp = this.progressionSvc.getMetadataDialogFieldsInfoForCurrentRole();
    }

    public get displayList(): boolean {
        return this.selectedIndex !== null;
    }

    ngOnInit(): void {
        this.graphs = this.progressionSvc.progressionSettings.charts;
        this.translations = {};

        this.translateSvc.get(_.flatten(this.graphs.map(g => g.chartData).filter(c => !!c.labels).map(c => c.labels))).pipe(
            tap(results => this.translations = results),
            mergeMap(() => this.loadGraph())
        ).subscribe();
    }

    public selectGraph(graph: Graph, index: number): void {
        this.index = index;
        this.selectedGraph = graph;
        this.loadGraph().pipe(take(1)).subscribe();
    }

    public onGraphClick({event, activeElement}: { event: MouseEvent; activeElement: number | null }): void {
        if ([undefined, null].includes(activeElement) || activeElement === this.selectedIndex) {
            this.unSelectGraphBar();
        } else {
            this.selectGraphBar(activeElement);
        }

        this.chartData = this.chartData.slice();
    }

    private translate(labels: string[]): string[] {
        return labels.map(l => this.translations[l]);
    }

    // format les data récupéré du back
    private initChildComponents(): Observable<{ data: number; label: string }[]> {
        return this.progressionSvc.getDataForGraph(this.selectedGraph.label, this.currentIdLesson, this.currentIdIdGroup).pipe(
            take(1),
            tap((graphData: { data: number, label: string }[]) => {
                // Overwrite Labels
                if (!!this.selectedGraph.chartData.labels) {
                    this.chartLabels = this.translate(this.selectedGraph.chartData.labels);
                } else {
                    this.chartLabels = graphData.map(value => value.label);
                }

                const colors = this.chartLabels.map((value, index) =>
                    this.selectedGraph.chartData.color[index % this.selectedGraph.chartData.color.length]
                );

                this.chartData = [{
                    data: graphData.map(value => value.data),
                    backgroundColor: colors,
                    hoverBackgroundColor: colors,
                }];
            })
        );
    }

    private loadGraph(): Observable<{ data: number; label: string }[]> {
        this.graphIsLoading = true;
        this.selectedGraph = !!this.selectedGraph ? this.selectedGraph : this.graphs[0];
        this.selectedIndex = null;
        this.details = null;

        return this.initChildComponents().pipe(
            tap(() => this.graphIsLoading = false)
        );
    }

    private unSelectGraphBar(): void {
        this.selectedIndex = null;
        this.details = null;

        this.chartData[0].hoverBackgroundColor =
            this.chartData[0].backgroundColor =
                this.chartLabels.map((value, index) =>
                    this.selectedGraph.chartData.color[index % this.selectedGraph.chartData.color.length]
                );
    }

    private selectGraphBar(activeElement: number): void {
        const addAlpha = (color: string, opacity: number) => {
            // coerce values so ti is between 0 and 1.
            const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
            return color + _opacity.toString(16).toUpperCase();
        };

        this.selectedIndex = activeElement;
        this.chartData[0].hoverBackgroundColor =
            this.chartData[0].backgroundColor =
                this.chartLabels.map((value, index) => {
                    const color = this.selectedGraph.chartData.color[index % this.selectedGraph.chartData.color.length];
                    return addAlpha(color, this.selectedIndex === index ? 1 : 0.3);
                });

        const data = this.progressionSvc.getDetails(this.selectedGraph.label, this.selectedIndex);
        this.details = _.merge({}, this.selectedGraph.details, {
            headerColor: this.selectedGraph.chartData.color[this.selectedIndex % this.selectedGraph.chartData.color.length],
            row: data
        });
    }

    /**
     * get the current lesson Id and groupId selected in the filter
     * and reload graph whith new data
     */
    reloadGraphWithcurrentIdLessonsAndIdGroup(data: { idLesson: number, idGroup: string }): void {
        this.currentIdLesson = data.idLesson;
        this.currentIdIdGroup = data.idGroup;
        this.loadGraph().pipe(take(1)).subscribe();
    }
}
