import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {CardsService} from 'fuse-core/components/card/cards.service';
import { Location } from '@angular/common'
import {DataCardInterface} from 'fuse-core/components/card/data-card.interface';
import {DataEntity} from 'octopus-connect';
import {combineLatest, Observable, of, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {ContextualService} from "@modules/activities/core/services/contextual.service";
import {AutoUnsubscribeTakeUntilClass} from "../../../../../shared/models/auto-unsubscribe-take-until.class";
import {takeUntil, tap} from "rxjs/operators";
import {AuthorizationService} from '@modules/authorization';
import {AsyncRules} from '@modules/activities/core/models/rules';
import {SharedEntitiesService} from '@modules/activities/core/services/shared-entities.service';

@Component({
    selector: 'app-lesson-page',
    templateUrl: './lesson-page.component.html'
})
export class LessonPageComponent extends AutoUnsubscribeTakeUntilClass implements OnInit {
    public datacard:DataCardInterface;
    public resource:DataEntity;

    public customOptions: OwlOptions = {
        autoWidth: true,
        loop: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        navSpeed: 700,
        navText: ['<', '>'],
        nav: false,
    };

    public learners = [];
    public classes = [];
    public workgroups = [];
    constructor(
        public cardsService: CardsService,
        private contextualService: ContextualService,
        private location: Location,
        private ref: ChangeDetectorRef,
        private route: ActivatedRoute,
        private router: Router,
        private authorizationService: AuthorizationService,
        private sharedEntitiesService: SharedEntitiesService
    ) {
        super();
    }

    ngOnInit(): void {
        combineLatest([this.sharedEntitiesService.loadLearners(), this.sharedEntitiesService.loadClasses(), this.sharedEntitiesService.loadWorkgroups()])
        .pipe(
            tap(([learners, classes, workgroups]) => {
                this.learners = learners;
                this.classes = classes;
                this.workgroups = workgroups;
            })
        )
        .subscribe()
        this.datacard = this.cardsService.currentDatacard;

        // if we don't have data, redirect to lessons/list/search to load our lesson and reopen it
        if (!this.datacard || +this.datacard.resource.id !== +this.route.snapshot.params['lessonId']) {
            this.router.navigate(
                ['lessons', 'list', 'search'],
                {'queryParams':
                        {
                            'id': this.route.snapshot.params['lessonId'],
                            'openLessonPage': 1
                        },
                        'skipLocationChange': true,
                });
        }

        this.resource = this.datacard.resource;
        this.cardsService.setTypeOfCard();
        this.ref.detectChanges()

        this.setupContextual();
    }

    private setupContextual(): void {
        this.contextualService.actionLessonPreview$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(() => this.playPreview());
        this.contextualService.actionLessonPlay$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(() => this.playSession());
        this.contextualService.actionLessonOpenAssign$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(() => this.assignToLearners());

        this.contextualService.conditionLessonHasDocument$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => callback(this.filesCount > 0));
        this.contextualService.conditionLessonAssignable$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => callback(this.isAssignable()));

        this.contextualService.dataLessonDocumentCount$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => callback(this.filesCount.toString()));
        this.contextualService.dataLessonCollectionName$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => callback(this.collectionName));
    }

    public get filesCount(): number {
        return this.resource?.get('metadatas')?.files?.length;
    }

    public get collectionName(): string {
        return this.resource?.get('metadatas')?.indexation[0]?.label;
    }

    public goBackToPrevPage(): void {
        const url = this.datacard.originPath;
        const urlParams = this.getParamsFromURI(url);
        const queryParams = {};

        urlParams.forEach((value, key) => {
            if (key !== 'id' && key !== 'openLessonPage') {
                queryParams[key] = value;
            }
        });

        this.router.navigate([this.removeAllURLParameters(url)], { queryParams });
    }

    public getParamsFromURI(relativeUrl){
        const [ , paramString ] = relativeUrl.split( '?' );
        return new URLSearchParams( paramString );
    };

    public removeAllURLParameters(url) {
        //prefer to use l.search if you have a location/link object
        var urlparts = url.split('?');
        if (urlparts.length >= 2) {
            return urlparts[0];
        }
        return url;
    }

    public downloadDoc(uri: string): void {
        this.cardsService.downloadDoc(uri);
    }

    public isFavorite(): boolean {
        return this.cardsService.isFavorite;
    }

    public bookmark(): void {
        this.cardsService.bookmark();
    }

    public playPreview(): void {
        this.cardsService.playPreview();
    }

    public assignToLearners(): void {
        this.datacard.openAssign(this.datacard.resource)
    }

    public playSession(): void {
        this.openAssignmentWithUserDataFormModal(this.resource, this.datacard);
    }

    private openAssignmentWithUserDataFormModal(entity: DataEntity, datacard:DataCardInterface): void {
        this.cardsService.openAssignmentWithUserDataFormModal(entity, datacard, this.learners, this.classes, this.workgroups);
    }

    // TODO Refacto pour utiliser le authorizationService (c'est au module assignation de définir règles et de les exposer au niveau de authorizationService
    public isAssignable(): any{
        // hide button for usage.collectif only lesson
        return this.resource?.get('usage').some(e => e.label === 'usage.autonomie');
        // return this.cardsService.displayField('assignIcon') ? this.datacard.isAssignable$ : of(false);
    }

    public isEditable(): Observable<boolean> {
        return this.authorizationService.currentUserCan<Observable<boolean>>(AsyncRules.EditLesson, of(false), this.resource);
    }


}
