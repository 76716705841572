import {Injectable} from '@angular/core';
import {combineLatest, Observable, of, ReplaySubject} from 'rxjs';
import {DataEntity, OctopusConnectService, PaginatedCollection} from 'octopus-connect';
import {filter, mergeMap, map, take, tap} from 'rxjs/operators';
import {CollectionOptionsInterface} from 'octopus-connect';
import * as _ from 'lodash-es';
import {CommunicationCenterService} from '@modules/communication-center';
import {TypedDataEntityInterface} from '../../../shared/models/octopus-connect/typed-data-entity.interface';

/**
 * Used to visualize editable bd data.
 * Sometimes it's only used for `text` fields, sometimes only the others
 */
export type IBdFormData = {
    title?: string;
    associatedLessonId?: string | number;
}

const granuleEndpoint = 'granule';
const bdEndpoint = 'bd';
const searchEndpoint = 'basic_search';

const bdTypologyLabel = 'bd';

@Injectable({
    providedIn: 'root'
})
/**
 * Define the exchange between front & backend in matter of bd
 */
export class BdRepositoryService {

    constructor(
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService
    ) {
    }

    /**
     * Delete a bd defined by the granule id. The metadatas, activity & activity_content are deleted on cascade
     * @param id
     */
    public destroyBd(id: number | string): Observable<boolean> {
        // Hack: no need to reload the entity, we can mock it :)
        const entity = new DataEntity(granuleEndpoint, {}, this.octopusConnect, id);
        return entity.remove();
    }

    /**
     * Return the bd {@link DataEntity} as a granule (contains granule, medatada, activity & activityContent)
     * @param id
     */
    public getBd(id: string | number): Observable<DataEntity> {
        return this.octopusConnect.loadEntity(granuleEndpoint, id);
    }

    /**
     * Path a bd entity and return it
     *
     * @param bdId the id of the bd
     * @param data the data to path, each data are not required, only the given key will be patched.
     * Some data are in metadata of granule, other in activity_content. This method know what to do.
     * @param returnUpdatedGranule If true or by default, returns the patched bd granule (but a request is made),
     * otherwise returns the granule in the same state as before patching (and no request are done)
     */
    public updateBd(bdId: string | number, data: IBdFormData, returnUpdatedGranule = true): Observable<DataEntity> {
        const updateActivityContent = (bdGranule: DataEntity) => {
            return this.getBdActivityContent(bdGranule.get('reference').activity_content[0].id).pipe(
                mergeMap(bdActivityContent => {
                        if (data.hasOwnProperty('associatedLessonId')) {
                            bdActivityContent.set('associated_nodes', [data.associatedLessonId?.toString()]);
                        }
                        if(data.hasOwnProperty('title')) {
                            bdActivityContent.set('content', {...bdActivityContent.get('content'), title: data.title});
                        }
                        return bdActivityContent.save();
                    }
                ),
                take(1)
            );
        };

        const updateMetadata = (bdGranule: DataEntity) => {
            const metadata = <DataEntity>bdGranule.getEmbed('metadatas');
            metadata.set('title', data.title);
            return metadata.save();
        };

        return this.getBd(bdId).pipe(
            mergeMap(bdGranule => {
                const obs: Observable<DataEntity | any>[] = [of([])];
                if (data.hasOwnProperty('text') || data.hasOwnProperty('associatedLessonId')) {
                    obs.push(updateActivityContent(bdGranule));
                }
                if (data.hasOwnProperty('title')) {
                    obs.push(updateMetadata(bdGranule));
                }

                return combineLatest(obs).pipe(
                    mergeMap(() => returnUpdatedGranule ? this.getBd(bdId) : of(bdGranule))
                );
            }),
        );
    }

    /**
     * Obtains the paginated list of notes
     * @param filterOptions
     * @return The {@link DataEntity} are `granules` and the are not of `bds` but `BasicSearch` endpoint
     */
    public getPaginatedBds(filterOptions: CollectionOptionsInterface = {}): Observable<PaginatedCollection> {
        return this.getBdActivityTypologyId().pipe(
            map(typologyId => {
                filterOptions = _.merge({
                    filter: {
                        typology: typologyId,
                    }
                }, filterOptions);
                return this.octopusConnect.paginatedLoadCollection(searchEndpoint, filterOptions);
            }),
            take(1)
        );
    }

    /**
     * Create and obtain a new bd activity
     *
     * - The activity is create by the generic activity creation of activity module.
     * - This way to create an activity don't set an activity content, that's why an activity_content are created and associated here.
     * - This way to create an activity don't allow to set default values, that's why the empty activity are updated here just after creation.
     *
     * @param bdData
     */
    public createBd(bdData: IBdFormData): Observable<DataEntity> {
        const onActivityCreated = new ReplaySubject<Observable<DataEntity>[]>(1);

        const bdPayload = {
            content: {
                title: bdData.title,
                creationDate: Date.now(),
                pages: [
                    {
                        title: '',
                        comicBoxes: [
                            {
                                title: '',
                                canvasJson: null,
                            },
                            {
                                title: '',
                                canvasJson: null,
                            },
                            {
                                title: '',
                                canvasJson: null,
                            },
                            {
                                title: '',
                                canvasJson: null,
                            },
                            {
                                title: '',
                                canvasJson: null,
                            },
                            {
                                title: '',
                                canvasJson: null,
                            },
                            {
                                title: '',
                                canvasJson: null,
                            },
                            {
                                title: '',
                                canvasJson: null,
                            },
                        ],
                    }
                ]
            }
        };

        this.octopusConnect.createEntity(bdEndpoint, bdPayload)
            .pipe(
                tap(activityContent => {
                    this.communicationCenter
                        .getRoom('activities')
                        .next('createActivities', {
                            types: [{label: bdTypologyLabel, activity_content: activityContent.id, metadata: {title: bdData.title}}],
                            callbackSubject: onActivityCreated
                        });
                })
            )
            .subscribe();

        return onActivityCreated.pipe(
            // We can do that because here there is only one bd to create
            mergeMap(obs => obs[0]),
            mergeMap(bdGranule => this.updateBd(bdGranule.id, bdPayload as any)),
            take(1)
        );
    }

    /**
     * Obtains the `activity_content` from `bds` endpoint
     *
     * @param id
     */
    private getBdActivityContent(id: string | number): Observable<TypedDataEntityInterface<{ associated_nodes: (string|number)[], content: {title: string} }>> {
        return this.octopusConnect.loadEntity(bdEndpoint, id) as Observable<TypedDataEntityInterface<{ associated_nodes: (string|number)[], content: {title: string} }>>;
    }

    /**
     * Obtain the id of the only one activity typology with `bd` as label
     */
    private getBdActivityTypologyId(): Observable<string> {
        return this.octopusConnect.loadCollection('variables/instance')
            .pipe(
                filter(collection => collection.entities.length > 0),
                map(collection => collection.entities[0].get('activityTypes')),
                map(activityTypes =>
                    activityTypes.filter(activityType =>
                        activityType.label === bdTypologyLabel
                    ).map(activityType => activityType.id)[0]
                ),
                take(1)
            );
    }
}
