import {takeUntil} from 'rxjs/operators';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DatacardService} from '../../../../../../shared/datacard.service';
import {CorpusRessource} from '@modules/corpus/core/corpus-ressource.class';
import {CorpusService} from '@modules/corpus/core/corpus.service';
import {MediaUploadService} from '@modules/corpus/core/media-upload.service';
import {Observable, of, Subject} from 'rxjs';
import {CardsService} from 'fuse-core/components/card/cards.service';
import {AuthorizationService} from '@modules/authorization';

import {AsyncRules} from '@modules/corpus/core/async.rules';

@Component({
    selector: 'app-corpus-favorites',
    templateUrl: './corpus-favorites.component.html',
    styleUrls: ['./corpus-favorites.component.scss']
})
export class CorpusFavoritesComponent implements OnInit, OnDestroy {
    public dataCards: any[] = [];

    private type = 'currentUser';
    private unsubscribeInTakeUntil = new Subject<void>();

    countEntities = 0;
    pageIndex = 0;
    pageRange = 6;
    pageRangeOptions = [6];
    @ViewChild('scrollContainer', {static: true}) scrollContainer: ElementRef;

    constructor(
        public corpusService: CorpusService,
        private datacardService: DatacardService,
        private cardService: CardsService,
        private mediaUploadService: MediaUploadService,
        private authorizationService: AuthorizationService,
    ) {
    }

    ngOnInit(): void {
        this.cardService.settingBookmark.subscribe(() => {
            this.loadResources();
        });

        this.loadResources();
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    /**
     * Loads resources that have been bookmarked
     */
    private loadResources(): void {
        const callbacks = {
            'isEditableAndErasable&': (resource) => this.isEditableAndErasable(resource, this.type),
            'openAssign': (originalResource) => this.openResourceEditionModal(originalResource),
            'openDialog': (resource) => this.corpusService.openDialog(resource),
            'openDuplicate': (originalResource) => this.openResourceEditionModal(originalResource),
            'openEditor': (originalResource) => this.openResourceEditionModal(originalResource),
            'openMetaDialog': (originalResource) => this.corpusService.openRessource(originalResource),
            'play': (originalResource) => this.corpusService.openRessource(originalResource),
        };

        this.corpusService
            .loadFavoritesCorpusResources({bookmarks: true, range: 6}).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((resources: CorpusRessource[]) => {
                this.corpusService.onFilesChanged.next(resources);
                this.dataCards = this.datacardService.processResources(resources, callbacks, this.type);
                this.addFlags();
                this.setPaginator();
            });
    }

    private openResourceEditionModal(resource: CorpusRessource): void {
        this.mediaUploadService.openResourceEditionModal(resource);
    }

    setPaginator(): void {
        this.countEntities = this.corpusService.paginatedCollection.paginator.count;
        this.pageIndex = this.corpusService.paginatedCollection.paginator.page - 1;
        this.pageRange = this.corpusService.paginatedCollection.paginator.range;
    }

    onPaginateChange(event): void {
        this.scrollToTop();
        this.corpusService.paginatedCollection.paginator.page = event.pageIndex + 1;
    }

    /**
     * scroll to top when changing page
     */
    public scrollToTop(): void {
        this.scrollContainer.nativeElement.scrollTop = 0;
    }

    /**
     * add some additionnal data because we come from favorites page
     * and in regard on some settings we will have divergente behaviour
     * @private
     */
    private addFlags(): void {
        if (this.cardService.settings.hidePlayPreviewFromFavoritesPage) {
            this.dataCards.map(d => d.isPreviewAccessible = false);
        }
        if (this.cardService.settings.allowedMultiPdfDownloadFromFavoritePage) {
            this.dataCards.map(d => d['isInFavoritePage'] = true);
        }
    }

    private isEditableAndErasable(resource, type: string) {
        return this.authorizationService.currentUserCan<Observable<boolean>>(AsyncRules.editResource, of(false), resource, type)
    }
}
