import {Component, Injectable} from '@angular/core';
import {filter, map, take, takeUntil, tap} from 'rxjs/operators';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import {combineLatest, Observable, Subject} from 'rxjs';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../../settings';
import {AuthenticationService} from '@modules/authentication';
import {CollectionOptionsInterface} from 'octopus-connect';
import {OctopusConnectService} from 'octopus-connect';
import {currentTimestamp} from '../../../../shared/utils';
import {NoAssignmentForLongTimeAgoNewsComponent} from './components/no-assignment-for-long-time-ago-news/no-assignment-for-long-time-ago-news.component';
import {NoAssignmentComponent} from './components/no-assignment/no-assignment.component';
import {LetsTryAssignmentComponent} from './components/lets-try-assignment/lets-try-assignment.component';
import {Subscription} from 'rxjs';
import {NewsInterface} from 'fuse-core/news/news.interface';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {DataCollection} from 'octopus-connect/lib/models/data-structures/data-collection.class';
import {TypedDataEntityInterface} from '../../../../shared/models/octopus-connect/typed-data-entity.interface';
import {InstitutionGroup} from '@modules/groups-management/core/definitions';
import {MyActivitiesWelcomeComponent} from '@modules/assignation/core/onboarding/components/my-activities-welcome/my-activities-welcome.component';

const SEVEN_DAYS_IN_SECONDS = 604800;
const NOT_MODELS_URL_REGEX = /^(?!\/lessons\/list\/models).*$/;
const MY_ACTIVITIES_URL_REGEX = /^\/followed\/tab.*$/;

const MY_ACTIVITIES_WELCOME_WEIGHT = 80;
const LETS_TRY_ASSIGNMENT_WEIGHT = 90;
const NO_ASSIGNMENT_FOR_LONG_TIME_AGO_WEIGHT = LETS_TRY_ASSIGNMENT_WEIGHT + 10;
const NO_ASSIGNMENT_AT_ALL_WEIGHT = NO_ASSIGNMENT_FOR_LONG_TIME_AGO_WEIGHT + 10;

const settingsStructure: ModelSchema = new ModelSchema({
    displayNews: Structures.object({
        default: []
    }),
});

interface NewsSettingsInterface {
    displayNews: {
        [role: string]: string[]
    };
}

@Injectable({
    providedIn: 'root'
})
export class OnboardingService {

    /**
     * A news about the fact that the user has an assignment that he can do by himself for try the system
     */
    static letsTryAssignmentNews: Partial<NewsInterface> = {
        id: 'letsTryAssignment',
        component: LetsTryAssignmentComponent as Component,
        weight: LETS_TRY_ASSIGNMENT_WEIGHT,
    };

    /**
     * A news about the fact to create a learner if it's not already done.
     */
    static createNoAssignmentForLongTimeAgoNews: Partial<NewsInterface> = {
        id: 'createNoAssignmentForLongTimeAgo',
        component: NoAssignmentForLongTimeAgoNewsComponent as Component,
        weight: NO_ASSIGNMENT_FOR_LONG_TIME_AGO_WEIGHT,
        channel: {
            snackbar: {
                acceptedUrlRegex: NOT_MODELS_URL_REGEX
            }
        }
    };

    static noAssignmentAtAll: Partial<NewsInterface> = {
        id: 'noAssignmentAtAll',
        component: NoAssignmentComponent as Component,
        weight: NO_ASSIGNMENT_AT_ALL_WEIGHT,
    };

    /** A news to welcome the user on the my activities page */
    static myActivitiesWelcome: Partial<NewsInterface> = {
        id: 'myActivitiesWelcome',
        component: MyActivitiesWelcomeComponent as Component,
        weight: MY_ACTIVITIES_WELCOME_WEIGHT,
        channel: {
            snackbar: {
                acceptedUrlRegex: MY_ACTIVITIES_URL_REGEX
            }
        }
    };

    private subscriptionLearnerList: Subscription;
    private onLogout = new Subject<void>();
    private almostOneLearnerExist = new Subject<void>();
    private currentUser: UserDataEntity;
    private settings: NewsSettingsInterface;

    constructor(
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
        private octopusConnect: OctopusConnectService
    ) {

        this.settings = settingsStructure.filterModel(modulesSettings.assignation) as NewsSettingsInterface;

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((currentUser: UserDataEntity) => {
                this.currentUser = currentUser;
                if (!!currentUser) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('notifications')
            .getSubject('sendNotification')
            .subscribe(notif => {
                if (notif.type === 'NEW_ASSIGNATION') {
                    // an assignment is just created if there is a new i remove it
                    this.removeNews();
                }
            });
    }

    /**
     * Emit list news to the communication center according to the app state and the settings
     * If the user is a teacher have a learner and no assignment for more than seven day emit alert
     */
    private emitNews(): void {
        if (this.isThisNewsAllowed(OnboardingService.letsTryAssignmentNews)) {
            this.emitLetsTryAssignmentNews();
        }

        if (this.isThisNewsAllowed(OnboardingService.createNoAssignmentForLongTimeAgoNews)) {
            this.emitNoAssignmentForLongTimeAgoNewsIfAlmostOneLearner();
        }

        if (this.isThisNewsAllowed(OnboardingService.noAssignmentAtAll)) {
            this.emitNoAssignmentAtAllNews();
        }

        if (this.isThisNewsAllowed(OnboardingService.myActivitiesWelcome)) {
            this.emitMyActivitiesWelcomeNews();
        }
    }

    // TODO mettre ça dans un service/communication center serait pas mal
    private isUserOwnNoPaidLicences$: Observable<boolean> = this.communicationCenter
        .getRoom('groups-management')
        .getSubject('userLicenses')
        .pipe(map((userLicenses: TypedDataEntityInterface<{ type: { label: string } }>[]) =>
            userLicenses.every(l => {
                const label = l.get('type')?.label;
                return label !== 'Class' && label !== 'Institution';
            })
        ));

    private isUsersInstitutionOwnNoPaidLicences$: Observable<boolean> =
        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('institutionList')
            .pipe(
                map(institutionList => (institutionList || []).length == 0)
            );



    private isUserHasFreeLicence$: Observable<boolean> =
        combineLatest([
            this.isUserOwnNoPaidLicences$,
            this.isUsersInstitutionOwnNoPaidLicences$
        ]).pipe(
            map(([isUserOwnNoPaidLicences, isUserInstitutionOwnNoPaidLicences]) =>
                isUserOwnNoPaidLicences
                && isUserInstitutionOwnNoPaidLicences
            )
        );



    /**
     * If the business rule accept it, emit news
     * user have almost a learner and no active assignment for more than seven day emit alert
     */
    private emitNoAssignmentForLongTimeAgoNewsIfAlmostOneLearner(): void {
        this.subscriptionLearnerList = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('learnerList').pipe(
                takeUntil(this.almostOneLearnerExist), // when appli is loading we have multiple emit first are without any learner
                tap(() => this.removeNews([OnboardingService.createNoAssignmentForLongTimeAgoNews])),
                filter((learners) => !!learners && learners.length !== 0),
            ).subscribe(learners => {
                    if (learners.length > 0) {
                        this.almostOneLearnerExistCloseListener();
                        // is there no assignment from more than seven days if it is send news
                        this.sendNewsIfLastAssignmentIsTooOld(SEVEN_DAYS_IN_SECONDS);
                    }
                }
            );
    }

    /**
     * Remove all news managed by this service.
     * @remarks If the news are not displayed it's not a problem.
     */
    private removeNews(news = []): void {
        if (news.length === 0) {
            news = [
                OnboardingService.createNoAssignmentForLongTimeAgoNews,
                OnboardingService.noAssignmentAtAll,
                OnboardingService.letsTryAssignmentNews,
                OnboardingService.myActivitiesWelcome
            ];
        }
        this.communicationCenter.getRoom('news').next('delete', news);
    }

    /**
     * send alert news if all the assignment are closed and more recent is more than timeToCheck time
     * @param timeToCheck time to compare from now to last assignemnt ended
     */
    private sendNewsIfLastAssignmentIsTooOld(timeToCheck: number): void {
        this.getAssignmentsCreatedByCurrentUser$({progress: 100})
            .pipe(
                take(1),
                map(collection => collection.entities
                    .filter(data =>
                        // assignment currently opened
                        data.get('state') !== 'closed' && data.get('assignated_user').uid
                        ||
                        // assignment closed but less than seven days ago
                        (data.get('state') === 'closed' && currentTimestamp() - data.get('dates').value2 < timeToCheck)))
            ).subscribe(assignements => {
            if (assignements.length > 0) {
                // no message to shown
            } else {
                // user is not a learner and there's learner created and no assignment from more than seven days message to send create assignment
                if (this.isThisNewsAllowed(OnboardingService.createNoAssignmentForLongTimeAgoNews)) {
                    this.communicationCenter.getRoom('news').next('add', [OnboardingService.createNoAssignmentForLongTimeAgoNews]);
                }
            }
        });
    }

    /**
     * execute all needs to prepare the app for the current user
     * @private
     */
    private postAuthentication(): void {
        this.emitNews();
    }

    /**
     * execute all needs to clean the app on logout
     * @private
     */
    private postLogout(): void {
        this.almostOneLearnerExistCloseListener();
        this.onLogout.next();
        this.onLogout.complete();
        if (!!this.subscriptionLearnerList) {
            this.subscriptionLearnerList.unsubscribe();
        }
        this.removeNews();

    }

    /**
     * if a learner is return the emit news will be done if needed
     * no need to wait until other learner list change
     */
    private almostOneLearnerExistCloseListener(): void {
        this.almostOneLearnerExist.next();
        this.almostOneLearnerExist.complete();
    }

    private isThisNewsAllowed(news: Partial<NewsInterface>): boolean {
        return this.settings.displayNews.hasOwnProperty(this.authService.accessLevel) ?
            this.settings.displayNews[this.authService.accessLevel].includes(news.id) :
            this.settings.displayNews['default'].includes(news.id);
    }

    private emitNoAssignmentAtAllNews(): void {
        this.getAssignmentsCreatedByCurrentUser$().pipe(
            takeUntil(this.onLogout),
            tap(() => this.removeNews([OnboardingService.noAssignmentAtAll])),
            filter(assignments => assignments.entities.length === 0)
        ).subscribe(() => {
            this.communicationCenter.getRoom('news').next('add', [OnboardingService.noAssignmentAtAll]);
        });
    }

    private getAssignmentsCreatedByCurrentUser$(filters: { [key: string]: any } = {}): Observable<DataCollection> {
        const filterOptions: CollectionOptionsInterface = {
            filter: {
                'assignator': this.currentUser.id,
                ...filters,
            },
        };

        return this.octopusConnect.paginatedLoadCollection('assignation_search', filterOptions).collectionObservable;
    }

    private emitLetsTryAssignmentNews(): void {
        // Only if the user as a free licence
        this.isUserHasFreeLicence$.pipe(
            filter(hasFreeLicence => hasFreeLicence),
            tap(() => this.removeNews([OnboardingService.letsTryAssignmentNews])),
        ).subscribe(() => {
            this.getAssignmentsCreatedByCurrentUser$({progress: 100}).pipe(
                take(1),
                filter(assignments => assignments.entities.length > 0),
            ).subscribe(() => {
                this.communicationCenter.getRoom('news').next('add', [OnboardingService.letsTryAssignmentNews]);
            });
        });
    }

    private emitMyActivitiesWelcomeNews(): void {
        this.communicationCenter.getRoom('news').next('add', [OnboardingService.myActivitiesWelcome]);
    }
}

