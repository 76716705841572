import {Component, Inject, OnInit} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-black-list-modal',
    templateUrl: './black-list-modal.component.html',
    styleUrls: ['./black-list-modal.component.scss']
})
export class BlackListModalComponent implements OnInit {

    public param: object;

    constructor(
        public dialogRef: MatDialogRef<BlackListModalComponent>,
        @Inject(MAT_DIALOG_DATA) private data: Object
    ) { }

    ngOnInit() {
        this.param = {
            browserName: this.data['userBrowser'].name,
            browserVersion: this.data['userBrowser'].version
        };
    }

}
