import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { GamificationService } from '../gamification.service';
import { Creature } from '../definitions';

@Component({
  selector: 'app-gallery-thumbnail',
  templateUrl: './gallery-thumbnail.component.html',
  styleUrls: ['./gallery-thumbnail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GalleryThumbnailComponent implements OnInit {

  @Input()
  creature: Creature;

  get userPoints(): any {
    return this.gamificationService.userPoints;
  }

  get imgToDisplay(): string {
    if (this.creature.creature.attributes.userImageFid) {
      return this.creature.creature.attributes.userImage;
    } else if (this.userPoints >= this.creature.creature.attributes.price) {
      return this.creature.creature.attributes['image-t'];
    } else {
      return this.creature.creature.attributes['image-b'];
    }
  }

  constructor(private gamificationService: GamificationService) { }

  ngOnInit(): void {
  }

  setAvatar(ev: MouseEvent): void {
    ev.stopPropagation();
    if (!this.creature.creature.attributes.userImageFid) {
      return;
    }
    this.gamificationService.setAvatar(this.creature).then(res => {
    }).catch(err => {
      console.log('Error setting avatar', err);

    });
  }

}
