import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {Observable} from 'rxjs';
import {AuthorizationService} from '@modules/authorization';
import {AsyncRules} from '@modules/groups-management/core/models/rules';
import {defaultLoginRoute} from '../../../../settings';
import {map} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class IsUserCanAccessUsersAdminFeaturesGuard  {
    constructor(private authorization: AuthorizationService, private router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


       return this.authorization.currentUserCan<Observable<boolean>>(AsyncRules.AccessInstitutionUsersManagement).pipe(
                map((isAuthorized) => {
                    if (!isAuthorized) {
                        this.router.navigate([defaultLoginRoute]);
                        return false;
                    }
                    return true;
                })
        );

    }

}
