import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarGenericComponent } from './progress-bar-generic.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        ProgressBarGenericComponent
    ],
    declarations: [ProgressBarGenericComponent]
})
export class ProgressBarGenericModule { }
