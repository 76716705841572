// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: relative;
}

.wall-item {
  width: 100%;
}

.walls-main-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.mc {
  flex: 1;
  position: relative;
}

.walls-container {
  padding: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: auto;
}

.header {
  background: rgba(48, 87, 146, 0.1);
  color: #305792;
  font-size: 22px;
  line-height: 75px;
  padding-left: 31px;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/ideas-wall/core/ideas-walls-list/ideas-walls-list.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,aAAA;EACA,WAAA;EACA,sBAAA;AACF;;AAEA;EACE,OAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,kBAAA;EACA,MAAA;EACA,SAAA;EACA,cAAA;AACF;;AAEA;EACE,kCAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;AACF","sourcesContent":[":host {\r\n  position: relative;\r\n}\r\n\r\n.wall-item {\r\n  width: 100%;\r\n}\r\n\r\n.walls-main-container {\r\n  display: flex;\r\n  width: 100%;\r\n  flex-direction: column;\r\n}\r\n\r\n.mc {\r\n  flex: 1;\r\n  position: relative;\r\n}\r\n\r\n.walls-container {\r\n  padding: 20px;\r\n  position: absolute;\r\n  top: 0;\r\n  bottom: 0;\r\n  overflow: auto;\r\n}\r\n\r\n.header {\r\n  background: rgba(48, 87, 146, 0.1);\r\n  color: #305792;\r\n  font-size: 22px;\r\n  line-height: 75px;\r\n  padding-left: 31px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
