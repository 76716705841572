import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-no-assignment',
    templateUrl: './no-assignment.component.html',
    styleUrls: ['./no-assignment.component.scss']
})
export class NoAssignmentComponent {
    constructor(public router: Router) {
    }

    public goToLessonsPage(): void {
        this.router.navigate(['/lessons/list/models']);
    }
}
