import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ModelSchema, Structures} from 'octopus-model';
import {
    brand,
    brandLogoSvg,
    defaultLoginRoute,
    defaultURL,
    modulesSettings,
} from '../../../../settings';

import {AuthenticationService} from '../authentication.service';
import {LayoutConfigService} from 'fuse-core/services/layout-config.service';
import {TranslateService} from '@ngx-translate/core';
import {fuseAnimations} from 'fuse-core/animations';


const settingsAuthStructure: ModelSchema = new ModelSchema({
    askForHelp: Structures.boolean(false),
    enableGAR: Structures.boolean(false),
    enableSSO: Structures.boolean(false),
    urlSSO: Structures.object(),
    displayLoginLogo: Structures.boolean(false),
    activeChangePasswordStrategy: Structures.boolean(false),
    validateEmailStrategyActivated: Structures.boolean(false),
});

@Component({
    selector: 'fuse-user-blocked',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class UnauthorizedComponent implements OnInit, OnDestroy {

    public brandLogoSvg = brandLogoSvg;
    public brand = brand;

    constructor(
        private layoutConfigService: LayoutConfigService,
        private router: Router,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private translate: TranslateService,
    ) {

    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {

    }


}
