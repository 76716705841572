
import {take, takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-edit-lesson',
  templateUrl: './edit-lesson.component.html'
})
export class EditLessonComponent implements OnInit, OnDestroy {
    public selectedLessonContent: DataEntity[] = [];
    public selectedLesson: DataEntity;
    private unsubscribeInTakeUntil = new Subject<void>();
    private corpusService: any;
    activity: any;
    currentActivityId: any;
    stepIndex: any;
    lessonId: any;
    params: any;
    forSublesson;

    title: string;

    constructor(
        private octopusConnect: OctopusConnectService,
        public lessonsService: LessonsService,
        public activitiesService: ActivitiesService,
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        public translate: TranslateService) {
    }

    ngOnInit(): any {
        this.forSublesson = true;
        this.route.queryParams.subscribe(params => {
            this.params = params;
            if (params['activityId']) {
                this.currentActivityId = params['activityId'];
            }
            if (params['stepIndex']) {
                this.stepIndex = params['stepIndex'];
            }
            if (params['lessonId']) {
                this.lessonId = params['lessonId'];
            }

        });

      if (this.corpusService && !this.corpusService.formats) { // get granule formats
          this.corpusService.loadFormats();
      }

      this.lessonsService.getLessonObs(this.lessonId ? this.lessonId : this.lessonsService.selectedLessonId).pipe(
          takeUntil(this.unsubscribeInTakeUntil))
          .subscribe((lesson: DataEntity) => this.updateCurrentLesson(lesson));

      this.lessonsService.onLessonUpdate.pipe(
          takeUntil(this.unsubscribeInTakeUntil))
          .subscribe((lesson: DataEntity) => this.updateCurrentLesson(lesson));
    }

    private updateCurrentLesson(entity: DataEntity): void {
        this.selectedLesson = entity;
        this.lessonsService.updateCurrentLesson(entity);
        this.title = this.selectedLesson.get('metadatas').title;
        const lessonContentArray = entity.get('reference');
        this.activitiesService
            .setActivitiesAfterLoaded(lessonContentArray, this.forSublesson).pipe(
            take(1))
            .subscribe((entities) => {
                if (this.forSublesson) {
                    entities.forEach((val, index) => {
                        this.selectedLessonContent[index] = val;
                    });

                    this.setLessonEdited();
                }
            });
    }

    private setLessonEdited(): void {
        if (!this.currentActivityId) {
            this.currentActivityId = this.selectedLessonContent[0].id;
        }
        const selectedActivity = this.selectedLessonContent.find((entity) => +entity.id === +this.currentActivityId);
        this.lessonsService.setCurrentActivity(selectedActivity);
        // this.lessonsService.setCurrentLessonContentEdited(this.selectedLessonContent);
        this.navigateToMainActivity(selectedActivity);
    }

    /**
     * navigate to the selected activity of lesson, click on the arrow in lesson editor
     * @param {DataEntity} row
     */
    public navigateToMainActivity(row: DataEntity): void {
        if (row) {
            const entityActivityFormat = this.activitiesService.activityFormats.find((formatEntity) => {
                // format can be an string (id) or an object {id:..,label..}, depend on format lesson or activity.
                const formatId = row.get('format').id ? row.get('format').id : row.get('format');
                return +formatEntity.id === +formatId;
            });
            if (entityActivityFormat && entityActivityFormat.get('label') === 'activity') {
                const tempLabel = row.get('metadatas').typology.label;
                this.navigateToActivity(row.id, tempLabel);
            }
            if (entityActivityFormat && entityActivityFormat.get('label') === 'lesson') {
                this.navigateToActivity(row.id, 'poll');
            }
        }

    }

    private navigateToActivity(id, url): void {
        if (this.stepIndex > 0) {
            // TODO Use a shared way to redirect to the editor
            this.router.navigate(['lessons', this.lessonId, 'edit', 'stepto', id, url], {queryParams: {lessonId: this.lessonId, activityId: id, stepIndex: this.stepIndex}});
        } else {
            this.router.navigate([id, url], {relativeTo: this.route, queryParams: {lessonId: this.lessonId, activityId: id, stepIndex: this.stepIndex}});
        }

    }

    /**
     * on exit lesson the route is not the same for all instance there s a default setting we can overide
     */
    public exit(): void {
        this.router.navigate(this.lessonsService.settings.routeOnExitLesson);
    }

    public nextActivity(): any {
        if (this.selectedLessonContent.length > 0 && +this.stepIndex < this.selectedLessonContent.length) {
            this.stepIndex = +this.stepIndex + 1;
            this.navigateToMainActivity(this.selectedLessonContent[this.stepIndex]);
        }
    }

    public previousActivity(): any {
        if (+this.stepIndex > 0) {
            this.stepIndex = +this.stepIndex - 1;
            this.navigateToMainActivity(this.selectedLessonContent[this.stepIndex]);
        }
    }

    private get settings(): any {
        if (this.lessonsService.settings && this.lessonsService.settings.lessonStep) {
            return this.lessonsService.settings.lessonStep.typeSteps[this.params.stepIndex];
        }

        return null;
    }

    public get stepTitle(): string {
        return this.settings && this.settings.label ? this.settings.label : '';
    }

    public get getIcon(): string {
        return this.settings && this.settings.icon ? this.settings.icon : '';
    }

    public stepIcon(index: any): string {
        let iconStep: string;

        switch (+index) {
            case 0:
                iconStep = 'activity-video';
                break;
            case 1:
                iconStep = 'how_to_vote';
                break;
            case 2:
                iconStep = 'activity-video';
                break;
            case 3:
                iconStep = 'how_to_vote';
                break;
            case 4:
                iconStep = 'decrypt';
                break;
            default:
                iconStep = 'error stepIndex';
                break;
        }
        return iconStep;
    }

    ngOnDestroy(): any {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

}
