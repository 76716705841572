import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DataEntity} from 'octopus-connect';
import {IdeasWallService} from '../ideas-wall.service';

@Component({
    selector: 'categories-view',
    templateUrl: './categories-view.component.html',
    styleUrls: ['./categories-view.component.scss']
})
export class CategoriesViewComponent implements OnInit, OnChanges {

    @Input('categories') categories: DataEntity[];
    @Input('ideas') ideas: DataEntity[];
    ideasByCategory: {[key: number]: DataEntity[]} = {};

    constructor(
        private wallsService: IdeasWallService,
    ) { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['ideas']) {
            if (this.ideas) {
                this.ideasByCategory = {};
                this.ideas
                    .filter(idea => idea.get('category').length > 0)
                    .forEach(idea => {
                        const category: number = idea.get('category')[0];

                        if (!this.ideasByCategory[category]) {
                            this.ideasByCategory[category] = [];
                        }

                        this.ideasByCategory[category].push(idea);
                    });
            }
        }
    }

    addCategory(): void {
        this.wallsService.createCategory();
    }

    get locked(): boolean {
        return this.wallsService.currentWall.get('locked') || this.wallsService.currentWall.get('locked_category');
    }
}
