import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CONTEST_BASE_ROUTE, CONTEST_TAB_ROUTES} from '@modules/contest/core/contest.routes';
import {ContestConfigurationService} from "@modules/contest/core/services/contest-configuration.service";
import {CommunicationCenterService} from "@modules/communication-center";
import {ContestService} from "@modules/contest";
import {DataEntity} from "octopus-connect";

@Component({
  selector: 'app-contest-tabs',
  templateUrl: './contest-tabs.component.html'
})
export class ContestTabsComponent implements OnInit {

    public matTabs: string[] = ["home", "lessons", "manage", "ranking"];
    contestId: string;
    private links: {[key: string]: string};

    constructor(
        private configuration: ContestConfigurationService,
        private contestService: ContestService,
        private communicationCenter: CommunicationCenterService,
        private router: Router, 
        private route: ActivatedRoute
    ) {
        this.links = this.buildLinks();

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.matTabs = this.configuration.contestDetailsTabs;
                }
            });
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.contestId = params['id'];
            this.contestService.checkAndOpenSurvey(this.contestId);
        });
    }

    private buildLinks() {
        const links = {};
        for (const [key, value] of Object.entries(CONTEST_TAB_ROUTES)) {
            links[key] = `${CONTEST_BASE_ROUTE}/${value}`;
        }
        return links;
    }

    public isActive(label: string): boolean {
        const link = this.links[label].replace(':id', this.contestId);
        return this.router.isActive(link, true);
    }
}
