import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {Router} from '@angular/router';
import {localizedDate} from '../../../../../shared/utils';
import {take, tap} from 'rxjs/operators';
import {InstitutionGroupService} from '../../services/institution-group.service';
import {AuthorizationService} from '@modules/authorization';
import {AsyncRules, SyncRules} from '@modules/groups-management/core/models/rules';
import {combineLatest, Observable} from 'rxjs';
import {InstitutionManagementRoutes} from '@modules/groups-management/core/institution-management/institution-management.routes';

@Component({
    selector: 'app-institution-information',
    templateUrl: './institution-information.component.html'
})
export class InstitutionInformationComponent implements OnInit, OnDestroy {

    // eslint-disable-next-line max-len
    public institutions: { id: number | string, groupName: string, created: string, adminCount: number, trainerCount: number, learnerCount: number, groupCount: 0, workgroupCount: 0, code: string, startDate: string, endDate: string, showInstCode: boolean, showClassCode: boolean } = {
        id: '',
        groupName: '',
        created: '',
        adminCount: 0,
        trainerCount: 0,
        learnerCount: 0,
        groupCount: 0,
        workgroupCount: 0,
        code: '',
        startDate: '',
        endDate: '',
        showInstCode: true,
        showClassCode: true
    };

    public classCodeEnabled = true;
    public hasAccessInstitutionUsersManagement: boolean;
    public isAllowedToChangeGroupsCode: boolean;

    constructor(
        private communicationCenter: CommunicationCenterService,
        private router: Router,
        private institutionGroupService: InstitutionGroupService,
        private authorization: AuthorizationService
    ) {
        this.setInfos();
    }

    private setInfos(): void {
        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('institutionList')
            .pipe(take(1))
            .subscribe((data: any[]) => {
                if (data.length > 0) {
                    this.institutions.id = data[0]?.id;
                    this.institutions.groupName = data[0]?.institutiongroupname;
                    this.institutions.created = data[0]?.created;
                    this.institutions.adminCount = data[0]?.userscounts?.directors ? data[0]?.userscounts?.directors : 0;
                    this.institutions.trainerCount = data[0]?.userscounts?.trainers ? data[0]?.userscounts?.trainers : 0;
                    this.institutions.learnerCount = data[0]?.userscounts?.learners ? data[0]?.userscounts?.learners : 0;
                    this.institutions.groupCount = data[0]?.userscounts?.groups ? data[0]?.userscounts?.groups : 0;
                    this.institutions.workgroupCount = data[0]?.userscounts?.workgroups ? data[0]?.userscounts?.workgroups : 0;
                    this.institutions.code = data[0]?.code ? data[0]?.code : '';
                    this.institutions.startDate = data[0]?.license?.startDate ? localizedDate(Number.parseInt(data[0].license.startDate)) : 'no-licence';
                    this.institutions.endDate = data[0]?.license?.endDate ? localizedDate(Number.parseInt(data[0].license.endDate)) : '';
                    this.institutions.showInstCode = !data[0]?.hideInstCode; // use show to respect logic toogle true false
                    this.institutions.showClassCode = !data[0]?.hideCode; // use show to respect logic toogle true false
                }
            });
    }

    ngOnInit(): void {
        this.authorization.currentUserCan<Observable<boolean>>(AsyncRules.AccessInstitutionUsersManagement)
            .subscribe((hasAccessInstitutionUsersManagement) => {
                this.hasAccessInstitutionUsersManagement = hasAccessInstitutionUsersManagement;
            });

        this.authorization.currentUserCan<Observable<boolean>>(AsyncRules.ActivateGroupsCode).pipe(
            tap((value) => this.isAllowedToChangeGroupsCode = value)
        ).subscribe();

    }

    ngOnDestroy(): void {
    }

    public navigateToAdmins(): void {
        this.router.navigate(['institution', 'multi', 'users']);
    }

    public navigateToTrainers(): void {
        this.router.navigate(['institution', 'multi', 'users']);
    }

    public navigateToLearners(): void {
        this.router.navigate(['groups', 'list', 'learners']);
    }

    // classroom
    public navigateToGroups(): void {
        this.router.navigate(['groups', 'list', 'groups']);
    }

    // groups
    public navigateToWorkgroups(): void {
        this.router.navigate(['groups', 'list', 'workgroups']);
    }

    /**
     * when user toogle the button we save if we hide or not the code
     * @param state : is code must be shown or not
     */
    public saveHideInstitutionState(state: boolean): void {
        this.institutions.showInstCode = state;
        this.institutionGroupService.setInstCode(this.institutions.id, !state);
    }

    /**
     *
     * @param state : new state checked or not checked equal show class code
     */
    public saveClassCodeStatus(state: boolean): void {
        this.institutions.showClassCode = state;
        this.institutionGroupService.toggleCodeClassStatus(this.institutions.id);
    }

    public isAllowedToChangeInstitutionCode(): boolean {
        return this.authorization.currentUserCan(SyncRules.ActivateInstitutionsCode);
    }

    public isAllowedToSeeAllGroups(): boolean {
        return this.authorization.currentUserCan(SyncRules.SeeAllGroup);
    }

    public isAllowedToSeeAllWorkGroups(): boolean {
        return this.authorization.currentUserCan(SyncRules.SeeAllWorkgroup);
    }
}
