import { Injectable } from '@angular/core';
import {CommunicationRoom} from './communication-room.class';

@Injectable()
export class CommunicationCenterService extends CommunicationRoom {

    /**
     * Rooms index
     * @type {{}}
     */
    private rooms: {[key: string]: CommunicationRoom} = {};

    /**
     * Created a new communication room, and register it
     * @param {string} roomId Name of the room
     * @returns {CommunicationRoom} The created communication room
     */
    private createRoom(roomId: string): CommunicationRoom {
        if (!this.rooms[roomId]) {
            const newRoom: CommunicationRoom = new CommunicationRoom();
            this.rooms[roomId] = newRoom;
            return newRoom;
        }
    }

    /**
     * Gets the communication room by if
     * @param {string} roomId Room id
     * @returns {CommunicationRoom} The selected communication room
     */
    getRoom(roomId: string): CommunicationRoom {
        this.createRoom(roomId);
        return this.rooms[roomId];
    }
}
