import {Component, Input, OnInit} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {Params, Router} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {AuthenticationService} from '@modules/authentication/core/authentication.service';

interface TooltipSettings {
    queryParams: Params | null;
    lessonId: number | string;
    assignmentId: number | string;
    tooltipY: number | string;
    tooltipX: number | string;
    type: any;
    title: number | string;
    coeff: number | string;
    learnerSelected: any;
}

@Component({
    selector: 'info-graph',
    templateUrl: './info-graph.component.html',
    styleUrls: ['./info-graph.component.scss']
})
export class InfoGraphComponent implements OnInit {
    @Input('infoSettings') infoSettings: ReplaySubject<TooltipSettings>;
    public settingsTooltip: TooltipSettings;

    constructor(private router: Router,
                private communicationCenter: CommunicationCenterService,
                private authService: AuthenticationService) {
    }

    public get isAtLeastTrainer(): boolean {
        return this.authService.isAtLeastTrainer();
    }

    ngOnInit(): any {
        this.infoSettings.subscribe((data) => {
            this.settingsTooltip = data;
        });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('current')
            .subscribe((data) => {
                if (data !== null) {
                    this.router.navigate(['followed', 'assignment', 'lessons', this.settingsTooltip.lessonId, 'player']);
                }
            });
    }

    public lauchAssignment(): void {
        this.communicationCenter
            .getRoom('assignment')
            .next('launch', this.settingsTooltip.assignmentId);
    }

    public navigateToAssignmentList(): void {
        this.router.navigate(['followed', 'list'], {queryParams: this.settingsTooltip.queryParams});
    }
}
