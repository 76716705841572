import {Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {NewsInterface} from 'fuse-core/news/news.interface';
import {takeUntil} from 'rxjs/operators';
import {NewsService} from 'fuse-core/news/news.service';
import {Subject} from 'rxjs';
import {DashboardService} from '../../services/dashboard.service';

@Component({
    selector: 'app-widget-news',
    templateUrl: './widget-news.component.html',
})
export class WidgetNewsComponent implements OnInit, OnDestroy {
    public newsList: NewsInterface[] = [];

    @ViewChild('container', { read: ViewContainerRef, static: true }) private container: ViewContainerRef;

    private newsLimit = -1;
    private unsubscribeInTakeUntil = new Subject<void>();

    constructor(private newsService: NewsService,
                private dashboardService: DashboardService,
                private componentFactoryResolver: ComponentFactoryResolver) {
    }

    ngOnInit(): void {
        this.newsLimit = this.dashboardService.getNewsLimit();

        this.newsService.getNews$().pipe(
            takeUntil(this.unsubscribeInTakeUntil)
        ).subscribe(news => {
            this.newsList = news;
            this.refreshNews();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    private refreshNews(): void { this.container.clear();
        this.newsList.sort((a, b) => b.weight - a.weight) // trié du plus grand au plus petit
            .slice(0, this.newsLimit === -1 ? this.newsList.length : this.newsLimit)
            .forEach(news => {
                const componentFactory = this.componentFactoryResolver.resolveComponentFactory(<any>news.component);
                this.container.createComponent(componentFactory);
            });
    }
}
