import {Component, Input, OnInit} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {FormDataModel} from "@modules/account-management/core/model/form-data.model";

@Component({
    selector: 'fuse-mail-confirm',
    templateUrl: './mail-confirm.component.html',
    styleUrls: ['./mail-confirm.component.scss'],
    animations: fuseAnimations
})
export class FuseMailConfirmComponent implements OnInit {
    formTitle: string;
    contentText: string[];
    formRedirection: string;
    formRedirectionLink: string;

    @Input('data') formData: FormDataModel;

    ngOnInit() {
        this.formTitle = this.formData.title;
        this.contentText = this.formData.content;
        this.formRedirection = this.formData.redirection;
        this.formRedirectionLink = this.formData.redirectionLink;
    }
}
