import {Component, Input, OnInit} from '@angular/core';
import {DetailsData} from '@modules/graph-assignation/core/progression/details-list/details-data';

@Component({
    selector: 'app-details-list',
    templateUrl: './details-list.component.html',
})
export class DetailsListComponent implements OnInit {

    @Input() data: DetailsData = null;
    displayedColumns: string[];

    constructor() {
    }

    public ngOnInit(): void {
        this.displayedColumns = this.data.headers.map(h => h.id);
    }
}
