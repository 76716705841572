import { takeUntil } from 'rxjs/operators';
import { GamificationService } from './../../@modules/gamification/core/gamification.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {HeaderNavigationService} from '../service/header-navigation.service';
import {Subject} from 'rxjs';
import {brand, modulesSettings, langs, brandLogoSvg, tralalereBar} from '../../settings';
import {ModelSchema, Structures} from 'octopus-model';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash-es';
import {Router} from '@angular/router';

const settingsToolbar: ModelSchema = new ModelSchema({
    helpMenu: Structures.object({default: true}),
    showWebAppBtn: Structures.boolean(false),
    translationAccess: Structures.object({show: false, withFlag: true}),
    notificationButtonAccess: Structures.boolean(),
    showTechnicalProblemMessage: Structures.boolean(false)
});

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

    private $destroy = new Subject<void>();

    public canBack = false;
    public level = 0;
    public coins = 0;
    public settings: { [key: string]: any };
    public showTranslateMenu: boolean;
    public showFlagImg: boolean;
    public langs: object[] = langs;
    public languages: any;
    public navigation: any;
    public selectedLanguage: any;
    public brand = brand;
    public isConnectPage = false;

    public back(): void {
        if (!this.isConnectPage){
            this.headerNavService.back();
        } else {
            this.router.navigate(['/profile/show-profile']);
        }

    }

    public toggleMenu(): void {
        this.communicationCenter.getRoom('mainMenu').next('toggle', null);
    }

    ngOnDestroy(): void {
        this.$destroy.next();
        this.$destroy.complete();
    }

    ngOnInit(): void {
        this.showTranslateMenu = this.settings.translationAccess.show;
        this.showFlagImg = this.settings.translationAccess.withFlag;
        this.headerNavService.backAvailabilityChanged.subscribe((value) => {
            this.canBack = value;
        });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {'id': this._translateService.currentLang});

        this.communicationCenter
            .getRoom('gamification')
            .getSubject('levelData')
            .pipe(takeUntil(this.$destroy))
            .subscribe((data: {points: number, level: number}) => {
                this.coins = data.points;
                this.level = data.level;
            });
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;
        localStorage.setItem('lang', lang.id);

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    setIsConnectPage(): void{
        if (this.router.url === '/connect') {
            this.isConnectPage = true;
        } else {
            this.isConnectPage = false;
        }
    }

    constructor(
        private headerNavService: HeaderNavigationService,
        private communicationCenter: CommunicationCenterService,
        private gamificationService: GamificationService,
        private _translateService: TranslateService,
        private router: Router,
    ) {
        this.settings = settingsToolbar.filterModel(modulesSettings.featureAccess);
        this.languages = this.langs;
        // check if it is the connect page, to only display back button
        this.setIsConnectPage();
        router.events.subscribe((val) => {
            this.setIsConnectPage();
        });
    }
}
