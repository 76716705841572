import {Directive, ElementRef, Renderer2, AfterViewInit, OnChanges, SimpleChanges, Input} from '@angular/core';
import {Subject} from 'rxjs';
import {AutoUnsubscribeTakeUntilClass} from '../../../app/shared/models';
import {takeUntil} from 'rxjs/operators';

@Directive({
    selector: '[tabOrder]'
})
/**
 * this directive change the tab index of 'a' || tagName === 'button' || tagName === 'mat-checkbox' || tagName === 'input' || tagName === 'mat-select'
 * in regard of the real position of the parent element in the html
 * use to have tab in good order even if we have change order with css order: 1 order: 2 etc...
 * the directive must be in the same html level for each field parent
 * be carefful checkbox target is in reality input not add mat-checkbox to list of tagName
 */
export class TabOrderDirective extends AutoUnsubscribeTakeUntilClass implements AfterViewInit {
    @Input() updateTabOrder: Subject<boolean>;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
        super();
    }

    ngAfterViewInit() {
        this.setTabOrder();
        // listen from an update forced by client=> use if html change dynamicly adding new element to recalcul
        // taborder
        if (this.updateTabOrder) {
            this.updateTabOrder.pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe(() => {
                this.setTabOrder();
            });
        }
    }

    /**
     * set the tab order we push it only children actualy but if needed
     * we could add tabindex to parent too here like this :
     * this.renderer.setAttribute(this.elementRef.nativeElement, 'tabindex', tabIndex.toString());
     * before calling the to child method
     * @private
     */
    private setTabOrder(): void {
        const orderValue = window.getComputedStyle(this.elementRef.nativeElement).order;
        const tabIndex = parseInt(orderValue, 10) || 0;
        this.setTabIndexForChildren(tabIndex);
    }

    /**
     * set tabindex on input select elements etc.. inside parent element use to determine position
     */
    private setTabIndexForChildren(tabIndex: number) {
        const children = this.elementRef.nativeElement.getElementsByTagName('*');
        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            const tagName = child.tagName.toLowerCase();
            if (tagName === 'a' || tagName === 'button' || tagName === 'input' || tagName === 'mat-select') {
                this.renderer.setAttribute(child, 'tabindex', tabIndex.toString());
            }
        }
    }
}
