
import {takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {DatacardService} from '../../../../../../shared/datacard.service';
import {OctopusConnectService} from 'octopus-connect';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Subject} from 'rxjs';
import {LessonsCardService} from '@modules/activities/core/lessons/services/lessons-card.service';

@Component({
    selector: 'app-lessons-consulted',
    templateUrl: './lessons-consulted.component.html'
})
export class LessonsConsultedComponent implements OnInit, OnDestroy {
    public dataCards: any[] = [];

    private type = 'currentUser';
    private unsubscribeInTakeUntil = new Subject<void>();

    constructor(
        public lessonsService: LessonsService,
        private activitiesService: ActivitiesService,
        private dialog: MatDialog,
        private datacardService: DatacardService,
        private octopusConnect: OctopusConnectService,
        private lessonsCardService: LessonsCardService,
    ) {
    }

    ngOnInit(): void {
        const callbacks = this.lessonsCardService.getCallbacks();
        this.lessonsService.loadConsultedLessons().pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(resources => {
                this.dataCards = this.datacardService.processResources(resources.slice(0, 6), callbacks, this.type);
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }
}
