export const achievements = {
    isOnboardingEnabled: true,
    navLinks: {
        default: [{
            path: 'my-profile',
            keyTranslate: 'achievement.my_profile',
        },
            {
                path: 'my-city',
                keyTranslate: 'achievement.my_city',
            },
            {
                path: 'leaderboard',
                keyTranslate: 'achievement.leaderboard',
            }
        ],
        gar: [{
            path: 'my-profile',
            keyTranslate: 'achievement.my_profile',
        },
            {
                path: 'my-city',
                keyTranslate: 'achievement.my_city',
            }
        ]
    },
    isOpenBadgeActive: {default: true, gar: false},
};
