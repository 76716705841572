import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {LayoutConfigService} from 'fuse-core/services/layout-config.service';
import {TralaTranslationLoaderService} from '../../../../trala-translation-loader.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AccountManagementProviderService} from '@modules/account-management/core/account-management-provider.service';
import {RequestSubscribeFormProfile} from '@modules/account-management/core/form-profil.class';
import {fuseAnimations} from 'fuse-core/animations';
import {brand, langs, brandLogoSvg, captcha} from '../../../../settings';
import {Subscription} from 'rxjs';
import {ReCaptchaV3Service} from 'ng-recaptcha';

@Component({
  selector: 'app-request-subscribe',
  templateUrl: './request-subscribe.component.html',
  styleUrls: ['./request-subscribe.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations : fuseAnimations
})
export class FuseRequestSubscribeComponent implements OnInit {

  requestSubscribeForm: UntypedFormGroup;
  requestSubscribeFormErrors: any;
  termsNotAccepted: boolean;
  public captcha = captcha;
  public captchaPassed: boolean;

  userInformation: RequestSubscribeFormProfile = new RequestSubscribeFormProfile({});

  public brand = brand;
  public brandLogoSvg = brandLogoSvg;

  public formData: any = {
      title: 'account-management.subscribe_request_confirm_title',
      content: ['account-management.subscribe_request_confirm_first_sentence'],
      redirection: 'generic.back_to_authentication',
      redirectionLink: '/login'
  };

  public requestValidate: boolean;

  private singleExecutionSubscription: Subscription;

  constructor(
    private layoutConfig: LayoutConfigService,
    private formBuilder: UntypedFormBuilder,
    public accountProvider: AccountManagementProviderService,
    private translationLoader: TralaTranslationLoaderService,
    private recaptchaV3Service: ReCaptchaV3Service,
  )
  {
    const defaultLang: string[] = langs.map((lang) => lang.id);
    this.translationLoader.loadTranslations(...defaultLang);


    this.requestSubscribeFormErrors = {
      name        : {},
      last_name        : {},
      you_are        : {},
      email          : {},
      terms: {},
      city: {},
      academy: {},
      captcha: {},
    };
  }


  ngOnInit()
  {
    this.requestValidate = false;

    this.requestSubscribeForm = this.formBuilder.group({
      name          : ['', Validators.required],
      last_name          : ['', Validators.required],
      city          : ['', Validators.required],
      email          : ['', [Validators.required, Validators.email]],
      you_are          : ['', Validators.required],
      terms: [false, Validators.requiredTrue],
      academy          : ['', Validators.required],
      captcha: ['', Validators.required]
    });

    this.requestSubscribeForm.valueChanges.subscribe(() => {
      this.requestSubscribeFormValuesChanged();
    });


  }

  requestSubscribeFormValuesChanged()
  {
    for ( const field in this.requestSubscribeFormErrors )
    {
      if ( !this.requestSubscribeFormErrors.hasOwnProperty(field))
      {
        continue;
      }

      // Clear previous errors
      this.requestSubscribeFormErrors[field] = {};

      // Get the control
      const control = this.requestSubscribeForm.get(field);

      if (!this.requestSubscribeForm.get('terms').errors){
        this.termsNotAccepted = false;
      }

      if ( control && control.dirty && !control.valid )
      {

        this.requestSubscribeFormErrors[field] = control.errors;
      }
    }

    if (!this.validateField( this.requestSubscribeForm.value.pseudo )){
      const controlPseudo = this.requestSubscribeForm.get('pseudo');
      controlPseudo.setErrors({invalid: true});
    }
  }

  newRegister() {

    if (this.requestSubscribeForm.get('terms').errors) {
      this.termsNotAccepted = true;

    }

    if (!this.requestSubscribeForm.invalid){
      const message = {
        name: this.requestSubscribeForm.value.name,
        email: this.requestSubscribeForm.value.email,
        last_name: this.requestSubscribeForm.value.last_name,
        you_are: this.requestSubscribeForm.value.you_are,
        academy: this.requestSubscribeForm.value.academy,
        city: this.requestSubscribeForm.value.city
      };

      let captchaOk = false;

      switch (this.captcha) {
        case 'recaptcha':
          if (this.singleExecutionSubscription) {
            this.singleExecutionSubscription.unsubscribe();
          }
          this.singleExecutionSubscription = this.recaptchaV3Service.execute('requestForm')
              .subscribe((token) => {
                    this.accountProvider.verifyToken(token).subscribe(verifyResult => {
                      // if score >= 0.7 (0 = bot -> 1 = human)
                      // (google api is returning success: boolean, challenge_ts: string, hostname: string, score: number, action: string)
                      if (verifyResult['data'][0]['score'] >= 0.7) {
                        captchaOk = true;
                        this.captchaPassed = true;
                        captchaOk = true;
                      } else {
                        this.captchaPassed = false;
                      }
                    });
                  }
              );
          break;

        case 'hcaptcha':
          if (this.requestSubscribeForm.get('captcha').status === 'VALID' && this.requestSubscribeForm.get('captcha').value !== ''){
            captchaOk = true;
            this.captchaPassed = true;
          } else {
            this.captchaPassed = false;
          }
          break;

        default:
          console.log('no captcha in setting');
      }

      if (captchaOk === true){
        this.accountProvider.sendMessage(message, this.getFormConfirmation.bind(this));
      }

    }
  }

  private validateField(field){
    const re = /^[A-Za-z0-9-'_.]+$/;
    return re.test(String(field).toLowerCase());
  }

  private getFormConfirmation(): void{
    this.requestValidate =  true;
  }

}
