import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-multi-tabs-view',
  templateUrl: './multi-tabs-view.component.html'
})
export class MultiTabsViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
