import {Component, inject} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {distinctUntilChanged, map, startWith, switchMap, tap} from 'rxjs/operators';
import {BasicPageService, PageAttributes} from 'fuse-core/components/basic-page/basic-page.service';
import {combineLatest} from 'rxjs';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {brand} from '../../../../app/settings';

@Component({
    selector: 'app-basic-page-list-as-cards',
    templateUrl: './basic-page-list-as-cards.component.html',
    styleUrls: ['./basic-page-list-as-cards.component.scss']
})
export class BasicPageListAsCardsComponent {
    private route = inject(ActivatedRoute);
    private basicPageService = inject(BasicPageService);
    private sanitized = inject(DomSanitizer);
    private translate = inject(TranslateService);
    private language$ = this.translate.onLangChange.pipe(
        distinctUntilChanged(),
        startWith([]), // histoire d'avoir une valeur par defaut pour le premier chargement
    );

    public alias$ = this.route.paramMap.pipe(
        map(params => params.get('alias'))
    );
    public page$ = combineLatest(
        [
            this.language$,
            this.alias$
        ]
    ).pipe(
        switchMap(([_lang, alias]) => this.basicPageService.loadPage(alias)),
    );

    public downloadDocumentLink(card: PageAttributes): string {
        if (!!card.document) {
            return card.document.toString();
        }

        return null;
    }


    public getImageSrc(index: number): string {
        return `assets/${brand}/images/backgrounds/basic-page-as-card-image-${index}.svg`;
    }
}
