export class CorpusRessourcesTypes {
    static TEXT = 'text';
    static URL = 'url';
    static VIDEO_URL = 'videoUrl';
    static MEDIA = 'media';
    static AUDIO = 'audio';
    static VIDEO = 'video';
    static DOCUMENT = 'document';
    static IMAGE = 'image';
    static DIVIDER = 'divider';
}

export const endpointsByFormatName = {
    text: 'corpus-text-ressource',
    url: 'corpus-url-ressource'
};
