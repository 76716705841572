import {Component, Input} from '@angular/core';

@Component({
  selector: 'register-redirect',
  templateUrl: './register-redirect.component.html',
  styleUrls: ['./register-redirect.component.scss']
})
export class RegisterRedirectComponent {
    @Input() redirect: { url: string, target: string };

    constructor() { }

    public redirectRegistration(): void {
        window.open(this.redirect.url, this.redirect.target);
    }
}
