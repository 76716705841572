export class SvgIcon {
    [key: string]: string
}

export const SVGICONTHEME: SvgIcon = {
    'allTypes': 'toustypes',
    'accessibility': 'accessibility',
    'archive': 'archiver',
    'arrow_left_circle_outline': 'arrow_left_circle_outline',
    'arrow_right_circle_outline': 'arrow_right_circle_outline',
    'assignment_ind': 'assignment_ind',
    'brand': 'brand',
    'brand_square': 'brand',
    'check': 'check',
    'class_add': 'add',
    'close': 'cross',
    'contact': 'contact',
    'cross': 'cross',
    'cross_outline': 'cross_outline',
    'dashboard': 'heart',
    'delete': 'trash',
    'document': 'document',
    'drag_indicator': 'arrow_down_circle_outline',
    'duplicate': 'duplicate',
    'edit': 'edit',
    '_exit': 'minimize-2',
    'editor-play': 'play_outline',
    'false': 'false',
    'Faux': 'false',
    'feedback': 'contact',
    'file': 'corpus',
    'file_download': 'file_download',
    'file_text': 'file_text_outline',
    'fill-in-blanks': 'remplir_blancs',
    'for_further': 'for_further',
    'groups-form-add': 'check',
    'help': 'help',
    'icon-arrow-back': 'arrow_back',
    'icon_pdf': 'icon_pdf',
    'image__button-fullscreen': 'fullscreen',
    'institution_add': 'add',
    'mat-card-actions__icon-menu-trigger': 'chevron-up',
    'movies': 'movies_outline',
    'memory-back-card': 'memory',
    'qcm__register__null': 'true',
    'qcm__register__pristine': 'true',
    'qcm__register__incorrect': 'false',
    'qcm__register__not-correct': 'false',
    'lessons': 'transition',
    'link': 'url',
    'lock': 'lock',
    'pdf': 'pdf',
    'pdf_full_name': 'pdf_full_name',
    'play': 'arrow_right',
    'play-in-class': 'play',
    'round-videoprojeter': 'round_videoprojeter',
    'round-videoprojeteroff': 'round_videoprojeteroff',
    'save': 'save',
    'shield': 'confi',
    'sound_off': 'sound_off',
    'sound_on': 'sound_on',
    'step-done': 'step_done',
    'step-inprogress': 'step_inprogress',
    'step-pasfait': 'step_pasfait',
    'student_add': 'check_circle_outline',
    'tools-circle': 'cube_outline_circle',
    'trainer_add': 'add',
    'transition': 'transition',
    'trash': 'trash',
    'true': 'true',
    'unarchive': 'desarchiver',
    'unassign': 'unassign',
    'unlock': 'unlock',
    'video': 'video',
    'visibility': 'visibility',
    'visibility_cgurgpd': 'cgu',
    'visibility_legalnotice': 'visibility',
    'visibility_privacy': 'privacy',
    'Vrai': 'true',
    'workgroup_add': 'add',
    'xxx': 'dislike',
    'arrow_right':'arrow_right',
    'arrow_left':'arrow_left',
    'equalizer_1': 'equalizer_1',
    'equalizer_2': 'equalizer_2',
    'equalizer_3': 'equalizer_3',
    'equalizer_4': 'equalizer_4',
    'bookmark-added': 'bookmark_added'
};