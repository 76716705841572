import {UserDrawingInterface} from './user-drawing.interface';
import * as paper from 'paper';

export class UserDrawing implements UserDrawingInterface {
    public angles: paper.Path.Circle[] = [];
    public end: paper.Path.Circle = null;
    public path: paper.Path = null;
    public start: paper.Path.Circle = null;
    public lastPoint: paper.Point;

    constructor(options: Partial<UserDrawingInterface>) {
        Object.keys(options).forEach(k => this[k] = options[k]);
    }

    public apply(operation: (item: paper.Path) => void): this {
        operation(this.path);
        operation(this.start);
        operation(this.end);
        this.angles.forEach(a => operation(a));

        return this;
    }

    public clone(): UserDrawing {
        return new UserDrawing({
            angles: this.angles.map(a => a.clone()),
            end: this.end.clone(),
            path: this.path.clone(),
            start: this.start.clone()
        });
    }
}