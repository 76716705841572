import { Injectable } from '@angular/core';

@Injectable()
export class WidgetGroupService {

    constructor(
    ) {
    }

}
