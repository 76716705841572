
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from 'app/settings';
import {localizedDate} from '../../../shared/utils';


const settingsLicensingSchema: ModelSchema = new ModelSchema({
    visible: Structures.boolean(false),
    restrict: Structures.array()
});

@Injectable({
    providedIn: 'root'
})

export class LicensingService {
    private settings: {[key: string]: any;};

    constructor(private octopusConnect: OctopusConnectService, private communicationCenter: CommunicationCenterService) {
        this.settings = settingsLicensingSchema.filterModel(modulesSettings.licensing);
        this.communicationCenter.getRoom('authentication').getSubject('userData').subscribe((data) => {
            if (data) {
                this.postAuthentication();
            }
        });

    }
    public postAuthentication(): void {
        this.getMethods().subscribe((data) => {
            const licenses = [];
            for (const entity of data) {
                    licenses.push({
                        id: entity.get('access').id,
                        name: entity.get('access').name,
                        unlockedDate: localizedDate(entity.get('date')),
                        code: entity.get('code'),
                        expirationDate: localizedDate(entity.get('expirationDate')),
                        expired: entity.get('expired'),
                        uid: entity.get('uid')
                    });
            }

            this.communicationCenter.getRoom('licenses').next('methods', licenses.filter((entity) => {
                return !entity.expired;
            }));
        });

        this.communicationCenter.getRoom('licenses').next('settings', this.settings);
    }

    public addMethod(code: string, uid: string = null): Observable<DataEntity> {
        return this.octopusConnect.createEntity('licenses', {uid, code});
    }

    public syncSSOMethod(sso: string): Observable<DataEntity> {
        return this.octopusConnect.createEntity('licenses', {sso});
    }

    public getMethods(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('licenses').pipe(map(data => data.entities));
    }
}
