import {Component} from '@angular/core';
import {BaseActivityComponent} from '../base-activity.component';
import {Observable, of} from 'rxjs';

@Component({
    selector: 'app-divider',
    templateUrl: './divider.component.html'
})
export class DividerComponent extends BaseActivityComponent<any, any> {
    public resourceURI: string;
    public description: string;
    public filemime: string;

    public reference: any;

    protected setContentData(activityAttributes): void {
        this.reference = activityAttributes.metadatas;
        this.description = activityAttributes.metadatas.description;
        this.filemime = activityAttributes.reference.filemime;
        this.resourceURI = activityAttributes.reference.uri;
        this.activitiesService.saveUserSave(this.activity.id.toString(), this.activitiesService.currentAssignmentID, [], 1, 'divider-save');
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected  getGrade(): {oldGrade: number, newGrade: number} {
        throw new Error('Method not implemented.');
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }

    protected validate(): void {
        throw new Error('Method not implemented.');
    }
}
