import {Injectable} from '@angular/core';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import {CommunicationCenterService} from "@modules/communication-center";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ConditionsService {
    public conditionUpdate$ = new Subject<void>();

    constructor(
        private communicationCenter: CommunicationCenterService,
    ) {
        this.communicationCenter
            .getRoom('contextualUpdate')
            .getSubject('condition')
            .subscribe(() => {
                this.conditionUpdate$.next();
            });
    }

    public check$(conditionData: string): Observable<boolean> {
        if (conditionData) {
            const conditions = conditionData.split('&');

            return combineLatest(
                conditions.map((conditionString: string) => {
                    const [subject, condition, ...values] = conditionString.split('/');
        
                    return new Observable<boolean>((observer) => {
                        this.communicationCenter.getRoom('contextualConditions')
                            .next(subject, {condition, callback: (result: boolean) => {observer.next(result)}});
                    });
                })
            ).pipe(
                map((results) => {
                    return results.every((result) => result);
                })
            );
        }

        return of(true);
    }
}
