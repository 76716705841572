export const mainMenu = {
    displayMenu: {
        default: {
            level0: [
                "achievement",
                {
                    'name': 'followed-tab',
                    'translate': {
                        'parent': {
                            learner: 'assignment.title_my_activity',
                            default: 'assignment.title'
                        }
                    }
                },
                "onlyModels",
                "gamification",
                {
                    name: "how-to",
                    onlyForLocalizations: ['fr'],
                },
                "access-future-engineer",

                {
                    name: "contact-us",
                    class: ['menu-break'],
                },

                "mentions-legales",
                "cgurgpd",
                'politique-confidentialite'
            ],
        },
        trainer: {
            level0: [
                "onlyModels",
                {
                    'name': 'parameter',
                    'children': ['param-child-groups', 'param-child'],
                    'translate': {
                        'parent': {
                            learner: 'generic.my_classes_learner',
                            default: 'navigation.groups-management'
                        },
                        'childs': {
                            'param-child-groups': {
                                learner: 'generic.my_class',
                                default: 'groups-management.classes'
                            }
                        }
                    }
                },
                {
                    'name': 'followed-tab',
                    'translate': {
                        'parent': {
                            learner: 'assignment.title_my_activity',
                            default: 'assignment.title'
                        }
                    }
                },
                {
                    'name': 'how-to',
                    onlyForLocalizations: ['fr'],
                },
                {
                    'name': 'educational-sheets-as-cards',
                    onlyForLocalizations: ['fr'],
                    class: ['menu-break'],
                },
                "access-future-engineer",
                "contact-us",
                "mentions-legales",
                "cgurgpd",
                'politique-confidentialite'
            ],
        },
    },
};
