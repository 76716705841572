import {Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { ReplaceTextPipe } from '@fuse/pipes/replace-text.pipe';
import {TranslateService} from '@ngx-translate/core';

interface TimeSlotToStringOptions {
    language_key?: string;
}

const TO_REPLACE_KEY = '{timeSlot}';

@Directive({
    selector: '[appTimeSlotToString]',
})
export class TimeSlotToStringDirective implements OnChanges, OnInit {
    greetings = {
        ['00:00:00-11:59:59']: 'time_slot_to_string.{language_key}_good_morning',
        ['12:00:00-18:59:59']: 'time_slot_to_string.{language_key}_good_afternoon',
        ['19:00:00-23:59:59']: 'time_slot_to_string.{language_key}_good_evening',
    };

    @Input() appTimeSlotToString: TimeSlotToStringOptions = {
        language_key: null
    };

    constructor(
        private translate: TranslateService,
        private replaceTextPipe: ReplaceTextPipe,
        private element: ElementRef
    ) {}

    ngOnInit(): void {
        this.updateText();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateText();
    }

    updateText(): void {
        const currentDate = new Date();
        const oldValue = this.element.nativeElement.innerHTML;
        const triggeredTimeSlot = Object.keys(this.greetings).find((timeSlot) =>
            this.isBetween(currentDate, timeSlot)
        );

        if (!!triggeredTimeSlot) {
            const timeSlotKey = this.greetings[triggeredTimeSlot];
            const languageKey = this.appTimeSlotToString.language_key ? this.appTimeSlotToString.language_key : 'default';
            const timeSlotKeyWithLanguage = timeSlotKey.replace('{language_key}', languageKey);
            this.translate.get(timeSlotKeyWithLanguage).subscribe((translation: string) => {
                const newValue = this.replaceTextPipe.transform(oldValue, {
                    [TO_REPLACE_KEY]: translation,
                });
                this.element.nativeElement.innerHTML = newValue;
            });
        }
    }

    isBetween(toTestDate: Date, timeSlot: string): boolean {
        const [from, to] = timeSlot
            .split('-')
            .map((rawTime) => this.getSameDateButTime(toTestDate, rawTime));
        return from < toTestDate && to > toTestDate;
    }

    getSameDateButTime(targetDate: Date, time: string): Date {
        const cloneDate = new Date(targetDate.getTime());
        const [h, m, s] = time.split(':').map((str) => Number(str));
        cloneDate.setHours(h);
        cloneDate.setMinutes(m);
        cloneDate.setSeconds(s);
        return cloneDate;
    }
}
