// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-tags-selection-core .mat-table .mat-header-row {
  display: none;
}
app-tags-selection-core .mat-toolbar {
  min-height: initial;
}
app-tags-selection-core .toolbar-bottom {
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/tags-selection/tags-selection-core/tags-selection-core.component.scss"],"names":[],"mappings":"AAES;EACI,aAAA;AADb;AAKK;EACI,mBAAA;AAHT;AAMK;EACI,YAAA;AAJT","sourcesContent":["app-tags-selection-core {\r\n     .mat-table {\r\n         .mat-header-row {\r\n             display: none;\r\n         }\r\n     }\r\n\r\n     .mat-toolbar {\r\n         min-height: initial;\r\n     }\r\n\r\n     .toolbar-bottom {\r\n         height: auto;\r\n     }\r\n }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
