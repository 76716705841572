import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {DataEntity} from 'octopus-connect';
import {StepperPosition} from '@modules/activities/core/shared-components/basic-stepper-container/stepper.position';
import {CommunicationCenterService} from '@modules/communication-center';
import {tap} from 'rxjs/operators';

export enum LayoutAlign {
    'space-evenly',
    'center',
    'start'
}

export enum ArrowPosition {
    top,
    left,
    right,
    bottom,
}

export enum StepItemType {
    media,
    activity,
    divider
}

export interface StepItem {
    type: StepItemType;
    isActive: boolean;
    isArrowActive: boolean;
    isCurrent: boolean;
    encapsulatedObject: any;
}

export interface StepperOptions {
    steps: StepItem[];
    onStepItemClick?: Subject<DataEntity>;
    stepperLayout?: {
        align?: LayoutAlign;
        position?: StepperPosition;
        arrowPosition?: ArrowPosition;
    };
    recap?: {
        isActive?: boolean;
        isAllowed?: boolean;
        isArrowActive?: boolean;
    };
    isListFormatStepper?: boolean; // list format replace old format with only number one after one and click on it to navigate
}

@Component({
    selector: 'app-generic-stepper',
    templateUrl: './generic-stepper.component.html'
})
export class GenericStepperComponent implements OnInit {
    private isDisplayStepper = true;
    private isLockedStepper = false;

    @Input() set options(options: StepperOptions) {
        this._options = Object.assign(GenericStepperComponent.defaultOptions, options);
        this.selectedStep = this._options.steps.find(s => s.isCurrent);
    }

    @Input() lessonMode = false; // by default preview mode but in reality it depend if there is an assignment or not we need this info to apply specific css or not

    static defaultOptions: StepperOptions = {
        steps: [],
        stepperLayout: {
            align: LayoutAlign.start,
            arrowPosition: ArrowPosition.right,
        },
        recap: {
            isActive: false,
            isAllowed: false,
            isArrowActive: false
        },
    };

    private _options: StepperOptions;
    public selectedStep: StepItem;

    constructor(private communicationCenter: CommunicationCenterService) {
        this.communicationCenter
            .getRoom('stepper')
            .getSubject('display')
            .pipe(tap((isDisplayStepper: boolean) => this.isDisplayStepper = isDisplayStepper))
            .subscribe();

        this.communicationCenter
            .getRoom('stepper')
            .getSubject('locked')
            .pipe(tap((isLockedStepper: boolean) => this.isLockedStepper = isLockedStepper))
            .subscribe();
    }

    ngOnInit(): void {
    }

    public get displayStepper(): boolean {
        // do not display stepper if there is only 1 step
        return this.isDisplayStepper && this._options !== null && this._options.steps.length >= 1;
    }

    get options(): StepperOptions {
        return this._options;
    }

    public get layoutAlign(): string {
        return LayoutAlign[this.options.stepperLayout.align];
    }

    public get arrowPositionClass(): string {
        return ArrowPosition[this.options.stepperLayout.arrowPosition];
    }

    public get onlyActivitiesSteps(): StepItem[] {
        return this.options.steps.filter(s => s.type === StepItemType.activity);
    }

    public get layout(): string {
        return this.options.stepperLayout.arrowPosition === ArrowPosition.top || this.options.stepperLayout.arrowPosition === ArrowPosition.bottom ? 'row' : 'column';
    }

    public clickOnElem(step: StepItem): void {
        if (this.options.onStepItemClick && ((step && !step.isArrowActive) || !step)) {
            this.options.onStepItemClick.next(step ? step.encapsulatedObject : null);
        }
    }

    public getLabelToDisplay(step: StepItem): string {
        switch (step.type) {
            case StepItemType.media : {
                return 'M';
            }
            case StepItemType.divider : {
                return 'i';
            }
            default: { // Attention, on ne gère pas le type "recap" car il est en dur coté html
                return (this.onlyActivitiesSteps.indexOf(step) + 1).toString();
            }
        }
    }

    /**
     * return the last step allowed it mean it's the step just after the most advanced step of the steps who was already done
     */
    public lastStepAllowed(): number {
        return this.options.steps.filter(s => s.isActive === true).length > 0 ? this.options.steps.filter(s => s.isActive === true).length : 0;
    }

    /**
     * go to previous activity
     */
    previous(): void {
        if (this.currentIndex() > 0) {
            this.selectedStep = this._options.steps[this.currentIndex() - 1];
            this.options.onStepItemClick.next(this.selectedStep.encapsulatedObject);
        }
    }

    /**
     * go to next activity
     */
    next(): void {
        if (this.currentIndex() < this._options.steps.length - 1) {
            this.selectedStep = this._options.steps[this.currentIndex() + 1];
            this.options.onStepItemClick.next(this.selectedStep.encapsulatedObject);
        }
    }

    currentIndex(): number {
        return this._options.steps.findIndex(s => s.isCurrent);
    }

    isPreviousVisible(): boolean {
        return this.currentIndex() > 0;
    }

    isNextVisible(): boolean {
        return this.currentIndex() < this._options.steps.length - 1;
    }

    stepTitle(step: StepItem): string {
        // TODO faire en sorte que encapsulatedObject soit toujours une DataEntity
        if (step.encapsulatedObject.attributes) {
            return step.encapsulatedObject.get('metadatas').title;
        }
        return step.encapsulatedObject.title;
    }

    /**
     * stepper navigation is locked
     */
    public get isLocked():boolean{
        return this.isLockedStepper;
    }
}

