import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ButtonHelpService} from "fuse-core/components/button-help/button-help.service";

@Component({
    selector: 'app-button-help',
    templateUrl: './button-help.component.html'
})
export class ButtonHelpComponent implements OnInit {

    constructor(
        private router: Router,
        private buttonHelpService: ButtonHelpService,
    ) {
    }

    ngOnInit(): void {
    }

    public openHelpContent(): void {
        this.buttonHelpService.openCurrentHelpPage();
    }

    public isDisplay(): boolean {
        return this.buttonHelpService.hasHelpPage();
    }
}
