import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Subject } from 'rxjs';
import {DataEntity} from 'octopus-connect';

@Injectable()
export class GroupsListingService
{
    onSelectedEntityChanged: BehaviorSubject<any> = new BehaviorSubject([]);
    onEntityDataChanged: BehaviorSubject<any> = new BehaviorSubject([]);
    onSearchTextChanged: Subject<any> = new Subject<void>();
    onFilterChanged: Subject<any> = new Subject<void>();

    entities: {[key: number]: DataEntity} = {};

    filterBy: string;

    constructor()
    {}
}
