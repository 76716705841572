import { MatIconRegistry } from '@angular/material/icon';
import { Injectable } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {brand} from '../../settings';
import {SVGICONDEFAULT} from '../default/svgIconDefaultList';
import {SVGICONTHEME} from '../cycle1tnecanope/svgIconThemeList';
import {SvgIconListBase} from '../default/svgIconList';

@Injectable()
export class SvgIconList extends SvgIconListBase {

    iconsSvgDefaultCollection = SVGICONDEFAULT;
    iconsSvgThemeCollection = SVGICONTHEME;

    constructor(
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer
    ) {
        super(matIconRegistry, domSanitizer);
        const pathIconSvg = 'assets/' + brand + '/icons/';
        this.iconRegister(this.mergeIconList(this.iconsSvgThemeCollection, this.iconsSvgDefaultCollection), pathIconSvg);
    }

}
