import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import {combineLatest, Observable, of} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';
import * as _ from 'lodash-es';
import {IGamecodeFormOptions} from '@modules/gamecode/core/gamecode.service';

interface IGamecodeDataInterface {
    title: string;
    associatedLessonId?: string | number;
}

interface ModalDataInterface {
    availableLessons$: Observable<DataEntity[]>;
    defaultValues: IGamecodeDataInterface;
    getLessonById: (lessonId: string | number) => Observable<DataEntity>;
    options: IGamecodeFormOptions;
    saveGamecode: (data: IGamecodeDataInterface) => Observable<DataEntity>;
}

@Component({
    selector: 'app-gamecode-data-editor-modal',
    templateUrl: './gamecode-data-editor-modal.component.html',
})
export class GamecodeDataEditorModalComponent implements OnInit {
    /**
     * Define if the component is currently loaded for initialization
     */
    public isInitializing = true;
    /**
     * Define if the component is currently in saving (should be used for temporally disable component)
     */
    public isSaving = false;

    /**
     * List of available lessons (not associated lessons and current note already associated lesson)
     */
    public lessons: DataEntity[] = [];

    /**
     * Reactive form used to receive and control user inputs
     */
    public noteForm: UntypedFormGroup;

    /**
     * Used to set or get the associated lesson
     */
    public selectedLesson: DataEntity = null;
    public availableLessonsAreLoading = true;

    public get lessonIsDisabled(): boolean {
        return this.isSaving || <boolean>_.get(this.modalData.options, 'associatedLessonId.disable', false);
    }

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) private modalData: ModalDataInterface,
        private communicationCenter: CommunicationCenterService,
        private dialog: MatDialog,
        private formBuilder: UntypedFormBuilder,
        public selfDialogRef: MatDialogRef<GamecodeDataEditorModalComponent>
    ) {
    }

    ngOnInit(): void {
        this.noteForm = this.formBuilder.group({
            title: [_.get(this.modalData, 'defaultValues.title', ''), Validators.required],
            application: [_.get(this.modalData, 'defaultValues.application', ''), Validators.required],
        });

        this.isInitializing = false;
    }

    /**
     * Save the gamecode. No need to know if it's a path or a creation.
     * @param value
     */
    public onSubmit(value: IGamecodeDataInterface): void {
        this.isSaving = true;
        this.noteForm.disable();

        this.modalData.saveGamecode(value).pipe(
            tap((dataEntity) => {
                this.selfDialogRef.close(dataEntity);
            }),
            take(1)
        ).subscribe();
    }
}
