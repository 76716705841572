import {Component, Input} from '@angular/core';

/**
 * component that open on click a pdf on another tab
 */
@Component({
    selector: 'app-read-pdf',
    templateUrl: './read-pdf.component.html'
})
export class ReadPdfComponent {

    @Input('url') pdfUrl = '';

    constructor() {
    }

    openPdf(): void {
        window.open(this.pdfUrl, '_blank');
    }
}
