import {EditableActivity, EditableActivityDefaultValues} from '@modules/activities/core/lessons/editor/models/editable-activity.class';
import {DataEntity} from 'octopus-connect';

type EditableToolActivityDefaultValues = (EditableActivityDefaultValues & { toolType: string });

export class EditableToolActivity extends EditableActivity {
    private _toolType: string;
    public set toolType(value: string) {
        this._toolType = value;
    }

    public get toolType(): string {
        if (!!this._toolType) {
            return this._toolType;
        }
        return this.granuleActivity
            && this.granuleActivity.get('reference')
            && this.granuleActivity.get('reference').config
            && this.granuleActivity.get('reference').config.tool;
    }

    constructor(args: DataEntity | EditableToolActivityDefaultValues) {
        super(args);

        if (args.type === 'DefaultValues') {
            this._toolType = (<EditableToolActivityDefaultValues>args).toolType;
        }
    }
}
