import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-progress',
    templateUrl: './progress.component.html'
})
/**
 * component that show level of learner and number of coins wins
 */
export class ProgressComponent implements OnInit {
    @Input() level: number = 0;
    @Input() coins: number = 0;
    @Input() img: string = 'coin';

    constructor() {
    }

    ngOnInit(): void {
    }

}
