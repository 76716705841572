import {ChangeDetectionStrategy, Component, HostBinding, inject, OnInit} from '@angular/core';
import {combineLatest, Observable, of, pipe, ReplaySubject, Subject, switchMap} from 'rxjs';
import {filter, map, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {LessonsService} from '@modules/activities';
import {LessonEntity, LessonReference, NavigateToLessonOptions} from '@modules/activities/core/models';
import {
    AutoUnsubscribeTakeUntilClass,
    TypedDataCollectionInterface,
    TypedDataEntityInterface
} from '../../../../../shared/models';
import {ChapterEntity, ChaptersService} from 'fuse-core/services/chapters.service';
import {usingLoading} from 'app/shared/rxjs';
import {CommunicationCenterService} from '@modules/communication-center';
import {UserDataEntity} from '@modules/authentication';
import {FilterData} from 'octopus-connect/lib/models/types';
import {DataEntity} from 'octopus-connect';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';


interface LocalAssignationAttributes {
    'assignated_node': {
        id: string | number,
    },
    'config': string,
}

interface LocalAssignationCollection extends TypedDataCollectionInterface<LocalAssignationAttributes> {
}

interface LocalAssignationEntity extends TypedDataEntityInterface<LocalAssignationAttributes> {
}

interface AssignationState extends TypedDataEntityInterface<{
    label: string
}> {
}

@Component({
    selector: 'app-lessons-in-chapters-list',
    templateUrl: './lessons-in-chapters-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LessonsInChaptersListComponent extends AutoUnsubscribeTakeUntilClass implements OnInit {
    @HostBinding('class') hostClass: string = 'english';
    
    public readonly parentPageUrl = '../..';
    public isLessonsLoading$ = new Subject<boolean>();
    public isChapterLoading$ = new Subject<boolean>();
    private lessonsService = inject(LessonsService);
    private chaptersService = inject(ChaptersService);
    private configurationService = inject(LessonsConfigurationService);
    private route = inject(ActivatedRoute);
    private communicationCenter = inject(CommunicationCenterService);
    private conf = inject(LessonsConfigurationService);
    private readonly routeParams$ = this.route.params.pipe(takeUntil(this.unsubscribeInTakeUntil));
    private readonly conceptId$: Observable<number> = this.routeParams$.pipe(
        map(params => params.conceptId)
    );
    private readonly educationalLevelId$: Observable<number> = this.routeParams$.pipe(
        map(params => params.gradeId)
    );
    private readonly chapterId$: Observable<number> = this.routeParams$.pipe(
        map(params => params.chapterId)
    );
    public readonly chapter$ = this.chapterId$.pipe(
        mergeMap(chapterId => this.loadChapter(chapterId)),
        usingLoading(this.isChapterLoading$)
    );
    public readonly chapterName$ = this.chapter$.pipe(map(chapter => chapter.get('name')));
    private readonly lessonsFilters$ = combineLatest([
        this.educationalLevelId$,
        this.conceptId$,
        this.chapterId$
    ]);
    public readonly lessons$ = this.lessonsFilters$.pipe(
        mergeMap(([educationalLevelId, conceptId, chapterId]) =>
            this.loadLessons(educationalLevelId, conceptId, chapterId)),
        usingLoading(this.isLessonsLoading$)
    );
    private readonly user$ = this.communicationCenter
        .getRoom('authentication')
        .getSubject<UserDataEntity>('userData')
        .pipe(
            filter(userData => !!userData),
        );
    private readonly assignationTypes$ = this.communicationCenter
        .getRoom('assignment')
        .getSubject<{
            id: string | number,
            label: string
        }[]>('assignationTypes');

    private readonly autoAssignmentType$ = this.assignationTypes$.pipe(
        map(assignationTypes =>
            assignationTypes.find(assignationType => assignationType.label === 'auto')
        ),
        filter(assignationType => !!assignationType),
    );

    private readonly assignmentStates$ = this.communicationCenter
        .getRoom('assignments')
        .getSubject<AssignationState[]>('statesList');

    private readonly assignatedState$ = this.assignmentStates$.pipe(
        map(assignatedStates =>
            assignatedStates.find(assignationState => assignationState.get('label') === 'assigned')
        ),
        filter(assignationState => !!assignationState),
    );

    public readonly lessonsWithAssignments$ = this.lessons$.pipe(
        mergeMap(lessons => combineLatest([
            of(lessons),
            this.user$,
            this.autoAssignmentType$,
        ])),
        mergeMap(([lessons, user, autoAssignmentType]) =>
            combineLatest(lessons.map(lesson =>
                    this.loadAutoAssignmentsForLessonsOfCurrentUser(lesson, user, autoAssignmentType)
                        .pipe(
                            map(assignments => ({
                                    lesson,
                                    assignment: assignments.entities.length > 0 ? assignments.entities[0] : null
                                })
                            )
                        )
                )
            )
        )
    );
    private readonly loadPaginatedAssignmentsCallBack$ = this.communicationCenter
        .getRoom('assignment')
        .getSubject<(filters: FilterData) => Observable<LocalAssignationCollection>>('loadPaginatedAssignmentsCallback');

    public getChildrenAsButtons(batch: {
        lesson: LessonEntity,
        assignment: LocalAssignationEntity
    }) {
        return batch.lesson.get('reference').map((child, index) => ({
            title: child.title,
            action: ($event: Event) => {
                $event.stopPropagation();
                this.openLesson(batch, index);
            },
            score: this.getLessonProgress(batch.assignment, child)
        }));

    }

    ngOnInit(): void {
        this.conceptId$.pipe(take(1)).subscribe(conceptId => {
            this.hostClass = this.conf.periodBackGroundToUse(conceptId.toString());
        });
    }

    private loadLessons(educationalLevelId: number, conceptId: number, chapterId: number): Observable<LessonEntity[]> {
        const optionsInterface = {
            filter: {
                level: educationalLevelId,
                chapters: chapterId,
                concepts: conceptId
            },
            page: 1, // On veut le début de la liste
            range: 50 // Et on espère qu'il n'y ai pas plus de 50 leçons
        };

        const allLessons = 'all'; // Je veux voir toutes les leçons
        const administrators = 6; // On se fait passer pour un learner (code legacy, je sais pas pourquoi)

        return this.lessonsService
            .loadPaginatedLessons(allLessons, [administrators], '', optionsInterface)
            .pipe(takeUntil(this.unsubscribeInTakeUntil));
    }

    private loadChapter(chapterId: number): Observable<ChapterEntity> {
        return this.chaptersService.getChapter(chapterId);
    }

    private createAssignment(lesson: LessonEntity) {
        return combineLatest([
            this.user$,
            this.autoAssignmentType$,
            this.assignatedState$,
        ]).pipe(
            map(([user, autoAssignmentType, assignedAssignmentState]) => ({
                assignated_node: lesson.id,
                assignated_user: user.id,
                assignator: user.id,
                dates: {value: 0, value2: 0},
                state_term: assignedAssignmentState.id,
                type_term: autoAssignmentType.id,
            })),
            mergeMap(createAssignmentData => this.communicationCenter
                .getRoom('assignment')
                .getSubject('createSimpleAssignment')
                .pipe(
                    mergeMap((createAssignmentFunction: (data) => Observable<DataEntity>) => createAssignmentFunction(createAssignmentData))
                )
            )
        );
    }

    private openLesson(batch: {
        lesson: LessonEntity;
        assignment: LocalAssignationEntity
    }, stepIndex) {
        const navigateToLessonOptions: NavigateToLessonOptions = {
            startOnStepIndex: stepIndex,
            exitLessonUrl: null
        };

        const assignment$ = !!batch.assignment ? of(batch.assignment) : this.createAssignment(batch.lesson);

        assignment$.pipe(
            take(1),
            map(assignment => this.lessonsService.loadAndNavigateToLessonFromAssignment(assignment, navigateToLessonOptions))
        ).subscribe();
    }

    private loadAutoAssignmentsForLessonsOfCurrentUser(lesson: LessonEntity, user: UserDataEntity, autoAssignmentType: {
        id: string | number
    }) {
        return this.loadPaginatedAssignmentsCallBack$.pipe(
            switchMap(callback => callback({
                assignated_user: user.id,
                assignments_type: autoAssignmentType.id,
                assignated_lesson_id: lesson.id
            }))
        );
    }
    
    private getLessonProgress(assignment: LocalAssignationEntity, child: LessonReference) {
        let progress = 0;
        
        if (assignment) {
            const config = JSON.parse(assignment.get('config'));
            
            if (config) {
                if (config[child.id]) {
                    progress = config[child.id].score;
                }
            }
        }
        
        return progress;
    }
}
