
import {take, mergeMap, map, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {modulesSettings} from '../../../settings';
import {
    IGlobalPeriod,
    IImage,
    IImagePeriod,
    IMove,
    IParamsZoneAround,
    IPeriod,
    ITimelineData,
    ITitle,
    ITitlePeriod,
    IZoneImage
} from '@modules/timeline/core/models/timeline-data.models';
import {IPositionAndStyle, IpositionInterval} from '@modules/timeline/core/models/position-and-style.models';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {ModelSchema, Structures} from 'octopus-model';
import {TranslateService} from '@ngx-translate/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {Router} from '@angular/router';
import {v4 as uuidv4} from 'uuid';

const settingsStructure: ModelSchema = new ModelSchema({
        activeGesture: Structures.boolean(false),
        isActiveCursorDate: Structures.boolean(false),
        showPeriodSeparator: Structures.boolean(true),
        showDotPeriodSeparator: Structures.boolean(true),
        showDotMiddlePeriodSeparator: Structures.boolean(false),
        isSeparatorShowOneInsteadOfZero: Structures.boolean(false)

    })
;

@Injectable({
    providedIn: 'root'
})
export class TimelineService {
    public fromTxt: string;
    public toTxt: string;
    public theseDays: string;
    public settings: { [key: string]: any };
    private arrayZoneImageValue: IZoneImage[] = [];
    private ajustementPositionPx: number = 0;
    /**
     * back data
     */
        // region getter for private data without setter
    private _timelineData: ITimelineData = {
        title: '', dynamic_date: {start_date: 0, end_date: 0}, dynamic_size: 8000,
        globalPeriod: [], titlePeriod: [], imagePeriod: []
    };

    onTimelineTempConsultIsDone: { [k: string]: ReplaySubject<void> } = {};

    get timelineData(): ITimelineData {
        return this._timelineData;
    }

    private _dataReady: boolean = false;
    get dataReady(): boolean {
        return this._dataReady;
    }

    private _maPosition: IMove = {position: 0, type: 'smooth', numeroImage: 0, ajustementPositionPx: 0};
    get maPosition(): IMove {
        return this._maPosition;
    }

    private _imageList: IImage [] = [];
    get imageList(): IImage [] {
        return this._imageList;
    }

    private _paramsZone: IParamsZoneAround = {width: 0, startBackgroundCss: '', endBackgroundCss: ''};
    get paramsZone(): IParamsZoneAround {
        return this._paramsZone;
    }

    private _arrayPeriodeColor: IPositionAndStyle[] = [];
    get arrayPeriodeColor(): IPositionAndStyle[] {
        return this._arrayPeriodeColor;
    }

    private _intervalSize: number = 500;
    private _firstInterval: number = -3500;
    private _positionIntervals: IpositionInterval[] = [];
    get positionIntervals(): { year: number, left: number }[] {
        return this._positionIntervals;
    }

    public _positionSeparateurZone: number[] = [];
    get positionSeparateurZone(): number[] {
        return this._positionSeparateurZone;
    }

    private _arrayDot: number[] = [];
    get arrayDot(): number[] {
        return this._arrayDot;
    }

    private _arrayMiddleDot: number[] = [];
    get arrayMiddleDot(): number[] {
        return this._arrayMiddleDot;
    }


    private _title: ITitle = {mainTitle: '', titledotPeriod: '', titleImage: '', titleHistoryPeriod: '', dates: ''};
    get title(): ITitle {
        return this._title;
    }

    private _currentSliderValue: number = 0;
    get currentSliderValue(): number {
        return this._currentSliderValue;
    }

    // endregion

    constructor(private communicationCenter: CommunicationCenterService,
                private dialog: MatDialog,
                private octopusConnect: OctopusConnectService,
                private router: Router,
                private translate: TranslateService,
    ) {
        this.settings = settingsStructure.filterModel(modulesSettings.timeline);

        this.communicationCenter.getRoom('timeline')
            .getSubject('execute')
            .pipe(
                mergeMap((data: { onComplete: ReplaySubject<void> }) => {
                    const onCompleteSubject = new ReplaySubject<void>(1);
                    const onCompleteSubjectId: string = uuidv4();
                    this.onTimelineTempConsultIsDone[onCompleteSubjectId] = onCompleteSubject;
                    this.router.navigate(['timeline'], {queryParams: {onComplete: onCompleteSubjectId}, skipLocationChange: false});
                    return onCompleteSubject.pipe(
                        tap(() => data.onComplete.next())
                    );
                })
            ).subscribe();
    }

    /**
     * reset all value to default value
     */
    private resetToDefaultValue(): void {
        this._timelineData = {
            title: '', dynamic_date: {start_date: 0, end_date: 0}, dynamic_size: 8000,
            globalPeriod: [], titlePeriod: [], imagePeriod: []
        };
        this._dataReady = false;
        this._maPosition = {position: 0, type: 'smooth', numeroImage: 0, ajustementPositionPx: 0};
        this._imageList = [];
        this._paramsZone = {width: 0, startBackgroundCss: '', endBackgroundCss: ''};
        this._arrayPeriodeColor = [];
        this._positionIntervals = [];
        this._arrayMiddleDot = [];
        this._positionSeparateurZone = [];
        this._arrayDot = [];
        this._title = {mainTitle: '', titledotPeriod: '', titleImage: '', titleHistoryPeriod: '', dates: ''};
    }

    /**
     * get data from timeline by id
     * @param id : id of the timeline
     */
    public getTimelineData(id: number): Observable<DataEntity> {
        return this.octopusConnect.loadEntity('timeline', id);
    }

    /**
     * set all the data need from back in the front object :
     * @param data : all data from back for managing timeline
     */
    private initTimeLineData(data: DataEntity): void {
        const allPeriodData = data.get('period');
        this.setInterval(data);
        this.setGlobalPeriodData(allPeriodData);
        this.setDotPipePeriod(allPeriodData);
        this.setImagePeriodData(allPeriodData);
        // to always launch after this.setImagePeriodData()
        this.setCommonTimelineData(data);
        this._currentSliderValue = this.timelineData.dynamic_date.start_date;
    }

    /**
     * interval_size is the time beetwen two vertical line on stepper
     * first_interval is the begin of the line for begin on a round value not at the most left position
     * @param data data from back
     */
    private setInterval(data: DataEntity): void {
        if (data.get('intervalSize')) {
            this._intervalSize = +data.get('interval_size');
        }
        if (data.get('firstInterval')) {
            this._firstInterval = +data.get('first_interval');
        }
    }

    /**
     * set the common data of all period of the timeline in the object of type ITimelineData
     * @param data : all data from back for managing timeline
     */
    private setCommonTimelineData(data: DataEntity): void {
        this._timelineData.title = data.get('title');
        this._timelineData.dynamic_date = data.get('dynamic_date');

        // the dynamic size back data take all picture including doublon we will remove duplicate from back to front
        // and so don't use : this._timelineData.dynamic_size = data.get('dynamic_size');
        let totalWidth = 0;
        let previousData = null;
        this.timelineData.imagePeriod.forEach(imagePeriod => {
            if (previousData === null || imagePeriod.image.uri !== previousData.image.uri) {
                totalWidth = totalWidth + +imagePeriod.image.width;
            }
            previousData = imagePeriod;
        });
        // set the global size manualy because data become from back take doublon in calcul
        // add Tcolor zone in calcul because we put by hand at begining and at the end
        this._timelineData.dynamic_size = totalWidth + this._paramsZone.width * 2;
    }

    /**
     * set the pipe period (the first level) from back in the object of type ITimelineData
     * @param allPeriodData : all period data with array inside array each time in another fields period.
     * in reality back object is more complex but we take only wht we need in front.
     * than IGlobalPeriod[] but only fields of IGlobalPeriod[] are used.
     */
    private setGlobalPeriodData(allPeriodData: IGlobalPeriod[]): void {
        this._timelineData.globalPeriod = allPeriodData.map((data: IGlobalPeriod) => {
            return {
                title: data.title,
                dynamic_date: data.dynamic_date,
                dynamic_size: data.dynamic_size,
                color: data.color
            };
        });
    }

    /**
     * set the dot period from back in the object of type ITimelineData. use to put dark dot in timeline.
     * @param allPeriodData : all period data with array inside array in fields perdiod
     */
    private setDotPipePeriod(allPeriodData: any[]): void {
        this._timelineData.titlePeriod = allPeriodData.flatMap((data: IPeriod) => {
            return data.period.map((dotPeriod: ITitlePeriod) => {
                return {
                    title: dotPeriod.title,
                    dynamic_date: dotPeriod.dynamic_date,
                    dynamic_size: +dotPeriod.dynamic_size
                };
            });
        });
    }

    /**
     * set the image period from back in the object of type ITimelineData
     * @param allPeriodData : all period data with array inside array in fields period
     */
    private setImagePeriodData(allPeriodData: any[]): void {
        // get data from the period under period
        const dataImageZoneTemp = allPeriodData.map((data: IPeriod) => {
            return data.period.map((imagePeriod: any) => {
                return imagePeriod.period;
            });
        }).flat(2);
        // set the data for image period
        this._timelineData.imagePeriod = dataImageZoneTemp.flatMap((data: IImagePeriod) => {
            return {
                id: data.id,
                title: data.title,
                dynamic_date: data.dynamic_date,
                dynamic_size: +data.dynamic_size,
                image: data.image,
                points: data.points
            };
        });
    }

    /**
     * return an array with info to segment timeline and zone of colors for segment timeline in color zone
     */
    private getColorZoneParams(): IPositionAndStyle[] {
        const data: IPositionAndStyle[] = [];
        let cumulPourcentage = 0;
        let lastPourcentage = 0;
        const tailleFrise = Math.abs(+this.timelineData.dynamic_date.start_date) +
            Math.abs(+this.timelineData.dynamic_date.end_date);

        this.timelineData.globalPeriod.forEach(dataGlobalPeriod => {
            const finPortion = +dataGlobalPeriod.dynamic_date.end_date;
            const position = 100 * ((Math.abs(+this.timelineData.dynamic_date.start_date) + finPortion)) / tailleFrise;
            const pourcentageZoneTotal = position - lastPourcentage;

            if (data.length === 0) {
                data.push({
                    position: 0,
                    color: dataGlobalPeriod.color,
                    width: position
                });
            } else {
                data.push({
                    position: cumulPourcentage,
                    color: dataGlobalPeriod.color,
                    width: pourcentageZoneTotal
                });
            }
            cumulPourcentage = cumulPourcentage + pourcentageZoneTotal;
            lastPourcentage = lastPourcentage + pourcentageZoneTotal;
        });
        return data;
    }

    /**
     * return an array of pourcent who are the dark dot position in timeline in pourcent
     * represent begin an end of a period
     */
    private getdotPosition(): number[] {
        const data: number[] = [];
        let cumulPourcentage = 0;
        let lastPourcentage = 0;
        const tailleFrise = Math.abs(+this.timelineData.dynamic_date.start_date) +
            Math.abs(+this.timelineData.dynamic_date.end_date);

        this.timelineData.titlePeriod.forEach(dataTitlePeriod => {
            const finPortion = +dataTitlePeriod.dynamic_date.end_date;
            const position = 100 * ((Math.abs(+this.timelineData.dynamic_date.start_date) + finPortion)) / tailleFrise;
            const pourcentageZoneTotal = position - lastPourcentage;
            // adjust because timeline thumb not begin and finish at the gegin or end of the slide block
            data.push(cumulPourcentage - 0.5);
            cumulPourcentage = cumulPourcentage + pourcentageZoneTotal;
            lastPourcentage = lastPourcentage + pourcentageZoneTotal;
        });
        return data;
    }

    /**
     * return an array of pourcent who are the dark dot position in timeline in pourcent
     * represent the middle beetween begin and end of a period
     */
    private getCenterPerioddotPosition(): number[] {
        const dotCenter: number[] = [];
        const periodInPourcent = this.getdotPosition();
        let i = 1;
        periodInPourcent.forEach(position => {
            if (i < periodInPourcent.length) {
                dotCenter.push((position + periodInPourcent[i]) / 2);
                i++;
            }
        });
        return dotCenter;
    }

    /**
     * set the position of white pipe separator of main history zone
     * split at place where color zone could change
     * Note : only if setting allow separator
     */
    private setPipePeriodPosition(): void {
        if (this.settings.showPeriodSeparator === true) {
            this._positionSeparateurZone = this.arrayPeriodeColor
                .filter(res => res.position !== 0 && res.position !== 100)
                .map(res => {
                    // adjust - 1 because thumb not begin at the begin begin and not finish at the finish exactly
                    return res.position - 1;
                });
        }
    }


    /**
     * set an array of date zone with the image order from 0 to X to determine
     * by calcul after a sliderClick() event the good image position
     */
    private setZoneImageLimit(): void {
        let previousRes = null;
        let i = 0;
        this.timelineData.imagePeriod.forEach(res => {
            if (previousRes === null || res.image.uri !== previousRes.image.uri) {
                this._imageList.push({
                    idPeriod: +res.id,
                    uri: res.image.uri,
                    width: res.image.width,
                    height: res.image.height
                });
                this.arrayZoneImageValue.push({
                    numero: i,
                    start: +res.dynamic_date.start_date,
                    end: +res.dynamic_date.end_date
                });
                i++;
            }
            previousRes = res;
        });
    }

    /**
     * set the zone before and after the image in the viewer : size and color
     * @param data : All back data :
     * get('colors')[0] => color of begining ,
     * get('colors')[1] => color of end
     * get('width') => width of bloc before and after images
     *
     */
    private initParamsZoneAroundImage(data: DataEntity): void {
        this._paramsZone.width = data.get('width');
        this._paramsZone.startBackgroundCss = data.get('colors')[0]; // css of background zone before image in timeline
        this._paramsZone.endBackgroundCss = data.get('colors')[1]; // css of background zone after image in timeline
    }

    /**
     * set the title of the main history period to show in regard off period (dot zone)
     * @param sliderPosition : current year
     */
    private setTitleHistoryPeriodByDate(sliderPosition: number): void {
        this._title.mainTitle = '';
        this.timelineData.titlePeriod.forEach(dot => {
            if (+sliderPosition >= +dot.dynamic_date.start_date && +sliderPosition < +dot.dynamic_date.end_date) {
                this._title.titledotPeriod = dot.title;
                // get translation
                if (!this.fromTxt || !this.toTxt || !this.theseDays) {
                    this.translate.get('generic.from').subscribe((translation: string) => this.fromTxt = translation);
                    this.translate.get('generic.to').subscribe((translation: string) => this.toTxt = translation);
                    this.translate.get('generic.theseDays').subscribe((translation: string) => this.theseDays = translation);
                }
                let endDates;
                if (+dot.dynamic_date.end_date >= 2020) {
                    endDates = this.theseDays;
                } else {
                    endDates = +dot.dynamic_date.end_date;
                }
                this._title.dates = this.fromTxt + ' ' + +dot.dynamic_date.start_date + ' ' + this.toTxt + ' ' + endDates;
            }
        });
    }

    /**
     * set the title of the current image period to show in regard off period
     * @param sliderPosition : current year
     */
    private setTitleImagePeriodByDate(sliderPosition: number): void {
        this._title.mainTitle = '';
        this.timelineData.imagePeriod.forEach(image => {
            if (+sliderPosition >= +image.dynamic_date.start_date && +sliderPosition < +image.dynamic_date.end_date) {
                this._title.titleImage = image.title;
            }
        });
    }

    /**
     * set the title to show in regard off period antiquity mid age etc.
     * @param sliderPosition : current year
     */
    private setTitleByDate(sliderPosition: number): void {
        this._title.titleHistoryPeriod = '';
        this.timelineData.globalPeriod.forEach(period => {
            if (+sliderPosition >= +period.dynamic_date.start_date && +sliderPosition <= +period.dynamic_date.end_date) {
                this._title.titleHistoryPeriod = period.title;
            }
        });
    }

    /**
     * init all the title in regard of the current year
     */
    private initTimelineTitle(year: number): void {
        this.setTitleHistoryPeriodByDate(year);
        this.setTitleImagePeriodByDate(year);
        this.setTitleByDate(year);
    }

    /**
     * get the timeline data
     */
    public getTimeline(): Observable<DataEntity[]> {
        this.resetToDefaultValue();
        return this.octopusConnect.loadCollection('timeline').pipe(
            take(1),
            map((collection) => collection.entities),);
    }

    /**
     * init all data we need in front with back data
     * @param data all back data
     */
    public initAllData(data: DataEntity): void {
        // to always launch before initParamsZoneAroundImage()
        this.initParamsZoneAroundImage(data);
        this.initTimeLineData(data);
        this._arrayPeriodeColor = this.getColorZoneParams();
        this._positionIntervals = this.getPositionIntervals();
        this.setPipePeriodPosition();
        this.setDotToShowInRegardOfSettings();
        this.setZoneImageLimit();
        this.initTimelineTitle(+this.timelineData.dynamic_date.start_date);
        this.ajustementPositionPx = +data.get('decalagePositionImage');
        this.maPosition.ajustementPositionPx = this.ajustementPositionPx;
        this._dataReady = true;
    }

    /**
     * return the position of the interval in regard of the first date position pass
     * and the size in year beetween each interval
     */
    private getPositionIntervals(): IpositionInterval[] {
        const begin = this._firstInterval;
        const step = this._intervalSize;
        const debut = +this.timelineData.dynamic_date.start_date;
        const fin = +this.timelineData.dynamic_date.end_date;

        const interval: IpositionInterval[] = [];
        for (let year = begin; year < fin - step; year = year + step) {
            // 99 because thumb not begin at zero and do 42 px size 21px equal arround 1% less
            if (year === 0 && this.settings.isSeparatorShowOneInsteadOfZero) {
                const pourcent = 99 * (Math.abs(debut) + 1) / (Math.abs(debut) + Math.abs(fin));
                interval.push({year: 1, left: pourcent});
            } else {
                const pourcent = 99 * (Math.abs(debut) + year) / (Math.abs(debut) + Math.abs(fin));
                interval.push({year: year, left: pourcent});
            }
        }
        return interval;
    }

    /**
     * set the dot to show begin and end of period and or middle of period
     * in regard of settings
     */
    private setDotToShowInRegardOfSettings(): void {
        if (this.settings.showDotPeriodSeparator === true) {
            this._arrayDot = this.getdotPosition();
        }
        if (this.settings.showDotMiddlePeriodSeparator === true) {
            this._arrayMiddleDot = this.getCenterPerioddotPosition();
        }
    }

    /**
     * set the slider in position with the current image of the period in midle of screen
     * @param position : slider value in year
     */
    private calculImagePosition(sliderPosition: number): number {
        let positionImageToShow = 0;
        const decalage = +this.timelineData.imagePeriod[0].dynamic_size;
        const ImageWidth = +this.timelineData.imagePeriod[0].dynamic_size;

        if (sliderPosition === +this.timelineData.dynamic_date.start_date) {
            positionImageToShow = decalage;
        } else {
            this.arrayZoneImageValue.forEach(dot => {
                if (+sliderPosition > dot.start && +sliderPosition <= dot.end) {
                    if (+dot.numero === 0) {
                        positionImageToShow = decalage;
                    } else {
                        // numero begin to 0 and each image increase of + 1
                        positionImageToShow = decalage + (ImageWidth * +dot.numero);
                    }
                }
            });
        }
        return positionImageToShow;
    }

    /**
     * get number of image 0 to x concerned by period
     * @param position : slider value in year
     */
    private calculImageToShow(sliderPosition: number): number {
        let numero = 0;
        this.arrayZoneImageValue.forEach(dot => {
            if (+sliderPosition > dot.start && +sliderPosition <= dot.end) {
                numero = +dot.numero;
            }
        });
        return numero;
    }

    /**
     * for moment only one element to click so use the main id
     * open the media associated
     * @param idPeriod id common to image and dot
     */
    public openBasicModalWithInfo(idPeriod: Number): void {
        // get info of document to open
        const imagewithdocToOpen = this.timelineData.imagePeriod.filter(image => +image.id === idPeriod)[0];
        const dialogConfig = new MatDialogConfig();

        dialogConfig.data = {
            titleDialog: imagewithdocToOpen.points[0].title,
            panelClass: 'entity-form-dialog timeline-dialog',
        };

        // modal contain in HTML pass by innerHtml
        dialogConfig.data.bodyDialog = '<div class = "popInContentTimeline">' +
            '<img class="imgPopinTimeline" src="' + imagewithdocToOpen.points[0].media[0].uri + '"> ' +
            '<div class="txtPopinTimeline">' + imagewithdocToOpen.points[0].description + '</div> ' +
            '</div>';

        // open modal construct by passing html
        const confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
        confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                // nothing
            }
        });
    }

    /**
     * change info slider and title after a move by gesture on image
     * @param numImage : image affiché de 0 à x
     */
    public currentImage(numImage: number): void {
        this._currentSliderValue = +this.arrayZoneImageValue[numImage].start;
        this.setTitleByDate(this._currentSliderValue);
        this.setTitleHistoryPeriodByDate(this._currentSliderValue);
        this.setTitleImagePeriodByDate(this._currentSliderValue);
    }

    /**
     * move the images in regard of slider value linar move not smoothy.
     * @param position : slider value
     */
    public sliderMove(position: number): void {
        this.setTitleByDate(position);
        this.setTitleHistoryPeriodByDate(position);
        this.setTitleImagePeriodByDate(position);
        this._maPosition = {position: this.calculPositionInPixelImage(position), type: 'auto', numeroImage: -1, ajustementPositionPx: 0};
    }

    /**
     * return the position in pixel of the image according of the slider current year
     * @param sliderValue : value of the cursor in the slider in year
     */
    private calculPositionInPixelImage(sliderValue: number): number {
        const sizePixel = +this.timelineData.dynamic_size;
        const minValue = this.timelineData.dynamic_date.start_date;
        const totalValue = Math.abs(minValue) + Math.abs(this.timelineData.dynamic_date.end_date);
        const positionInPixel = sizePixel * ((Math.abs(minValue) + sliderValue) / totalValue);

        return positionInPixel;
    }

    /**
     * move to the good image in middle of screen whith a smooth effect
     * position is used only if calculImageToShow is différent of -1
     * new Method to validate before deleting old one
     * @param position : slider value in year
     */
    public sliderClick(position: number): void {
        this.setTitleByDate(position);
        this.setTitleHistoryPeriodByDate(position);
        this.setTitleImagePeriodByDate(position);
        this._maPosition = {
            position: this.calculImagePosition(position), type: 'smooth',
            numeroImage: this.calculImageToShow(position),
            ajustementPositionPx: this.ajustementPositionPx
        };
    }
}
