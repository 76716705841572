import { Component, OnInit } from '@angular/core';
import {brand, brandLogoSvg} from 'app/settings';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  public brand = brand;
  public brandLogoSvg = brandLogoSvg;

  constructor() { }

  ngOnInit() {
  }

}
