// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  width: 400px;
}
.content .title {
  text-align: center;
  font-weight: bold;
}
.content .error-container {
  max-height: 250px;
  min-height: 50px;
}
.content .buttons {
  text-align: center;
}
.content .buttons input {
  margin-right: 10px;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/browser-test/core/modals/generic-error-modal/generic-error-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AACE;EACE,kBAAA;EACA,iBAAA;AACJ;AAEE;EACE,iBAAA;EACA,gBAAA;AAAJ;AAGE;EACE,kBAAA;AADJ;AAGI;EACE,kBAAA;EACA,iBAAA;AADN","sourcesContent":[".content {\r\n  width: 400px;\r\n\r\n  .title {\r\n    text-align: center;\r\n    font-weight: bold;\r\n  }\r\n\r\n  .error-container {\r\n    max-height: 250px;\r\n    min-height: 50px;\r\n  }\r\n\r\n  .buttons {\r\n    text-align: center;\r\n\r\n    input {\r\n      margin-right: 10px;\r\n      margin-left: 10px;\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
