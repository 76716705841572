import {ElementRef, Injectable} from '@angular/core';
import {IdeaItemComponent} from './idea-item/idea-item.component';
import {IdeaDrop} from './idea-drop.interface';
import {IdeasWallService} from './ideas-wall.service';
import {CategoryItemComponent} from './category-item/category-item.component';
import {CategoryDrop} from './category-drop.interface';
import {IdeasWallMainViewComponent} from './ideas-wall-main-view/ideas-wall-main-view.component';

@Injectable({
    providedIn: 'root'
})
export class IdeasWallDdService {

    columns: {[key: number]: ElementRef} = {};
    ideas: IdeaItemComponent[] = [];
    categories: CategoryItemComponent[] = [];
    mainView: IdeasWallMainViewComponent;

    overColumnNumber: number;
    overColumn = false;
    overCategoriesContainer = false;
    overPlaceholderHeight = 0;

    constructor(
        private wallsService: IdeasWallService
    ) { }


    /**
     *
     * @param {ElementRef} column
     * @param {number} columnNumber
     */
    registerColumn(column: ElementRef, columnNumber: number): void {
        this.columns[columnNumber] = column;
    }


    /**
     *
     * @param {number} columnNumber
     */
    unRegisterColumn(columnNumber: number): void {
        delete this.columns[columnNumber];
    }


    /**
     *
     * @param draggable
     * @returns {number}
     */
    checkIfOnColumn(draggable: any): number {
        for (const num in this.columns) {
            if (draggable.hitTest(this.columns[num].nativeElement, '50%')) {
                return +num;
            }
        }

        return -1;
    }


    /**
     *
     * @param {IdeaItemComponent} idea
     */
    registerIdea(idea: IdeaItemComponent): void {
        this.ideas.push(idea);
    }


    /**
     *
     * @param {IdeaItemComponent} idea
     */
    unRegisterIdea(idea: IdeaItemComponent): void {
        const index: number = this.ideas.indexOf(idea);

        if (index !== -1) {
            this.ideas.splice(index, 1);
        }
    }


    /**
     *
     * @param draggable
     * @param gap {number}
     * @returns {IdeaDrop}
     */
    checkIfOnIdea(draggable: any, gap: number = 0): IdeaDrop {

        for (const idea of this.ideas) {

            const rect: ClientRect = idea.mainContainer.nativeElement.getBoundingClientRect();

            gap = 0;

            const rectObj: any = {
                top: rect.top - gap,
                right: rect.right,
                bottom: rect.bottom - gap,
                left: rect.left,
                width: rect.width,
                height: rect.height
            };

            if (draggable.hitTest(rectObj, '5%')) {

                const thisRect: ClientRect = draggable.target.getBoundingClientRect();

                const trc: any = {
                    top: thisRect.top - gap,
                    right: thisRect.right,
                    bottom: thisRect.bottom - gap,
                    left: thisRect.left,
                    width: thisRect.width,
                    height: thisRect.height
                };

                const thisCenterY: number = trc.top + trc.height / 2;

                const ideaRect: ClientRect = idea.element.nativeElement.getBoundingClientRect();
                const ideaCenterY: number = ideaRect.top + ideaRect.height / 2;

                return {
                    position: (thisCenterY < ideaCenterY) ? 'top' : 'bottom',
                    idea: idea
                };
            }
        }

        return null;
    }


    /**
     *
     * @param draggable
     * @param direction {string}
     * @returns {CategoryDrop}
     */
    checkIfOnCategoryWithPosition(draggable: any, direction: string): CategoryDrop {

        for (const category of this.categories) {

            if (draggable.hitTest(category.topElement.nativeElement, '5%')) {

                const thisRect: ClientRect = draggable.target.getBoundingClientRect();
                const categoryRect: ClientRect = category.element.nativeElement.getBoundingClientRect();

                if (!direction || direction === 'vertical') {
                    const thisCenterY: number = thisRect.top + thisRect.height / 2;
                    const categoryCenterY: number = categoryRect.top + categoryRect.height / 2;

                    return {
                        position: (thisCenterY < categoryCenterY) ? 'top' : 'bottom',
                        category: category
                    };
                } else if (direction === 'horizontal') {
                    const thisCenterX: number = thisRect.left + thisRect.width / 2;
                    const categoryCenterX: number = categoryRect.left + categoryRect.width / 2;

                    return {
                        position: (thisCenterX < categoryCenterX) ? 'left' : 'right',
                        category: category
                    };
                }

            }
        }

        return null;
    }


    /**
     *
     */
    resetIdeasComponents(exception: IdeaItemComponent = null): void {
        this.ideas.forEach(idea => {
            if (idea !== exception) {
                idea.hidePlaceholders();
            }
        });
    }


    /**
     *
     * @param {CategoryItemComponent} exception
     */
    resetCategoriesComponents(exception: CategoryItemComponent = null): void {
        this.categories.forEach(category => {
            if (category !== exception) {
                category.unsetOver();
                category.hidePlaceholders();
            }
        });
    }

    /**
     *
     * @param {CategoryItemComponent} category
     */
    registerCategory(category: CategoryItemComponent): void {
        this.categories.push(category);
    }


    /**
     *
     * @param {CategoryItemComponent} category
     */
    unRegisterCategory(category: CategoryItemComponent): void {
        const index: number = this.categories.indexOf(category);

        if (index !== -1) {
            this.categories.splice(index, 1);
        }
    }


    /**
     *
     * @param draggable
     * @returns {CategoryItemComponent}
     */
    checkIfOnCategory(draggable: any): CategoryItemComponent {

        for (const category of this.categories) {
            if (draggable.hitTest(category.element.nativeElement, '20%')) {
                return category;
            }
        }

        return null;
    }


    /**
     *
     * @param draggable
     * @returns {boolean}
     */
    checkIfOnCategoriesContainer(draggable: any): boolean {
        return draggable.hitTest(this.mainView.categoriesContainer.nativeElement);
    }
}
