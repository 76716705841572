import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-dash-widget',
    templateUrl: './dash-widget.component.html',
    styleUrls: ['./dash-widget.component.scss']
})

export class DashWidgetComponent {


    @Input() DashWidgetConf: any;

    constructor(
    ) {

    }

}

