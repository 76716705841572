import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IdeasWallService} from '../ideas-wall.service';
import {DataEntity} from 'octopus-connect';
import {IdeasWallDdService} from '../ideas-wall-dd.service';

@Component({
    selector: 'ideas-view',
    templateUrl: './ideas-view.component.html',
    styleUrls: ['./ideas-view.component.scss']
})
export class IdeasViewComponent implements OnInit, OnChanges {

    @Input('ideas') ideas: DataEntity[];
    columnsCount = 6;
    columnsArray: number[];

    ideasByColumn: {[key: number]: DataEntity[]} = {};

    constructor(
        private wallsService: IdeasWallService,
        private dragAndDrop: IdeasWallDdService
    ) {
        this.columnsArray = Array(this.columnsCount).fill(0).map((x, i) => i);
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['ideas']) {
            if (this.ideas) {
                this.ideasByColumn = {};
                this.ideas
                    .filter(idea => idea.get('category').length === 0)
                    .forEach(idea => {
                        const colNum: number = idea.get('column');

                        if (!this.ideasByColumn[colNum]) {
                            this.ideasByColumn[idea.get('column')] = [];
                        }

                        this.ideasByColumn[idea.get('column')].push(idea);
                    });

                this.wallsService.ideasByColumn = this.ideasByColumn;
            }

        }
    }

    get overColumnNum(): number {
        return this.dragAndDrop.overColumnNumber;
    }

    get overColumnNumber(): number {
        if (this.dragAndDrop.overColumn) {
            return this.dragAndDrop.overColumnNumber;
        }
    }

    get overColumnPlaceholderHeight(): number {
        return this.dragAndDrop.overPlaceholderHeight;
    }

    addIdea(): void {
        this.wallsService.createIdea();
    }

    get locked(): boolean {
        return this.wallsService.currentWall.get('locked') || this.wallsService.currentWall.get('locked_item');
    }
}
