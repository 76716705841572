import {PatternInterface} from '../pattern.interface';

/**
 * Should be used to implement something display in the pdf
 */
export class VisualPatternInterface extends PatternInterface {
    width: number;
    padding: {
        top: number,
        left: number,
        bottom: number,
        right: number
    };
}