import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import Keyboard from 'latex-keyboard';
import {v4 as uuidv4} from 'uuid';
import {ReplaySubject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ItemAnswerInterface} from '@modules/activities/core/models/item-answer.interface';

@Component({
    selector: 'app-keyboard-latex-field',
    templateUrl: './keyboard-latex-field.component.html',
    styleUrls: ['./keyboard-latex-field.component.scss']
})
export class KeyboardLatexFieldComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() private symbols: any;
    @Input() private optionsFromSettings: any;
    @Input() private blacklistTabs: string[];
    @Input() public disable: boolean;
    @Input() public displayForSummary: boolean;
    @Input() public displaySolution: boolean;
    @Input() public answerCorrected: ReplaySubject<ItemAnswerInterface>;
    @Output() public onOptionChange: EventEmitter<boolean> = new EventEmitter();
    @Output() doesUserResponded: EventEmitter<boolean> = new EventEmitter();
    @Output() clickAnswer: EventEmitter<boolean> = new EventEmitter();
    private latexKeyboard: Keyboard;
    public uuid: string;
    @Output() onKeyboardReady: EventEmitter<Keyboard> = new EventEmitter();

    constructor(private elementRef: ElementRef) { }

    ngOnInit(): void {
        this.uuid = uuidv4();
        this.answerCorrected.pipe(
            tap((answer: ItemAnswerInterface) => this.showErrors(answer))
        ).subscribe();
    }

    ngAfterViewInit(): void {
        this.initialise();
    }

    ngOnDestroy(): void {
    }

    private initialise(): void {
        const keyboardOptions = {
            inputFields: [
                {
                    placeholder: '\\blue{[?]}',
                    welcomeText: '',
                    katexOptions: {                           /* See https://katex.org/docs/options.html */
                        throwOnError: false,
                        strict: false,
                        allowAllSymbols: true
                    },
                    renderedLatexDivId: `latex-rendering-${this.uuid}`           /* Id of div where rendering occurs         */
                }
            ],
            blacklistTabs: this.blacklistTabs,
            keyboardDivId: `latex-keyboard-${this.uuid}`,      /* Id of div where keyboard tabs are        */
            tabDivContainerGridClass: 'keyboard-grid-container',  /* Class name of each div grid tab          */
            tabDivContainerItemClass: 'keyboard-grid-item',       /* Class name of each div item tab          */
            tabDivMenuEntryClass: 'keyboard-tab-title',       /* Class name of tab titles (123, abc, ..)  */
            tabSwitchClassListener: 'toggleTab',                /* Class name where tab switch has to occur */
            hideKeyboardButtonClass: 'hideKeyboardButton',      /* Class name of close '✖' button containers */
            moveLeftButtonClass: 'moveLeft',                 /* Class name of Move Left button(s) */
            moveRightButtonClass: 'moveRight',                /* Class name of Move Right button(s) */
            backspaceButtonClass: 'backspace',                 /* Class name of Move Backspace button(s) */
        };

        this.latexKeyboard = new Keyboard(this.symbols, {...keyboardOptions, ...this.optionsFromSettings});

        this.latexKeyboard.addEventListener('change', (fields: string[]) => {
            const fieldsData = fields.join('').trim();

            if (fieldsData === '') {
                this.doesUserResponded.emit(false);
            } else {
                this.doesUserResponded.emit(true);
            }
            this.onOptionChange.emit(fieldsData !== '');
        });

        // TODO: will be refacto, keep in comment for now
        /*this.latexKeyboard.addEventListener('display', (isDisplayed: boolean) => {
            this.activitiesService.onLatexKeyboardDisplayChange.next(isDisplayed);
            if (isDisplayed) {
                this.callbackToTriggerOnAfterViewChecked = () => {
                    const inputId = this.latexKeyboard.getInputIdFocused();
                    document.getElementById(inputId).scrollIntoView();
                };
            }
        });*/

        this.latexKeyboard.addEventListener('validate', (validated: boolean) => {
            if (validated) {
                this.clickAnswer.emit(true);
            }
        });
        this.onKeyboardReady.emit(this.latexKeyboard);
    }

    private showErrors(answer: ItemAnswerInterface): void {
        const allInput = this.elementRef.nativeElement.querySelectorAll('.base');
        const anstxtbase = allInput && allInput[0] || null;

        if (anstxtbase) {
            anstxtbase.classList.remove('currently-correct');
            anstxtbase.classList.remove('was-correct');
            anstxtbase.classList.remove('incorrect');
            anstxtbase.classList.remove('pristine');
            anstxtbase.classList.remove('missing');

            anstxtbase.classList.add(answer.state);
        }
    }

}
