import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'intToBoolean'
})

@Injectable()
export class IntToBooleanPipe implements PipeTransform {

    transform(value: any, args_one: string, args_two: string): any {
        if(value==undefined) return value;
        value.map( item => {
            item[args_one] = this.convertIntToBoolean(item[args_one]);
            item[args_two] = this.convertIntToBoolean(item[args_two]);
        });
        return value;
    }

    convertIntToBoolean(response): boolean {
        response = !!+response;
        return response;
    }

}
