import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import {GraphBayardService} from './services/graph-bayard.service';
import {ProgressGraphComponent} from './component/progress-graph/progress-graph.component';
import {AttendanceGraphComponent} from './component/attendance-graph/attendance-graph.component';
import {SharedFiltersComponent} from './component/shared-filters/shared-filters.component';
import {GraphSelectorComponent} from './component/graph-selector/graph-selector.component';
import {MultiGraphViewComponent} from './component/multi-graph-view/multi-graph-view.component';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from '../../../shared/shared.module';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {CdkTableModule} from '@angular/cdk/table';
import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {FakeStepperComponent} from './component/fake-stepper/fake-stepper.component';
import {defaultRoute} from '../../../settings';
import {IsAtLeastTrainerGuard} from '../../../guards/is-at-least-trainer-guard.service';
import {GraphBayardRoutes} from '@modules/graph-bayard/core/graph-bayard.routes';
import {AuthorizationService} from '@modules/authorization';
import {SyncRules} from '@modules/graph-bayard/core/model/rules';
import {GraphBayardAuthorizationService} from './services/graph-bayard-authorization.service';
import {IsLearnerGuard} from '../../../guards/is-learner.guard';
import {OwnProgressGraphComponent} from '@modules/graph-bayard/core/component/own-progress-graph/own-progress-graph.component';
import {SingleGraphViewComponent} from '@modules/graph-bayard/core/component/single-graph-view/single-graph-view.component';
import {FuseNavigationItem} from 'fuse-core/types';

const routes: Routes = [
    {
        path: 'graph-bayard',
        children: [
            {
                path: 'multi',
                component: MultiGraphViewComponent,
                children: [
                    {path: GraphBayardRoutes.Attendance, component: AttendanceGraphComponent},
                    {path: GraphBayardRoutes.Progress, component: ProgressGraphComponent},
                    {
                        path: '**',
                        redirectTo: GraphBayardRoutes.Attendance,
                        pathMatch: 'full'
                    }
                ],
                canActivate: [IsAtLeastTrainerGuard]
            },
            {
                path: 'single',
                component: SingleGraphViewComponent,
                children: [
                    {path: GraphBayardRoutes.OwnProgress, component: OwnProgressGraphComponent},
                ],
                canActivate: [IsLearnerGuard]
            },
            {
                path: '**',
                redirectTo: defaultRoute,
                pathMatch: 'full'
            }
        ],
        canActivate: [IsUserLogged]
    }
];

@NgModule({
    declarations: [
        AttendanceGraphComponent,
        MultiGraphViewComponent,
        GraphSelectorComponent,
        ProgressGraphComponent,
        SharedFiltersComponent,
        FakeStepperComponent,
        SingleGraphViewComponent,
        OwnProgressGraphComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forRoot(routes, {}),
        SharedModule,
        FuseSharedModule,
        MatProgressSpinnerModule,
        CdkTableModule
    ],
    providers: [
        IsUserLogged
    ]
})
export class GraphBayardModule {
    private static isMenuSet = false;

    constructor(
        private dynamicNavigation: DynamicNavigationService,
        private authService: AuthenticationService,
        private authorizationService: AuthorizationService,
        private communicationCenter: CommunicationCenterService,
        private graphBayardAuthorizationService: GraphBayardAuthorizationService,
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.graphBayardAuthorizationService.activeRulesOnStartup();
    }


    static forRoot(): ModuleWithProviders<GraphBayardModule> {

        return {
            ngModule: GraphBayardModule,
            providers: [
                GraphBayardService
            ]
        };
    }

    private postLogout(): void {
        GraphBayardModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'graph-bayard');

        Object.keys(GraphBayardRoutes)
            .map(route => GraphBayardRoutes[route].toLowerCase())
            .map(route => `graph-bayard-single-${route}`)
            .forEach(menuLink => {
                this.dynamicNavigation.clearMenuItem('level0', menuLink);
            });
    }

    private postAuthentication(): void {
        try {
            if (!GraphBayardModule.isMenuSet && this.authorizationService.currentUserCan<boolean>(SyncRules.seeGraphsLinksInMenu)) {
                this.dynamicNavigation.registerModuleMenu('level0', {
                    'id': 'graph-bayard',
                    'title': 'graph-bayard',
                    'translate': 'graph_bayard.menu_link',
                    'type': 'item',
                    'icon': 'analytics',
                    'url': '/graph-bayard/multi',
                });

                Object.keys(GraphBayardRoutes)
                    .map(route => GraphBayardRoutes[route].toLowerCase())
                    .map(route => ({
                        'id': `graph-bayard-single-${route}`,
                        'title': `graph-bayard-single-${route}`,
                        'translate': `graph_bayard.single_${route}_link`,
                        'type': `item`,
                        'icon': `analytics`,
                        'url': `/graph-bayard/single/${route}`,
                    }) satisfies FuseNavigationItem).forEach(menuLink => {
                    this.dynamicNavigation.registerModuleMenu('level0', menuLink);
                });

                GraphBayardModule.isMenuSet = true;
            }
        } catch (e) {
            console.error(e);
        }
    }
}
