import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { fuseAnimations } from "fuse-core/animations";
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {CollectionOptionsInterface, DataEntity, OctopusConnectService, PaginatedCollection} from 'octopus-connect';
import {Subscription} from 'rxjs';
import {AssignationService} from '@modules/assignation';
import {AuthenticationService} from '@modules/authentication';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {CommunicationCenterService} from '@modules/communication-center';
import {Observable} from 'rxjs/index';
import {map, take} from 'rxjs/operators';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-assignation-groups-list',
    templateUrl: './assignation-groups-list.component.html',
    styleUrls: ['./assignation-groups-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class AssignationGroupsListComponent implements OnInit {

    public displayedColumns: string[] = ['lesson', 'startDate', 'learnersCount', 'questionSet', 'status'];
    public columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
    expandedElement: IAssignationGroup | null;

    public get displayedFilters(): string[] {
        return [];
    }

    resources;
    dataSource = new MatTableDataSource();
    private optionsInterface: CollectionOptionsInterface;
    public allTypes = [];
    public isLoading = true;
    private subscribeData = new Subscription();
    countEntities = 10;
    pageIndex = 0;
    pageRange = 10;
    pageRangeOptions = [10];
    private currentUser: UserDataEntity;
    public assignationGroupsPaginated: PaginatedCollection;
    private assignationGroups: IAssignationGroup[] = [];

    constructor(public assignationService: AssignationService,
                private authService: AuthenticationService,
                private octopusConnect: OctopusConnectService,
                private communicationCenter: CommunicationCenterService
    ) {
        this.communicationCenter
            .getRoom("authentication")
            .getSubject("userData")
            .subscribe((data: UserDataEntity) => {
                this.currentUser = data;
            });

        this.optionsInterface = {
            filter: this.authService.accessLevel === 'learner' ? {'assignated_user': this.authService.userData.id} : {'assignator': this.currentUser.id},
            page: 1,
            range: 10
        };
    }

    ngOnInit(): void {
        this.assignationService.loadAssignationsTypes().subscribe(types => {
            this.allTypes = types;
        });

        if (this.shouldDisplayFilters() === false) {
            return this.fixNoDataRequest();
        }
    }

    ngOnDestroy(): void {
        this.closeSubscribe();
    }

    public shouldDisplayFilters(): boolean {
        return this.displayedFilters.length > 0;
    }

    launchSearch(options: CollectionOptionsInterface = null): void {
        this.optionsInterface = options;
        this.loadData();
    }

    loadData(): void {
        this.isLoading = true;
        this.closeSubscribe();
        this.subscribeData = this.loadPaginatedAssignationGroups(this.optionsInterface)
            .subscribe((res: DataEntity[]) => {
                this.formatData(res);
                this.dataSource.data = this.assignationGroups;
                this.isLoading = false;
                this.setPaginator();
            });
    }

    private loadPaginatedAssignationGroups(filterOptions: CollectionOptionsInterface = {}): Observable<Object[]> {
        this.assignationGroupsPaginated = this.octopusConnect.paginatedLoadCollection('assignations-group', filterOptions);
        return this.assignationGroupsPaginated.collectionObservable.pipe(map(collection => collection.entities));
    }

    private closeSubscribe(): void {
        if (this.subscribeData) {
            this.subscribeData.unsubscribe();
        }
    }

    setPaginator(): void {
        if (this.assignationGroupsPaginated.paginator) {
            this.countEntities = this.assignationGroupsPaginated.paginator.count;
            this.pageIndex = this.assignationGroupsPaginated.paginator.page - 1;
            this.pageRange = this.assignationGroupsPaginated.paginator.range;
        }
    }
    /**
     * format data
     * @param res dataentity array with data not formated
     */
    private formatData(res: DataEntity[]): void {
        this.assignationGroups = [];
        res.forEach(log => {
            this.assignationGroups.push({
                lesson: log.get('lesson'),
                startDate: +(log.get('startDate'))>0 ? log.get('startDate') : log.get('created'),
                learners: Object.keys(log.get('assignations')),
                assignations: log.get('assignations'),
                status: this.getAssignationsStatus(log.get('assignations')),
                questionSet: log.get('questionSet')
            });
        });
    }

    private fixNoDataRequest(): void {
        this.launchSearch({
            filter: this.authService.accessLevel === 'learner' ?
                {'assignated_user': this.authService.userData.id} :
                {'assignator': this.authService.userData.id},
            page: 1,
            range: 10
        });
    }

    onPaginateChange(event): void {
        this.isLoading = true;
        this.assignationGroupsPaginated.paginator.page = event.pageIndex + 1;
    }

    getAssignationsStatus(assignations: Assignations): string {
        let isAssigned = false;
        let isInProgress = false;
        let isComplete = false;

        for (const key in assignations) {
            if (assignations.hasOwnProperty(key)) {
                const assignation = assignations[key];
                const status = this.getAssignationStatus(assignation);

                switch (status) {
                    case "assignment.state.assigned":
                        isAssigned = true;
                        break;
                    case "assignment.state.pending":
                        isInProgress = true;
                        break;
                    case "assignment.state.valid":
                        isComplete = true;
                        break;
                }
            }
        }

        if (isInProgress) {
            return "assignment.state.pending";
        } else if (isAssigned && isComplete) {
            return "assignment.state.pending";
        } else if (isAssigned) {
            return "assignment.state.assigned";
        } else {
            return "assignment.state.valid";
        }
    }


    getAssignationStatus(assignation: Assignation): string {
        if (assignation['ratings'].every((value) => value === 0)) {
            return "assignment.state.assigned";
        }
        if (assignation['ratings'].every((value) => value === 1)) {
            return "assignment.state.valid";
        }
        if (assignation['ratings'].some((value) => value > 0)) {
            return "assignment.state.pending";
        }
        return "assignment.state.assigned";
    }

}

interface Assignation {
    id: string;
    progress?: string;
    username? : string;
}

interface Assignations {
    [key: string]: Assignation;
}

interface IAssignationGroup {
    lesson: string;
    learners: string[];
    status: string;
    startDate?: string;
    assignations?: Assignations[];
    questionSet?: string;
}
