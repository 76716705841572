import {Injectable} from '@angular/core';
import {CommunicationCenterService} from "@modules/communication-center";
import {Subject} from "rxjs";

/**
 * This service's scope is to :
 *   - receive messages on rooms 'contextualActions' and 'contextualConditions'
 *   - expose Observables for the components of the module to subscribe to
 *   - have one Observable per action and condition handled in the module
 *   - have no other logic implemented. The components and services of the module handle the logic
 */
@Injectable({
    providedIn: 'root'
})
export class ContextualService {
    public actionCorpusAdd$ = new Subject<void>();

    constructor(
        private communicationCenter: CommunicationCenterService,
    ) {
        this.communicationCenter
            .getRoom('contextualActions')
            .getSubject('corpus')
            .subscribe((action) => {
                switch (action) {
                    case 'add':
                        this.actionCorpusAdd$.next();
                        break;
                }
            });
    }
}
