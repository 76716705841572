import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


@Component({
  selector: 'app-select-corpus-upload',
  templateUrl: './select-corpus-upload.component.html',
  styleUrls: ['./select-corpus-upload.component.scss']
})
export class SelectCorpusUploadComponent implements OnInit {
    @Output() corpusSelect = new EventEmitter<{userCorpus: boolean, upload: boolean}>();
    @Input() public uploadAvailable: boolean = null;

  constructor() { }

  ngOnInit(): void {
  }

  public chooseResource(userCorpus?, upload?): void {
      this.corpusSelect.emit({userCorpus, upload});
  }

}
