import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReadableDirective } from './directives/readable.directive';



@NgModule({
  declarations: [
      ReadableDirective
   ],
  imports: [
    CommonModule
  ],
  exports: [
      ReadableDirective
  ]
})
export class TtsModule { }
