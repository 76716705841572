import {Component, OnInit} from '@angular/core';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {map, take, tap} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {ConceptsService} from '@modules/activities/core/services/concepts.service';
import {EducationalLevelService} from '@modules/activities/core/services/educational-level.service';
import {EducationalLevelAttributeInterface} from '@modules/activities/core/activities-list-educational-level/educational-level-attribute.interface';
import {ConceptEntity, TypedDataEntityInterface} from '../../../../shared/models';
import {brand} from '../../../../settings';
import {Observable} from 'rxjs';

type EducationalLevel = TypedDataEntityInterface<EducationalLevelAttributeInterface>;

@Component({
    selector: 'app-activities-list-educational-level',
    templateUrl: './activities-list-educational-level.component.html'
})
export class ActivitiesListEducationalLevelComponent implements OnInit {
    public levelsDataEntity: EducationalLevel[];
    public concepts: ConceptEntity[] = [];
    public levelConcepts: ConceptEntity[] = [];
    public selectedLevel: EducationalLevel;
    public userProgress: DataEntity;
    public brand = brand;
    public showSpinner = true;
    public showPlaceholder = true;

    constructor(
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService,
        public activitiesService: ActivitiesService,
        private educationalLevelService: EducationalLevelService,
        private conceptService: ConceptsService
    ) {

    }

    ngOnInit(): void {
        combineLatest([
            this.refreshLevelsDataEntity().pipe(tap(() =>  this.initSelectedUserLevel())),
            this.conceptService.getConcepts()
                .pipe(
                    tap((data) => this.concepts = data.entities)
                )
        ])
            .pipe(
                take(1)
            )
            .subscribe(() => {
            if (this.selectedLevel) {
                this.displayConcepts(this.selectedLevel.id);
            } else {
                this.showSpinner = false;
            }
        });
    }

    private initSelectedUserLevel(): void {
        const userLevels = this.educationalLevelService.getUserLevels();
        if (userLevels && userLevels.length > 0 && userLevels[0]) {
            // on utilise le premier mais il peut y en avoir plusieurs
            this.selectedLevel = this.levelsDataEntity.find((level) => level.id === userLevels[0].id);
            if (this.selectedLevel) {
                this.getEducationalLevelUserProgression(this.selectedLevel.id);
            }
        }
    }

    private saveLastUserSelectedLevel(selectedLevel: EducationalLevel): void {
        this.educationalLevelService.setUserEducationalLevels([selectedLevel.id]).subscribe();
    }

    private getEducationalLevelUserProgression(selectedLevelId: string|number): void {
        this.educationalLevelService.getEducationalLevelUserProgression(selectedLevelId).pipe(
            take(1),
            tap((userProgression: DataCollection) => this.userProgress = userProgression.entities[0])
            ).subscribe();
    }

    public getConceptProgress(conceptId: string): any{
        if (!this.userProgress){
            return {};
        }

        return this.userProgress.get(conceptId) || {};
    }

    private refreshLevelsDataEntity(): Observable<EducationalLevel[]> {
        return this.educationalLevelService.getEducationalLevel()
            .pipe(
                take(1),
                map((dataEducationalLevel: DataCollection) => this.levelsDataEntity = <EducationalLevel[]>dataEducationalLevel.entities)
            );
    }

    public onEducationalLevelChanged(level: EducationalLevel): void {
        this.saveLastUserSelectedLevel(level);
        this.displayConcepts(level.id);
        this.getEducationalLevelUserProgression(level.id);
    }

    private displayConcepts(id: string|number): void {
        this.levelConcepts = this.concepts.filter(c => c.get('levels') && c.get('levels').some(lid => lid === id));
        this.showSpinner = false;
        this.showPlaceholder = false;
    }

    /**
     * store id selected to go back here on close modal exo
     * @param id : string
     */
    storeIddConceptSelected(id: string): void {
        this.communicationCenter.getRoom('concept').getSubject('lastConceptId').next(id);
    }

    /**
     * is user has assignment pending
     * @param id
     */
    public userHasAssignmentPending(id: number | string): boolean {
        return this.userProgress
            && this.userProgress.get(id.toString())
            && this.userProgress.get(id.toString()).currentUser
            && this.userProgress.get(id.toString()).currentUser.pending > 0;
    }
}
