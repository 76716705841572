/**
 * status of answer used for userSave
 *  manque un état pour gérer le bouton "tester réponse"
 */
export enum answerStatusEnum {
    // activity is finished and correct
    'correct' = 1,
    // activity is not finished
    'missing' = 2,
    // activity is finished but incorrect
    'wrong' = 3,
    // answer entered or selected but not saved
    'answered' = 4,
}