import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { modulesSettings } from 'app/settings';
import { BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common';
import {ModelSchema, Structures} from 'octopus-model';

const settingsStructure = new ModelSchema({
    ignoreBackButtonCliquedOnHome: Structures.boolean(false),
    backRoutes: Structures.array([]),
});


@Injectable()
export class HeaderNavigationService {
    private settings = settingsStructure.filterModel(modulesSettings.header);
    private currentBackDestination?: string = null;
    // par défaut pas de bouton back  
    public backAvailabilityChanged = new BehaviorSubject<boolean>(false); 
    
    private backAvailable(): boolean {
        let routeCanBack = false;
        this.currentBackDestination = null;

            this.settings.backRoutes.forEach((route) => {
                const fragmentUlr = this.router.url.split('/');
                const fragmentRouteOrigin = route['origin'].split('/');
                
                let routesMatches = true; 
                if (fragmentRouteOrigin.length === fragmentUlr.length) {
                    fragmentRouteOrigin.forEach((parts, index) => {
                        if (parts !== ':param' ){
                            if (fragmentRouteOrigin[index] !== fragmentUlr[index]) {
                                routesMatches = false;
                                return;
                            }
                        }
                    }); 
                } else {
                    routesMatches = false;
                }

                if (routesMatches) {
                    routeCanBack = true;
                    this.currentBackDestination = route['destination'];
                    return;
                }
            });
        return routeCanBack;
    }
    
    public back(): void {
        if (this.currentBackDestination) {
            this.navigateBack();
        }
    }

   private navigateBack(): void {
        this.router.navigateByUrl(this.currentBackDestination, { replaceUrl: true });
   }
    constructor(
        private router: Router,
        private location: Location
        ) { 
        /*if (window['cordova'] !== undefined) {
            document.addEventListener('backbutton', (event) => {
                // Voir si on garde le stop propagation (retire le quit sur android)
                event.stopPropagation();
                if (this.backAvailable()) {
                    this.back();
                } // Nothing else for now do we will need to implement something  ? 
            });
        }*/

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (this.backAvailable()) {
                    this.backAvailabilityChanged.next(true);
                } else {
                    this.backAvailabilityChanged.next(false);
                }
            } 
            // Filtre sur les Navigation start pour manipuler la navigation sur le pop state (quand la location change)
            // Voir si on remet l'anulation de la navigation sur le btn back, ce n'est pas spécifié.
            // else if (event instanceof NavigationStart) {
            //      // Bouton back du navigateur
            //     if (event.navigationTrigger === 'popstate') {
            //         // Annule la navigation
            //        this.navigateBack();
            //     } 
            // }    
        });
        
    }
}
