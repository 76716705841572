import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {defaultRoute} from '../../../app/settings';
import {CommunicationCenterService} from '@modules/communication-center';

@Component({
    selector: 'app-default-route',
    template: ``,
})
export class DefaultRouteComponent implements OnInit {

    constructor(private router: Router,
                private communicationCenter: CommunicationCenterService) { }

    ngOnInit(): void {
        this.communicationCenter.getRoom('authentication').getSubject('userData').subscribe(userData => {
            if (userData) {
                this.router.navigate([defaultRoute]);
            }
        });
    }

}
