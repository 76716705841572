import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { CustomBlock } from './custom-blocks/custom-block.model';
import {Observable} from 'rxjs/internal/Observable';
import {take} from 'rxjs/operators';
import {OctopusConnectService} from 'octopus-connect';
import { Location } from '@angular/common'

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnInit {
    collectionId: string;
    collection$: Observable<any>;
    customBlocks: CustomBlock[] = [];
    public showLoader = true;

    constructor(
        private route: ActivatedRoute,
        private octopusConnect: OctopusConnectService,
        private location: Location
    ) {}

    ngOnInit(): void {
        this.collectionId = this.route.snapshot.paramMap.get('alias');
        this.collection$ = this.octopusConnect.loadEntity('collections', this.collectionId).pipe(take(1));

        this.collection$.subscribe(data => {
            this.customBlocks = data.get('blocks');
            this.showLoader = false;
        });
    }

    public goBackToPrevPage(): void {
        this.location.back();
    }
}
