import {takeUntil} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DataEntity} from 'octopus-connect';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {BasicPageService} from 'fuse-core/components/basic-page/basic-page.service';
import {Subject, Subscription} from 'rxjs';
import {BlockHandlerService} from "fuse-core/components/collection/custom-blocks/block-handler.service";
import {LoadTemplateDataPipe} from "fuse-core/pipes/load-template-data.pipe";
import {LoadTemplateComponentPipe} from "fuse-core/pipes/load-template-component.pipe";

@Component({
    selector: 'app-basic-page',
    templateUrl: './basic-page.component.html',
    styleUrls: ['./basic-page.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BasicPageComponent implements OnInit, OnDestroy {

    @Input() private alias: string;
    @Input('page') private inputPage: DataEntity;
    @Input('title') public displayTitle = true;
    @Output() private action = new EventEmitter<void>();
    private langSubscription: Subscription;
    private unsubscribeInTakeUntil = new Subject<void>();
    public loadedPage: DataEntity;
    public iframeUrl: SafeResourceUrl;
    public url: URL;

    constructor(
        private route: ActivatedRoute,
        private translate: TranslateService,
        private domSanitizer: DomSanitizer,
        private pageService: BasicPageService,
        private loadTemplateDataPipe: LoadTemplateDataPipe,
        private loadTemplateComponentPipe: LoadTemplateComponentPipe,
        public blockHandler: BlockHandlerService
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe((value) => {
            if (!this.inputPage && value['alias']) {
                this.alias = value['alias'];
                this.initialise();
            }
        });
        this.route.title.subscribe((title) => {
            if (title) {
                this.alias = title;
                this.initialise();
            }
        });
    }

    private initialise(): void {
        this.getPage();
        if (!this.langSubscription) {
            this.langSubscription = this.translate.onLangChange.pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe(() => {
                this.getPage();
            });
        }
    }

    private getPage(): void {
        this.pageService.loadPage(this.alias).subscribe((page) => {
            this.loadedPage = page;
            if (this.loadedPage.get('body')){
                this.loadTemplateComponentPipe
                    .transform(this.page.get('body'))
                    .pipe(takeUntil(this.unsubscribeInTakeUntil))
                    .subscribe((components) => {
                        this.loadedPage.attributes['components'] = components;
                    });
                this.loadTemplateDataPipe
                    .transform(this.page.get('body'))
                    .pipe(takeUntil(this.unsubscribeInTakeUntil))
                    .subscribe((bodyWithData) => {
                        this.loadedPage.attributes['body'] = this.domSanitizer.bypassSecurityTrustHtml(bodyWithData);
                    });
            }
            if (this.loadedPage.get('iframeUrl')){
                this.url = new URL(this.loadedPage.attributes['iframeUrl']);
                this.url.searchParams.forEach((value, key) => {
                    if (key === 'uid') {
                        this.url.searchParams.set('uid', this.pageService.userData.id.toString());
                    }
                    if (key === 'prenom') {
                        this.url.searchParams.set('prenom', this.pageService.userData.get('label'));
                    }
                    if (key === 'codeclasse') {
                        this.url.searchParams.set('codeclasse', this.pageService.userData.get('groups')[0].toString());
                    }
                    if (key === 'token') {
                        this.url.searchParams.set('token', JSON.parse(localStorage.getItem('http_accessToken')));
                    }
                });
                this.iframeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.url.href);
            }
            this.loadedPage.get('pages').forEach((subpage) => {
                this.loadTemplateDataPipe
                    .transform(subpage.body)
                    .pipe(takeUntil(this.unsubscribeInTakeUntil))
                    .subscribe((bodyWithData) => {
                        subpage.body = this.domSanitizer.bypassSecurityTrustHtml(bodyWithData);
                    });
            });
        });
    }

    public clickOnPage(event: MouseEvent) {
        this.blockHandler.onLinkClick(event, () => {
            this.action.emit();
        });
    }

    public get page(): DataEntity {
        return this.inputPage ? this.inputPage : this.loadedPage;
    }

    public get brand(): string {
        return this.pageService.brand;
    }

    public get isCustomBanner(): boolean {
        return this.pageService.isCustomBanner && this.alias && this.alias.includes('home-' + this.brand);
    }
    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }
}
