import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';

/**
 * generic component to upload a file all logic must be place outside the component
 * upload is made by button or by drag and drop
 * example of use :
 *              <app-upload-file-generic
 *                   (file)="uploadFileEvent($event)"
 *                   (error)="fileError($event)"
 *                   [title]="'account-management.upload_file_title' | translate"
 *                   [btnLabel]="'account-management.upload_file_btn_label' | translate"
 *                   [description]="" ]="'account-management.upload_description' | translate"
 *                   [maxSizeFile]="profileService.settings.feedbackMaxFileSize">
 *                   [error]="errorToShow"
 *              </app-upload-file-generic>
 */
@Component({
    selector: 'app-upload-file-generic',
    templateUrl: './upload-file-generic.component.html',
})
export class UploadFileGenericComponent {
    @Input() title = ''; // before button
    @Input() btnLabel = ''; // label of button
    @Input() description = ''; // description after the button
    @Input() maxSizeFile: string; // max size allowed
    @Input() extensionAllowed = '.png, .jpg, .jpeg'; // extenssion allowed to show when open button click
    @Input() formatAllowed = ['image/jpg', 'image/png', 'image/jpeg']; // file allowed to be selected and send
    @Input() errorMsg = '';
    @Output() file = new EventEmitter<File>(); // file add send to parent
    @Output() error = new EventEmitter<ErrorUploadFile>(); // error when drop file

    /**
     * droped file or file adding by click come here
     * @param files : add file by drag and drop or by click on button
     */
    public fileDropped(files: NgxFileDropEntry[]): void {
        for (const droppedFile of files) {

            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    this.fileAddEvent(file);
                });
            } else {
                this.error.emit(ErrorUploadFile.errorDuringDropFile);
            }
        }
    }

    /**
     * event launch when a file is choosen
     * @param file : file choose event
     */
    public fileAddEvent(file: File): void {
        if (this.isSizeTooBig(file)) {
            return;
        }

        if (file && this.validateFileType(file)) {
            this.file.emit(file);
        } else {
            this.error.emit(ErrorUploadFile.formatNotAllowed);
        }
    }

    /**
     * check if size is allowed
     * @param file : file choose event
     * @private
     */
    private isSizeTooBig(file: File): boolean {
        if (file.size > +(this.maxSizeFile + '000000')) {
            this.error.emit(ErrorUploadFile.fileIsTooBig);
            return true;
        } else {
            return false;
        }
    }

    /**
     * check if format to upload is allowed
     * @param file : file
     * @private
     */
    private validateFileType(file: File): boolean {
        if (file) {
            return this.formatAllowed.includes(file.type);
        }
        return false;
    }

    /**
     * not used for moment code it if needed
     * @param event
     */
    public fileOver(event): void {
    }

    /**
     * not used for moment code it if needed
     * @param event
     */
    public fileLeave(event): void {
    }
}

export enum ErrorUploadFile {
    formatNotAllowed = 'formatNotAllowed',
    fileIsTooBig = 'fileIsTooBig',
    errorDuringDropFile = 'errorDuringDropFile'
}
