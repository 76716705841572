/**
 * Défini si le resultat est correct, était correct, etc.
 */
export enum ItemAnswerStateEnum {
    // Intouché donc disponible
    pristine = 'pristine',
    // Actuellement bon par rapport a la consigne actuelle
    currentlyCorrect = 'currently-correct',
    // Etait bon par rapport a une précédente consigne
    wasCorrect = 'was-correct',
    // Faux
    incorrect = 'incorrect',
    // manquante
    missing = 'missing'
}