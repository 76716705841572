import {Component, Inject, OnInit} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

/**
 * component to show pop up metadata info in graph dashboard
 */
@Component({
    selector: 'app-info-metadata-dialog',
    templateUrl: './info-metadata-dialog.component.html',
})
export class InfoMetadataDialogComponent implements OnInit {
    public metadata;
    public methodsNames: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            metadatas: any;
            settings: { metadataDialogFields: string[] };
        }, public dialogRef: MatDialogRef<InfoMetadataDialogComponent>) {
    }

    ngOnInit(): void {
        this.metadata = this.data.metadatas;
        this.methodsNames = !!this.metadata.licenseContent && this.metadata.licenseContent.map(x => x.label).join(',');
    }

    display(field: string): boolean {
        return this.data.settings.metadataDialogFields.includes(field);
    }
}
