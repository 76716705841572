import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import {TralalereBarComponent} from './tralalere-bar.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../../shared/shared.module';
import {ButtonHelpModule} from "fuse-core/components/button-help/button-help.module";

@NgModule({
    imports: [
        CommonModule,
        MatMenuModule,
        MatIconModule,
        MatToolbarModule,
        SharedModule,
        ButtonHelpModule
    ],
    exports: [
        TralalereBarComponent
    ],
    declarations: [
        TralalereBarComponent
    ]
})

export class TralalereBarModule { }
