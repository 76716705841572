import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ButtonBlock} from "fuse-core/components/basic-page/custom-blocks/button-block/button-block.model";
import {Params, Router} from "@angular/router";
import {ActionsService} from "fuse-core/services/actions.service";
import {ConditionsService} from "fuse-core/services/conditions.service";
import {Observable} from "rxjs";
import {LoadTemplateDataPipe} from "fuse-core/pipes/load-template-data.pipe";

@Component({
    selector: 'app-button-block',
    templateUrl: './button-block.component.html',
    styleUrls: ['./button-block.component.scss']
})
export class ButtonBlockComponent implements OnInit, OnChanges {
    @Input() private blockData: ButtonBlock;
    @Output() private action = new EventEmitter<() => void>();

    private link: URL = null;

    constructor(
        private actions: ActionsService,
        private conditions: ConditionsService,
        private loadTemplateData: LoadTemplateDataPipe,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.blockData) {
            if (this.blockData.link) {
                this.loadTemplateData.transform(this.blockData.link).subscribe((link) => {
                    if (link.indexOf('http') === 0) {
                        this.link = new URL(link);
                    } else if (link.indexOf('/') === 0) {
                        this.link = new URL(document.location.origin + link);
                    } else {
                        this.link = new URL(document.location.origin + '/' + link);
                    }
                });
            }
        }
    }

    public get text(): string {
        return this.blockData.text;
    }

    public get icon(): string {
        return this.blockData.icon;
    }

    public get description(): string {
        return this.blockData.description;
    }

    public triggerAction(): void {
        this.action.emit(() => {
            if (this.blockData.action) {
                this.actions.do(this.blockData.action);
            }

            if (this.link) {
                if (this.link.origin === document.location.origin) {
                    const params: Params = {};
                    for (let [key, value] of this.link.searchParams.entries()) {
                        params[key] = value;
                    }
                    this.router.navigate([this.link.pathname], {queryParams: params});
                } else {
                    window.open(this.link, '_blank');
                }
            }
        });
    }

    public isDisplayed$(): Observable<boolean> {
        return this.conditions.check$(this.blockData.condition);
    }

    public isButton(): boolean {
        return this.blockData.button;
    }

    public isSecondary(): boolean {
        return this.blockData.secondary;
    }
}
