
import {take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {Observable, ReplaySubject} from 'rxjs';
import {defaultLoginRoute} from '../../../../../settings';
import {AuthenticationService} from '@modules/authentication';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {AuthorizationService} from '@modules/authorization';
import {AsyncRules} from '@modules/activities/core/models/rules';

@Injectable({
    providedIn: 'root'
})
export class CanShowLessonListGuard  {
    constructor(public lessonsService: LessonsService,
                private authService: AuthenticationService,
                private router: Router,
                private activitiesService: ActivitiesService,
                private authorization: AuthorizationService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        const subject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

        this.authorization.currentUserCan<Observable<boolean>>(AsyncRules.AccessLessonList)
            .subscribe((isAuthorized) => {
                if (isAuthorized === false) {
                    subject.next(false);
                    this.router.navigate([defaultLoginRoute]);
                }
            });

        this.activitiesService.licensingMethods.pipe(take(1)).subscribe((methods) => {
            const noLicensingMethods = this.activitiesService.licensingSettings
                && this.activitiesService.licensingSettings.restrict.includes('lessons-list')
                && methods.length === 0;

            if (this.authService.isTrainer() && noLicensingMethods) {
                subject.next(false);
                this.router.navigate(['/licensing-restricted']);
            } else {
                subject.next(true);
            }
        });

        return subject;
    }
}
