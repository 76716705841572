import {Injectable} from '@angular/core';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {modulesSettings} from '../settings';
import {AuthenticationService} from '@modules/authentication';
import {ModelSchema, Structures} from 'octopus-model';
import {FuseNavigationItem, MenuItemConfig} from 'fuse-core/types';
import {TranslateService} from "@ngx-translate/core";

const settingsStructure = new ModelSchema({
    displayMenu: Structures.object()
});

@Injectable()
export class DynamicNavigationService {

    private currentMenu: string;

    private menus: { [key: string]: { [key: number]: FuseNavigationItem[] } } = {};
    private pathClassItems: Array<string>;
    private settings: { [key: string]: any };

    constructor(
        private fuseNavigationService: FuseNavigationService,
        private authenticationService: AuthenticationService,
        private translate: TranslateService,
    ) {
        this.settings = settingsStructure.filterModel(modulesSettings.mainMenu);
    }

    public get currentMenuId(): string {
        return this.currentMenu;
    }

    switchClassPath(
        menuId: string
    ): void {
        const refClassPath = [
            'homepage',
            'dashboard',
            'groups',
            'workgroups',
            'learner',
            'corpus',
            'login',
            'projects',
            'register',
            'research-sheet',
            'tools',
            'research-template',
            'forms',
            'followed',
            'section-draft',
            'ideas-wall',
            'lessons',
            'player',
            'activities'];
        let classPaths: Array<string>;
        const classListTemp: Array<string> = [];
        const sitemapLevel = 'level0';
        const wrapper = document.getElementsByTagName('body')[0];
        classPaths = window.location.pathname.split('/');
        this.pathClassItems = Array.from(wrapper.classList);


        wrapper.className = ''; // remove all class
        wrapper.classList.add(this.pathClassItems[0]);

        if (menuId === sitemapLevel) {
            wrapper.classList.remove('level1');
            wrapper.classList.add('level0');
        } else {
            wrapper.classList.remove('level0');
            wrapper.classList.add('level1');
        }

        for (const key in classPaths) {

            if (refClassPath.indexOf(classPaths[key]) !== -1) {
                classListTemp.push(classPaths[key]);
            }
        }
        // use just the last module path for css class
        wrapper.classList.add(classListTemp[classListTemp.length - 1]);

    }

    /**
     * Permet d'ajouter un élément au menu ou de rafraichir sa position et ses propriété s'il un élément avec le même id existe.
     * @param menuId
     * @param menuItem
     */
    public refreshModuleMenu(
        menuId: string,
        menuItem: FuseNavigationItem | FuseNavigationItem[]
    ): void {
        this.clearMenuItem(menuId, ...((Array.isArray(menuItem) ? menuItem : [menuItem])).map(mi => mi.id));
        this.internalRegisterModuleMenu(menuId, menuItem);
    }

    /**
     * @deprecated on utilise maintenant {@link refreshModuleMenu}
     * @param menuId
     * @param menuItem
     */
    public registerModuleMenu(menuId: string, menuItem: FuseNavigationItem | FuseNavigationItem[]): void {
        this.internalRegisterModuleMenu(menuId, menuItem);
    }

    /**
     * check if menu item exist
     * @param menuId
     * @param itemId
     */
    public menuExist(menuId: string, itemId: string): boolean {
        return !!this.settings.displayMenu[this.authenticationService.accessLevel]
            && !!this.settings.displayMenu[this.authenticationService.accessLevel][menuId]
            && !!this.settings.displayMenu[this.authenticationService.accessLevel][menuId].filter(item => item === itemId || item.name === itemId).length;
    }
    
    private getMenuLevelConfig(levelId: string): (string|MenuItemConfig)[] {
        return this.settings.displayMenu[this.authenticationService.accessLevel]
            ? this.settings.displayMenu[this.authenticationService.accessLevel][levelId]
            : this.settings.displayMenu.default[levelId];
    }
    
    private getMenuItemConfig(menuLevel: (string|MenuItemConfig)[], menuItem: FuseNavigationItem): MenuItemConfig {
        return menuLevel.find(item => typeof item === 'object' && item.name === menuItem['id']) as MenuItemConfig;
    }

    private internalRegisterModuleMenu(
        menuId: string,
        menuItem: FuseNavigationItem | FuseNavigationItem[]
    ): void {
        const menuIsArray = Array.isArray(menuItem);
        let weight = 0;
        const menuLevel = this.getMenuLevelConfig(menuId);
        const alreadyExist: boolean = this.flattenWeightedMenu(menuId).some((element) => element['id'] === menuItem['id']);
        if (menuId && !alreadyExist) {
            if (menuIsArray) {
                menuItem = (<FuseNavigationItem[]>menuItem).filter(item => menuLevel.includes(item['id']));
            } else if (!menuLevel.includes(menuItem['id']) && !menuLevel.find(item => typeof item === 'object' && item.name === menuItem['id'])) {
                return;
            }
            const itemObject = menuLevel.find(item => typeof item === 'object' && item.name === menuItem['id']) as MenuItemConfig;
            if (itemObject) {
                this.removeChildrenIfNotInSettings(itemObject, menuItem as FuseNavigationItem);
                if (itemObject.translate) {
                    menuItem['translate'] = this.getTranslateByRole(itemObject, 'parent');
                    for (const childKey in itemObject.translate.childs) {
                        if (itemObject.translate.childs.hasOwnProperty(childKey)) {
                            const child = menuItem['children'].find(someChild => someChild.id === childKey);
                            if (child) {
                                child.translate = this.getTranslateByRole(itemObject, 'childs', childKey);
                            }
                        }
                    }
                }
                if (itemObject.hasOwnProperty('displayIcon')) {
                    menuItem['displayIcon'] = itemObject['displayIcon'];
                }
                if (itemObject.hasOwnProperty('icon')) {
                    menuItem['icon'] = itemObject['icon'];
                }
                if (itemObject.hasOwnProperty('class')) {
                    menuItem['class'] = itemObject['class'];
                }
            }

            weight = menuLevel.indexOf(menuItem['id']) >= 0 ? menuLevel.indexOf(menuItem['id']) : menuLevel.indexOf(itemObject);


            this.menus[menuId] = this.menus[menuId] || {};
            this.menus[menuId][weight] = this.menus[menuId][weight] || [];

            // temporaire
            if (menuIsArray) {

                for (let i = 0; i < (<FuseNavigationItem[]>menuItem).length; i++) {
                    const element = menuItem[i];
                    this.menus[menuId][menuLevel.indexOf(element['id'])] = [element];
                }
            } else {
                let castedMenuItem = menuItem as FuseNavigationItem;
                if (castedMenuItem.children?.length === 1 && castedMenuItem.type === 'collapse' && castedMenuItem.id !== 'forFurther') {
                    castedMenuItem.url = castedMenuItem.children[0].url;
                    castedMenuItem = castedMenuItem.children[0];
                    castedMenuItem.children = [];
                }
                this.menus[menuId][weight].push(castedMenuItem);
            }

            if (this.currentMenu === menuId) {
                this.generateMenu(menuId, true);
            }
        }
    }

    private removeChildrenIfNotInSettings(settingsMenuItem: MenuItemConfig, activeMenuItem: FuseNavigationItem): void {
        if (settingsMenuItem?.children) { // Si on a un filtre de sous-menu imposé
            activeMenuItem.children = activeMenuItem.children ?? [];
            activeMenuItem.children = activeMenuItem.children.filter(item => settingsMenuItem.children.includes(item.id));
        }
    }

    private getTranslateByRole(item, itemType: string, childId: string = null): string {
        let translate = item.translate[itemType];
        if (itemType === 'childs') {
            translate = translate[childId];
        }
        const role = translate[this.authenticationService.accessLevel] ? this.authenticationService.accessLevel : 'default';
        return translate[role];
    }

    setChildren(
        menuId: string,
        itemId: string,
        children: FuseNavigationItem[]
    ): void {
        this.clearChildren(menuId, itemId);

        children.forEach(child => {
            this.addChildTo(menuId, itemId, child);
        });
    }

    clearChildren(
        menuId: string,
        itemId: string
    ): void {
        const flatten: Object = this.flattenWeightedMenu(menuId);

        for (const key in flatten) {
            if (flatten[key]['id'] === itemId) {
                flatten[key]['children'] = [];
            }
        }
    }

    addChildTo(
        rootMenuId: string,
        parentMenuId: string,
        childElementToAddToParentMenu: FuseNavigationItem
    ): void {
        const flattenActiveMenu = this.flattenWeightedMenu(rootMenuId);
        const menuSettingsLevel = this.getMenuLevelConfig(rootMenuId);
        for (const key in flattenActiveMenu) {
            if (flattenActiveMenu[key]['id'] === parentMenuId) {
                if (!flattenActiveMenu[key]['children']) {
                    flattenActiveMenu[key]['children'] = [];
                }

                flattenActiveMenu[key]['children'].push(childElementToAddToParentMenu);
                flattenActiveMenu[key]['children'].sort((a, b) => a.weight < b.weight ? -1 : 0);

                const itemSettings = this.getMenuItemConfig(menuSettingsLevel, flattenActiveMenu[key]);
                this.removeChildrenIfNotInSettings(itemSettings, flattenActiveMenu[key]);
            }
        }
    }

    clearMenu(menuId: string): void {
        this.menus[menuId] = {};
    }

    clearMenuItem(menuId: string, ...itemIdList: string[]): void {
        if (this.menus[menuId]) {
            itemIdList.forEach(itemId => {
                for (const key in this.menus[menuId]) {
                    for (const subKey in this.menus[menuId][key]) {
                        if (this.menus[menuId][key][subKey]['id'] === itemId) {
                            this.menus[menuId][key].splice(+subKey, 1);
                        }
                    }
                }
            });

            this.generateMenu(menuId, true);
        }
    }
    
    private hideMenuItemsForLocalization(menu: FuseNavigationItem[]): FuseNavigationItem[] {
        const menuLevel = this.getMenuLevelConfig(this.currentMenu);
        return menu.filter((menuItem) => {
            const menuItemConfig = this.getMenuItemConfig(menuLevel, menuItem);
            return !menuItemConfig // when the menuItem is configured with a simple string, this variable is undefined
                || (menuItemConfig.hasOwnProperty('onlyForLocalizations') && menuItemConfig.onlyForLocalizations.includes(this.translate.currentLang))
                || !menuItemConfig.hasOwnProperty('onlyForLocalizations');
        });
    }

    private flattenWeightedMenu(id: string): FuseNavigationItem[] {

        let flatten: FuseNavigationItem[] = [];

        for (const weight in this.menus[id]) {
            if (this.menus[id][weight]) {
                flatten = flatten.concat(this.menus[id][weight]);
            }
        }

        return flatten;
    }

    public generateMenu(id: string, refresh = false): void {
        if (this.currentMenu === id && !refresh) {
            return;
        }
        this.currentMenu = id;

        const menu: FuseNavigationItem[] = this.hideMenuItemsForLocalization(this.flattenWeightedMenu(id));

        this.fuseNavigationService.unregister(id);
        this.fuseNavigationService.register(id, menu);
        this.fuseNavigationService.setCurrentNavigation(id);
    }
}
