import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';

@Component({
    selector: 'app-dictionary-keyboard',
    templateUrl: './dictionary-keyboard.component.html',
})

export class DictionaryKeyboardComponent implements OnChanges {
    @Output('selectedElement') choosenElement = new EventEmitter<string>();
    @Input('key') keyInput: string;

    public keyboardElement = [
        {value: 'A', html: '<span>A</span>', class: 'row1'},
        {value: 'B', html: '<span>B</span>', class: 'row1'},
        {value: 'C', html: '<span>C</span>', class: 'row1'},
        {value: 'D', html: '<span>D</span>', class: 'row1'},
        {value: 'E', html: '<span>E</span>', class: 'row1'},
        {value: 'F', html: '<span>F</span>', class: 'row1'},
        {value: 'G', html: '<span>G</span>', class: 'row1'},
        {value: 'H', html: '<span>H</span>', class: 'row1'},
        {value: 'I', html: '<span>I</span>', class: 'row1'},
        {value: 'J', html: '<span>J</span>', class: 'row2'},
        {value: 'K', html: '<span>K</span>', class: 'row2'},
        {value: 'L', html: '<span>L</span>', class: 'row2'},
        {value: 'M', html: '<span>M</span>', class: 'row2'},
        {value: 'N', html: '<span>N</span>', class: 'row2'},
        {value: 'O', html: '<span>O</span>', class: 'row2'},
        {value: 'P', html: '<span>P</span>', class: 'row2'},
        {value: 'Q', html: '<span>Q</span>', class: 'row2'},
        {value: 'R', html: '<span>R</span>', class: 'row2'},
        {value: 'S', html: '<span>S</span>', class: 'row3'},
        {value: 'T', html: '<span>T</span>', class: 'row3'},
        {value: 'U', html: '<span>U</span>', class: 'row3'},
        {value: 'V', html: '<span>V</span>', class: 'row3'},
        {value: 'W', html: '<span>W</span>', class: 'row3'},
        {value: 'X', html: '<span>X</span>', class: 'row3'},
        {value: 'Y', html: '<span>Y</span>', class: 'row3'},
        {value: 'Z', html: '<span>Z</span>', class: 'row3'},
        {
            value: '123',
            html: '<span class="downstair">1</span><span class="upstair">2</span><span class="downstair">3</span>',
            class: 'row3 numbers'
        }
    ];

    public selectedElement = 'A'; // default value A

    ngOnChanges(): void {
        if (this.keyInput) {
            this.selectedElement = this.keyInput;
        }
    }

    clickEvent(elem: string): void {
        this.choosenElement.emit(elem);
    }
}
