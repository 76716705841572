/**
 * List of available actions executed by {@link UserActionButtonComponent}
 */
export enum UserActionButtonLabels {
    goBack = 'goBack',
    modifyAnswer = 'modifyAnswer',
    gotoNext = 'gotoNext',
    gotoNextLesson = 'gotoNextLesson',
    launchNextActivity = 'launchNextActivity',
    launchPreviousActivity = 'launchPreviousActivity',
    resetAnswer = 'resetAnswer',
    saveAnswer = 'saveAnswer',
    seeAnswer = 'seeAnswer',
    seeSolution = 'seeSolution',
    testAnswer = 'testAnswer',
}