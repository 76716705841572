import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {DataCollection, DataEntity} from 'octopus-connect';
import {takeUntil} from 'rxjs/operators';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ContestService} from '@modules/contest';
import {ActivatedRoute} from '@angular/router';
import {Contest, ContestRegistrationEntity} from '@modules/contest/core/model/contest';
import {AutoUnsubscribeTakeUntilClass} from "shared/models";

@Component({
    selector: 'app-contest-groups',
    templateUrl: './contest-groups.component.html',
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ContestGroupsComponent extends AutoUnsubscribeTakeUntilClass {

    groups: DataEntity[] = [];
    learners: DataEntity[] = [];
    columnsToDisplay = ['name', 'learnersCount', 'progressBar', 'progressCount'];
    expandedElement: DataEntity | null;
    dataSource = new MatTableDataSource();
    private contest: Contest;
    private registrations: ContestRegistrationEntity[] = [];

    constructor(
        private contestService: ContestService,
        private route: ActivatedRoute,
    ) {
        super();

        this.route.parent.params.subscribe(params => {
            if (params['id']) {
                this.contestService
                    .getContestById(params['id'])
                    .subscribe((contest: Contest) => {
                        this.contest = contest;
                        this.initialize();
                    });
            }
        });
    }

    private initialize(): void {
        this.getRegistrations()
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil)
            )
            .subscribe(registrations => {
                this.registrations = registrations;
            });

        this.getProgression().pipe(
            takeUntil(this.unsubscribeInTakeUntil)
        ).subscribe(
            (progressions) => {
                if (progressions.entities) {
                    const data = [];
                    progressions.entities.forEach((group: DataEntity) => {
                        data.push({
                            id: group.id,
                            name: group.get('label'),
                            learnersCount: group.get('learners').length,
                            progressBar: group.get('average'),
                            progressCount: group.get('average'),
                            learners: group.get('learners'),
                        });
                    });
                    this.dataSource.data = data;
                }
            }
        );
    }

    private getRegistrations(): Observable<ContestRegistrationEntity[]> {
        return this.contestService.getUserRegistrations(this.contest.id);
    }

    private getProgression(): Observable<DataCollection> {
        return this.contestService.getContestProgression(this.contest.id);
    }

    public editRegistration(): void {
        this.contestService
            .openContestRegistrationForm({
                contest: this.contest,
                registrations: this.registrations,
            })
            .subscribe((result) => {
                if (result) {
                    this.resetUnsubscribe();
                    this.initialize();
                }
            })
    }
}
