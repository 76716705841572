import {Component, Inject, OnInit} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-wins-summary',
    templateUrl: './wins-summary.component.html',
    styleUrls: ['./wins-summary.component.scss']
})
/**
 * resume of win medal and star
 * take in entry an array of { medal: string, silver: string, bronze: string, gold: string, platinium: string }
 * medal field can have value 'locked' , 'bronze', 'silver', 'gold' or 'platinium'
 * other fiels gold bronze etc are used for star they can have value with empty string '' for disabled effect on it without number
 * the other value possible are number in string format like '1' '2' etc.
 * to call it we will use code like this
 *  const dialogRef = this.dialog.open(WinsSummaryComponent, {
                panelClass: 'feedback-earning-dialog',
                data: [{medal: 'locked', silver: '', bronze: '4', gold: '1',  platinium: ''},
                       {medal: 'platinium', silver: '23', bronze: '', gold: '11', platinium: '4'},
                       {medal: 'gold', gold: '', silver: '', bronze: '', platinium: ''},
                       {medal: 'bronze', gold: 'locked', silver: 'locked', bronze: 'locked', platinium: 'locked'}
                ]
            });
 */
export class WinsSummaryComponent implements OnInit {
    public data: { medal: string, silver: string, bronze: string, gold: string, platinium: string }[] = [];

    constructor(public dialogRef: MatDialogRef<WinsSummaryComponent>,
                @Inject(MAT_DIALOG_DATA) data) {
        this.data = data;
    }

    ngOnInit(): void {}
}
