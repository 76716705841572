import * as _ from 'lodash-es';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataEntity} from 'octopus-connect';
import {MindmapRepositoryService} from '@modules/mindmap/core/mindmap-repository.service';
import {MindmapService} from '../mindmap.service';
import {brand} from '../../../../settings';
import {tap} from 'rxjs/operators';

export type Action = 'show' | 'edit' | 'delete' | 'goToLesson'

@Component({
    selector: 'app-mindmap-card',
    templateUrl: './mindmap-card.component.html',
})
export class MindmapCardComponent implements OnInit {
    /**
     * Mindmap resource used to get card data
     */
    @Input()
    public mindmapBasicSearchEntity: DataEntity;

    @Input()
    public embedded: boolean;
    @Input()
    public forceAssociationOnSave: DataEntity;
    /**
     * Emit when granule has changed from this component and need to be reloaded
     */
    @Output()
    public hasChanged = new EventEmitter<void>();
    @Output()
    public actionTriggered = new EventEmitter<Action>();
    /**
     * Mindmap creation date
     */
    public creationDate: string;
    /**
     * card image url (in the left side)
     */
    public imageUri: string;
    /**
     * Mindmap last modification date
     */
    public lastUpdate: string;
    /**
     * Mindmap associated lesson name
     */
    public associatedLessons: { title: string; id: string | number }[] = [];
    /**
     * Define if assiciated lesson name is loading from it's id
     */
    public associatedLessonIsLoading = true;
    /**
     * Define mindmap title
     */
    public title: string;
    private mindmapEntity: DataEntity;

    constructor(
        private mindmapSvc: MindmapService,
        private mindmapRepoSvc: MindmapRepositoryService
    ) {
    }

    ngOnInit(): void {
        this.creationDate = new Date(
            this.mindmapBasicSearchEntity.get('created') * 1000
        ).toLocaleDateString();
        this.imageUri = this.getImageUri();
        this.lastUpdate = new Date(
            this.mindmapBasicSearchEntity.get('changed') * 1000
        ).toLocaleDateString();
        this.title = this.mindmapBasicSearchEntity.get('title');

        // For get the associated lesson title, we need to load the mindmap activity content.
        // We don't have the activity content here and to have it, we need to load the granule and everything (incluce the activitycontent) is loaded with it.
        this.mindmapRepoSvc
            .getMindmap(this.mindmapBasicSearchEntity.id)
            .pipe(
                tap((mindmap) => (this.mindmapEntity = mindmap)),
                // There are only one activity content and one assignated lesson by mindmap
                tap((mindmap) =>
                    this.associatedLessons = _.get(
                        mindmap.get('reference'),
                        'activity_content[0].associated_nodes'
                    ) || []
                ),
                tap(() => (this.associatedLessonIsLoading = false))
            )
            .subscribe();
    }

    /**
     * Go to mindmap editor.
     * No need to know how to edit a mindmap here.
     * @param $event
     */
    public edit($event: MouseEvent): void {
        $event.preventDefault();
        this.actionTriggered.next("edit");
        const forceAssociation = this.forceAssociationOnSave?.id || null;
        this.mindmapSvc.goToMindmapDataEdition(this.mindmapEntity, this.embedded, forceAssociation);
    }

    /**
     * Delete the current Mindmap
     * @param $event
     */
    public delete($event: MouseEvent): void {
        $event.preventDefault();
        this.actionTriggered.next("delete");
        this.mindmapSvc
            .deleteMindmap(this.mindmapEntity.id)
            .pipe(tap(() => this.hasChanged.next()))
            .subscribe();
    }

    /**
     * Display the content of the mindmap
     * @param $event
     */
    public show($event: MouseEvent): void {
        $event.preventDefault();
        this.actionTriggered.next("show");
        this.mindmapSvc.displayMindmap(this.mindmapBasicSearchEntity.id);
    }

    public goToLesson(id: string | number): void {
        this.actionTriggered.next("goToLesson");
        this.mindmapSvc.goToLesson(id);
    }

    /**
     * Extracted way to know the mindmap image urls
     */
    private getImageUri(): string {
        return './assets/' + brand + '/icons/illu_outil_cm.svg';
    }

    dissociateLesson($event: MouseEvent, associatedLesson: { title: string; id: string | number }) {
        $event.stopPropagation()
        this.associatedLessonIsLoading = true
        this.mindmapSvc.dissociateLesson(this.mindmapEntity, associatedLesson).subscribe(() => {
            this.ngOnInit();
        })
    }
}
