import {PluginType} from '@modules/activities/core/services/generic-plugins.service';

/**
 * Structure of a plugin setting
 * @remarks it's a copy of {@link PluginSetting} but used for {@link ModelSchema}
 */
export interface PluginSetting {
    label: string; // Don't know if it's better to have it here or call the plugin by communication center to get the toolIdentifier
    octopusConnectRoom: string;
    pluginType: PluginType;
    iconIdentifier: string;
}
