// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.folded:not(.unfolded) :host .nav-link > .nav-link-title,
.folded:not(.unfolded) :host .nav-link > .nav-link-badge {
  opacity: 0;
  transition: opacity 200ms ease;
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/navigation/vertical/item/item.component.scss"],"names":[],"mappings":"AAMY;;EAEI,UAAA;EACA,8BAAA;AALhB","sourcesContent":[":host {\r\n\r\n    .folded:not(.unfolded) & {\r\n\r\n        .nav-link {\r\n\r\n            > .nav-link-title,\r\n            > .nav-link-badge {\r\n                opacity: 0;\r\n                transition: opacity 200ms ease;\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
