import {AfterViewInit, ChangeDetectorRef, Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities';
import {CommunicationCenterService} from '@modules/communication-center';
import {ItemAnswerInterface} from '@modules/activities/core/models/item-answer.interface';
import {LessonNavigationService} from '@modules/activities/core/lesson-navigation.service';
import {FullscreenService} from 'fuse-core/services/fullscreen.service';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {
    QcuActivityConfigInterface,
    QcuActivityContentInterface,
    QcuBaseComponent
} from '@modules/activities/core/player-components/qcu-base/qcu-base.component';
import {
    missingRandomizeTools
} from '@modules/activities/core/player-components/what-is-missing/missing-randomize-tools';
import {TypedDataEntityInterface} from '../../../../../shared/models/octopus-connect/typed-data-entity.interface';
import {ActivityAttributesInterface} from '@modules/activities/core/models/activity-attributes.interface';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {ContextualService} from "@modules/activities/core/services/contextual.service";

@Component({
    selector: 'app-what-is-missing',
    templateUrl: './what-is-missing.component.html',
})
/**
 * this component extend qcu be carefull to not implement ngoninit or other element that can overide
 * the default behaviour of parent class without reason
 */
export class WhatIsMissingComponent extends QcuBaseComponent {
    public start = false;
    public availableAnswersForButton: ItemAnswerInterface[] = [];
    public hideElement: boolean;

    constructor(protected activatedRoute: ActivatedRoute,
                protected activitiesService: ActivitiesService,
                public config: LessonsConfigurationService,
                public fullscreenService: FullscreenService,
                protected lessonsService: LessonsService,
                protected communicationCenter: CommunicationCenterService,
                protected contextualService: ContextualService,
                protected ref: ChangeDetectorRef,
                protected lessonNavigationService: LessonNavigationService
    ) {
        super(activatedRoute, activitiesService, config, fullscreenService, lessonsService, communicationCenter, contextualService, ref, lessonNavigationService);
    }

    protected initialize() {
        this.start = false;
        this.hideElement = false;
        this.answersSelected = [];
        this.availableAnswersForButton = [];
        super.initialize();
        this.availableAnswers.forEach((answer: ItemAnswerInterface) => {
            answer.state = ItemAnswerStateEnum.pristine;
            answer.correct_answer = false;
        });
        if (this.displayForSummary) {
            this.start = true;
        }
    }


    /**
     * set the order of answers and of buttons
     * @private
     */
    private setAnswersAndButtonOrder(): void {
        const answers = missingRandomizeTools.shuffleAnswers(this.availableAnswers, this.availableAnswersForButton, true);
        this.availableAnswers = answers.availableAnswers;
        this.availableAnswersForButton = answers.availableAnswersForButton.map((availableAnswer) => {
            if (this.displayForSummary && availableAnswer.correct_answer) {
                availableAnswer.state = ItemAnswerStateEnum.currentlyCorrect;
            } else {
                availableAnswer.state = ItemAnswerStateEnum.pristine;
            }
            return availableAnswer;
        });
    }

    public dynamicImgContainerClass(option: ItemAnswerInterface, cardPosition: number): string {
        // only 3 carte and it s the last we add class to center it
        const centerLastCard = this.availableAnswers.length === 3 && cardPosition === 2 ? ' center-last-card' : '';
        const isHide = this.start &&
            (this.hideElement
            || (option.correct_answer
            && !this.answersSelected.some((answer) => answer.id === option.id)) )
            ? ' to-hide' : ' not-to-hide';
        const isImage = this.answerContainImg ? ' button-image ':'';
        return option.state + isHide + centerLastCard + isImage;
    }


    /**
     * récupere dans la config de l'activité la mise en page.
     */
    public getColumnClass(): string {
        return this.answersSelected.length > 3 ? 'columns-2' : 'columns-1';
    }

    public validate(option: ItemAnswerInterface, waitForValidationBeforeCheckAnswer?: boolean): void {
        if (!super.waitUntilCorrectionFinished) {
            this.onOptionChange(true);
            // answer was shuffle localy so we need to update state to had visual return on good or false answer
            option.state = option.correct_answer ? ItemAnswerStateEnum.currentlyCorrect : ItemAnswerStateEnum.incorrect;
            this.communicationCenter.getRoom('stepper').getSubject('locked').next(true);
            setTimeout(() => {
                option.state = ItemAnswerStateEnum.pristine;
                this.answersSelected = [{
                    id: option.id,
                    answer: option.answer,
                    image: option.image,
                    select: true,
                    correct_answer: option.correct_answer,
                    state: ItemAnswerStateEnum.pristine,
                    feedback: option.feedback,
                    audio: option.audio
                }];

                if (!waitForValidationBeforeCheckAnswer) {
                    this.checkAnswer();
                }
            }, 500);
        }
    }


    public startActivity(): void {
        this.hideElement = true;
        this.setAnswersAndButtonOrder();
        this.start = true;
        setTimeout(() => {this.hideElement = false;}, 3000);
    }

    public showContent(option: ItemAnswerInterface): boolean {
        return !this.start
            || !this.hideElement && !option.correct_answer
            || (option.correct_answer && this.answersSelected.some((answer) => answer.id === option.id));
    }
}
