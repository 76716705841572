import {Component, Inject, Optional} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-edit-lesson-warning',
    templateUrl: './edit-lesson-warning.component.html'
})
export class EditLessonWarningComponent {

    constructor(public selfDialogRef: MatDialogRef<EditLessonWarningComponent>,
                @Optional() @Inject(MAT_DIALOG_DATA) public data: { count: number }) {
    }

    accept(): void {
        this.selfDialogRef.close(true);
    }

    cancel(): void {
        this.selfDialogRef.close(false);
    }
}
