import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataEntity} from 'octopus-connect';
import {GamecodeService} from '../gamecode.service';
import {brand} from '../../../../settings';
import * as _ from 'lodash-es';
import {tap} from 'rxjs/operators';
import {GamecodeRepositoryService} from '@modules/gamecode/core/gamecode-repository.service';

const gamecodeIconLabel = 'notepad'; // TODO when we will have a gamecode icon, we can change it here

@Component({
    selector: 'app-gamecode-card',
    templateUrl: './gamecode-card.component.html',
})
export class GamecodeCardComponent implements OnInit {
    /**
     * Gamecode resource used to get card data
     */
    @Input()
    public gamecodeBasicSearchEntity: DataEntity;
    /**
     * Emit when granule has changed from this component and need to be reloaded
     */
    @Output()
    public hasChanged = new EventEmitter<void>();
    /**
     * Gamecode creation date
     */
    public creationDate: string;
    /**
     * card image url (in the left side)
     */
    public imageUri: string;
    /**
     * Gamecode last modification date
     */
    public lastUpdate: string;
    /**
     * Gamecode associated lesson name
     */
    public associatedLessonTitle: string | null;
    public associatedLessonId: string | number;
    /**
     * Define if assiciated lesson name is loading from it's id
     */
    public associatedLessonIsLoading = false;
    /**
     * Define gamecode title
     */
    public title: string;

    constructor(private gamecodeSvc: GamecodeService,
                private gamecodeRepoSvc: GamecodeRepositoryService) {
    }

    ngOnInit(): void {
        this.creationDate = new Date(this.gamecodeBasicSearchEntity.get('created') * 1000).toLocaleDateString();
        this.imageUri = this.getImageUri();
        this.lastUpdate = new Date(this.gamecodeBasicSearchEntity.get('changed') * 1000).toLocaleDateString();
        this.title = this.gamecodeBasicSearchEntity.get('title');

        // For get the associated lesson title, we need to load the gamecode activity content.
        // We don't have the activity content here and to have it, we need to load the granule and everything (incluce the activitycontent) is loaded with it.
    }

    /**
     * Go to gamecode editor.
     * No need to know how to edit a gamecode here.
     * @param $event
     */
    public edit($event: MouseEvent): void {
        $event.preventDefault();
        this.gamecodeSvc.goToGamecodeDataEdition(this.gamecodeBasicSearchEntity);
    }

    /**
     * Delete the current Gamecode
     * @param $event
     */
    public delete($event: MouseEvent): void {
        $event.preventDefault();
        this.gamecodeSvc.deleteGamecode(this.gamecodeBasicSearchEntity.id).pipe(
            tap(() => this.hasChanged.next())
        ).subscribe();
    }

    /**
     * Display the content of the gamecode
     * @param $event
     */
    public show($event: MouseEvent): void {
        $event.preventDefault();
        this.gamecodeSvc.displayGamecode(this.gamecodeBasicSearchEntity.id);
    }

    /**
     * Extracted way to know the gamecode image urls
     */
    private getImageUri(): string {
        if (this.gamecodeBasicSearchEntity.get('image_base64') && this.gamecodeSvc.application !== 'gleamcode') {
            return 'data:image/jpg;base64,' + this.gamecodeBasicSearchEntity.get('image_base64');
        }
        return './assets/' + brand + '/images/thumbs/' + this.gamecodeSvc.application + '.jpg';
    }

    public goToLesson(): void {
        this.gamecodeSvc.goToLesson(this.associatedLessonId);
    }
}
