import {NgModule} from '@angular/core';
import {DynamicComponent} from './dynamic.component';
import {FuseDirectivesModule} from '@fuse/directives/directives';

@NgModule({
    imports: [FuseDirectivesModule],
    declarations: [
        DynamicComponent
    ],
    exports: [
        DynamicComponent
    ],
})
export class DynamicModule {
}
