// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-button {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.content {
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/corpus/core/components/resource-upload-modal/resource-upload-modal.component.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,eAAA;AAAF;;AAGA;EACE,kBAAA;AAAF","sourcesContent":["// duplicated style on _dialog.scss\r\n.close-button {\r\n  position: absolute;\r\n  top: 0;\r\n  right: 0;\r\n  cursor: pointer;\r\n}\r\n\r\n.content {\r\n  position: relative;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
