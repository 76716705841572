
import {take, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {Observable, Subscription} from 'rxjs';
import {CommunicationCenterService} from '@modules/communication-center';

@Injectable({
    providedIn: 'root'
})
export class UploadResourceService {
    public corpusId: string | number;
    public formatsSubscription: Subscription;
    public formats: DataEntity[] = [];

    constructor(private communicationCenter: CommunicationCenterService,
                private octopusConnect: OctopusConnectService,
    ) {
        this.communicationCenter.getRoom('corpus')
            .getSubject('corpusSettings')
            .subscribe((settings) => this.corpusId = settings ? settings.globalCorpus : null);
    }

    public loadFormats(): void {
        if (this.formatsSubscription) {
            this.formatsSubscription.unsubscribe();
        }

        const obs: Observable<DataEntity[]> = this.octopusConnect.loadCollection('granule-format').pipe(map(collection => collection.entities));
        this.formatsSubscription = obs.subscribe(entities => this.formats = entities);
    }

    public createUrlEntity(url): Observable<DataEntity> {
        return this.octopusConnect.createEntity('corpus-url-ressource', url).pipe(take(1));
    }

    public createRessourceMetadata(metadatas): Observable<DataEntity> {
        return this.octopusConnect.createEntity('metadatas', metadatas).pipe(take(1));
    }

    public createGranule(resource): Observable<DataEntity> {
        return this.octopusConnect.createEntity('granule', resource).pipe(take(1));
    }

    public sendEventUploadFinished(entity: DataEntity): void{
        const resource = {
            ressourceEntity: entity
        };
        this.communicationCenter
            .getRoom('activities')
            .next('addResourceToActivity', resource);
    }
}
