import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {DataCollection, OctopusConnectService} from 'octopus-connect';
import {brand, cardLayout} from 'app/settings';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../app/settings';
const settingsStructure: ModelSchema = new ModelSchema({
    urlVideoException: Structures.array([]),
});

@Injectable({
    providedIn: 'root'
})

export class HomePageService {
    public cardLayout = cardLayout;
    public settings: { [key: string]: any };

    constructor(private octopusConnect: OctopusConnectService) {
        this.settings = settingsStructure.filterModel(modulesSettings.homePage);
    }

    public loadHomePage(): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('homepage_lms', {instance_name: brand});
    }
}
