import {Pipe, PipeTransform} from '@angular/core';
import {camelCase} from 'lodash-es';

@Pipe({
    name: 'camelCase'
})
export class CamelCasePipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): string {
        return camelCase(value);
    }

}
