import {Injectable} from '@angular/core';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../../settings';
import {DynamicGraphFilters} from '@modules/graph-bayard/core/model/dynamic-graph-filters';
import {RolesOrDefault} from '../../../../shared/roles';

const settingsStructure: ModelSchema = new ModelSchema({
    rolesCanShowBannerInfo: Structures.array(),
    filters: Structures.object({
        attendance: {always: [], hidden: []},
        progress: {always: [], hidden: []},
        ownProgress: {always: [], hidden: []}
    }),
    graphs: Structures.object({
        attendance: {
            forceFilteringByConcept: false,
        }
    }),
});

@Injectable({
    providedIn: 'root'
})
export class GraphConfigurationService {

    private settings: Partial<{
        rolesCanShowBannerInfo: RolesOrDefault[]
        filters: {
            attendance: DynamicGraphFilters,
            progress: DynamicGraphFilters,
            ownProgress: DynamicGraphFilters
        },
        graphs: {
            [graphName: string]: {
                forceFilteringByConcept: false,
            }
        },
    }>;

    constructor() {
        this.settings = settingsStructure.filterModel(
            modulesSettings.graphMulti
        );
    }

    public getRolesCanShowBannerInfo(): RolesOrDefault[] {
        return this.settings.rolesCanShowBannerInfo || [];
    }

    public getAttendanceFilters(): DynamicGraphFilters {
        return this.settings.filters?.attendance || {
            always: [], hidden: []
        };
    }

    public getProgressFilters(): DynamicGraphFilters {
        return this.settings.filters?.progress || {
            always: [], hidden: []
        };
    }

    public getOwnProgressFilters(): DynamicGraphFilters {
        return this.settings.filters?.ownProgress || {
            always: [], hidden: []
        };
    }

    public isThisGraphHasToBeFilteredByConcepts(graphIdentifier: string): boolean {
        const specificGraphSettings = this.settings.graphs[graphIdentifier];
        if (!!specificGraphSettings) {
            return specificGraphSettings.forceFilteringByConcept ?? false;
        }

        return false;
    }
}
